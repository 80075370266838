import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";

import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IInvoiceData,
  IInvoiceDataList,
  IInvoiceFilters,
  IInvoiceNFeResponse,
} from "../../../../types/main/invoice";
import { IPaginateResponse } from "../../../../types/main/pagination";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

interface IInvoiceFileResponse {
  base64: string;
  blobUrl: string;
}

const MODULE_BASE_URL = "/invoices";

type InvoiceListParams = {
  pagination?: PaginationState;
  columnFilters?: TableFilteredParams;
  sorting?: TableSortingParams;
  filters?: IInvoiceFilters;
};

export const getAllInvoices = async ({
  columnFilters,
  filters,
  pagination,
  sorting,
}: InvoiceListParams) => {
  try {
    const page = pagination?.pageIndex;

    const {
      data,
    }: AxiosResponse<IResponse<IPaginateResponse<IInvoiceDataList>>> =
      await recordApi.post(MODULE_BASE_URL, filters, {
        params: {
          page: page === undefined ? 1 : page + 1,
          take: pagination?.pageSize ?? 10,
          ...columnFilters,
          ...sorting,
        },
      });
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const createInvoices = async (tariffsIdGroups: IInvoiceData) => {
  try {
    const { data }: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(`${MODULE_BASE_URL}/save`, tariffsIdGroups);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getInvoiceNFe = async (
  invoiceId: number
): Promise<IInvoiceNFeResponse> => {
  try {
    const { data }: AxiosResponse<IResponse<IInvoiceNFeResponse>> =
      await recordApi.get(`${MODULE_BASE_URL}/nfe/${invoiceId}`);

    if (!data.body)
      return <IInvoiceNFeResponse>{
        nfes: [],
        message: "NFe não encontrada",
      };

    return data.body;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetInvoiceCsvAllUuids = async () => {
  try {
    const { data } = await recordApi.get(`getInvoiceCsvAllUuids`);
    return data;
  } catch (err: any) {
    return null;
  }
};

export const GetInvoiceCsvByIds = async (filters: TableFilteredParams) => {
  try {
    const { data } = await recordApi.post(`getInvoiceCsvByIds`, undefined, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};

export const getBlobInvoiceUrl = async ({ id }: { id: number }) => {
  try {
    const { data } = await recordApi.get<IInvoiceFileResponse>(
      `/invoicepdf/reducedInvoice/${id}`
    );

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const forceSendInvoiceEmail = async ({ id }: { id: number }) => {
  try {
    const { data } = await recordApi.post<IResponse<IResponse<void>>>(
      `${MODULE_BASE_URL}/${id}/send-emails`
    );
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
