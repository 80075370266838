export const defaultServiceAliquot = {
  serviceProvisionUuid: "",
  cofins: undefined,
  csll: undefined,
  ir: undefined,
  iss: undefined,
  pis: undefined,
  unique_aliquot: undefined,
  uuid: undefined,
};
