import { useCallback } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";

import { IChannelServicesProductsData } from "../../../../../../../types/main/channelServicesProducts";

interface IUseHiddenListOption {
  watch: UseFormWatch<FieldValues>;
  index: number;
}

export const useServiceProductHiddenListOption = ({
  watch,
  index,
}: IUseHiddenListOption) => {
  const currentServicesProducts = watch("servicesProducts");

  const thisService = watch(`servicesProducts[${index}].serviceProvisionUuid`);
  const thisProduct = watch(`servicesProducts[${index}].productUuid`);

  const isThisProductAlreadyChosen = useCallback(
    (productUuid: string) => {
      return currentServicesProducts.some(
        (item: IChannelServicesProductsData) =>
          productUuid !== thisProduct &&
          item?.productUuid === productUuid &&
          item?.serviceProvisionUuid === thisService
      );
    },
    [currentServicesProducts, thisProduct, thisService]
  );

  return {
    isThisProductAlreadyChosen,
  };
};
