import { Flex } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { PaymentMethodForm } from "../components/PaymentMethodForm";

interface IProductForm {
  isReadOnly?: boolean;
}

export const PaymentMethodManagement: React.FC<IProductForm> = ({
  isReadOnly,
}) => {
  const { uuid } = useParams();

  const isEditing = !!uuid;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Métodos de pagamento", href: "/paymentmethodslist" },
    {
      description: isReadOnly
        ? "Visualizar métodos de pagamento"
        : uuid
        ? "Editar métodos de pagamento"
        : "Cadastrar métodos de pagamento",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="2rem" color="gray.600">
          {isReadOnly
            ? "Visualizar métodos de pagamento"
            : isEditing
            ? "Editar métodos de pagamento"
            : "Cadastrar métodos de pagamento"}
        </HLarge2xl>
      </Flex>
      <PaymentMethodForm paymentMethodUUID={uuid} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
