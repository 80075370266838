import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Backdrop } from "../../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import {
  TableForm,
  ITableFormRefProps,
} from "../../../../../../main/components/TableForm";
import { TLargexl } from "../../../../../../main/components/Tipography";
import {
  IChannelEmailConfigData,
  IManageEmailConfig,
} from "../../../../../../types/main/channelEmailConfig";
import { emailConfigTableHeader } from "../../../constants/channelEmail.constants";
import { useChannelEmail } from "../../../hooks/ChannelEmail/useChannelEmail";
import { useFetchChannelEmailLists } from "../../../hooks/ChannelEmail/useFetchChannelEmailLists";
import { channemEmailSchema } from "../../../validation/channelEmailSchema";
import { WarningStep } from "../WarningStep";
import { CategoryConfigRow } from "./CategoryConfigRow";

interface IChannelForm {
  goBackToConfigTab: () => void;
  isReadOnly?: boolean;
  channelId?: string;
}

export const ChannelEmail = ({
  isReadOnly,
  channelId,
  goBackToConfigTab,
}: IChannelForm) => {
  const formMethods = useForm<IManageEmailConfig>({
    resolver: yupResolver(channemEmailSchema),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    formState: { isSubmitting, errors },
  } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const tableForm = useRef<ITableFormRefProps>(null);

  const [previousEmailConfig, setPreviousEmailConfig] = useState<
    IChannelEmailConfigData[]
  >([]);

  const channelEmailDataLists = useFetchChannelEmailLists({
    setIsLoading,
    channelId,
  });

  const { templateOptions, emailConfig, hasFetchError } = channelEmailDataLists;

  const { onSubmit } = useChannelEmail({
    channelId,
    setIsLoading,
    templateOptions,
  });

  const isEmailConfigInherited = watch("isEmailConfigInherited");

  useEffect(() => {
    if (emailConfig && templateOptions) {
      setValue("isEmailConfigInherited", emailConfig.isEmailConfigInherited);
      setValue("emailConfigs", emailConfig.emailConfigs);
      setPreviousEmailConfig(emailConfig.emailConfigs);
    }
  }, [emailConfig, setValue, templateOptions]);

  useEffect(() => {
    if (!isEmailConfigInherited) {
      setValue("emailConfigs", previousEmailConfig);
    }
  }, [isEmailConfigInherited, previousEmailConfig, setValue]);

  if (!channelId || hasFetchError) {
    return (
      <WarningStep
        goTo={!hasFetchError ? goBackToConfigTab : undefined}
        hasError={hasFetchError}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Grid
          h="100%"
          templateRows="(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
        >
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.isEmailConfigInherited}>
              {" "}
              <FormLabel htmlFor="isEmailConfigInherited">
                Herdar configurações?
              </FormLabel>
              <BooleanRadioGroup
                isReadOnly={isReadOnly}
                fieldname="isEmailConfigInherited"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
              <FormErrorMessage>
                {errors.isEmailConfigInherited &&
                  errors.isEmailConfigInherited.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <TLargexl mb="1em" mt="4rem" alignSelf="flex-start" textAlign="left">
          Configuração por Categoria de Produto/Serviço
        </TLargexl>
        <Grid
          h="100%"
          templateRows="(1, 1fr)"
          templateColumns="1fr"
          gap={4}
          opacity={isEmailConfigInherited ? 0.8 : 1}
        >
          <GridItem colSpan={1}>
            <TableForm
              ref={tableForm}
              tableHeader={emailConfigTableHeader}
              rowReferenceName="emailConfigs"
              canDeleteRow={false}
              canAddRow={false}
              isReadOnly={isReadOnly || isEmailConfigInherited}
              renderProp={(index: number) => (
                <CategoryConfigRow
                  isReadOnly={isReadOnly || isEmailConfigInherited}
                  index={index}
                  {...channelEmailDataLists}
                />
              )}
            />
          </GridItem>
        </Grid>

        <Flex w="100%" justify="flex-end" mb="2rem" mt="2rem">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/channelslist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                Confirmar alterações
              </Button>
            )}
          </ButtonGroup>
        </Flex>
        {isLoading && <Backdrop />}
      </form>
    </FormProvider>
  );
};
