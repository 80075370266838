import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const ButtonSubmit = ({
  contractUUID,
  isSubmitting,
  isDisabled,
}: {
  contractUUID?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <Flex w="100%" justify="flex-end" marginBottom="4rem">
      <ButtonGroup spacing="58" alignItems="center">
        <Link to="/contractlist">
          <Button variant="link" color="gray.700">
            Cancelar
          </Button>
        </Link>
        {!isDisabled && (
          <Button
            backgroundColor="blue.500"
            type="submit"
            width="214px"
            isLoading={isSubmitting}
            ml="32px"
          >
            {contractUUID ? "Editar contrato" : "Cadastrar contrato"}
          </Button>
        )}
      </ButtonGroup>
    </Flex>
  );
};
