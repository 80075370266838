import { DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useFieldArray, useFormContext } from "react-hook-form";

const MAX_BOLETO_INSTRUCTIONS = 5;
const MIN_BOLETO_INSTRUCTIONS = 0;

type BoletoInstructionsProps = {
  isReadOnly?: boolean;
};

export const BoletoInstructions = ({ isReadOnly }: BoletoInstructionsProps) => {
  const { control, register, formState } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: "instructions", // unique name for your Field Array
  });

  const handleAdd = () => {
    if (fields.length < MAX_BOLETO_INSTRUCTIONS) {
      append({});
    }
  };

  const handleRemove = (index: number) => {
    if (fields.length > MIN_BOLETO_INSTRUCTIONS) {
      remove(index);
    }
  };

  const canAdd = fields.length < MAX_BOLETO_INSTRUCTIONS && !isReadOnly;
  const canRemove = fields.length > MIN_BOLETO_INSTRUCTIONS && !isReadOnly;

  return (
    <Flex flexDir="column" gap="10px">
      <Flex gap="10px" w="100%" flexWrap="wrap">
        {fields &&
          fields.map((field, index) => {
            return (
              <FormControl
                width="calc(33.33% - 10px)"
                key={field.id}
                isInvalid={
                  Array.isArray(formState.errors.instructions) &&
                  !!formState.errors.instructions[index]
                }
              >
                <InputGroup>
                  <Input
                    {...register(`instructions[${index}].text`)}
                    maxLength={40}
                    readOnly={isReadOnly}
                  />
                  <InputRightElement
                    children={
                      <DeleteIcon
                        color="red.500"
                        onClick={() => handleRemove(index)}
                        cursor="pointer"
                        display={canRemove ? "block" : "none"}
                      />
                    }
                  />
                </InputGroup>
                <FormErrorMessage>
                  {Array.isArray(formState.errors.instructions) &&
                    formState.errors.instructions[index]?.text?.message}
                </FormErrorMessage>
              </FormControl>
            );
          })}
      </Flex>
      <Button
        onClick={handleAdd}
        width="100px"
        hidden={!canAdd}
        variant="outline"
      >
        Adicionar
      </Button>
    </Flex>
  );
};
