import { ResponseType } from "axios";
import { useRef, useState } from "react";
import { recordApi } from "../services/RecordService";

export interface IBlobError {
  response: {
    data: Blob | any;
  };
}

interface IDownloadFileProps {
  readonly apiDefinition: {
    endpoint: string;
    responseType?: ResponseType;
  };
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: (error: IBlobError) => Promise<void> | (() => void);
  readonly filename: string;
  readonly extension?: string;
  readonly viewUrlRequest?: string;
}

export const useAnchorDownload = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  filename,
  extension = "pdf",
  viewUrlRequest = "",
}: IDownloadFileProps) => {
  const { endpoint, responseType = "blob" } = apiDefinition;

  const ref = useRef<HTMLAnchorElement | null>(null);
  const [url, setFileUrl] = useState<string>();

  const callApi = async () => {
    return recordApi.get(endpoint, {
      responseType,
    });
  };

  const viewInNewTab = async () => {
    try {
      preDownloading();
      const { data } = await recordApi.get(viewUrlRequest);
      postDownloading();
      if (data?.url) {
        data?.url && window.open(data?.url, "_blank");
      } else {
        onError({ response: { data: "Erro ao buscar Boleto." } });
      }
    } catch (error: any) {
      if (error?.response?.statusCode === 401) {
        window.sessionStorage.clear();
        window.location.href = window.location.origin;
      }
      onError(error);
    }
  };

  const downloadFile = async () => {
    try {
      preDownloading();
      const { data } = await callApi();
      const url = URL.createObjectURL(new Blob([data]));
      setFileUrl(url);
      ref.current?.click();
      postDownloading();
      URL.revokeObjectURL(url);
    } catch (error: any) {
      if (error?.response?.statusCode === 401) {
        window.sessionStorage.clear();
        window.location.href = window.location.origin;
      }
      onError(error);
    }
  };

  return {
    downloadFile,
    ref,
    url,
    name: `${filename}.${extension}`,
    viewInNewTab,
  };
};
