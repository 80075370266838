import * as yup from "yup";

import { checkForSpecialChars } from "../../../../main/common/utils/checkStrings";

export const automaticDebitSchema = yup
  .object({
    paymentLayoutUuid: yup.string().required("Esse campo é obrigatório"),
    name: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    description: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    optingRequires: yup.string().required("Esse campo é obrigatório"),
    paymentBankUuid: yup.string().required("Esse campo é obrigatório"),
    nsa: yup
      .number()
      .lessThan(1000000000000000, "NSA deve conter no máximo 15 dígitos")
      .typeError("Esse campo é obrigatório")
      .required("Esse campo é obrigatório"),
    agreementCode: yup
      .string()
      .max(20, "Código de convênio deve conter no máximo 20 dígitos")
      .required("Esse campo é obrigatório"),
    paymentTransmissionChannel: yup
      .mixed()
      .test("required", "Esse campo é obrigatório", (value) => {
        const [arrayOfUuid] = value;
        if (arrayOfUuid && arrayOfUuid.length > 0) return true;
        return false;
      }),
    templateUuid: yup
      .mixed()
      .nullable()
      .test({
        test: (templateUuid, schema) => {
          const paymentTransmissionChannelContainEmail =
            schema.parent.paymentTransmissionChannel[1]?.filter(
              (item: string) => item === "E-mail"
            ).length === 1;
          if (paymentTransmissionChannelContainEmail && !templateUuid) {
            return false;
          }
          return true;
        },
      }),
  })
  .required();
