import * as yup from "yup";

import { checkForSpecialChars } from "../../../../main/common/utils/checkStrings";

export const serviceProvisionSchema = yup
  .object({
    name: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório")
      .min(4, "Nome deve conter no mínimo 4 caracteres."),
    issueInvoice: yup.boolean().required("Esse campo é obrigatório"),
    contributesToEfd: yup.boolean().required("Esse campo é obrigatório"),
  })
  .required();
