import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Backdrop } from "../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { IServiceProvisionData } from "../../../../../types/main/serviceProvision";
import { useExistingServiceProvision } from "../../hooks/useExistingServiceProvision";
import { useServiceProvisionForm } from "../../hooks/useServiceProvisionForm";
import { serviceProvisionSchema } from "../../validation/serviceProvisionSchema";

interface IServiceProvisionForm {
  isReadOnly?: boolean;
  serviceProvisionUUID?: string | undefined;
}

export const ServiceProvisionForm = ({
  isReadOnly,
  serviceProvisionUUID,
}: IServiceProvisionForm) => {
  const formMethods = useForm<IServiceProvisionData>({
    resolver: yupResolver(serviceProvisionSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { onSubmit, isLoading } = useServiceProvisionForm({
    reset,
    setValue,
    isReadOnly,
    serviceProvisionUUID,
  });

  const { existingServiceProvision } = useExistingServiceProvision({
    uuid: serviceProvisionUUID,
  });

  useEffect(() => {
    if (existingServiceProvision) {
      setValue("name", existingServiceProvision.name);
      setValue("issueInvoice", existingServiceProvision.issueInvoice);
      setValue("contributesToEfd", existingServiceProvision.contributesToEfd);
    }
  }, [existingServiceProvision, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          w="100%"
          h="100%"
          justifyContent="flex-start"
          gap="15px"
          flexWrap="wrap"
        >
          <FormControl
            isInvalid={!!errors.name}
            isReadOnly={isReadOnly}
            maxWidth="500px"
          >
            <FormLabel htmlFor="name">Descrição</FormLabel>
            <Input id="name" {...register("name")} isReadOnly={isReadOnly} />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            maxWidth="300px"
            isInvalid={!!errors.issueInvoice}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <FormLabel htmlFor="issueInvoice">Emite Nota Fiscal?</FormLabel>
            <BooleanRadioGroup
              isReadOnly={isReadOnly}
              fieldname="issueInvoice"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />

            <FormErrorMessage>
              {errors.issueInvoice && errors.issueInvoice.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl
            maxWidth="300px"
            isInvalid={!!errors.contributesToEfd}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <FormLabel htmlFor="contributesToEfd">
              Contribui para o EFD?
            </FormLabel>

            <BooleanRadioGroup
              isReadOnly={isReadOnly}
              fieldname="contributesToEfd"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
            <FormErrorMessage>
              {errors.contributesToEfd && errors.contributesToEfd.message}
            </FormErrorMessage>
          </FormControl>
        </Flex>
        <Flex width="100%" justifyContent="flex-end">
          <ButtonGroup
            spacing="58"
            alignItems="center"
            position="relative"
            mt="5rem"
          >
            <Link to="/serviceprovisionlist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>

            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                isDisabled={isReadOnly}
                ml="32px"
              >
                {existingServiceProvision
                  ? "Editar Serviço"
                  : "Cadastrar Serviço"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </FormProvider>
  );
};
