import { DEFAULT_REGEX_ONLY_NUMBERS_WITH_THREE_DECIMALS } from "../constants/defaultValidations";

export const validateNumberInput = (
  eventValue: string,
  regex = DEFAULT_REGEX_ONLY_NUMBERS_WITH_THREE_DECIMALS
) => {
  const testRegex = regex;

  return !!testRegex.test(eventValue);
};
