/* eslint-disable react/no-danger */
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";

export const PureError = ({ error }: { error: string }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const errorFormatted = useMemo(() => {
    try {
      if (!error) return null;

      return (
        JSON.stringify(JSON.parse(error), undefined, 2)?.replaceAll(
          "\\n",
          "\n\t"
        ) ?? ""
      );
    } catch (er) {
      return error;
    }
  }, [error]);

  return (
    <Flex justifyContent="center">
      <Button
        variant="outline"
        onClick={onOpen}
        colorScheme="red"
        hidden={!errorFormatted}
      >
        Erro
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} isCentered size="5xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody
            maxW="100%"
            wordBreak="break-all"
            overflow="auto"
            maxHeight={600}
          >
            <Flex
              as="pre"
              wordBreak="break-all"
              overflowWrap="break-word"
              whiteSpace="pre-wrap"
            >
              {errorFormatted}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};
