interface IOptions {
  label: string;
  value: string | number;
  checked?: boolean;
}

const MAX_VALUE_LENGTH_FIT_IN_CELL = 18;

export const getOnlyCheckedOptionsValueName = (optionsState: IOptions[]) => {
  return optionsState
    .filter((item) => !item.checked)
    .map((item) => item.value.toString());
};

const checkIfCellValueIsStringOrNumber = (cellValue: any) => {
  const isDate = !!Date.parse(cellValue);
  const isString = typeof cellValue === "string";
  const isNumber = typeof cellValue === "number";

  return !isDate && (isString || isNumber);
};

export const getTooltipContentAndCopyInfo = (cell: any) => {
  const cellValue = Array.isArray(cell.value)
    ? cell.value.join(", ")
    : cell.value;

  const isStringOrNumber = checkIfCellValueIsStringOrNumber(cellValue);

  const cellLength = cellValue?.length ?? 0;

  const shouldCopyValueTooBig =
    cellLength > MAX_VALUE_LENGTH_FIT_IN_CELL && isStringOrNumber;

  const titleToolTip = cellValue && isStringOrNumber ? cellValue : "";

  return {
    titleToolTip,
    shouldCopyValueTooBig,
  };
};
