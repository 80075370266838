import { Image } from "@chakra-ui/react";
import React from "react";

import { LogoWhite, LogoColor } from "../../common/assets";

interface ILogo {
  color: "white" | "black";
  width?: string;
  height?: string;
}

export const Logo: React.FC<ILogo> = ({
  color,
  height = "50px",
  width = "50px",
}: ILogo) => {
  const logo =
    color === "white" ? (
      <Image
        src={LogoWhite}
        width={width}
        height={height}
        maxW="100px"
        minW="80px"
      />
    ) : (
      <Image src={LogoColor} width={width} height={height} />
    );

  return logo;
};
