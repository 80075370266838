import * as yup from "yup";

export const massManualWriteOffSchema = yup
  .object({
    tariffCarIds: yup.string().required("Esse campo é obrigatório"),
    reasonForManualLow: yup.string().required("Esse campo é obrigatório"),
    writeOffReasons: yup.string().required("Esse campo é obrigatório"),
    dateOfLow: yup.string().required("Esse campo é obrigatório"),
    ignoreFineAndInterest: yup.boolean().required("Esse campo é obrigatório"),
  })
  .required();
