import { Button, Flex, FormControl, FormLabel, Input } from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";

export type CreditGrantedFiltersPayload = {
  remittanceId: string;
};

type Props = {
  loadReport: (payload: CreditGrantedFiltersPayload) => void;
};

export const CreditGrantedFilters = memo(({ loadReport }: Props) => {
  const formMethods = useForm<CreditGrantedFiltersPayload>();

  const {
    handleSubmit,
    formState: { errors },
    register,
  } = formMethods;

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(loadReport)}>
        <Flex
          w="100%"
          justifyContent="flex-start"
          alignItems="center"
          gap="15px"
          marginBlock="2rem"
        >
          <FormControl isInvalid={!!errors.remittanceId} maxWidth="200px">
            <FormLabel htmlFor="remittanceId">Remessa</FormLabel>
            <Input {...register("remittanceId")} />
          </FormControl>
          <Button alignSelf="flex-end" type="submit">
            Buscar
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
});
