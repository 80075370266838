import { AxiosResponse } from "axios";

import { accessApi } from "../../../../main/services/AccessService";
import { IProfile } from "../../../../types/main/profile";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import { IUser } from "../../../../types/main/user";

interface IUserDataDTO {
  fullName: string;
  username: string;
  profile: string;
  email: string;
}

interface IUserUpdateDTO {
  uuid: string;
  fullName: string;
  username: string;
  profile: {
    uuid: string;
  };
  email: string;
}

export const allUsers = async (): Promise<IResponse<Array<IUser>>> => {
  try {
    const response: AxiosResponse<IResponse<Array<IUser>>> =
      await accessApi.get(`users`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const deleteUser = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await accessApi.delete(`users/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewUser = async ({
  fullName,
  username,
  profile,
  email,
}: IUserDataDTO) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await accessApi.post(`users`, {
        fullName,
        username,
        profile,
        email,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const UpdateUser = async ({
  uuid,
  fullName,
  username,
  profile,
  email,
}: IUserUpdateDTO) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await accessApi.patch(`users`, {
        uuid,
        fullName,
        username,
        profile,
        email,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetUserByUUID = async (
  uuid: string
): Promise<IResponse<IUser>> => {
  try {
    const { data }: AxiosResponse<IResponse<IUser>> = await accessApi.get(
      `user`,
      {
        params: {
          uuid,
        },
      }
    );

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getProfiles = async () => {
  try {
    const response: AxiosResponse<IResponse<Array<IProfile>>> =
      await accessApi.get(`profiles`);

    return response;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
