import { format } from "date-fns";
import { useCallback, useState } from "react";

import {
  DatePickerFilter,
  DocumentColumnFilter,
} from "../../../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { getCreditGranted } from "../../../service/ReportService";
import { CreditGrantedFiltersPayload } from "./Filters";

export const useCreditGranted = () => {
  const [report, setReport] = useState<{
    data: Array<any>;
    isFetching: boolean;
  }>({
    data: [],
    isFetching: false,
  });

  const { columns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Remessa",
            accessor: "remittance",
          },
          {
            Header: "Número do Contrato",
            accessor: "contractnumber",
          },
          {
            Header: "Nome do Cliente",
            accessor: "customername",
          },
          {
            Header: "CPF/CNPJ",
            accessor: "document",
            Filter: DocumentColumnFilter,
            Cell: ({ value }: { value: string }) => {
              const document =
                value.length > 11
                  ? value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g,
                      "$1.$2.$3/$4-$5"
                    )
                  : value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{1,2})/g,
                      "$1.$2.$3-$4"
                    );

              return document;
            },
          },
          {
            Header: "Canal",
            accessor: "channel",
          },
          {
            Header: "Hierarquia",
            accessor: "parentchannel",
          },
          {
            Header: "Tipo de Serviço",
            accessor: "servicedescription",
          },
          {
            Header: "Produto",
            accessor: "productdescription",
          },
          {
            Header: "Data de Vencimento",
            accessor: "dueDate",
            filter: "date",
            Filter: DatePickerFilter,
            Cell: ({ value }) => {
              if (!value) return "";
              return format(new Date(value), "dd/MM/yyyy");
            },
          },
          {
            Header: "Valor Total",
            accessor: "totalreceived",
            Cell: ({ value }) => {
              if (!value) return "";
              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
        ],
      },
    ],
  });

  const loadReport = useCallback(
    async (payload: CreditGrantedFiltersPayload) => {
      setReport((old) => ({
        ...old,
        isFetching: true,
      }));

      const response = await getCreditGranted(payload);

      setReport({
        data:
          response.type === "success" && response.body
            ? response.body.data
            : [],
        isFetching: false,
      });
    },
    []
  );

  return {
    report,
    columns,
    loadReport,
  };
};
