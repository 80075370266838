import { Button, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { MultiSelect } from "../../../../../../main/components/MultiSelect";
import { TreeSelect } from "../../../../../../main/components/TreeSelect";
import { useBlockedClientsFilters } from "./useBlockedClientsFilters";

export type BlockedClientsFiltersPayload = {
  channels: number[];
  products: string[];
};

type Props = {
  loadReport: (payload: BlockedClientsFiltersPayload) => void;
};

export const BlockedClientsFilters = memo(({ loadReport }: Props) => {
  const formMethods = useForm<BlockedClientsFiltersPayload>();

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { channels, isFetching, products } = useBlockedClientsFilters();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(loadReport)}>
        <Flex
          w="100%"
          justifyContent="flex-start"
          alignItems="flex-end"
          gap="15px"
          marginBlock="2rem"
        >
          {!isFetching && (
            <>
              <FormControl isInvalid={!!errors.channels} maxWidth="500px">
                <TreeSelect
                  data={channels}
                  placeholder="Canais"
                  name="channels"
                  // onSelectChange={(value) => {
                  //   setValue("channels", value as number[]);
                  // }}
                  // setTreeData={setChannels}
                />
              </FormControl>

              <FormControl
                maxWidth="300px"
                isInvalid={!!errors.products}
                mb={2}
              >
                <FormLabel htmlFor="products">Produtos</FormLabel>
                <MultiSelect
                  buttonLabel="Produtos"
                  fieldname="products"
                  defaultOptions={products.map((item) => ({
                    label: item.name,
                    value: item.uuid,
                  }))}
                />
              </FormControl>
            </>
          )}

          <Button
            isLoading={isFetching}
            alignSelf="flex-end"
            mb="8px"
            type="submit"
          >
            Buscar
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
});
