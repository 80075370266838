import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  enumNameOfReports,
  IResponseReports,
} from "../../../../types/main/reports";
import { IResponse } from "../../../../types/main/response";
import { BlockedClientsFiltersPayload } from "../components/reports/BlockedClients/Filters";
import { CreditGrantedFiltersPayload } from "../components/reports/CreditGranted/Filters";
import { ReceiptsFiltersPayload } from "../components/reports/Receipts/Filters";
import { ReceivedChargesFiltersPayload } from "../components/reports/ReceivedCharges/Filters";
import { SendChargesFiltersPayload } from "../components/reports/SendCharges/Filters";
import { ReportsUnion } from "../pages/ReportList";

export const getReports = async (
  nameReport: keyof typeof enumNameOfReports
): Promise<IResponse<IResponseReports>> => {
  try {
    const {
      location: { origin },
    } = window;
    const response: AxiosResponse<IResponse<IResponseReports>> =
      await recordApi.get(`reports/${nameReport}`, {
        params: {
          origin,
        },
      });
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getBlockedClients = async (
  filters: BlockedClientsFiltersPayload
): Promise<IResponse<any>> => {
  try {
    const response: AxiosResponse<IResponse<any>> = await recordApi.post(
      `reports/blockedClients`,
      {
        ...filters,
      }
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getSendCharges = async (
  nameReport: ReportsUnion,
  payload: SendChargesFiltersPayload
): Promise<IResponse<any>> => {
  try {
    const {
      location: { origin },
    } = window;

    const response: AxiosResponse<IResponse<any>> = await recordApi.get(
      `reports/sendCharges`,
      {
        params: {
          nameReport,
          origin,
          ...payload,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getReceivedCharges = async (
  nameReport: ReportsUnion,
  filters: ReceivedChargesFiltersPayload
): Promise<IResponse<any>> => {
  try {
    const {
      location: { origin },
    } = window;

    const response: AxiosResponse<IResponse<any>> = await recordApi.post(
      `reports/receivedCharges`,
      {
        ...filters,
      },
      {
        params: {
          nameReport,
          origin,
          ...filters,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getReceipts = async (
  filters: ReceiptsFiltersPayload
): Promise<IResponse<any>> => {
  try {
    const response: AxiosResponse<IResponse<any>> = await recordApi.post(
      `reports/receipts`,
      filters
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getRepickReceipts = async (
  filters: ReceiptsFiltersPayload
): Promise<IResponse<any>> => {
  try {
    const response: AxiosResponse<IResponse<any>> = await recordApi.post(
      `reports/repickReceipts`,
      filters
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getEFD = async (): Promise<IResponse<any>> => {
  try {
    const response: AxiosResponse<IResponse<any>> = await recordApi.get(
      `reports/efd`
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getCreditGranted = async (
  filters: CreditGrantedFiltersPayload
): Promise<IResponse<any>> => {
  try {
    const response: AxiosResponse<IResponse<any>> = await recordApi.get(
      `reports/creditGranted`,
      {
        params: {
          ...filters,
        },
      }
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};
