/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  ArrowRightIcon,
  ArrowLeftIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@chakra-ui/icons";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  IconButton,
  Tooltip,
  Select,
  NumberInput,
  NumberInputField,
  Box,
  Skeleton,
} from "@chakra-ui/react";
import { lightFormat } from "date-fns";
import React from "react";
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";

import { isNumeric } from "../../common/utils/isNumeric";
import { DefaultColumnFilter, fuzzyTextFilterFn } from "../DataTable/Filters";
import { useDataTable } from "../DataTable/hooks/useDataTable";
import { HLargexs, TLargesm, TSmallsm } from "../Tipography";
import { ServerColumnFilter } from "./Filter";
import { IControlledDataTableProps } from "./IControlledDataTable";

import "./styles.css";

export function ControlledDataTable({
  columns,
  data,
  totalElements = 1,
  pageIndex,
  changePage,
  changeAmountPerPage,
  isLoading,
}: IControlledDataTableProps) {
  const { inputFilter } = useDataTable();

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      multi: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = Array.isArray(row.values[id])
            ? row.values[id].join()
            : row.values[id];
          return rowValue !== undefined ? filterValue.includes(rowValue) : true;
        });
      },
      date: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          try {
            const dateRowValue = lightFormat(new Date(rowValue), "dd/MM/yyyy");
            return rowValue !== undefined ? dateRowValue === filterValue : true;
          } catch (err) {
            return rowValue !== undefined ? rowValue === filterValue : true;
          }
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const filterRender = React.useMemo(
    () => ({
      makePlaceholderFilter(placeholder: any) {
        return inputFilter(placeholder);
      },
    }),
    [inputFilter]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data: data ?? [],
      defaultColumn,
      filterTypes,
      filterRender,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns]);
    }
  );

  const totalPages = Math.ceil(totalElements / pageSize);

  function controlNextPage() {
    changePage(pageIndex + 1);
  }

  function controlPreviousPage() {
    changePage(pageIndex - 1);
  }

  function goToPage(page: number) {
    if (page <= totalPages && page > 0) changePage(page);
  }

  function controlPageSize(pageSize: number) {
    changeAmountPerPage(pageSize);
    setPageSize(pageSize);
  }

  return (
    <>
      <Box
        className="custom-scroll"
        overflowX="auto"
        maxW="calc(100vw - (160px + 160px) )"
      >
        <Table
          {...getTableProps()}
          variant="striped"
          colorScheme="gray"
          size="md"
        >
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr
                height="36px"
                background="gray.100"
                {...headerGroup.getHeaderGroupProps({
                  className: "header-table-css-global",
                })}
              >
                {headerGroup.headers.map((column, index) => {
                  if (!columns[0].columns[index].hidden) {
                    return (
                      <Th
                        key={column.id}
                        paddingBlock={0}
                        isNumeric={isNumeric(column)}
                      >
                        <Flex alignItems="center" justifyContent="center">
                          <Box paddingBlock="12px">
                            <HLargexs textAlign="center">
                              {column.render("Header")}
                            </HLargexs>
                          </Box>
                        </Flex>
                      </Th>
                    );
                  }
                  return <Td key={column.id} p="0" />;
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {headerGroups.map((headerGroup) => (
              <Tr
                height="56px"
                {...headerGroup.getHeaderGroupProps({
                  className: "header-table-css-global",
                })}
              >
                {headerGroup.headers.map((column, i) => {
                  if (!columns[0].columns[i].hidden) {
                    return (
                      <Th {...column.getHeaderProps()}>
                        <TLargesm
                          as="span"
                          className="input-controlled-search"
                          ml="2.5%"
                        >
                          {column.canFilter ? (
                            <ServerColumnFilter
                              value={columns[0].columns[i].value}
                              setValue={columns[0].columns[i].setValue}
                              accessor={columns[0].columns[i].accessor}
                              filterType={columns[0].columns[i].filter}
                              multiOptions={columns[0].columns[i].multiOptions}
                            />
                          ) : null}
                        </TLargesm>
                      </Th>
                    );
                  }
                  return <Td key={column.id} p="0" />;
                })}
              </Tr>
            ))}
            {page.map((row) => {
              prepareRow(row);

              return (
                <Tr height="56px" {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    if (isLoading) {
                      return (
                        <Td key={cell.column.id}>
                          <Skeleton height="20px" />
                        </Td>
                      );
                    }
                    if (cell.column.id !== "hidden") {
                      return (
                        <Td {...cell.getCellProps()} textAlign="center">
                          <TLargesm>{cell.render("Cell")}</TLargesm>
                        </Td>
                      );
                    }
                    return <Td key={cell.column.id} p="0" />;
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </Box>

      <Flex justifyContent="space-between" m={4} alignItems="center">
        <Flex alignItems="center">
          <TSmallsm as="span" mr="8px">
            Exibir linhas por página
          </TSmallsm>
          <Select
            w="75px"
            value={pageSize}
            onChange={(e) => {
              controlPageSize(Number(e.target.value));
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex alignItems="center">
          <TSmallsm mr="8px">Ir para a página</TSmallsm>{" "}
          <NumberInput
            colorScheme="gray"
            w="60px"
            min={1}
            max={totalPages}
            focusBorderColor="gray.100"
            onChange={(value: any) => {
              const page = value ?? 1;
              goToPage(page);
            }}
            defaultValue={pageIndex}
          >
            <NumberInputField />
          </NumberInput>
          <Flex>
            <Tooltip label="First Page">
              <IconButton
                variant="unstyled"
                _focus={{ borderColor: "white" }}
                aria-label="First"
                onClick={() => goToPage(1)}
                isDisabled={pageIndex === 1}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label="Previous Page">
              <IconButton
                _focus={{ borderColor: "white" }}
                variant="unstyled"
                aria-label="Next"
                onClick={controlPreviousPage}
                isDisabled={pageIndex === 1}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>
          <Flex alignItems="center">
            <TSmallsm mr={8}>
              <TSmallsm as="span">{pageIndex}</TSmallsm> de{" "}
              <TSmallsm as="span">{totalPages}</TSmallsm>
            </TSmallsm>
          </Flex>
          <Flex>
            <Tooltip label="Next Page">
              <IconButton
                _focus={{ borderColor: "white" }}
                variant="unstyled"
                aria-label="Next"
                onClick={controlNextPage}
                isDisabled={pageIndex === totalPages}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
            <Tooltip label="Last Page">
              <IconButton
                _focus={{ borderColor: "white" }}
                variant="unstyled"
                aria-label="last"
                onClick={() => goToPage(totalPages)}
                isDisabled={pageIndex === totalPages}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}
