/* eslint-disable no-unreachable */
import { AccountInfo, IPublicClientApplication } from "@azure/msal-browser";
import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { DEFAULT_COMMUNICATION_ERROR_MESSAGE } from "../common/constants/defaultMessages";
import { setDefaultHeaders } from "../common/utils/setDefaultHeaders";
import { Backdrop } from "../components/Backdrop";
import { authUser } from "../services/LoginService";
import { authRequest } from "./LoginConfig";

interface ILoginError {
  isUserForbidden: boolean;
  forbiddenMessage: string;
}

interface IAuthContext {
  account: AccountInfo | undefined;
  permissionLevel: string;
  currentUuid: string;
  setPermissionLevel: React.Dispatch<string>;
  setCurrentUuid: React.Dispatch<string>;
  handleLogout: (instance: IPublicClientApplication) => void;
  handleLogin: () => void;
  loginError: ILoginError;
  isLoginLoading: boolean;
  setIsLoginLoading: React.Dispatch<boolean>;
}

interface IAuthProvider {
  children: ReactNode;
}

export const AuthContext = createContext({} as IAuthContext);

export const LoginProvider = ({ children }: IAuthProvider) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();

  const accountSignedIn = useAccount(accounts[0] || {});

  const [account, setAccount] = useState<AccountInfo | undefined>();

  const [permissionLevel, setPermissionLevel] = useState("");
  const [currentUuid, setCurrentUuid] = useState("");

  const [loginError, setLoginError] = useState({
    isUserForbidden: false,
    forbiddenMessage: "",
  });

  const [isLoginLoading, setIsLoginLoading] = useState(false);

  const handleLogout = useCallback((instance: IPublicClientApplication) => {
    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }, []);

  const handleLogin = useCallback(() => {
    if (!isAuthenticated) {
      instance
        .loginRedirect(authRequest)
        .catch((e) => {
          console.error(e);
        })
        .then(() => {
          console.info("Logged in with SSO Redirect");
        });
    } else {
      instance
        .ssoSilent(authRequest)
        .catch((e) => {
          console.error(`errror in refresh: ${e}`);
        })
        .then(() => {
          console.info("Logged in with SSO");
        });
    }
  }, [instance, isAuthenticated]);

  const checkUserEmailPermission = useCallback(async () => {
    try {
      setIsLoginLoading(true);

      const response = await authUser();

      setPermissionLevel(response.permissionLevel);
      setCurrentUuid(response.uuid);
      setDefaultHeaders(response.permissionLevel);

      setLoginError({
        isUserForbidden: false,
        forbiddenMessage: "",
      });
    } catch (error: any) {
      setLoginError({
        isUserForbidden: true,
        forbiddenMessage:
          error?.body?.message ?? DEFAULT_COMMUNICATION_ERROR_MESSAGE,
      });
    }
    setIsLoginLoading(false);
  }, [setCurrentUuid, setPermissionLevel]);

  useEffect(() => {
    if (accountSignedIn) {
      setAccount(accountSignedIn);
      checkUserEmailPermission();
    }
  }, [accountSignedIn, checkUserEmailPermission]);

  const context = useMemo(
    () => ({
      account,
      permissionLevel,
      currentUuid,
      setPermissionLevel,
      setCurrentUuid,
      handleLogout,
      isLoginLoading,
      loginError,
      setIsLoginLoading,
      handleLogin,
    }),
    [
      account,
      permissionLevel,
      currentUuid,
      handleLogout,
      isLoginLoading,
      loginError,
      handleLogin,
    ]
  );

  return (
    <AuthContext.Provider value={context}>
      {children}
      {isLoginLoading && <Backdrop />}
    </AuthContext.Provider>
  );
};
