import { Button, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { DatePicker } from "../../../../../../main/components/DateTimePicker/DatePicker";
import { TreeSelect } from "../../../../../../main/components/TreeSelect";
import { useReceivedChargesFilters } from "./useReceivedChargesFilter";

export type ReceivedChargesFiltersPayload = {
  channels: number[];
  startDate: string;
  endDate: string;
};

type Props = {
  loadReport: (payload: ReceivedChargesFiltersPayload) => void;
};

export const ReceivedChargesFilters = memo(({ loadReport }: Props) => {
  const formMethods = useForm<ReceivedChargesFiltersPayload>();

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { channels, isFetching } = useReceivedChargesFilters();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(loadReport)}>
        <Flex
          w="100%"
          justifyContent="flex-start"
          alignItems="flex-end"
          gap="15px"
          marginBlock="2rem"
        >
          {!isFetching && (
            <>
              <FormControl isInvalid={!!errors.channels} maxWidth="500px">
                <TreeSelect
                  data={channels}
                  placeholder="Canais"
                  name="channels"
                  // onSelectChange={(value) => {
                  //   setValue("channels", value as number[]);
                  // }}
                  // setTreeData={setChannels}
                />
              </FormControl>

              <FormControl
                maxWidth="300px"
                isInvalid={!!errors.startDate}
                mb={2}
              >
                <FormLabel htmlFor="startDate">Data Inicio</FormLabel>
                <DatePicker fieldname="startDate" />
              </FormControl>

              <FormControl maxWidth="300px" isInvalid={!!errors.endDate} mb={2}>
                <FormLabel htmlFor="endDate">Data Fim</FormLabel>
                <DatePicker fieldname="endDate" />
              </FormControl>
            </>
          )}

          <Button
            isLoading={isFetching}
            alignSelf="flex-end"
            mb="8px"
            type="submit"
          >
            Buscar
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
});
