import { Button, Flex } from "@chakra-ui/react";
import { GiFiles } from "react-icons/gi";
import { useNavigate } from "react-router-dom";

import { TSmallmd } from "../../../../../main/components/Tipography";

export function GoToProcessingRemittance({
  processingRemittanceIds,
}: {
  processingRemittanceIds: string | undefined;
}) {
  const navigate = useNavigate();
  const goToProcessingRemittance = () => {
    navigate("/processing-remittances", {
      state: { remittanceId: processingRemittanceIds },
    });
  };

  return (
    <Flex
      mt="2"
      flexDir="row"
      justifyContent="flex-start"
      gap="2"
      alignItems="center"
      color="#718096"
      as={Button}
      variant="unstyled"
      padding="0"
      onClick={goToProcessingRemittance}
    >
      <GiFiles />
      <TSmallmd fontWeight="medium">Ver Envios/Retornos</TSmallmd>
    </Flex>
  );
}
