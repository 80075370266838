import { Flex } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";

import { ITariffList } from "../../pages/TariffList";
import { TariffValues } from "./TariffValues";

export const TariffDetailCell = ({
  info,
}: {
  info: CellContext<ITariffList, any>;
}) => {
  const { original } = info.row;

  const valueAsCurrency = Number(info.getValue()).toLocaleString("pt-br", {
    style: "currency",
    currency: "BRL",
  });

  const MAX_VALUE_LENGTH_FOR_CELL = 14;

  const displayValue =
    valueAsCurrency.length > MAX_VALUE_LENGTH_FOR_CELL
      ? `${valueAsCurrency.slice(0, MAX_VALUE_LENGTH_FOR_CELL)}..`
      : valueAsCurrency;

  return (
    <Flex justifyContent="flex-start" alignItems="center" gap={4}>
      <TariffValues
        totalInterestReceived={original.totalTariff.totalInterestReceived}
        totalInterestProvided={original.totalTariff.totalInterestProvided}
        totalFineReceived={original.totalTariff.totalFineReceived}
        totalFineProvided={original.totalTariff.totalFineProvided}
        totalReceived={original.totalTariff.totalReceived}
        totalProvided={original.totalTariff.totalProvided}
      />
      {displayValue}
    </Flex>
  );
};
