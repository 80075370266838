import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ServiceProvisionForm } from "../components/ServiceProvisionForm/index";

interface IServiceProvisionManagement {
  isReadOnly?: boolean;
}

export const ServiceProvisionManagement = ({
  isReadOnly,
}: IServiceProvisionManagement) => {
  const { uuid } = useParams();

  const isEditingOrVisualizing = !!uuid;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e Consultas", href: "" },
    { description: "Serviços", href: "/serviceprovisionlist" },
    {
      description: isReadOnly
        ? "Visualizar Serviço"
        : uuid
        ? "Editar Serviço"
        : "Cadastrar Serviço",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600" mb="1em">
        {!isEditingOrVisualizing
          ? "Cadastrar Serviço"
          : isReadOnly
          ? "Visualizando Serviço"
          : "Editar Serviço"}
      </HLarge2xl>

      <ServiceProvisionForm
        serviceProvisionUUID={uuid}
        isReadOnly={isReadOnly}
      />
    </Wrapper>
  );
};
