import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Grid,
  ButtonGroup,
  FormErrorMessage,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { DEFAULT_REGEX_ESPECIAL_CHARACTERS } from "../../../../../main/common/constants/defaultValidations";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { IProductsData } from "../../../../../types/main/products";
import { useExistingProduct } from "../../hooks/useExistingProduct";
import { useGetAllCategory } from "../../hooks/useGetAllCategory";
import { useProducts } from "../../hooks/useProducts";
import { productSchema } from "../../validation/productSchema";

interface IProductForm {
  productUUID?: string;
  isReadOnly?: boolean;
}

export const ProductForm: React.FC<IProductForm> = ({
  productUUID,
  isReadOnly,
}) => {
  const { existingProductData } = useExistingProduct(productUUID);
  const { allcategory } = useGetAllCategory();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<IProductsData>({
    resolver: yupResolver(productSchema),
  });

  const { isLoading, onSubmitToCreateProduct, onSubmitToUpdateProduct } =
    useProducts(productUUID);

  useEffect(() => {
    if (existingProductData && allcategory) {
      setValue("category", existingProductData.category);
      setValue("name", existingProductData.name);
      setValue("productMask", existingProductData.productMask);
    }
  }, [existingProductData, getValues, allcategory, setValue]);

  return (
    <>
      <form
        onSubmit={handleSubmit(
          productUUID ? onSubmitToUpdateProduct : onSubmitToCreateProduct
        )}
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "5rem",
        }}
      >
        <Grid templateRows="(2, 1fr)" templateColumns="repeat(4, 1fr)" gap={4}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Descrição</FormLabel>
              <Input
                id="name"
                placeholder="Texto"
                {...(register("name"),
                {
                  onChange: (event: any) => {
                    setValue(
                      "name",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS,
                        ""
                      )
                    );
                    trigger();
                  },
                  value: watch("name"),
                })}
                isReadOnly={isReadOnly}
                autoComplete="off"
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.category}>
              <FormLabel htmlFor="category">Categoria de produto</FormLabel>
              <Input
                isDisabled={isReadOnly}
                id="category"
                placeholder="Selecionar categoria"
                {...register("category", {
                  onChange: (event: any) => {
                    setValue(
                      "category",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS,
                        ""
                      )
                    );
                    trigger();
                  },
                  value: watch("category"),
                })}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                autoComplete="off"
                list="listcategory"
              />
              <datalist id="listcategory">
                {allcategory &&
                  allcategory.map((item) => (
                    <option
                      aria-label="category"
                      key={`${item.category}-${item.productMask}`}
                      value={item.category}
                    />
                  ))}
              </datalist>
              <FormErrorMessage>
                {errors.category && errors.category.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl>
              <FormLabel htmlFor="mask">
                Mascara do identificador de Tarifa
              </FormLabel>
              <Tooltip
                hasArrow
                bg="blue.500"
                placement="bottom"
                label="O Campo Máscara é responsável pela gestão da visualização da formatação do produto dentro da Fatura em PDF. Esse campo pode ser null o que indica que não tem máscara."
              >
                <Input
                  isDisabled={isReadOnly}
                  id="mask"
                  placeholder="(XX) XXXXX-XXXX"
                  {...register("productMask")}
                  _disabled={{
                    cursor: "default",
                    color: "inherit",
                    opacity: "inherit",
                  }}
                  autoComplete="off"
                />
              </Tooltip>
              <small>Use &quot;X&quot; para representar os elementos</small>
            </FormControl>
          </GridItem>
        </Grid>
        <Flex w="100%" justify="flex-end">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/productlist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                {productUUID ? "Editar produto" : "Cadastrar produto"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </>
  );
};
