/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { GridLoader } from "react-spinners";
import { DataTable } from "../../../../main/components/DataTable";
import { DatePickerFilter } from "../../../../main/components/DataTable/Filters";
import { HLarge2xl, TSmallmd } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IRemittanceView } from "../../../../types/main/remittances";
import {
  IColumnDataTable,
  ITableColumns,
} from "../../../../types/main/reports";
import { DiscardInterestAndFineCell } from "../components/DiscardInterestAndFineCell";
import { DueDateCellEditable } from "../components/DueDateCellEditable";
import { ConfirmationModal } from "../components/Simulation/ConfirmationModal";
import { EmptyPaymentMethods } from "../components/Simulation/EmptyPaymentMethods";
import {
  getColumnValuesSumAsCurrency,
  getWarningFromRemittanceView,
} from "../functions/cells";
import { useSimulation } from "../hooks/useSimulation";

export const RemittanceSimulation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    remittanceSimulated,
    isLoading,
    saveRemittances,
    setRemittanceSimulates,
    hasCARWithNullPaymentMethod,
    isMountingTable,
    loadTariffsCarsFromFilters: refreshTable,
  } = useSimulation();

  const blockSaveButton = remittanceSimulated?.some(
    (remittance) => remittance.shouldBlockForInconsistentDueDate
  );

  const columns: IColumnDataTable[] = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Método de pagamento",
            accessor: "paymentMethodDescription",
          },
          {
            Header: "Canal",
            accessor: "channels",
            Cell: (cell: {
              row: { original: IRemittanceView };
              value: string | null;
            }) => {
              const { row, value } = cell;
              const total = row.original.countChannels;

              if (total > 1) return `${total} Canais`;
              return value;
            },
          },
          {
            Header: "Serviço",
            accessor: "services",
          },
          {
            Header: "Categoria de Produto",
            accessor: "productCategories",
          },
          {
            Header: "Vencimento",
            accessor: "remittanceDueDate",
            Filter: DatePickerFilter,
            filter: "date",
            Cell: (cell: {
              row: { original: IRemittanceView };
              value: string | null;
            }) => {
              const { row, value } = cell;
              const warning = getWarningFromRemittanceView(row.original);

              return (
                <DueDateCellEditable
                  key={row.original.key}
                  dueDate={value}
                  warning={warning}
                  remittance={row.original}
                  setRemittances={setRemittanceSimulates}
                />
              );
            },
          },
          {
            Header: "Qtd. Clientes",
            accessor: "countClients",
          },
          {
            Header: "Valor",
            accessor: "value",
            filter: "currency",
            Cell: (cell: any) =>
              cell.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            Header: "Desconsiderar Juros + Multa",
            accessor: "discardInterestAndFine",
            Cell: (cell: any) => {
              const { value, row } = cell;

              return (
                <DiscardInterestAndFineCell
                  discardInterestAndFine={value}
                  remittance={row.original}
                  setRemittances={setRemittanceSimulates}
                />
              );
            },
          },
        ] as ITableColumns[],
      },
      {
        Header: " ",
        hasFooter: true,
        sticky: "right",
        absoluteFooterLabel: "Valor total",
        columns: [
          {
            Header: "Juros",
            accessor: "totalInterest",
            filter: "currency",
            Cell: (cell: any) =>
              cell.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "totalInterest"),
          },
          {
            Header: "Multa",
            accessor: "totalFine",
            filter: "currency",
            Cell: (cell: any) => {
              return cell.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "totalFine"),
          },
          {
            Header: "Desconto",
            accessor: "totalDiscount",
            filter: "currency",
            Cell: (cell: any) => {
              return cell.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "totalDiscount"),
          },
          {
            Header: "Valor Total de Repick",
            accessor: "totalRepick",
            filter: "currency",
            Cell: (cell: any) =>
              cell.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "totalRepick"),
          },
          {
            Header: "Valor Total",
            accessor: "totalValue",
            filter: "currency",
            Cell: (cell: any) =>
              cell.value.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "totalValue"),
          },
        ] as ITableColumns[],
      },
    ],
    [setRemittanceSimulates]
  );

  const hasNullPaymentMethod =
    hasCARWithNullPaymentMethod.current &&
    hasCARWithNullPaymentMethod.current?.length > 0;

  return (
    <Wrapper>
      {hasNullPaymentMethod && (
        <EmptyPaymentMethods
          isLoading={isLoading}
          remittanceSimulation={hasCARWithNullPaymentMethod.current!}
        />
      )}
      <HLarge2xl mb="32px" color="gray.600">
        Simulação de Remessa
      </HLarge2xl>

      {isMountingTable && (
        <Alert status="info" my="2rem">
          <AlertIcon />
          <TSmallmd mr={4}>
            Dados obtidos, estamos montando a tabela para você.
          </TSmallmd>
          <GridLoader color="#2c58a4" size={6} />
        </Alert>
      )}

      <DataTable
        refreshTable={hasNullPaymentMethod ? refreshTable : undefined}
        module="Remittance"
        isLoading={isLoading}
        columns={columns}
        data={remittanceSimulated ?? []}
        showCheckbox={false}
      />
      <Flex
        justifyContent="flex-end"
        mb="2rem"
        gap="1rem"
        zIndex="0"
        position="relative"
        mt="100px"
      >
        <Link to="/remittance">
          <Button variant="ghost">Voltar aos Filtros</Button>
        </Link>
        <Tooltip
          label="Resolva as divergências antes de Gravar."
          bg="red.500"
          isDisabled={!blockSaveButton || isLoading}
        >
          <span>
            <Button
              width="200px"
              onClick={onOpen}
              disabled={blockSaveButton || isLoading}
            >
              Gravar
            </Button>
          </span>
        </Tooltip>
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          saveRemittances={saveRemittances}
        />
      </Flex>
    </Wrapper>
  );
};
