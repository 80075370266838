import { FormControl, Switch, Td, Tr } from "@chakra-ui/react";

import { IApi } from "../../../../../types/main/api";
import { useApiRow } from "./useApiRow";

interface IApisRow {
  api: IApi;
  isReadOnly?: boolean;
}

export const ApisRow = ({ api, isReadOnly }: IApisRow) => {
  const { apisWithPermission, handleApiPermission } = useApiRow({ api });

  return (
    <Tr
      borderBottomWidth="1px"
      borderBottomStyle="solid"
      borderBottomColor="blue.100"
    >
      <Td>{api.description}</Td>
      <Td>{api.endpoint}</Td>
      <Td>{api.method}</Td>
      <Td>
        <FormControl>
          <Switch
            isReadOnly={isReadOnly}
            id="has-permission"
            isChecked={!!apisWithPermission}
            onChange={(e) => handleApiPermission(api.uuid, e.target.checked)}
          />
        </FormControl>
      </Td>
    </Tr>
  );
};
