import { useCallback, useEffect, useState } from "react";

import { customToast } from "../../../../main/common/utils/customToast";
import { IClientOptions } from "../../../../types/main/client";
import { IContracts, IContractsFields } from "../../../../types/main/contract";
import { IResponse } from "../../../../types/main/response";
import { getAllChannels } from "../../Channels/services/ChannelService";
import { getClientOptions } from "../../Clients/service/ClientService";
import { allProducts } from "../../Products/service/ProductService";
import { IUseGetAllProduct } from "../../shared/hooks/Products/useGetAllProducts";
import { GetContractByUUID } from "../service/ContractService";

export const useDataContracts = (uuid: string | undefined) => {
  const [allClientsList, setAllClientsList] = useState<Array<IClientOptions>>();

  const [existingContractData, setExistingContractData] = useState<
    IContractsFields | undefined
  >();

  const [allProductsList, setAllProductsList] =
    useState<Array<IUseGetAllProduct>>();

  const [allChannelsList, setAllChannelsList] =
    useState<Array<{ name: string; uuid: string; deletedAt?: Date }>>();

  const getExistingContract = useCallback(async (uuid?: string) => {
    try {
      let responseContract: IResponse<IContracts> = { type: "" };
      if (uuid) {
        responseContract = await GetContractByUUID(uuid);
        if (responseContract.body)
          setExistingContractData(responseContract.body);
      }

      const responseClient = await getClientOptions(
        undefined,
        responseContract.body?.clientUuid
      );

      setAllClientsList(responseClient);

      const responseProducts = await allProducts({
        return_even_if_deleted: responseContract?.body?.product?.uuid,
      });
      if (responseProducts.type === "success" && responseProducts.body) {
        const categoryList = responseProducts.body.map(
          ({ name, uuid, deletedAt }) => {
            return { name, uuid, deletedAt };
          }
        );
        setAllProductsList(categoryList);
      }

      const responseChannel = await getAllChannels({
        return_even_if_deleted: responseContract?.body?.channelId,
      });
      if (responseChannel.type === "success" && responseChannel.body) {
        const channelsList = responseChannel.body.map(
          ({ id, description, deletedAt }) => {
            return { name: description, uuid: id.toString(), deletedAt };
          }
        );
        setAllChannelsList(channelsList);
      }
    } catch (error: any) {
      customToast("error", error.message ?? "Não encontrado.");
    }
  }, []);

  useEffect(() => {
    // if (uuid) {
    getExistingContract(uuid);
    // }
  }, [getExistingContract, uuid]);

  return {
    existingContractData,
    allClientsList,
    allProductsList,
    allChannelsList,
  };
};
