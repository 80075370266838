import {
  IRemittanceListView,
  IRemittanceView,
} from "../../../../types/main/remittances";
import { WarningType } from "../../Tariff/constants/tariff.constants";

export const getColumnValuesSumAsCurrency = (
  rows: any[] | undefined,
  field: keyof IRemittanceView
) => {
  if (!rows) return 0;
  const sumValues = rows.reduce(
    (sum: number, row: any) => Number(row.values[field]) + sum,
    0
  );
  return sumValues.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

type WarningKeys = keyof typeof WarningType;

export const getWarningFromRemittanceView = (
  remittance: IRemittanceView
): WarningKeys | undefined => {
  if (
    remittance.hasExpiredDueDate &&
    remittance.shouldBlockForInconsistentDueDate
  )
    return "expired";
  if (
    remittance.hasMissingDueDate &&
    remittance.shouldBlockForInconsistentDueDate
  )
    return "missing";

  return undefined;
};

export const getWarningFromRemittanceListView = (
  remittance: IRemittanceListView
): WarningKeys | undefined => {
  if (remittance.hasExpiredDueDate) return "expired";
  if (remittance.hasMissingDueDate) return "missing";

  return undefined;
};
