export const DEFAULT_REGEX_ONLY_NUMBERS_WITH_THREE_DECIMALS =
  /^[0-9]+[,.]?[0-9]{0,3}?$/;

export const DEFAULT_REGEX_ONLY_NUMBERS_WITH_TWO_DECIMALS =
  /^[0-9]+[,.]?[0-9]{0,2}?$/;

export const DEFAULT_REGEX_ESPECIAL_CHARACTERS =
  /[`!@#$%^&*¨()+\-=\\[\]{};':"\\|,.<>\\/?~]/g;

export const DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED =
  /[`!@#$%^&*¨()+=\\[\]{};':"\\|,.<>\\/?~]/g;

export const DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUT_DOT_AND_AT =
  /[`!#$%^&*¨()+\-=\\[\]{};':"\\|,<>\\/?~]/g;

export const DEFAULT_REGEX_ESPECIAL_CHARACTERS_AND_NUMBERS =
  /[`!@#$%^&*¨()+\-=\\[\]{};':"\\|,.<>\\/?~0-9]/g;

export const DEFAULT_REGEX_ESPECIAL_CHARACTERS_AND_NUMBERS_HYPHEN_PERMITED =
  /[`!@#$%^&*¨()+=\\[\]{};':"\\|,.<>\\/?~0-9]/g;

export const DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUTH_MINUS_SIGN =
  /[`!@#$%^&*¨()+=\\[\]{};':"\\|,.<>\\/?~]/g;

export const DEFAULT_REGEX_ONLY_NUMBERS_WITHOUT_DECIMALS = /^[0-9]*$/;

export const DEFAULT_ISO_DATE_FORMAT =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
