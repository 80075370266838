import { BadgeTooltip } from "../../../../../../main/components/BadgeTooltip";
import { IProcessingRemittanceView } from "../../../../../../types/main/processingRemittance";
import { REMITTANCE_STATUS_LABEL } from "../../../constants/remittance";

export const StatusDescriptionCell = ({
  row,
}: {
  row: { original: IProcessingRemittanceView };
}) => {
  const { original } = row;

  return (
    <BadgeTooltip
      label={original.processingStatusPtBr}
      colorScheme={
        REMITTANCE_STATUS_LABEL[original.processingStatus]?.color ?? "gray"
      }
    />
  );
};
