import { Route, Routes } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_WEB_HOOKS_PERMISSION_LEVEL,
  GET_WEB_HOOKS_PERMISSION_LEVEL,
  UPDATE_WEB_HOOKS_PERMISSION_LEVEL,
} from "./constants/permissions";
import { WebHooksList } from "./pages/WebHooksList";
import { WebHooksManager } from "./pages/WebHooksManager";

export const WebHooksModule = () => {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_WEB_HOOKS_PERMISSION_LEVEL} />}
      >
        <Route path="/webhooks" element={<WebHooksList />} />
        <Route
          path="/viewwebhooks/:id"
          element={<WebHooksManager isReadOnly />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute permission={CREATE_WEB_HOOKS_PERMISSION_LEVEL} />
        }
      >
        <Route path="/managewebhooks" element={<WebHooksManager />} />
      </Route>
      <Route
        element={
          <PrivateRoute permission={UPDATE_WEB_HOOKS_PERMISSION_LEVEL} />
        }
      >
        <Route path="/managewebhooks/:id" element={<WebHooksManager />} />
      </Route>
    </Routes>
  );
};
