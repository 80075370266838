import { CellContext } from "@tanstack/react-table";
import { differenceInMinutes, format } from "date-fns";
import { ptBR } from "date-fns/locale";

const MIN_SYNC_TIME_SENIOR = 2;

export const getSeniorSyncMessage = (
  info: CellContext<any, any>,
  baseUpdatedAt: Date | undefined
) => {
  if (!baseUpdatedAt)
    return {
      message: "",
      cellValue: "",
    };

  const lastSync = info.getValue() ? new Date(info.getValue()) : null;

  if (!lastSync)
    return {
      message: "Não sincronizado",
      cellValue: "Não sincronizado",
    };

  const updatedAt = new Date(baseUpdatedAt);

  const minutesDifference = lastSync
    ? differenceInMinutes(updatedAt, lastSync)
    : 0;

  const message =
    minutesDifference <= MIN_SYNC_TIME_SENIOR ? "Atualizado" : "Desatualizado";

  const cellValue = format(lastSync, "dd/MM/yyyy", {
    locale: ptBR,
  });

  return {
    message,
    cellValue,
  };
};
