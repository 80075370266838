import { useToast } from "@chakra-ui/react";
import { useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IProcessingCarCancellation } from "../../../../types/main/processingCar";
import { cancelCAR } from "../../CARs/service/ProcessingCarService";

export const useAllProcessingCarTypeReasonForCancellationRequest = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitCarCancellation = async ({
    data,
    onClose,
    refreshTable,
    reloadCar,
  }: {
    data: Array<IProcessingCarCancellation>;
    onClose: () => void;
    refreshTable?: () => void;
    reloadCar?: () => void;
  }) => {
    try {
      setIsLoading(true);
      const response = await cancelCAR({ data });
      refreshTable?.();
      if (reloadCar) reloadCar();
      setTimeout(() => {
        onClose();
      }, 2000);
      toast({
        title: "Cancelamento efetuado.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return {
    isLoading,
    onSubmitCarCancellation,
  };
};
