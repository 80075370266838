import { useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NumberFormatProps } from "react-number-format";

import { ClientFormTextPerType } from "../../../../modules/Records/Clients/constants/ClientType.constant";
import { InputMask } from "../../InputMask";
import { CustomFilterProps } from "../TableHeader";

export const DocumentFilter = ({ column }: CustomFilterProps) => {
  const formMethods = useForm<{ document: string }>();

  const [document, setDocument] = useState<string | undefined>();

  const handleChange = ({ value }: NumberFormatProps) => {
    if (!value) {
      column.setFilterValue(value);
      return;
    }
    setDocument(value.toString());
  };

  const onSubmit = ({ document }: { document: string }) => {
    column.setFilterValue(document);
  };

  const formatMask = useMemo(() => {
    const filterValueString = document?.toString();

    if (filterValueString && filterValueString.length <= 11)
      return `${ClientFormTextPerType.PF.documentMask}#`;

    return ClientFormTextPerType.PJ.documentMask;
  }, [document]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={formMethods.handleSubmit(onSubmit)}>
        <InputMask
          fieldname="document"
          format={formatMask}
          mask=""
          fontSize="sm"
          onInputChange={handleChange}
        />
      </form>
    </FormProvider>
  );
};
