/* eslint-disable react/no-unstable-nested-components */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";

import { getDateFormatted } from "../../../../../../main/common/utils/dateHelper";
import { TableServer } from "../../../../../../main/components/DataTableServer";
import { IInvoicingSimulation } from "../../../../../../types/main/invoicing";
import { DueDateModal } from "./DueDateModal";

type DivergentDataProps = {
  invoicesSimulated: IInvoicingSimulation;
  isLoading: boolean;
};

export const DivergentDueDate = ({
  invoicesSimulated,
  isLoading,
}: DivergentDataProps) => {
  const columns: ColumnDef<any>[] = useMemo(
    () => [
      {
        header: "TARIFA",
        accessorKey: "ids",
        filterFn: "fuzzy",
        cell: (info) => {
          const { ids, updated } = info.row.original;
          return (
            <Box textDecor={updated ? "line-through" : "none"}>
              {ids.join(", ")}
            </Box>
          );
        },
      },
      {
        header: "Data de Vencimento",
        accessorKey: "dueDate",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => {
          const { dueDate } = info.row.original;
          return (
            <Box textDecor="none">{`${dueDate.slice(0, 2).join(", ")}...`}</Box>
          );
        },
      },
      {
        header: "Modificar",
        cell: (info) => {
          const { ids, dueDate } = info.row.original;
          return <DueDateModal ids={ids} date={dueDate.join()} />;
        },
      },
    ],
    []
  );

  const invoiceWithDivergentDueDate = useMemo(() => {
    return invoicesSimulated?.tariffsIdPerInvoice?.filter(
      ({ tariffsIdAndDueDateFromGroup }) => {
        const uniqueDueDates = Array.from(
          new Set(tariffsIdAndDueDateFromGroup.map(({ dueDate }) => dueDate))
        );

        return uniqueDueDates.length > 1 || uniqueDueDates[0] === null;
      }
    );
  }, [invoicesSimulated?.tariffsIdPerInvoice]);

  const rows = useMemo(
    () =>
      invoiceWithDivergentDueDate?.map(({ tariffsIdAndDueDateFromGroup }) => {
        const data = tariffsIdAndDueDateFromGroup.reduce(
          (acc, curr) => {
            const formattedDueDate =
              getDateFormatted(curr.dueDate) ?? "Não informado";

            return {
              ids: [...acc.ids, curr.id],
              dueDate: [...acc.dueDate, formattedDueDate],
            };
          },
          {
            ids: [] as number[],
            dueDate: [] as string[],
          }
        );

        return data;
      }),
    [invoiceWithDivergentDueDate]
  );

  return (
    <Accordion allowToggle pb={4}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              ⚠️ Há simulações com data de vencimento diferentes encontradas.
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <TableServer
            data={rows}
            defaultColumns={columns}
            isLoading={isLoading}
            minPaddingBlockTd={2}
            hideColumnVisibilityControl
            hidePerPageControl
            hidePageSkipControl
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
