import { format } from "date-fns";
import { useCallback, useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";

import { DatePicker } from "../../DateTimePicker/DatePicker";
import { CustomFilterProps } from "../TableHeader";

export const DateFilter = ({ column }: CustomFilterProps) => {
  const formMethods = useForm<{ date: Date }>();
  const location = useLocation();

  const onChange = useCallback(
    (date: Date | undefined) => {
      column.setFilterValue(date ? format(date, "yyyy-MM-dd") : undefined);
    },
    [column]
  );

  useEffect(() => {
    onChange(formMethods.getValues("date"));
  }, [formMethods, location.key, onChange]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <DatePicker fieldname="date" placement="auto" onDateChange={onChange} />
      </form>
    </FormProvider>
  );
};
