import { CheckCircleIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Flex,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { TSmallmd } from "../../../../../../main/components/Tipography";
import { IProcessingRemittanceView } from "../../../../../../types/main/processingRemittance";
import { confirmManualSending } from "../../../service/processingRemittancesService";

type ConfirmSendingProps = {
  row: { original: IProcessingRemittanceView };
};

export const ConfirmSending = ({ row }: ConfirmSendingProps) => {
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const handleConfirm = async () => {
    setIsLoading(true);
    const response = await confirmManualSending(row.original.id);
    toast({
      title: response.type === "success" ? "Confirmado" : "Erro",
      description: response.body?.message ?? "",
      duration: 5000,
      isClosable: true,
      status: response.type === "success" ? "success" : "error",
    });
    setIsLoading(false);

    if (response.type === "success") navigate("/processing-remittances");
  };

  if (
    row.original.type === "RECEIVED" ||
    !["WAITING", "ERROR"].includes(row.original.processingStatus)
  )
    return null;

  return (
    <Flex alignItems="center" justifyContent="center">
      <Button
        colorScheme="facebook"
        onClick={onOpen}
        title="Confirmar Envio como Manual?"
        opacity={0.95}
      >
        <CheckCircleIcon />
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        size="xl"
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              fontSize="lg"
              fontWeight="bold"
              textAlign="center"
            >
              Confirmar Remessa como Enviada Manualmente
            </AlertDialogHeader>

            <AlertDialogBody textAlign="center">
              <TSmallmd>
                Tem certeza que deseja confirmar a remessa como enviada
                manualmente?
              </TSmallmd>
              <TSmallmd color="red">
                O envio automático não irá considerar essa remessa após a ação.
              </TSmallmd>
            </AlertDialogBody>

            <AlertDialogFooter justifyContent="center">
              {!isLoading && (
                <>
                  <Button ref={cancelRef} onClick={onClose}>
                    Cancelar
                  </Button>
                  <Button colorScheme="green" onClick={handleConfirm} ml={3}>
                    Confirmar
                  </Button>
                </>
              )}

              {isLoading && <Spinner />}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Flex>
  );
};
