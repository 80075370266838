import { SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Td,
  Tooltip,
} from "@chakra-ui/react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { BooleanRadioGroup } from "../../../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputSelectGroup } from "../../../../../../../main/components/InputSelectGroup";
import { useTableFormRowHandleErrors } from "../../../../../../../main/hooks/useTableFormRowHandleErrors";
import { IPaymentOptionsGroup } from "../../../../../../../types/main/paymentMethods";
import { IProductOption } from "../../../../../../../types/main/products";
import { IServiceProvisionOption } from "../../../../../../../types/main/serviceProvision";
import { ComissionForm } from "./ComissionForm";
import { DateRuleForm } from "./DateRuleForm";
import { useServiceProductHiddenListOption } from "./useServiceProductHiddenListOption";
import { useShowFormattedState } from "./useShowFormattedState";

interface IRowForm {
  servicesList: IServiceProvisionOption[];
  productList: IProductOption[];
  paymentMethodsList: IPaymentOptionsGroup[];
  index: number;
  isReadOnly?: boolean;
}

export const ServiceProductRow = memo(
  ({
    index,
    servicesList,
    paymentMethodsList,
    productList,
    isReadOnly,
  }: IRowForm) => {
    const {
      register,
      watch,
      clearErrors,
      formState: { errors },
    } = useFormContext();

    const { getErrorMessage, hasError } = useTableFormRowHandleErrors({
      errors,
      index,
    });

    const { isThisProductAlreadyChosen } = useServiceProductHiddenListOption({
      index,
      watch,
    });

    const {
      commissionValueFormatted,
      thisCreditGuarantor,
      dueDateRuleFormatted,
      resetCreditGuarantorPaymentMethod,
    } = useShowFormattedState({ index });

    return (
      <>
        <Td paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl
            isInvalid={hasError("servicesProducts", `serviceProvisionUuid`)}
          >
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage(
                "servicesProducts",
                `serviceProvisionUuid`
              )}
              placement="bottom"
              fontSize="small"
            >
              <Select
                sx={{ "&>select": { width: "fit-content" } }}
                placeholder="-"
                fontSize="sm"
                disabled={isReadOnly}
                className="fit-content-select"
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                {...register(`servicesProducts[${index}].serviceProvisionUuid`)}
              >
                {servicesList &&
                  servicesList.map(({ name, uuid, deletedAt }) => (
                    <Box
                      as="option"
                      key={uuid}
                      value={uuid}
                      color={deletedAt ? "red.500" : "black"}
                    >
                      {deletedAt ? `(inativo) ${name}` : name}
                    </Box>
                  ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="100%" paddingInlineStart="1px" paddingInlineEnd="1px">
          <FormControl isInvalid={hasError("servicesProducts", `productUuid`)}>
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("servicesProducts", `productUuid`)}
              placement="bottom"
              fontSize="small"
            >
              <Select
                placeholder="-"
                fontSize="sm"
                disabled={isReadOnly}
                className="fit-content-select"
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                {...register(`servicesProducts[${index}].productUuid`)}
              >
                {productList &&
                  productList.map(({ name, uuid, deletedAt }) => (
                    <Box
                      as="option"
                      key={uuid}
                      value={uuid}
                      hidden={isThisProductAlreadyChosen(uuid)}
                      color={deletedAt ? "red.500" : "black"}
                    >
                      {deletedAt ? `(inativo) ${name}` : name}
                    </Box>
                  ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl
            isInvalid={hasError("servicesProducts", `paymentMethodUuid`)}
          >
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("servicesProducts", `paymentMethodUuid`)}
              placement="bottom"
              fontSize="small"
            >
              <InputSelectGroup
                menuPlacement="top"
                menuBodyPosition
                name={`servicesProducts[${index}].paymentMethodUuid`}
                option={paymentMethodsList}
                disabled={isReadOnly}
              />
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl isInvalid={hasError("servicesProducts", `dueDateRule`)}>
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("servicesProducts", `dueDateRule`)}
              placement="bottom"
              fontSize="small"
            >
              <Flex flexDir="row" justifyContent="center" alignItems="center">
                <Input
                  readOnly
                  type="text"
                  placeholder={dueDateRuleFormatted}
                  _placeholder={{
                    cursor: "default",
                    color: "inherit",
                    opacity: "inherit",
                  }}
                  _readOnly={{
                    cursor: "default",
                    color: "inherit",
                    opacity: "inherit",
                  }}
                />
                <Popover closeOnBlur={false}>
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <SettingsIcon
                          onClick={() =>
                            clearErrors(
                              `servicesProducts[${index}].dueDateRule`
                            )
                          }
                          marginLeft="-25px"
                          color="gray.600"
                          cursor="pointer"
                          zIndex={1}
                          transition="all 0.2s ease-in-out"
                          _hover={{
                            color: "gray.500",
                          }}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverBody>
                            <DateRuleForm
                              index={index}
                              onCancel={onClose}
                              isReadOnly={isReadOnly}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </Flex>
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl
            isInvalid={hasError("servicesProducts", `commissionValue`)}
          >
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("servicesProducts", `commissionValue`)}
              placement="bottom"
              fontSize="small"
            >
              <Flex flexDir="row" justifyContent="center" alignItems="center">
                <Input
                  readOnly
                  type="text"
                  placeholder={commissionValueFormatted}
                  _placeholder={{
                    cursor: "default",
                    color: "inherit",
                    opacity: "inherit",
                  }}
                  _readOnly={{
                    cursor: "default",
                    color: "inherit",
                    opacity: "inherit",
                  }}
                />
                <Popover closeOnBlur={false}>
                  {({ onClose }) => (
                    <>
                      <PopoverTrigger>
                        <SettingsIcon
                          onClick={() =>
                            clearErrors(
                              `servicesProducts[${index}].commissionValue`
                            )
                          }
                          marginLeft="-25px"
                          color="gray.600"
                          cursor="pointer"
                          zIndex={1}
                          transition="all 0.2s ease-in-out"
                          _hover={{
                            color: "gray.500",
                          }}
                        />
                      </PopoverTrigger>
                      <Portal>
                        <PopoverContent>
                          <PopoverBody>
                            <ComissionForm
                              index={index}
                              onCancel={onClose}
                              isReadOnly={isReadOnly}
                            />
                          </PopoverBody>
                        </PopoverContent>
                      </Portal>
                    </>
                  )}
                </Popover>
              </Flex>
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl
            isInvalid={hasError("servicesProducts", `creditGuarantor`)}
          >
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("servicesProducts", `creditGuarantor`)}
              placement="bottom"
              fontSize="small"
            >
              <BooleanRadioGroup
                isReadOnly={isReadOnly}
                display="flex"
                width="100%"
                justifyContent="center"
                fieldname={`servicesProducts[${index}].creditGuarantor`}
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                onCheckChange={resetCreditGuarantorPaymentMethod}
              />
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl
            isInvalid={hasError(
              "servicesProducts",
              `paymentMethodGuarantorUuid`
            )}
          >
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage(
                "servicesProducts",
                `paymentMethodGuarantorUuid`
              )}
              placement="bottom"
              fontSize="small"
            >
              <InputSelectGroup
                menuPlacement="top"
                menuBodyPosition
                name={`servicesProducts[${index}].paymentMethodGuarantorUuid`}
                option={paymentMethodsList}
                disabled={!thisCreditGuarantor || isReadOnly}
              />
            </Tooltip>
          </FormControl>
        </Td>
      </>
    );
  }
);
