import { Alert, AlertIcon, AlertTitle, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import useSWR from "swr";

import { countReceivableMissingPaymentMethod } from "../../../CARs/service/CARsService";
import { countPreSavedRemittances } from "../../service/remittanceService";

export const PaymentMethodProcessAlert = () => {
  const { data, isLoading } = useSWR(
    "/tariffcar/process/payment-methods/count",
    countReceivableMissingPaymentMethod,
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 15, // 15 seconds
    }
  );

  const showAlert = !isLoading && !!data && data > 0;

  if (!showAlert) return null;

  return (
    <Alert status="info" mb="32px" borderRadius={4}>
      <AlertIcon />
      <AlertTitle mr={2} fontWeight={500} color="#49505c">
        Contas a Receber sem Método de Pagamento a serem processadas:{" "}
        <b>{data}</b>.
        <Link to="/invoice-remittance-logs">
          <Button variant="link" colorScheme="facebook" ml={2}>
            Mais detalhes.
          </Button>
        </Link>
      </AlertTitle>
    </Alert>
  );
};

export const PreSavedRemittanceProcessAlert = () => {
  const { data, isLoading } = useSWR(
    "/remittances/pre-saved-count",
    countPreSavedRemittances,
    {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 15, //  15 seconds
    }
  );

  const showAlert = !isLoading && !!data && data > 0;

  if (!showAlert) return null;

  return (
    <Alert
      status="warning"
      marginBlock="16px"
      borderRadius={4}
      background="#edf2f7"
    >
      <AlertIcon color="#3182ce" />
      <AlertTitle mr={2} fontWeight={500} color="#49505c">
        Remessas pré-salvas da ultima simulação a serem geradas:
        <b> {data}</b>.
      </AlertTitle>
    </Alert>
  );
};
