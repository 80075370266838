import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
  Select,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Backdrop } from "../../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputColor } from "../../../../../../main/components/InputColor";
import { InputEditor } from "../../../../../../main/components/InputEditor";
import { InputFile } from "../../../../../../main/components/InputFile";
import { TLargelg } from "../../../../../../main/components/Tipography";
import {
  IChannelInvoiceConfigData,
  IManageInvoiceConfig,
} from "../../../../../../types/main/channelInvoiceConfig";
import { useChannelInvoice } from "../../../hooks/ChannelInvoice/useChannelInvoice";
import { useFetchChannelInvoice } from "../../../hooks/ChannelInvoice/useFetchChannelInvoice";
import { channelInvoiceSchema } from "../../../validation/channelInvoiceSchema";
import { LayoutView } from "../../LayoutView";
import { WarningStep } from "../WarningStep";

interface IChannelInvoiceProps {
  goBackToConfigTab: () => void;
  isReadOnly?: boolean;
  channelId?: string;
}

type ManageInvoiceConfig = Omit<IManageInvoiceConfig, "invoiceConfigs"> & {
  invoiceConfigs?: IChannelInvoiceConfigData;
};

export const ChannelInvoice = ({
  isReadOnly,
  goBackToConfigTab,
  channelId,
}: IChannelInvoiceProps) => {
  const formMethods = useForm<ManageInvoiceConfig>({
    resolver: yupResolver(channelInvoiceSchema),
  });

  const {
    handleSubmit,
    setValue,
    watch,
    register,
    formState: { isSubmitting, errors },
  } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const [isLayoutViewOpen, setIsLayoutViewOpen] = useState(false);

  const { fontsList, existingInvoiceConfig, hasFetchError } =
    useFetchChannelInvoice({
      setIsLoading,
      channelId,
    });

  const { onSubmit } = useChannelInvoice({
    channelId,
    setIsLoading,
  });

  useEffect(() => {
    if (existingInvoiceConfig && fontsList) {
      setValue(
        "isInvoiceConfigInherited",
        existingInvoiceConfig.isInvoiceConfigInherited
      );

      setValue("invoiceConfigs", existingInvoiceConfig.invoiceConfigs);
    }
  }, [existingInvoiceConfig, fontsList, setValue]);

  if (!channelId || hasFetchError) {
    return (
      <WarningStep
        goTo={!hasFetchError ? goBackToConfigTab : undefined}
        hasError={hasFetchError}
      />
    );
  }

  const isInvoiceConfigInherited = watch("isInvoiceConfigInherited");

  const formOpacity = isInvoiceConfigInherited ? 0.8 : 1;

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Grid
          h="100%"
          templateRows="(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          gap={4}
        >
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.isInvoiceConfigInherited}>
              <FormLabel htmlFor="isInvoiceConfigInherited">
                Herdar configurações?
              </FormLabel>
              <BooleanRadioGroup
                isReadOnly={isReadOnly}
                fieldname="isInvoiceConfigInherited"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
              <FormErrorMessage>
                {errors.isInvoiceConfigInherited &&
                  errors.isInvoiceConfigInherited.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={1} opacity={formOpacity}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.fontUuid}>
              <FormLabel htmlFor="invoiceConfigs.fontUuid">
                Fonte da Fatura
              </FormLabel>
              <Select
                placeholder="-"
                disabled={isReadOnly || isInvoiceConfigInherited}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                {...register("invoiceConfigs.fontUuid")}
              >
                {fontsList &&
                  fontsList.map(({ name, uuid, code }) => (
                    <option
                      key={uuid}
                      value={uuid}
                      style={{
                        fontFamily: code,
                      }}
                    >
                      {name}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage>
                {errors.invoiceConfigs?.fontUuid &&
                  errors.invoiceConfigs?.fontUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1} opacity={formOpacity}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.color}>
              <FormLabel htmlFor="invoiceConfigs.color">
                Cor da Fatura
              </FormLabel>
              <InputColor
                fieldname="invoiceConfigs.color"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.color &&
                  errors.invoiceConfigs?.color.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <TLargelg mb="1em" mt="2rem" display="flex">
          Cabeçalho
        </TLargelg>
        <Grid
          h="100%"
          templateRows="(1, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
          opacity={formOpacity}
        >
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.headerImage}>
              <FormLabel htmlFor="invoiceConfigs.headerImage">
                Imagem do Cabeçalho
              </FormLabel>
              <InputFile
                fieldname="invoiceConfigs.headerImage"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.headerImage &&
                  errors.invoiceConfigs?.headerImage.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          mt="1rem"
          h="100%"
          templateRows="(2, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={4}
          opacity={formOpacity}
        >
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.headerInformation}>
              <FormLabel htmlFor="invoiceConfigs.headerInformation">
                Informativo do cabeçalho
              </FormLabel>
              <Input
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
                type="text"
                {...register("invoiceConfigs.headerInformation")}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.headerInformation &&
                  errors.invoiceConfigs?.headerInformation.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.developerCompany}>
              <FormLabel htmlFor="invoiceConfigs.developerCompany">
                Informativo empresa desenvolvedora
              </FormLabel>
              <Input
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
                type="text"
                {...register("invoiceConfigs.developerCompany")}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.developerCompany &&
                  errors.invoiceConfigs?.developerCompany.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl
              isInvalid={!!errors.invoiceConfigs?.commissionDescription}
            >
              <FormLabel htmlFor="invoiceConfigs.commissionDescription">
                Descritivo de comissionamento - Retenção de Fiança
              </FormLabel>
              <Input
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
                type="text"
                {...register("invoiceConfigs.commissionDescription")}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.commissionDescription &&
                  errors.invoiceConfigs?.commissionDescription.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <TLargelg mb="1em" mt="2rem" display="flex">
          Rodapé
        </TLargelg>
        <Grid
          h="100%"
          templateRows="(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
          opacity={formOpacity}
        >
          <GridItem colSpan={1}>
            <FormControl
              isInvalid={!!errors.invoiceConfigs?.footerBackgroundImage}
            >
              <FormLabel htmlFor="invoiceConfigs.footerBackgroundImage">
                Imagem de fundo
              </FormLabel>
              <InputFile
                fieldname="invoiceConfigs.footerBackgroundImage"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.footerBackgroundImage &&
                  errors.invoiceConfigs?.footerBackgroundImage.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.footerLeftImage}>
              <FormLabel htmlFor="invoiceConfigs.footerLeftImage">
                Imagem da esquerda
              </FormLabel>
              <InputFile
                fieldname="invoiceConfigs.footerLeftImage"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.footerLeftImage &&
                  errors.invoiceConfigs?.footerLeftImage.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.footerRightImage}>
              <FormLabel htmlFor="invoiceConfigs.footerRightImage">
                Imagem da direita
              </FormLabel>
              <InputFile
                fieldname="invoiceConfigs.footerRightImage"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.footerRightImage &&
                  errors.invoiceConfigs?.footerRightImage.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.invoiceConfigs?.promotionalImage}>
              <FormLabel htmlFor="invoiceConfigs.promotionalImage">
                Imagem da promocional
              </FormLabel>
              <InputFile
                fieldname="invoiceConfigs.promotionalImage"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
              />
              <FormErrorMessage>
                {errors.invoiceConfigs?.promotionalImage &&
                  errors.invoiceConfigs?.promotionalImage.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={5} mt="1rem">
            <FormControl isInvalid={!!errors.invoiceConfigs?.footerText}>
              <FormLabel htmlFor="invoiceConfigs.footerText">
                Texto do rodapé
              </FormLabel>
              <InputEditor
                fieldname="invoiceConfigs.footerText"
                isReadOnly={isReadOnly || isInvoiceConfigInherited}
                editorFeatures="medium"
              />
              <FormErrorMessage mt="50px">
                {errors.invoiceConfigs?.footerText &&
                  errors.invoiceConfigs?.footerText.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Flex w="100%" justify="flex-end" mb="2rem" mt="4rem">
          <ButtonGroup spacing="58" alignItems="center">
            {!!existingInvoiceConfig?.invoiceConfigs && (
              <Button
                variant="outline"
                color="gray.700"
                onClick={() => setIsLayoutViewOpen(true)}
              >
                Visualizar Layout
              </Button>
            )}
            <Link to="/channelslist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                Confirmar alterações
              </Button>
            )}
          </ButtonGroup>
        </Flex>
        {!!existingInvoiceConfig?.invoiceConfigs && (
          <LayoutView
            isOpen={isLayoutViewOpen}
            onClose={() => setIsLayoutViewOpen(false)}
            channelId={channelId}
          />
        )}
        {isLoading && <Backdrop />}
      </form>
    </FormProvider>
  );
};
