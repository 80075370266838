import * as Yup from "yup";

export const webHooksSchema = Yup.object({
  status: Yup.boolean().required("Campo obrigatório."),
  url: Yup.string().required("Campo obrigatório."),
  headerKey: Yup.string().required("Campo obrigatório."),
  headerValue: Yup.string().required("Campo obrigatório."),
  timeOut: Yup.number().required("Campo obrigatório."),
  webhookType: Yup.string().required("Campo obrigatório."),
  productUuid: Yup.string().required("Campo obrigatório."),
  queueTimeOut: Yup.number().required("Campo obrigatório."),
  queueMaxRetries: Yup.number().required("Campo obrigatório."),
});
