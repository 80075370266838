import { InfoIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { format } from "date-fns";
import { debounceMyFunction } from "debounce-my-function";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { IconMessage } from "../../../../../../../main/common/assets";
import { customToast } from "../../../../../../../main/common/utils/customToast";
import { Backdrop } from "../../../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputSelectGroup } from "../../../../../../../main/components/InputSelectGroup";
import {
  HLargexs,
  HSmallmd,
} from "../../../../../../../main/components/Tipography";
import { IPaymentOptionsGroup } from "../../../../../../../types/main/paymentMethods";
import { enumProcessingStatusCar } from "../../../../../../../types/main/processingStatusCar";
import {
  calculateContractInterestAndFine,
  ICarUpdateData,
  updateCar,
} from "../../../../../CARs/service/CARsService";
import { CarManually } from "../../../../../shared/Components/Tariff/CarManually";
import {
  DEFAULT_VALIDATION_IN_REMITTANCE,
  DEFAULT_VALIDATION_TARIFF_OPEN,
} from "../../../../constants/tariff.constants";
import { cardCarSchema } from "../../../../validation/editCarSchema";
import { BadgeTariff, IBadgeTariff } from "../../BadgeTariff";
import { PopoverCar } from "./PopoverCar";

export interface ICardCarProps {
  isReadOnly?: boolean;
  uuid?: string;
  id?: number;
  paymentMethodUuid?: string;
  invoiceValue?: number;
  invoiceInterest?: number;
  invoiceFine?: number;
  invoiceDiscount?: number;
  invoiceTotal?: number;
  status?: IBadgeTariff;
  justification?: string;
  cancel: (empty?: any) => void;
  service?: string;
  allMethods?: IPaymentOptionsGroup[];
  discardInterestAndFine: boolean;
  hideModal?: boolean;
  reloadCar: () => void;
  enumProcessing: keyof typeof enumProcessingStatusCar;
  chargeUuid?: string | null;
  serviceInaactive?: boolean;
  dueDate?: Date | null;
  idContract?: number;
}

const CardCar = ({
  isReadOnly,
  uuid,
  paymentMethodUuid,
  invoiceInterest,
  invoiceFine,
  invoiceValue,
  invoiceTotal,
  status,
  justification,
  cancel,
  service,
  allMethods,
  discardInterestAndFine,
  hideModal,
  reloadCar,
  enumProcessing,
  chargeUuid,
  serviceInaactive,
  invoiceDiscount,
  dueDate,
  idContract,
  id,
}: ICardCarProps) => {
  const methods = useForm<ICarUpdateData>({
    resolver: yupResolver(cardCarSchema),
  });

  const [invoiceTotalState, setInvoiceTotalState] = useState("");
  const [invoiceInterestState, setinvoiceInterestState] = useState("");
  const [invoiceFineState, setinvoiceFineState] = useState("");
  const [firstLoad, setFirstLoad] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCarManuallyConfirm, setShowCarManuallyConfirm] = useState(false);

  const watchDiscardInterestAndFine = methods.watch("discardInterestAndFine");
  const watchDiscount = methods.watch("discount");
  const watchValue = methods.watch("value");

  useEffect(() => {
    methods.setValue("paymentMethodUuid", paymentMethodUuid);
    methods.setValue("value", invoiceValue || 0);
    methods.setValue("discardInterestAndFine", discardInterestAndFine);
    methods.setValue("discount", invoiceDiscount || 0);
    methods.setValue("invoiceTotal", invoiceTotal);
  }, [
    discardInterestAndFine,
    firstLoad,
    invoiceDiscount,
    invoiceValue,
    methods,
    paymentMethodUuid,
    invoiceTotal,
  ]);

  const handleUpdateCar = useCallback(
    async (data: ICarUpdateData, reload = true) => {
      try {
        await updateCar({ data, uuid });

        if (reload) reloadCar();

        customToast("success", "CAR atualizado com sucesso");
      } catch (err: any) {
        customToast("error", err?.message ?? "Erro ao atualizar CAR");

        return err?.response?.data;
      }
    },
    [reloadCar, uuid]
  );

  const onSubmitToUpdate = async (data: ICarUpdateData) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { invoiceInterest, invoiceFine, invoiceTotal, ...rest } = data;
    if (!data || data.invoiceTotal === undefined || data?.invoiceTotal < 0) {
      customToast(
        "error",
        "Valor total do contas a pagar não pode ser negativo"
      );

      return;
    }

    if (data?.invoiceTotal === 0) {
      setShowCarManuallyConfirm(true);
      handleUpdateCar({ ...rest }, false);
      return;
    }

    handleUpdateCar({ ...rest });
  };

  function formatNumberOrString(value: number | string | undefined) {
    if (!value && Number(value) !== 0) return "";
    return Number(value).toLocaleString("pt-br", {
      currency: "BRL",
      style: "decimal",
      minimumFractionDigits: 2,
    });
  }

  useEffect(() => {
    if (!firstLoad) {
      setInvoiceTotalState(formatNumberOrString(invoiceTotal));
      setinvoiceInterestState(formatNumberOrString(invoiceInterest));
      setinvoiceFineState(formatNumberOrString(invoiceFine));
      setFirstLoad(true);
    }
  }, [
    firstLoad,
    invoiceInterest,
    invoiceFine,
    invoiceTotal,
    invoiceValue,
    isReadOnly,
    invoiceDiscount,
  ]);

  const isInRemittance =
    !!chargeUuid && DEFAULT_VALIDATION_IN_REMITTANCE.includes(enumProcessing);

  const showPopover =
    !hideModal &&
    isReadOnly &&
    !!uuid &&
    !!service &&
    DEFAULT_VALIDATION_TARIFF_OPEN.includes(enumProcessing);

  const handleOnChangeDiscardInterestAndFine = useCallback(
    async (discardInterestAndFine: boolean) => {
      if (!idContract) return;
      const { type, body, message } = await calculateContractInterestAndFine({
        dueDate,
        idContract,
        invoiceValue: watchValue,
        invoiceDiscount: watchDiscount,
        discardInterestAndFine,
      });
      if (type !== "success") {
        customToast("error", message);
      } else {
        setinvoiceInterestState(formatNumberOrString(body?.invoiceInterest));
        methods.setValue("invoiceInterest", body?.invoiceInterest);
        setinvoiceFineState(formatNumberOrString(body?.invoiceFine));
        methods.setValue("invoiceFine", body?.invoiceFine);
        setInvoiceTotalState(formatNumberOrString(body?.invoiceTotal));
        methods.setValue("invoiceTotal", body?.invoiceTotal);
      }
    },
    [dueDate, idContract, methods, watchDiscount, watchValue]
  );

  const handleOnChangeValue = useCallback(
    async (value: any) => {
      setIsLoading(true);
      if (value.target.value !== "" && invoiceValue && dueDate && idContract) {
        const { type, body, message } = await calculateContractInterestAndFine({
          dueDate,
          idContract,
          invoiceValue: value.target.value,
          invoiceDiscount: watchDiscount,
          discardInterestAndFine: watchDiscardInterestAndFine,
        });
        if (type !== "success") {
          customToast("error", message);
        } else {
          setinvoiceInterestState(formatNumberOrString(body?.invoiceInterest));
          methods.setValue("invoiceInterest", body?.invoiceInterest);
          setinvoiceFineState(formatNumberOrString(body?.invoiceFine));
          methods.setValue("invoiceFine", body?.invoiceFine);
          setInvoiceTotalState(formatNumberOrString(body?.invoiceTotal));
          methods.setValue("invoiceTotal", body?.invoiceTotal);
        }
      }
      setIsLoading(false);
    },
    [
      dueDate,
      idContract,
      invoiceValue,
      methods,
      watchDiscount,
      watchDiscardInterestAndFine,
    ]
  );

  const handleOnChangeDiscount = useCallback(
    async (value: any) => {
      setIsLoading(true);
      if (value.target.value !== "" && invoiceValue && dueDate && idContract) {
        const { type, body, message } = await calculateContractInterestAndFine({
          dueDate,
          idContract,
          invoiceValue: watchValue,
          invoiceDiscount: value.target.value,
          discardInterestAndFine: watchDiscardInterestAndFine,
        });
        if (type !== "success") {
          customToast("error", message);
        } else {
          setinvoiceInterestState(formatNumberOrString(body?.invoiceInterest));
          methods.setValue("invoiceInterest", body?.invoiceInterest);
          setinvoiceFineState(formatNumberOrString(body?.invoiceFine));
          methods.setValue("invoiceFine", body?.invoiceFine);
          setInvoiceTotalState(formatNumberOrString(body?.invoiceTotal));
          methods.setValue("invoiceTotal", body?.invoiceTotal);
        }
      }
      setIsLoading(false);
    },
    [
      dueDate,
      idContract,
      invoiceValue,
      methods,
      watchValue,
      watchDiscardInterestAndFine,
    ]
  );

  const debounceHandleChangeValue = useMemo(
    () => debounceMyFunction(handleOnChangeValue, 1000),
    [handleOnChangeValue]
  );

  const debounceHandleChangeDiscount = useMemo(
    () => debounceMyFunction(handleOnChangeDiscount, 1000),
    [handleOnChangeDiscount]
  );

  if (showCarManuallyConfirm && uuid)
    return (
      <CarManually
        typeButton="button"
        reloadCar={reloadCar}
        uuidTariffCar={uuid}
        autoOpen
        setDate={new Date()}
        onCancelled={cancel}
        forcedManualLow
      />
    );

  return (
    <FormProvider {...methods}>
      {isLoading && <Backdrop />}
      <Grid
        as="form"
        onSubmit={
          isReadOnly ? (e) => e : methods.handleSubmit(onSubmitToUpdate)
        }
        borderRadius="6px"
        border="1px"
        borderColor="gray.200"
        backgroundColor="gray.50"
      >
        <Flex
          h="52px"
          justifyContent="space-between"
          backgroundColor="white"
          w="100%"
          alignItems="center"
          px="24px"
        >
          <HSmallmd mr="32px">CAR - {id}</HSmallmd>
          <Flex alignItems="center" gap="0px 10.5px">
            {justification && isReadOnly && (
              <Tooltip hasArrow label={justification} placement="top">
                <IconMessage />
              </Tooltip>
            )}
            {status && isReadOnly && (
              <BadgeTariff
                label={status.label}
                colorScheme={status.colorScheme}
              />
            )}
            {showPopover && !isInRemittance && dueDate && idContract && (
              <PopoverCar
                uuid={uuid}
                invoiceInterest={invoiceInterest || 0}
                invoiceFine={invoiceFine || 0}
                invoiceValue={invoiceValue || 0}
                invoiceDiscount={invoiceDiscount || 0}
                invoiceTotal={invoiceTotal || 0}
                paymentMethodUuid={paymentMethodUuid}
                service={service}
                allMethods={allMethods}
                discardInterestAndFine={discardInterestAndFine}
                reloadCar={reloadCar}
                enumProcessing={enumProcessing}
                serviceInaactive={serviceInaactive}
                dueDate={dueDate ?? new Date()}
                idContract={idContract}
              />
            )}
            {isInRemittance && (
              <Tooltip hasArrow label="Em Remessa aberta" placement="top">
                <InfoIcon color="blue.600" cursor="pointer" />
              </Tooltip>
            )}
            {!dueDate && (
              <Tooltip
                hasArrow
                label="Data de Vencimento não encontrada"
                placement="top"
              >
                <InfoIcon color="blue.600" cursor="pointer" />
              </Tooltip>
            )}
            {!isReadOnly && (
              <>
                <Button
                  variant="ghost"
                  colorScheme="gray"
                  size="sm"
                  onClick={() => cancel()}
                >
                  Cancelar
                </Button>
                <Button ml="13.5px" size="sm" type="submit">
                  Confirmar edição
                </Button>
              </>
            )}
          </Flex>
        </Flex>
        <Grid
          my="12px"
          templateColumns="repeat(2, 1fr)"
          templateRows="repeat(3, 1fr)"
          mx="24px"
          gap="12px 17px"
        >
          <GridItem>
            <FormControl
              isInvalid={!!methods.formState.errors.paymentMethodUuid}
            >
              <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
                Método de pagamento
              </HLargexs>
              <InputSelectGroup
                name="paymentMethodUuid"
                disabled={isReadOnly}
                option={allMethods ?? []}
              />

              <FormErrorMessage>
                {methods.formState.errors.paymentMethodUuid &&
                  methods.formState.errors.paymentMethodUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Serviço
            </HLargexs>
            <Input
              backgroundColor={isReadOnly ? "white" : "gray.100"}
              value={`${serviceInaactive ? "(inativo) " : ""}${service}`}
              isReadOnly
            />
          </GridItem>

          <GridItem>
            <FormControl
              isInvalid={!!methods.formState.errors.discardInterestAndFine}
            >
              <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
                Desconsiderar juros e multa
              </HLargexs>
              <Flex mt="12px">
                <BooleanRadioGroup
                  isReadOnly={isReadOnly}
                  fieldname="discardInterestAndFine"
                  options={[
                    { label: "Sim", value: true },
                    { label: "Não", value: false },
                  ]}
                  onCheckChange={
                    isReadOnly
                      ? undefined
                      : (e) => {
                          handleOnChangeDiscardInterestAndFine(e);
                        }
                  }
                />
              </Flex>
              <FormErrorMessage>
                {methods.formState.errors.discardInterestAndFine &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Confirmação de débito
            </HLargexs>

            <Input
              isReadOnly
              value={
                status?.dateOfLow
                  ? format(new Date(status.dateOfLow), "dd/MM/yyyy")
                  : ""
              }
              fontWeight="bold"
              backgroundColor={isReadOnly ? "white" : "gray.100"}
            />
          </GridItem>

          <GridItem>
            <FormControl isInvalid={!!methods.formState.errors.value}>
              <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
                Valor
              </HLargexs>
              <InputGroup>
                {isReadOnly ? (
                  <>
                    <InputLeftAddon children="R$" />
                    <Input
                      min="0"
                      isReadOnly
                      value={Number(invoiceValue).toLocaleString("pt-br", {
                        currency: "BRL",
                        style: "decimal",
                        minimumFractionDigits: 2,
                      })}
                      backgroundColor="white"
                    />
                  </>
                ) : (
                  <InputGroup>
                    <InputLeftAddon children="R$" />
                    <Input
                      type="number"
                      step="0.01"
                      min="0"
                      {...methods.register("value", {
                        required: "Campo obrigatório",
                        onChange: debounceHandleChangeValue,
                      })}
                      backgroundColor="white"
                    />
                  </InputGroup>
                )}
              </InputGroup>
              <FormErrorMessage>
                {methods.formState.errors.value && "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Juros
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                isReadOnly
                value={invoiceInterestState}
                backgroundColor={isReadOnly ? "white" : "gray.100"}
              />
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Multa
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                isReadOnly
                value={invoiceFineState}
                backgroundColor={isReadOnly ? "white" : "gray.100"}
              />
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Desconto
            </HLargexs>
            <InputGroup>
              {isReadOnly ? (
                <>
                  <InputLeftAddon children="R$" />
                  <Input
                    min={0}
                    isReadOnly
                    value={Number(invoiceDiscount).toLocaleString("pt-br", {
                      currency: "BRL",
                      style: "decimal",
                      minimumFractionDigits: 2,
                    })}
                    backgroundColor="white"
                  />
                </>
              ) : (
                <>
                  <InputLeftAddon children="R$" />
                  <Input
                    {...methods.register("discount", {
                      required: "Campo obrigatório",
                      onChange: debounceHandleChangeDiscount,
                    })}
                    backgroundColor="white"
                    type="number"
                    step="0.01"
                    min="0"
                  />
                </>
              )}
            </InputGroup>
          </GridItem>

          <GridItem colSpan={2}>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Valor total
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                isReadOnly
                value={invoiceTotalState}
                fontWeight="bold"
                backgroundColor={isReadOnly ? "white" : "gray.100"}
              />
            </InputGroup>
          </GridItem>
        </Grid>
      </Grid>
    </FormProvider>
  );
};

export { CardCar };
