import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography/HeadingLarge";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ServiceProviderForm } from "../components/ServiceProviderForm";

interface IServiceProviderManager {
  isReadOnly?: boolean;
}

export const ServiceProviderManager = ({
  isReadOnly,
}: IServiceProviderManager) => {
  const { uuid } = useParams();

  const isEditing = !!uuid && !isReadOnly;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Prestadores", href: "/serviceproviderlist" },
    {
      description: isReadOnly
        ? "Visualizar Prestador"
        : isEditing
        ? "Editar Prestador"
        : "Cadastrar Prestador",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="32px" color="gray.600">
          {isReadOnly
            ? "Visualizar Prestador"
            : isEditing
            ? "Editar Prestador"
            : "Cadastrar Prestador"}
        </HLarge2xl>
      </Flex>
      <ServiceProviderForm providerUUID={uuid} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
