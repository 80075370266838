import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import ReactQuill from "react-quill";

interface IInputEditor {
  fieldname: string;
  isReadOnly?: boolean;
  editorFeatures: "all" | "medium" | "basic" | "none";
}

const toolbarModule = {
  all: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      ["clean"],
    ],
  },
  medium: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  },
  basic: {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      ["clean"],
    ],
  },
  none: {
    toolbar: [],
  },
};

export const InputEditor = ({
  fieldname,
  isReadOnly,
  editorFeatures,
}: IInputEditor) => {
  const { clearErrors, setValue, watch } = useFormContext();

  const [content, setContent] = useState("");

  const handleChange = (contentValue: string, _: any, source: string) => {
    if (source === "user") {
      setContent(contentValue);
      setValue(fieldname, contentValue);
    }
  };

  const watchFieldname = watch(fieldname);

  useEffect(() => {
    if (watchFieldname) {
      setContent(watchFieldname);
    }
  }, [watchFieldname]);

  return (
    <ReactQuill
      onBlur={() => clearErrors(fieldname)}
      style={{
        height: "400px",
      }}
      theme="snow"
      modules={toolbarModule[isReadOnly ? "none" : editorFeatures]}
      id="fieldname"
      value={content}
      onChange={handleChange}
      readOnly={isReadOnly}
    />
  );
};
