import { AxiosResponse } from "axios";
import { TreeData } from "react-dropdown-tree-select";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IChannelData,
  IChannelList,
  IChannelsOption,
} from "../../../../types/main/channel";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/channels";

export const getAllChannels = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: number;
}) => {
  try {
    const { data }: AxiosResponse<IResponse<Array<IChannelList>>> =
      await recordApi.get(
        return_even_if_deleted
          ? `${MODULE_BASE_URL}?return_even_if_deleted=${return_even_if_deleted}`
          : MODULE_BASE_URL
      );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getChannelConfigById = async ({
  id,
  withProviderData,
}: {
  id: string;
  withProviderData?: boolean;
}) => {
  try {
    const { data }: AxiosResponse<IResponse<IChannelData>> =
      await recordApi.get(
        withProviderData
          ? `${MODULE_BASE_URL}/config/${id}?withProviderData=true`
          : `${MODULE_BASE_URL}/config/${id}`
      );

    return data.body ?? null;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const createNewChannel = async (channelData: IChannelData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, {
        ...channelData,
        servicesProducts: channelData.inheritance
          ? undefined
          : channelData.servicesProducts,
        parentId: channelData.parentId
          ? Number(channelData.parentId)
          : undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const updateChannel = async (channelData: IChannelData, id: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${id}`, {
        ...channelData,
        parentId: channelData.parentId
          ? Number(channelData.parentId)
          : undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getChannelOptions = async () => {
  try {
    const { data }: AxiosResponse<IResponse<Array<IChannelsOption>>> =
      await recordApi.get(`${MODULE_BASE_URL}/options`);

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getChannelOptionsTree = async () => {
  try {
    const { data }: AxiosResponse<IResponse<Array<TreeData>>> =
      await recordApi.get(`${MODULE_BASE_URL}/options/tree`);

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const deleteChannel = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
