import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { BillerForm } from "../components/BillerForm";

interface IBillerManagement {
  isReadOnly?: boolean;
}

export const BillerManagement = ({ isReadOnly }: IBillerManagement) => {
  const { uuid } = useParams();

  const isEditingOrVisualizing = !!uuid;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e Consultas", href: "" },
    { description: "Tarifadores", href: "/billerlist" },
    {
      description: isReadOnly
        ? "Visualizar Tarifador"
        : uuid
        ? "Editar Tarifador"
        : "Cadastrar Tarifador",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600" mb="1em">
        {!isEditingOrVisualizing
          ? "Cadastrar Tarifador"
          : isReadOnly
          ? "Visualizando Tarifador"
          : "Editar Tarifador"}
      </HLarge2xl>

      <BillerForm billerUUID={uuid} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
