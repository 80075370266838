import { recordApi } from "../../../../main/services/RecordService";
import { IUniqueWriteOffForm } from "../interfaces/unique-write-off.interface";

export const writeOffReceivable = async (
  data: IUniqueWriteOffForm,
  carUuid: string
) => {
  try {
    const response = await recordApi.post(
      `/tariffcar/write-off/${carUuid}`,
      data
    );
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
