import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { UseFormGetValues, UseFormSetValue } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { NewUser, UpdateUser } from "../service/UserService";

interface IUserData {
  fullName: string;
  username: string;
  profile: string;
  email: string;
}

interface IUseUserForm {
  setValue: UseFormSetValue<IUserData>;
  getValues: UseFormGetValues<IUserData>;
  userUuid?: string;
}

export const useUserForm = ({
  setValue,
  getValues,
  userUuid,
}: IUseUserForm) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  function getUsernameFromEmailFirstPart() {
    if (!getValues("username") && getValues("email").includes("@")) {
      setValue("username", getValues("email").split("@")[0]);
    }
  }

  const onSubmitToCreateUser = async (data: IUserData) => {
    try {
      setIsLoading(true);
      const { fullName, username, email, profile } = data;

      const newUser = {
        fullName,
        username,
        email,
        profile,
      };

      const response = await NewUser(newUser);
      toast({
        title: "Criação efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/userlist");
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const onSubmitToUpdateUser = async (data: IUserData) => {
    try {
      setIsLoading(true);
      if (!userUuid) return;
      const { fullName, username, email, profile } = data;

      const updateUser = {
        uuid: userUuid,
        fullName,
        username,
        email,
        profile: {
          uuid: profile,
        },
      };

      const response = await UpdateUser(updateUser);
      toast({
        title: "Atualização efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return {
    onSubmitToCreateUser,
    onSubmitToUpdateUser,
    getUsernameFromEmailFirstPart,
    isLoading,
    setIsLoading,
  };
};
