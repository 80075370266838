import { useCallback } from "react";
import { FieldValues, UseFormWatch } from "react-hook-form";

import { IServicePerProviderData } from "../../../../../types/main/servicePerProvider";

interface IUseHiddenListOption {
  watch: UseFormWatch<FieldValues>;
  index: number;
}

export const useAliquotsHiddenListOptions = ({
  watch,
  index,
}: IUseHiddenListOption) => {
  const currentServicesAliquotsChosen = watch("servicesPerProvider");

  const thisService = watch(
    `servicesPerProvider[${index}].serviceProvisionUuid`
  );

  const isThisServiceAlreadyChosen = useCallback(
    (serviceUuid: string) => {
      return currentServicesAliquotsChosen.some(
        (item: IServicePerProviderData) =>
          serviceUuid !== thisService &&
          item?.serviceProvisionUuid === serviceUuid
      );
    },
    [currentServicesAliquotsChosen, thisService]
  );
  return { isThisServiceAlreadyChosen };
};
