import { InputGroup, Menu, MenuButton, MenuList } from "@chakra-ui/react";
import "./date-picker.css";
import "react-day-picker/lib/style.css";
import React, { useCallback, useEffect, useRef } from "react";
import { DayModifiers } from "react-day-picker";
import DayPicker from "react-day-picker/DayPicker";
import { Controller, useFormContext } from "react-hook-form";
import { NumberFormatValues } from "react-number-format";

import {
  getDaysFullName,
  getFirstLetterOfDays,
  getMonthShortName,
} from "../../../common/utils/dateHelper";
import { TextInputMask } from "../../FormInputs/TextInputMask";
import { IRefProps } from "../../InputMask";
import { fromMonth, toMonth } from "./constants";
import { DayPickerClear } from "./DayPickerClear";
import { DayPickerNavbar } from "./DayPickerNavbar";
import { checkIfStringDateIsValid, getSelectedDate } from "./functions";
import { useDatePicker } from "./useDatePicker";
import { YearMonthForm } from "./YearMonthForm";

const dayShortNames = getFirstLetterOfDays();
const dayFullNames = getDaysFullName();
const monthNames = getMonthShortName();

interface IDatePicker {
  onClose?: () => void;
  onOpen?: () => void;
  isDisabled?: boolean;
  fieldname: string;
  placement?: "top" | "bottom" | "left" | "right" | "auto";
  onDateChange?: (date: Date | undefined) => void;
  minDateToday?: boolean;
  fontSize?: "xs" | "sm" | "md" | "lg" | "xl";
}

export const DatePicker: React.FC<IDatePicker> = ({
  onClose,
  onOpen,
  fieldname,
  isDisabled,
  placement = "bottom",
  onDateChange,
  fontSize = "md",
}) => {
  const { control, setError } = useFormContext();
  const { month, isOpen, setIsOpen, handleYearMonthChange } = useDatePicker({
    name: fieldname,
  });

  const handleDayClick = useCallback(
    (
      onChange: (...event: any[]) => void,
      day: Date | undefined,
      { selected }: DayModifiers,
      e?: React.MouseEvent<HTMLDivElement, MouseEvent>,
      customMonth?: { year?: number; month?: number }
    ) => {
      onChange(selected ? undefined : day);
      setIsOpen(false);

      if (customMonth) {
        handleYearMonthChange(undefined, customMonth);
      }

      if (onDateChange) onDateChange(day);
    },
    [handleYearMonthChange, onDateChange, setIsOpen]
  );

  const captionElement = useCallback(
    ({ date }: { date: Date }) => (
      <YearMonthForm
        date={date}
        name={fieldname}
        handleYearMonthChange={handleYearMonthChange}
      />
    ),
    [fieldname, handleYearMonthChange]
  );

  const input = useRef<IRefProps>(null);

  const checkIfIsValidDate = (values: NumberFormatValues) => {
    const isValid = checkIfStringDateIsValid(values.formattedValue);

    if (!isValid) {
      setError(fieldname, {
        message: "Informe uma data válida.",
      });
      return false;
    }
    return isValid;
  };

  useEffect(() => {
    if (isOpen && onOpen) onOpen();

    return () => {
      if (onClose) onClose();
    };
  }, [isOpen, onClose, onOpen]);

  return (
    <Menu
      isOpen={isOpen}
      closeOnBlur
      closeOnSelect
      placement={placement}
      strategy="fixed"
      preventOverflow
      onClose={() => setIsOpen(false)}
    >
      <MenuButton
        borderRadius="50"
        variant="solid"
        as={InputGroup}
        maxW="100%"
        onClick={() => {
          setIsOpen((oldState) => !oldState);
          if (input.current) input.current.focus();
        }}
      >
        <TextInputMask
          format="##/##/####"
          ref={input}
          name={fieldname}
          handleAllowance={checkIfIsValidDate}
          fontSize={fontSize}
          isDisabled={isDisabled}
        />
      </MenuButton>
      <MenuList padding={3} hidden={isDisabled} zIndex="overlay">
        <Controller
          name={fieldname}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <DayPicker
                showOutsideDays
                locale="pt-BR"
                selectedDays={getSelectedDate(value)}
                months={monthNames}
                month={month}
                fromMonth={fromMonth}
                toMonth={toMonth}
                weekdaysLong={dayFullNames}
                weekdaysShort={dayShortNames}
                firstDayOfWeek={0}
                todayButton="Hoje"
                onDayClick={(day, modifier, e) => {
                  handleDayClick(onChange, day, modifier, e);
                }}
                enableOutsideDaysClick
                navbarElement={DayPickerNavbar}
                captionElement={captionElement}
                onTodayButtonClick={(day, modifiers) =>
                  handleDayClick(onChange, day, modifiers)
                }
              />
            );
          }}
        />
        <DayPickerClear
          name={fieldname}
          onClear={() => {
            onDateChange?.(undefined);
            setIsOpen(false);
          }}
        />
      </MenuList>
    </Menu>
  );
};
