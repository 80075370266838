import { createContext, useContext, useMemo, useState } from "react";

interface ITableContext {
  isFilterOpen: boolean;
  open: () => void;
  close: () => void;
}

const TableContext = createContext({} as ITableContext);

export const TableProvider = ({ children }: { children: React.ReactNode }) => {
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const open = () => {
    setIsFilterOpen(true);
  };

  const close = () => {
    setIsFilterOpen(false);
  };

  const context = useMemo(
    () => ({ isFilterOpen, close, open }),
    [isFilterOpen]
  );

  return (
    <TableContext.Provider value={context}>{children}</TableContext.Provider>
  );
};

export const useTableContext = () => {
  const context = useContext(TableContext);

  if (!context) {
    throw new Error("useTableContext must be used within a TableProvider");
  }

  return context;
};
