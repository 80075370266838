import { Badge, Flex, ThemeTypings } from "@chakra-ui/react";

import { HSmallsm } from "../../../../../main/components/Tipography";

export interface IBadgeTariff {
  label: string;
  colorScheme: ThemeTypings["colorSchemes"];
  dateOfLow?: Date;
}
const BadgeTariff = ({ colorScheme, label }: IBadgeTariff) => {
  return (
    <Badge colorScheme={colorScheme} h="24px" borderRadius="6px">
      <Flex alignItems="center" h="24px" p="8px">
        <HSmallsm>{label}</HSmallsm>
      </Flex>
    </Badge>
  );
};
export { BadgeTariff };
