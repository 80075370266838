import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_BILLER_PERMISSION_LEVEL,
  GET_BILLER_PERMISSION_LEVEL,
  UPDATE_BILLER_PERMISSION_LEVEL,
} from "./constants/permissions";
import { BillerList } from "./pages/BillerList";
import { BillerManagement } from "./pages/BillerManagement";

export function BillerModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={CREATE_BILLER_PERMISSION_LEVEL} />}
      >
        <Route path="/managebiller" element={<BillerManagement />} />
      </Route>
      <Route
        element={<PrivateRoute permission={UPDATE_BILLER_PERMISSION_LEVEL} />}
      >
        <Route path="/managebiller/:uuid" element={<BillerManagement />} />
      </Route>
      <Route
        element={<PrivateRoute permission={GET_BILLER_PERMISSION_LEVEL} />}
      >
        <Route path="/billerlist" element={<BillerList />} />
        <Route
          path="/viewbiller/:uuid"
          element={<BillerManagement isReadOnly />}
        />
      </Route>
    </Routes>
  );
}
