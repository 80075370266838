import { ColorModeScript, ChakraProvider } from "@chakra-ui/react";
import React from "react";
import ReactDOM from "react-dom";

import { App } from "./main/App";
import { AuthProvider } from "./main/components";
import "./styles/styles.css";
import { AxiosInterceptor } from "./main/services/AxiosInterceptor";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { customTheme } from "./styles/theme";

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <AxiosInterceptor>
        <ChakraProvider theme={customTheme}>
          <ColorModeScript />
          <App />
        </ChakraProvider>
      </AxiosInterceptor>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

serviceWorker.unregister();

reportWebVitals();
