import { Flex, Spinner } from "@chakra-ui/react";

export const Backdrop = () => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      background="#0d29572e"
      position="fixed"
      width="105%"
      borderRadius="8px"
      height="105%"
      top="-5%"
      left="-2.5%"
      zIndex="overlay"
    >
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="blue.500"
        size="xl"
      />
    </Flex>
  );
};
