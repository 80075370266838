import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IPaymentLayout } from "../../../../types/main/paymentLayout";
import { allLayoutPaymentMethod } from "../service/PaymentMethodService";

export const useGetAllLayouts = () => {
  const toast = useToast();

  const [allLayout, setAllLayout] = useState<Array<IPaymentLayout>>();

  const updateBankList = useCallback(async () => {
    try {
      const response = await allLayoutPaymentMethod();
      if (response.type === "success" && response.body) {
        const list = response.body.map(({ name, paymentType, uuid }) => ({
          name,
          paymentType,
          uuid,
        }));
        setAllLayout(list);
      }
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    updateBankList();
  }, [updateBankList]);

  return { allLayout };
};
