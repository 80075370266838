import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import React from "react";

const redirectUri: string =
  process.env.NODE_ENV === "production"
    ? "https://ENV_HOST"
    : "http://localhost:3000";

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "544d53bf-5aab-4327-acd0-8bfa10793978",
    authority:
      "https://login.microsoftonline.com/8fff9011-8bd4-467b-b02a-94733be8f1a9",
    redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
};
export const authRequest = {
  scopes: ["openid", "profile"],
};
const pca = new PublicClientApplication(configuration);

export const AuthProvider: React.FC = ({ children }) => {
  return <MsalProvider instance={pca}>{children}</MsalProvider>;
};
