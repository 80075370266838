import {
  Button,
  ButtonGroup,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from "@chakra-ui/react";

interface IConfirmationPopoverProps {
  text: string;
  primaryButtonText: string;
  secondaryButtonText: string;
  triggerButtonText: string;
  onPrimaryButtonClick: () => void;
  title: string;
  disabled: boolean;
}

export const ConfirmationPopover = ({
  text,
  primaryButtonText,
  secondaryButtonText,
  triggerButtonText,
  onPrimaryButtonClick,
  title,
  disabled,
}: IConfirmationPopoverProps) => {
  const { isOpen, onToggle, onClose } = useDisclosure();

  const handleConfirm = () => {
    onPrimaryButtonClick();
    onClose();
  };
  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={onClose}
      placement="right"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <Button disabled={disabled} onClick={onToggle}>
          {triggerButtonText}
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>{text}</PopoverBody>
        <PopoverFooter display="flex" justifyContent="flex-end">
          <ButtonGroup size="sm">
            <Button variant="outline" onClick={onClose}>
              {secondaryButtonText}
            </Button>
            <Button colorScheme="red" onClick={handleConfirm}>
              {primaryButtonText}
            </Button>
          </ButtonGroup>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};
