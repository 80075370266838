import { Box } from "@chakra-ui/react";

export const CustomTableContainer = (props: any) => (
  <Box
    as="div"
    {...props}
    css={{
      overflowX: "auto",
      scrollbarWidth: "thin",
      scrollbarColor: "gray darkgray",
      "&::-webkit-scrollbar": {
        height: "12px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "darkgray",
        borderRadius: "5px",
        "&:hover": {
          backgroundColor: "gray",
        },
      },
    }}
  />
);
