import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import {
  ITableFormRefProps,
  ITableHeaderDataTableForm,
} from "../../../../main/components/TableForm";
import { IProviderData } from "../../../../types/main/providers";
import { IServicePerProviderData } from "../../../../types/main/servicePerProvider";
import { IServiceProvisionOption } from "../../../../types/main/serviceProvision";
import {
  newServiceProvider,
  updateServiceProvider,
} from "../services/ServiceProviderService";

interface IUseServiceProviderForm {
  simple_national: boolean;
  currentServicePerProvider: IServicePerProviderData[] | [];
  editProviderUUID?: string;
  isReadOnly?: boolean;
  serviceListLength?: number;
}

export const useServiceProviderForm = ({
  simple_national,
  currentServicePerProvider,
  editProviderUUID,
  isReadOnly,
  serviceListLength,
}: IUseServiceProviderForm) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const tableForm = useRef<ITableFormRefProps>(null);

  const [canAddRow, setCanAddRow] = useState(true);

  const onSubmit = useCallback(
    async ({
      providerData,
      servicesList,
    }: {
      providerData: IProviderData;
      servicesList: IServiceProvisionOption[];
    }) => {
      const validateDeletedAt = providerData.servicesPerProvider
        ?.map(
          // eslint-disable-next-line array-callback-return
          ({ serviceProvisionUuid }) => {
            return (
              servicesList
                // eslint-disable-next-line array-callback-return
                .map(({ name, uuid, deletedAt }) => {
                  if (serviceProvisionUuid === uuid && deletedAt) {
                    return name;
                  }
                })
                .find((name) => name)
            );
          }
        )
        .filter((name) => name);
      if (validateDeletedAt && validateDeletedAt.length > 0) {
        validateDeletedAt.forEach((name) => {
          toast({
            title: "Error",
            description: `${name} está inativo, selecione outro serviço ou contate o administrador`,
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        });
      } else {
        try {
          setIsLoading(true);

          let response;
          if (editProviderUUID)
            response = await updateServiceProvider(
              providerData,
              editProviderUUID
            );
          else response = await newServiceProvider(providerData);

          toast({
            title: "Efetuado com sucesso.",
            description: response.body?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          navigate("/serviceproviderlist");
          setIsLoading(false);
        } catch (error: any) {
          toast({
            title: "Ocorreu um problema.",
            description:
              error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          setIsLoading(false);
        }
      }
    },
    [editProviderUUID, navigate, toast]
  );

  const aliquotsTableHeader: ITableHeaderDataTableForm[] = useMemo<
    ITableHeaderDataTableForm[]
  >(() => {
    if (simple_national)
      return [
        {
          name: "SERVIÇO",
        },
        {
          name: "ALÍQUOTA GERAL",
        },
      ];
    return [
      {
        name: "SERVIÇO",
      },
      {
        name: "ALÍQUOTA COFINS",
      },
      {
        name: "ALÍQUOTA PIS",
      },
      {
        name: "ALÍQUOTA ISS",
      },
      {
        name: "ALÍQUOTA CSLL",
      },
      {
        name: "ALÍQUOTA IR",
      },
    ];
  }, [simple_national]);

  const setNewRowIfPossible = useCallback(
    (isReadOnly: boolean | undefined) => {
      if (isReadOnly || currentServicePerProvider.length === serviceListLength)
        setCanAddRow(false);
      else setCanAddRow(true);
    },
    [currentServicePerProvider.length, serviceListLength]
  );

  useEffect(() => {
    if (currentServicePerProvider) setNewRowIfPossible(isReadOnly);
  }, [currentServicePerProvider, isReadOnly, setNewRowIfPossible]);

  useEffect(() => {
    if (!editProviderUUID) tableForm.current?.addNewRow();
  }, [editProviderUUID]);

  return {
    isLoading,
    tableForm,

    aliquotsTableHeader,
    canAddRow,

    onSubmit,
  };
};
