import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { customToast } from "../../../../../../main/common/utils/customToast";
import { TSmallmd } from "../../../../../../main/components/Tipography";

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  saveRemittances: () => Promise<void>;
};

export const ConfirmationModal = ({
  saveRemittances,
  isOpen,
  onClose,
}: ConfirmationModalProps) => {
  const cancelRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    try {
      await saveRemittances();
    } catch (err: any) {
      setIsLoading(false);
      customToast("error", err?.body?.message, 5000);
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={isLoading}
      size="lg"
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          {isLoading ? "Gerando remessas..." : "Gerar Remessas?"}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody
          display="flex"
          flexDir="column"
          gap={4}
          alignItems="flex-start"
          justifyContent="center"
        >
          {!isLoading && (
            <>
              <TSmallmd>Confirmar a geração das Remessas.</TSmallmd>
              <TSmallmd
                bgColor="gray.200"
                padding="10px"
                borderRadius="5px"
                sx={{
                  "& strong": {
                    color: "blue.600",
                  },
                }}
              >
                <strong>Datas de Vencimento</strong> e{" "}
                <strong>Desconsiderar Juros + Multa </strong>
                serão atualizados caso tenham sofrido mudanças.
              </TSmallmd>
            </>
          )}

          {isLoading && (
            <>
              <TSmallmd>Aguarde enquanto geramos as remessas.</TSmallmd>
              <Spinner size="lg" color="green.500" alignSelf="center" />
            </>
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={onClose}
            colorScheme="gray"
            hidden={isLoading}
          >
            Cancelar ação
          </Button>
          <Button
            colorScheme="green"
            ml={3}
            onClick={confirm}
            disabled={isLoading}
          >
            Gerar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
