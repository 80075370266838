import { format_CPF_CNPJ } from "../../../../main/common/utils/format_CPF_CNPJ";
import {
  ITariffWithPaymentMethod,
  tariffStatusLabel,
} from "../../../../types/main/tariff";

export const formatTariffList = (tariffs: ITariffWithPaymentMethod[]) => {
  return tariffs.map(
    ({
      biller,
      contract,
      endDate,
      id,
      startDate,
      projectDescription,
      tariffIdentifier,
      tariffCar,
      totalTariff,
      tariffStatus,
      idProject,
      invoiceId,
      createdAt,
      blobName,
    }) => {
      const hasBoleto = tariffCar.some(
        ({ charge }) => charge?.boletoNossoNumero
      );
      const discount = tariffCar
        .map(({ discount, processingCar }) => {
          if (processingCar.processingStatusCar.enum !== "BLOCK") {
            return discount || 0;
          }
          return 0;
        })
        .reduce((accum, curr) => Number(accum) + Number(curr), 0);

      const tariffValue = tariffCar
        .map(({ value, processingCar }) => {
          if (processingCar.processingStatusCar.enum !== "BLOCK") {
            return value;
          }
          return 0;
        })
        .reduce((accum, curr) => Number(accum) + Number(curr), 0);

      return {
        tariffId: id.toString(),
        biller: biller.name,
        contract: contract.idContract,
        channel: contract.channel.description,
        channelParentId: contract.channel.parentId,
        clientName: contract.client.fullName,
        startDate,
        endDate,
        product: contract.product.name,
        projectDescription,
        tariffIdentifier,
        tariffValue,
        totalTariff,
        invoiceId,
        hasBoleto,
        status: tariffStatusLabel[tariffStatus.status].description ?? "",
        statusObject: tariffStatus,
        dateStatus: tariffStatus?.date || createdAt,
        idProject,
        remittanceIds: [
          ...new Set(
            tariffCar
              .map(({ charge }) => [charge?.remittance?.identifier])
              .flat()
              .filter((item) => item)
          ),
        ].join(","),
        discount,
        blobName,
        boletoNossoNumero: tariffCar
          .map(({ charge }) => {
            if (charge && charge.boleto) {
              return {
                nossoNumero: charge?.boleto?.nossoNumero,
              };
            }
            return null;
          })
          .filter((item) => item)[0],
        clientDocument: format_CPF_CNPJ(contract.client.document),
        createdAt,
      };
    }
  );
};
