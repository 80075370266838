/* eslint-disable react/no-unstable-nested-components */
import { Button, Flex } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { MODULES } from "../../../../main/common/constants/modules";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DueDateCell } from "../../../../main/components/DataTable/DueDateCell";
import { TableServer } from "../../../../main/components/DataTableServer";
import { useTableServer } from "../../../../main/components/DataTableServer/useTableServer";
import { LogAlert } from "../../../../main/components/LogAlert";
import { StatusSubtitle } from "../../../../main/components/StatusSubtitle";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IRemittanceListView } from "../../../../types/main/remittances";
import { AutoRemittance } from "../components/AutoRemittance";
import {
  PaymentMethodProcessAlert,
  PreSavedRemittanceProcessAlert,
} from "../components/ProcessAlert";
import { RemittancePopover } from "../components/RemittancePopover";
import { getWarningFromRemittanceListView } from "../functions/cells";
import { getAllRemittances } from "../service/remittanceService";

const StatusLabel = {
  "AGUARDANDO ENVIO": "yellow",
  "AGUARDANDO RETORNO": "orange",
  ENVIADO: "blue",
  CONCLUIDO: "green",
  CANCELADO: "red",
};

const StatusDescriptionCell = ({
  remittance,
  value,
}: {
  remittance: IRemittanceListView;
  value: string;
}) => {
  return (
    <BadgeTooltip
      label={`${value}`}
      colorScheme={StatusLabel[remittance.status] ?? "gray"}
    />
  );
};

export const RemittanceList = () => {
  const [isLoading, setIsLoading] = useState(false);

  const [remittances, setRemittances] = useState<{
    data: IRemittanceListView[];
    total: number;
  }>({
    data: [],
    total: 0,
  });

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer({
    pageCount: remittances.total,
  });

  const handleRemittanceList = useCallback(async () => {
    setIsLoading(true);
    const response = await getAllRemittances({
      pagination: paginationParams,
      columnFilters: columnFiltersParams,
      sorting: columnSortingParams,
    });

    if (response.type === "success" && response.body) {
      const remittanceList = response.body.data.map((remittance) => ({
        ...remittance,
        uuid: remittance.identifier.toString(),
      }));

      setRemittances({
        data: remittanceList,
        total: response.body.lastPage,
      });
    }
    setIsLoading(false);
  }, [columnFiltersParams, columnSortingParams, paginationParams]);

  const columns = useMemo<ColumnDef<IRemittanceListView, any>[]>(
    () => [
      {
        header: "Status",
        accessorKey: "status",
        cell: (info) => (
          <StatusDescriptionCell
            remittance={info.row.original}
            value={info.getValue()}
          />
        ),
        filterFn: "select",
        meta: [
          {
            value: "AGUARDANDO ENVIO",
            label: "Aguardando envio",
          },
          {
            value: "ENVIADO",
            label: "Enviado",
          },
          {
            value: "AGUARDANDO RETORNO",
            label: "Aguardando retorno",
          },
          {
            value: "CONCLUIDO",
            label: "Concluído",
          },
          {
            value: "CANCELADO",
            label: "Cancelado",
          },
        ],
        minSize: 50,
      },
      {
        header: "Identificador",
        accessorKey: "identifier",
      },
      {
        header: "Usuário",
        accessorKey: "username",
      },
      {
        header: "Data/Hora",
        accessorKey: "createdAt",
        filterFn: "date",
        cell: (info) => {
          return format(parseISO(info.getValue()), "dd/MM/yyyy - HH'h':mm", {
            locale: ptBR,
          });
        },
      },
      {
        header: "Método de pagamento",
        accessorKey: "paymentMethodDescription",
      },
      {
        header: "Canal",
        accessorKey: "channels",
        enableSorting: false,
        cell: (info) => {
          const total = info.row.original.countChannels;

          if (total > 1) return `${total} Canais`;
          return info.getValue();
        },
      },
      {
        header: "Serviço",
        accessorKey: "services",
        enableSorting: false,
        cell: (info) => {
          return info.getValue().join(", ");
        },
      },
      {
        header: "Categoria de Produto",
        accessorKey: "productCategories",
        enableSorting: false,
        cell: (info) => {
          return info.getValue().join(", ");
        },
      },
      {
        header: "Vencimento",
        accessorKey: "dueDate",
        filterFn: "date",
        cell: (info) => {
          const warning = getWarningFromRemittanceListView(info.row.original);

          return <DueDateCell dueDate={info.getValue()} warning={warning} />;
        },
      },
      {
        header: "Qtd. Clientes",
        accessorKey: "countClients",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Valor",
        accessorKey: "value",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Desconsiderar Juros + Multa",
        accessorKey: "discardInterestAndFine",
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Juros",
        accessorKey: "totalInterest",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Multa",
        accessorKey: "totalFine",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },
      {
        header: "Valor Total",
        accessorKey: "totalValue",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) =>
          Number(info.getValue()).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
      },

      {
        header: "ID da Remessa",
        accessorKey: "uuid",
        startHidden: true,
        enableSorting: false,
      },
      {
        header: (info) => (
          <RemittancePopover
            onLoading={setIsLoading}
            refetch={handleRemittanceList}
            table={info.table}
          />
        ),
        id: "actions",
        cell: (info) => (
          <RemittancePopover
            onLoading={setIsLoading}
            refetch={handleRemittanceList}
            table={info.table}
            info={info}
          />
        ),
      },
    ],
    [handleRemittanceList]
  );

  useEffect(() => {
    handleRemittanceList();
  }, [handleRemittanceList]);

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Remessas",
      href: "/",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <PaymentMethodProcessAlert />
      <LogAlert
        logType="ILEGAL_CAR_UPDATE"
        text="Tentativas de atualizações ilegais de CAR interceptas:"
      />

      <LogAlert
        variant="warning"
        logType="GENERATING_REMITTANCE_FEBRABAN_FILE_ADDING_CHARGE"
        text="Cobranças removidas das remessas por estarem inválidas:"
      />

      <LogAlert
        logType="GENERATING_REMITTANCE_FEBRABAN_FILE"
        text="Arquivos de remessas inválidos:"
        variant="error"
      />
      <HLarge2xl mb="32px" color="gray.600">
        Remessas
      </HLarge2xl>
      <Flex
        justifyContent="flex-start"
        alignItems="center"
        mb="1rem"
        gap="10px"
      >
        <Link to="/processing-remittances">
          <Button variant="outline">Envios/Retornos</Button>
        </Link>
      </Flex>
      <Flex justifyContent="flex-end" alignItems="center" mb="1rem" gap="10px">
        <AutoRemittance />
        {/* <Box>
          <SimpleMenu label="Exibir colunas">
            <SelectableColumns />
          </SimpleMenu>
        </Box> */}
        <CreateNewDataButton
          module={MODULES.REMITTANCE}
          path="/remittance"
          text="Gravar novas Remessas"
        />
      </Flex>
      <TableServer
        refresh={() => handleRemittanceList()}
        data={remittances.data}
        defaultColumns={columns}
        isLoading={isLoading}
        columnFiltersState={columnFiltersState}
        paginationState={paginationState}
        sortingState={sortingState}
      />
      {/* <DataTable
        refreshTable={handleRemittanceList}
        module="Remittance"
        isLoading={isLoading}
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={remittances ?? []}
        showCheckbox={false}
        popup={{
          RenderComponent: RemittancePopover,
          refreshTable: handleRemittanceList,
          callToDelete: cancelRemittance,
        }}
      /> */}
      <StatusSubtitle />
      <PreSavedRemittanceProcessAlert />
    </Wrapper>
  );
};
