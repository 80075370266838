/* eslint-disable react/no-unstable-nested-components */
import { Flex, GridItem } from "@chakra-ui/react";
import {
  CellContext,
  ColumnDef,
  HeaderContext,
  PaginationState,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useState } from "react";

import { Backdrop } from "../../../../main/components/Backdrop";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import {
  TableFilteredParams,
  TableSortingParams,
  useTableServer,
} from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ITotalTariff } from "../../../../types/main/tariff";
import { TariffDetailCell } from "../../Tariff/components/TariffDetailCell";
import { ITariffList } from "../../Tariff/pages/TariffList";
import { allTariff } from "../../Tariff/service/TariffService";
import { PopoverList } from "../components/PopoverList";

export type IMovementsList = {
  tariffId: string;
  contract: number;
  clientName: string;
  invoiceId: number | undefined;
  totalTariff: ITotalTariff;
};

export const TariffMovementlist: React.FC = () => {
  const [movements, setMovements] = useState<{
    data: IMovementsList[];
    total: number;
  }>({
    data: [],
    total: 0,
  });
  const [isExporting, setIsExporting] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    paginationState,
    columnFiltersState,
    sortingState,
  } = useTableServer({
    pageCount: movements.total,
  });

  const handleList = async (
    pagination?: PaginationState,
    filters?: TableFilteredParams,
    sorting?: TableSortingParams
  ) => {
    setIsLoading(true);
    const response = await allTariff({
      filters,
      pagination,
      sorting,
    });
    if (response.type === "success" && response.body) {
      const list = response.body.data.map(
        ({ id, contract, invoiceId, totalTariff }) => {
          const valueReceived: number =
            totalTariff.totalInterestReceived +
            totalTariff.totalFineReceived +
            totalTariff.totalReceived;
          return {
            tariffId: id.toString(),
            contract: contract.idContract,
            clientName: contract.client.fullName,
            invoiceId,
            valueReceived,
            totalTariff,
          };
        }
      );
      setMovements({
        data: list,
        total: response.body.lastPage,
      });
    }
    setIsLoading(false);
  };

  const columns = useMemo<ColumnDef<ITariffList | IMovementsList, any>[]>(
    () => [
      {
        header: "TARIFA",
        accessorKey: "tariffId",
      },
      {
        header: "CONTRATO",
        accessorKey: "contract",
      },
      {
        header: "CLIENTE",
        accessorKey: "clientName",
      },
      {
        header: "FATURA",
        accessorKey: "invoiceId",
      },
      {
        header: "VALOR RECEBIMENTO",
        accessorKey: "valueReceived",
        cell: (info) => (
          <TariffDetailCell info={info as CellContext<ITariffList, any>} />
        ),
      },
      {
        header: (info) => {
          const { table } = info as HeaderContext<IMovementsList, any>;

          return (
            <PopoverList
              refetch={handleList}
              table={table}
              onLoading={setIsExporting}
            />
          );
        },
        id: "actions",
        cell: (info) => {
          const data = info as CellContext<IMovementsList, any>;
          const { table } = data;
          return (
            <PopoverList
              info={data}
              refetch={handleList}
              table={table}
              onLoading={setIsExporting}
            />
          );
        },
      },
    ],
    []
  );

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Faturamento", href: "" },
    { description: "Movimentações", href: "" },
  ];

  useEffect(() => {
    handleList(paginationParams, columnFiltersParams, columnSortingParams);
  }, [columnFiltersParams, columnSortingParams, paginationParams]);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Movimentações
          </HLarge2xl>
        </Flex>
        <TableServer
          refresh={handleList}
          defaultColumns={columns}
          data={movements.data}
          isLoading={isLoading}
          columnFiltersState={columnFiltersState}
          paginationState={paginationState}
          sortingState={sortingState}
        />
      </GridItem>
      {isExporting && <Backdrop />}
    </Wrapper>
  );
};
