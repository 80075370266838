import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  TableContainer,
  HStack,
  VStack,
  ButtonGroup,
  Flex,
  Switch,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";

import { DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED } from "../../../../../main/common/constants/defaultValidations";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { IApi } from "../../../../../types/main/api";
import { IBillerData } from "../../../../../types/main/biller";
import { useExistingBiller } from "../../hooks/useExistingBiller";
import { billerSchema } from "../../validation/billerSchema";
import { ApisRow } from "../apisRow";
import { useBillerForm } from "./useBillerForm";

interface IBillerForm {
  isReadOnly?: boolean;
  billerUUID?: string | undefined;
}

export const BillerForm = ({ isReadOnly, billerUUID }: IBillerForm) => {
  const formMethods = useForm<IBillerData>({
    resolver: yupResolver(billerSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
    watch,
  } = formMethods;

  const { existingBiller } = useExistingBiller({
    uuid: billerUUID,
  });

  const { onSubmit, isLoading, apis } = useBillerForm({
    reset,
    setValue,
    isReadOnly,
    billerUUID,
  });

  const [allApis, setAllApis] = useState<IApi[]>([]);
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    if (existingBiller) {
      setValue("name", existingBiller.name);
      setValue("securityCode", existingBiller.securityCode);
      setValue(
        "apis",
        existingBiller.apis.map((api) => api.uuid)
      );
    }
  }, [existingBiller, setValue]);

  useEffect(() => {
    setAllApis(apis);
  }, [apis]);

  useEffect(() => {
    setIsChecked(allApis.length === existingBiller?.apis.length);
  }, [allApis.length, existingBiller?.apis.length]);

  const handleChangeAll = useCallback(
    (checked: boolean) => {
      setIsChecked(checked);
      setValue(
        "apis",
        allApis.filter(() => checked).map((api) => api.uuid)
      );
    },
    [allApis, setValue]
  );

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack>
          <FormControl isInvalid={!!errors.name}>
            <FormLabel htmlFor="name">Nome do tarifador</FormLabel>
            <Input
              id="name"
              {...(register("name"),
              {
                onChange: (event: any) => {
                  setValue(
                    "name",
                    event.target.value.replaceAll(
                      DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED,
                      ""
                    )
                  );
                },
                value: watch("name"),
              })}
              isReadOnly={isReadOnly}
            />
            <FormErrorMessage>
              {errors.name && errors.name.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors?.securityCode}>
            <FormLabel htmlFor="securityCode">Token</FormLabel>
            <HStack>
              <Input
                isReadOnly
                id="securityCode"
                {...register("securityCode")}
              />
              <FormErrorMessage>
                {errors.securityCode && errors.securityCode.message}
              </FormErrorMessage>
              <Button
                onClick={() => setValue("securityCode", uuid())}
                isDisabled={isReadOnly}
              >
                {existingBiller ? "Gerar novo token" : "Gerar token"}
              </Button>
            </HStack>
          </FormControl>
        </VStack>

        <TableContainer
          maxH="450px"
          overflowY="auto"
          mb="15px"
          mt="20px"
          border="1px solid"
          borderColor="gray.200"
          borderRadius="5px"
        >
          <Table variant="striped" colorScheme="gray">
            <Thead bg="white" position="sticky" top="0" zIndex="1">
              <Tr>
                <Th>DESCRIÇÃO </Th>
                <Th>ENDPOINT </Th>
                <Th>MÉTODO </Th>
                <Th>
                  HABILITADO
                  <Switch
                    mx={4}
                    isReadOnly={isReadOnly}
                    onChange={(e) => {
                      handleChangeAll(e.target.checked);
                    }}
                    isChecked={isChecked}
                  />
                  (TODOS)
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {Array.isArray(allApis) &&
                allApis.map((api) => (
                  <ApisRow isReadOnly={isReadOnly} key={api.uuid} api={api} />
                ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Flex width="100%" justifyContent="flex-end">
          <ButtonGroup
            spacing="58"
            alignItems="center"
            position="relative"
            mb="2rem"
            mt="1rem"
          >
            <Link to="/billerlist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>

            {!isReadOnly && (
              <Button
                marginBottom="1rem"
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                isDisabled={isReadOnly}
                ml="32px"
              >
                {existingBiller ? "Editar Tarifador" : "Cadastrar Tarifador"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </FormProvider>
  );
};
