import { accessApi } from "../../services/AccessService";
import { jobsApi } from "../../services/JobsService";
import { recordApi } from "../../services/RecordService";
import { structureApi } from "../../services/StrucureService";

export const setDefaultHeaders = (permissionLevel = "") => {
  accessApi.defaults.headers.common.permissionLevel = permissionLevel;
  recordApi.defaults.headers.common.permissionLevel = permissionLevel;
  structureApi.defaults.headers.common.permissionLevel = permissionLevel;
};

export const getTokenLocalStorage = () => {
  return localStorage.getItem("idToken");
};

export const setDefaultAuthorizationHeaders = () => {
  const token = getTokenLocalStorage();

  if (!token) return;

  accessApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  recordApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  structureApi.defaults.headers.common.Authorization = `Bearer ${token}`;
  jobsApi.defaults.headers.common.Authorization = `Bearer ${token}`;
};
