import { IPaymentOptionsGroup } from "../../../../../types/main/paymentMethods";
import { ITariffWithInvoice } from "../../../../../types/main/tariff";
import { ITariffCarWithCalculate } from "../../../../../types/main/tariffCar";
import { ICardReceivable } from "../../pages/TariffDetails";

export const getCardReceivableState = (
  cardReceivable: ICardReceivable[],
  receivable: ITariffCarWithCalculate,
  tariff: ITariffWithInvoice,
  paymentMethodOptions: IPaymentOptionsGroup[]
): ICardReceivable[] => {
  const newCard: ICardReceivable = {
    uuid: receivable.uuid,
    id: receivable.tariffCarId,
    status: {
      colorScheme: receivable.processingCar.processingStatusCar.color,
      label: receivable.processingCar.processingStatusCar.name,
      dateOfLow: receivable.processingCar.dateOfLow,
    },
    justification: receivable.processingCar.reasonForManualLow,
    paymentMethodUuid:
      receivable.paymentMethodUuid || receivable.paymentMethod?.uuid,
    invoiceInterest: receivable.calculate?.invoiceInterest || 0,
    invoiceFine: receivable.calculate?.invoiceFine || 0,
    invoiceTotal: receivable.calculate?.invoiceTotal || 0,
    invoiceValue: receivable.calculate?.invoiceValue || 0,
    invoiceDiscount: receivable.calculate?.invoiceDiscount || 0,
    cancel: (empty: any) => empty,
    service: receivable.service.name,
    allMethods: paymentMethodOptions || [],
    discardInterestAndFine: receivable.discardInterestAndFine || false,
    enumProcessing: receivable.processingCar.processingStatusCar.enum,
    chargeUuid: receivable.chargeUuid,
    serviceInaactive: !!receivable.service.deletedAt,
    dueDate: tariff?.dueDate,
    idContract: tariff.contract?.idContract,
  };

  return [...cardReceivable, newCard];
};
