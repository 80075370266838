import { Box, Flex, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import {
  DatePickerFilter,
  MultiSelectFilter,
} from "../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { PopoverList } from "../../../../main/components/Popover";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IResponse } from "../../../../types/main/response";
import { IUser } from "../../../../types/main/user";
import { allUsers, deleteUser } from "../service/UserService";

interface IUserTable {
  email: string;
  fullName: string;
  profileName: string;
  username?: string;
  uuid: string;
  lastLogin?: string;
}

export const UserList: React.FC = () => {
  const [users, setUsers] = useState<Array<IUserTable>>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Tipo do Usuário",
            accessor: "profileName",
            Filter: MultiSelectFilter({}),
            filter: "multi",
          },

          {
            Header: "Nome",
            accessor: "fullName",
          },
          {
            Header: "E-Mail",
            accessor: "email",
          },
          {
            Header: "Username",
            accessor: "username",
          },
          {
            Header: "Último Login",
            accessor: "lastLogin",
            Filter: DatePickerFilter,
          },
          {
            Header: "ID do Usuário",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const handleUserList = async () => {
    setIsLoading(true);
    const response: IResponse<Array<IUser>> = await allUsers();
    if (response.type === "success" && response.body) {
      const userList = response.body.map(
        ({ email, fullName, profile, username, uuid, lastLogin }) => {
          const dateLastLogin = new Date(lastLogin ?? "");
          return {
            email,
            fullName,
            profileName: profile.name,
            username,
            uuid,
            lastLogin: lastLogin
              ? dateLastLogin?.toLocaleDateString("pt-br", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : undefined,
          };
        }
      );
      setUsers(userList);
    }
    setIsLoading(false);
  };

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Usuários", href: "/userlist" },
    { description: "Gerenciamento de usuários", href: "" },
  ];

  useEffect(() => {
    handleUserList();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de usuários
          </HLarge2xl>
          <Box>
            <SimpleMenu label="Exibir colunas">
              <SelectableColumns />
            </SimpleMenu>

            <CreateNewDataButton
              module={MODULES.USERS}
              path="/manageuser"
              text="Cadastrar novo usuário"
            />
          </Box>
        </Flex>
        <DataTable
          refreshTable={handleUserList}
          module="Users"
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={users}
          popup={{
            RenderComponent: PopoverList,
            callToDelete: deleteUser,
            refreshTable: handleUserList,
            urlEdit: "/manageuser/",
            urlView: "/viewuser/",
          }}
          showCheckbox={false}
        />
      </GridItem>
    </Wrapper>
  );
};
