import { useToast } from "@chakra-ui/react";
import { useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { ITariffCarData } from "../../../../types/main/tariffCar";
import { createTariffCar } from "../../CARs/service/CARsService";

export const useTariffCar = () => {
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitToCreate = async ({
    data,
    id,
    onClose,
    reloadCar,
  }: {
    data: Array<ITariffCarData>;
    id: number;
    onClose: () => void;
    reloadCar: () => void;
  }) => {
    try {
      setIsLoading(true);
      const response = await createTariffCar({ data, id });
      reloadCar();
      setTimeout(() => {
        onClose();
      }, 2000);
      toast({
        title: "Criação efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    onSubmitToCreate,
  };
};
