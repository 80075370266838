import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Flex, Th, Tr } from "@chakra-ui/react";
import { Column, flexRender, HeaderGroup, Table } from "@tanstack/react-table";

import { DateFilter } from "../Filters/DateFilter";
import { DocumentFilter } from "../Filters/DocumentFilter";
import { NormalFilter } from "../Filters/NormalFilter";
import {
  SelectFilter,
  SelectProductFilter,
  SelectServicesFilter,
} from "../Filters/SelectFilter";

export type CustomFilterProps = {
  column: Column<any>;
  table: Table<any>;
};

function Filter(props: CustomFilterProps) {
  const { column } = props;

  if (column.columnDef.filterFn === "document") {
    return <DocumentFilter {...props} />;
  }

  if (column.columnDef.filterFn === "date") {
    return <DateFilter {...props} />;
  }

  if (column.columnDef.filterFn === "select") {
    return <SelectFilter {...props} />;
  }

  if (column.columnDef.filterFn === "selectProduct") {
    return <SelectProductFilter {...props} />;
  }

  if (column.columnDef.filterFn === "selectService") {
    return <SelectServicesFilter {...props} />;
  }

  return <NormalFilter {...props} />;
}

type TableHeaderProps = {
  headerGroup: HeaderGroup<any>;
  table: Table<any>;
  hideAllFilters?: boolean;
};

export const TableHeader = ({
  headerGroup,
  table,
  hideAllFilters,
}: TableHeaderProps) => {
  return (
    <>
      <Tr background="gray.100" height="56px">
        {headerGroup.headers.map((header) => (
          <Th
            key={header.id}
            colSpan={header.colSpan}
            fontWeight={700}
            fontSize="sm"
            paddingInline="2"
            minW={
              header.column.id === "actions"
                ? "fit-content"
                : header.column.columnDef.minSize ?? undefined
            }
            w={header.column.id === "actions" ? "fit-content" : undefined}
            overflowWrap="break-word"
            whiteSpace="normal"
            position={header.column.id === "actions" ? "sticky" : undefined}
            right={header.column.id === "actions" ? "0" : undefined}
            bgColor={header.column.id === "actions" ? "gray.100" : undefined}
          >
            {header.isPlaceholder ? null : (
              <Flex
                minW={
                  header.column.id === "actions" ? "fit-content" : undefined
                }
                cursor={header.column.getCanSort() ? "pointer" : "auto"}
                onClick={header.column.getToggleSortingHandler()}
                alignItems="center"
                justifyContent="flex-start"
                paddingInlineStart={header.column.getCanSort() ? "0" : "4"}
                gap={2}
              >
                <Flex flexDir="column" hidden={!header.column.getCanSort()}>
                  <TriangleUpIcon
                    height="8px"
                    aria-label="sorted ascending"
                    color={
                      header.column.getIsSorted() === "asc"
                        ? "blue.500"
                        : "gray.700"
                    }
                  />

                  <TriangleDownIcon
                    height="8px"
                    aria-label="sorted descending"
                    marginTop={2}
                    color={
                      header.column.getIsSorted() === "desc"
                        ? "blue.500"
                        : "gray.700"
                    }
                  />
                </Flex>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </Flex>
            )}
          </Th>
        ))}
      </Tr>
      <Tr hidden={hideAllFilters}>
        {headerGroup.headers.map((header) => {
          return header.column.getCanFilter() ? (
            <Th
              backgroundColor="#ffffff"
              paddingInline="2"
              key={header.id + header.index}
            >
              <Filter column={header.column} table={table} />
            </Th>
          ) : (
            <Th
              key={header.id + header.index}
              position="sticky"
              right="0"
              bgColor="white"
              paddingInline="2"
              minW={header.column.id === "actions" ? "fit-content" : undefined}
              w={header.column.id === "actions" ? "fit-content" : undefined}
            />
          );
        })}
      </Tr>
    </>
  );
};
