import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import { IResponse } from "../../../../../types/main/response";
import { ITariffWithInvoice } from "../../../../../types/main/tariff";
import { ITariffProps } from "../../interface/tariff.interface";
import { getTariffDueDateInfo, getWarningFromTariff } from "../dueDateHelpers";

const label = {
  OPEN: { title: "Em aberto", color: "yellow" },
  CLOSED: { title: "Concluída", color: "green" },
  CANCELED: { title: "Cancelada", color: "red" },
};

export const getTariffState = (
  tariffs: IResponse<ITariffWithInvoice>,
  tariffValue: number
): ITariffProps | undefined => {
  if (!tariffs.body) return undefined;

  const str_startDate = new Date(tariffs.body.startDate ?? "");
  const str_endDate = new Date(tariffs.body.endDate ?? "");
  const str_createdAt = new Date(tariffs.body.createdAt ?? "");
  const str_receiveDate = new Date(tariffs.body.tariffStatus.date ?? "");

  return {
    paymentType: tariffs.body.contract.typeOfPayment,
    badge: {
      label: label[tariffs.body.tariffStatus.status].title,
      colorScheme: label[tariffs.body.tariffStatus.status].color,
    },
    totalInterestReceived: tariffs.body.totalTariff.totalInterestReceived,
    totalInterestProvided: tariffs.body.totalTariff.totalInterestProvided,
    totalFineReceived: tariffs.body.totalTariff.totalFineReceived,
    totalFineProvided: tariffs.body.totalTariff.totalFineProvided,
    totalReceived: tariffs.body.totalTariff.totalReceived,
    totalProvided: tariffs.body.totalTariff.totalProvided,
    discountProvided: tariffs.body.totalTariff.discountProvided,
    discountReceived: tariffs.body.totalTariff.discountReceived,
    tariffValue,
    billerName: tariffs.body.biller.name,
    contract: tariffs.body.contract,
    clientName: tariffs.body.contract.client.fullName,
    startDate: tariffs.body.startDate
      ? format(str_startDate, "dd/MM/yyyy", {
          locale: ptBR,
        })
      : "dd/mm/aaaa",
    endDate: tariffs.body.endDate
      ? format(str_endDate, "dd/MM/yyyy", {
          locale: ptBR,
        })
      : "dd/mm/aaaa",
    productName: tariffs.body.contract.product.name,
    projectDescription: tariffs.body.projectDescription,
    tariffIdentifier: tariffs.body.tariffIdentifier,
    invoice: tariffs.body?.invoiceId?.toString() ?? "(sem fatura)",
    createdAt: tariffs.body.createdAt
      ? format(str_createdAt, "dd/MM/yyyy", {
          locale: ptBR,
        })
      : "dd/mm/aaaa",
    dueDate: getTariffDueDateInfo(tariffs.body),
    warning: getWarningFromTariff(tariffs.body),
    receiveDate: tariffs.body.tariffStatus.date
      ? format(str_receiveDate, "dd/MM/yyyy", {
          locale: ptBR,
        })
      : "-",
    providerName: tariffs.body.contract.channel.provider?.name,
    contractActive: !tariffs.body.contract.deletedAt,
    providerActive: !tariffs.body.contract.channel.provider?.deletedAt,
    productActive: !tariffs.body.contract.product.deletedAt,
    billerActive: !tariffs.body.biller.deletedAt,
    channel: tariffs.body.contract.channel,
  };
};
