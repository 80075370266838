import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Portal,
  Flex,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";
import { format } from "date-fns";
import { useCallback } from "react";

import { IconExport } from "../../../../../../../main/common/assets";
import { transformFilterStateToQueryParams } from "../../../../../../../main/components/DataTableServer/helpers/transformStateObject";
import {
  HLargexs,
  HSmallmd,
} from "../../../../../../../main/components/Tipography";
import { IBackgroundProcessesLogs } from "../../../../../../../types/main/backgroundProcessesLogs";
import {
  getAllBackgroundLogsCSV,
  getFilteredBackgroundLogsCSV,
} from "../../../../../BackgroundProcessesLogs/services/BackgroundProcessesLogs";

type PopoverClientProps = {
  table: Table<IBackgroundProcessesLogs>;
  onLoading: (loading: boolean) => void;
};

export const PopoverBackgroundLogs = ({
  table,
  onLoading,
}: PopoverClientProps) => {
  function downloadFile(data: string, filename: string) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${filename}_${format(new Date(), "dd-MM-yyyy_hh:mm:ss")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const exportAll = useCallback(async () => {
    onLoading(true);
    const data = await getAllBackgroundLogsCSV();
    onLoading(false);
    downloadFile(data, "Logs_segundo_plano");
  }, [onLoading]);

  const exportFiltered = useCallback(async () => {
    const dataFiltered = transformFilterStateToQueryParams(
      table.getState().columnFilters
    );
    onLoading(true);
    const data = await getFilteredBackgroundLogsCSV(dataFiltered);
    onLoading(false);
    downloadFile(data, "Logs_segundo_plano_filtrados");
  }, [table, onLoading]);

  return (
    <Popover placement="left">
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0">
                <Flex flexDir="column">
                  <HLargexs pl="24px" pt="12px" mb="6px">
                    EXPORTAR CSV
                  </HLargexs>
                  <Flex
                    pl="25.33px"
                    py="8px"
                    as="button"
                    alignItems="center"
                    _hover={{ bg: "gray.50" }}
                    onClick={exportAll}
                    width="100%"
                  >
                    <IconExport />
                    <HSmallmd ml="8.46px" color="gray.500">
                      Tabela inteira
                    </HSmallmd>
                  </Flex>
                  <Flex
                    pl="25.33px"
                    py="8px"
                    as="button"
                    alignItems="center"
                    _hover={{ bg: "gray.50" }}
                    onClick={exportFiltered}
                    width="100%"
                  >
                    <IconExport />
                    <HSmallmd ml="8.46px" color="gray.500">
                      Tabela filtrada
                    </HSmallmd>
                  </Flex>

                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
