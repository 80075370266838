export const MODULES = {
  USERS: "Users",
  CLIENTS: "Clients",
  BILLERS: "Billers",
  CHANNELS: "Channels",
  CONTRACTS: "Contracts",
  INVOICES: "Invoices",
  PAYMENT_METHODS: "PaymentMethods",
  PRODUCTS: "Products",
  REMITTANCE: "Remittance",
  SERVICES: "Services",
  TARIFF: "Tariff",
  CAR: "CAR",
  TEMPLATES: "Templates",
  PROVIDER: "Provider",
  MOVIMENTS: "Moviments",
  WEB_HOOK_TYPES: "WebHookTypes",
} as const;
