import { Alert, List, ListItem } from "@chakra-ui/react";
import { parseExpression } from "cron-parser";
import { useLocation } from "react-router-dom";

import { getDateFormatted } from "../../../../main/common/utils/dateHelper";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl, TSmallmd } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ProcessingRemittance } from "../components/Processings";

const nextCronExecution = (cronString: string) => {
  try {
    const intervalo = parseExpression(cronString);
    const proximaExecucao = intervalo.next().toISOString();
    return getDateFormatted(proximaExecucao, "HH'h':mm'm'");
  } catch (err) {
    console.error(err);
    return null;
  }
};

export const RemittanceProcessing = () => {
  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Remessas", href: "/remittancelist" },
    { description: "Envios/Retornos", href: "" },
  ];
  const { state } = useLocation() as {
    state?: { remittanceId: string };
  };

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600" mb="2rem">
        Processamento de Remessas
      </HLarge2xl>

      <ProcessingRemittance remittanceId={state?.remittanceId} />
      <Alert
        status="info"
        mb="32px"
        borderRadius={4}
        backgroundColor="gray.100"
      >
        <List sx={{ listStyle: "none" }} fontSize={14}>
          <ListItem paddingBottom={1} marginBottom={1}>
            <TSmallmd mb={2}>Remessas Boleto:</TSmallmd>
            <List paddingLeft={4}>
              <ListItem paddingBottom={1} marginBottom={1}>
                Próxima preparação dos arquivos:{" "}
                {nextCronExecution("*/12 * * * *")}
              </ListItem>
              <ListItem paddingBottom={1} marginBottom={1}>
                Próximo envio automático: {nextCronExecution("*/13 * * * *")}
              </ListItem>
              <ListItem paddingBottom={1} marginBottom={1}>
                Próximo processamento de retornos:{" "}
                {nextCronExecution("*/20 * * * *")}
              </ListItem>
            </List>
          </ListItem>
          <ListItem paddingBottom={1} marginBottom={1}>
            <TSmallmd mb={2}>Remessas Débito Automatico:</TSmallmd>
            <List paddingLeft={4}>
              <ListItem paddingBottom={1} marginBottom={1}>
                Próxima preparação dos arquivos:{" "}
                {nextCronExecution("*/10 * * * *")}
              </ListItem>
              <ListItem paddingBottom={1} marginBottom={1}>
                Próximo envio automático: {nextCronExecution("*/11 * * * *")}
              </ListItem>
              <ListItem paddingBottom={1} marginBottom={1}>
                Próximo processamento de retornos:{" "}
                {nextCronExecution("*/17 * * * *")}
              </ListItem>
            </List>
          </ListItem>

          <ListItem paddingBottom={1} marginBottom={2}>
            <TSmallmd mb={2}>Remessas Débito Terceiro:</TSmallmd>
            <List paddingLeft={4}>
              <ListItem>
                Próximo processamento de Remessas Débito Terceiro:{" "}
                {nextCronExecution("*/14 * * * *")}
              </ListItem>
            </List>
          </ListItem>

          <ListItem paddingBottom={1} marginBottom={1}>
            <TSmallmd mb={2}>
              Downloads Nexxera (Boleto e Débito Automatico):
            </TSmallmd>
            <List paddingLeft={4}>
              <ListItem>
                Próxima consulta para Downloads:{" "}
                {nextCronExecution("*/17 * * * *")}
              </ListItem>
            </List>
          </ListItem>
        </List>
      </Alert>
    </Wrapper>
  );
};
