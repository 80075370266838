import { Input, InputProps } from "@chakra-ui/react";
import {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useFormContext } from "react-hook-form";
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from "react-number-format";

interface IInputMask extends InputProps {
  isReadOnly?: boolean;
  format: string;
  fieldname?: string;
  onInputChange?: (values: NumberFormatValues, reason?: SourceInfo) => void;
  mask?: string;
  handleAllowance?: (values: NumberFormatValues) => boolean;
  saveMaskedValue?: boolean;
  beforeReceiveValue?: (value: string) => string;
}

export interface IRefProps {
  setInputValue: (value: string) => void;
  focus: () => void;
}

export const InputMask = forwardRef(
  (
    {
      isReadOnly,
      format,
      fieldname,
      onInputChange,
      mask = "_",
      handleAllowance,
      saveMaskedValue = false,
      beforeReceiveValue,
      ...rest
    }: IInputMask,
    ref: ForwardedRef<IRefProps>
  ) => {
    const { clearErrors, setValue, watch } = useFormContext();

    const [field, setField] = useState<string>();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (
      inputValues: NumberFormatValues,
      reason?: SourceInfo
    ) => {
      const saveValue = saveMaskedValue
        ? inputValues.formattedValue
        : inputValues.value;

      if (fieldname) setValue(fieldname, saveValue);
      setField(saveValue);
      if (onInputChange) onInputChange(inputValues, reason);
    };

    const watchFieldname = watch(fieldname ?? "");

    useEffect(() => {
      if (watchFieldname && fieldname) {
        const field = beforeReceiveValue?.(watchFieldname) ?? watchFieldname;
        setField(field);
      }
    }, [beforeReceiveValue, fieldname, watchFieldname]);

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) inputRef.current.focus();
      },
      setInputValue: (value: string) => {
        if (fieldname) setValue(fieldname, value);
        setField(value);
      },
    }));

    return (
      <Input
        isDisabled={isReadOnly}
        _disabled={{
          cursor: "default",
          color: "inherit",
          opacity: "inherit",
        }}
        onClick={() => clearErrors("document")}
        as={NumberFormat}
        format={format}
        mask={mask}
        value={field}
        onValueChange={handleChange}
        getInputRef={inputRef}
        isAllowed={handleAllowance}
        // ref={ref}
        {...rest}
      />
    );
  }
);
