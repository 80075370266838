import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_TEMPLATES_PERMISSION_LEVEL,
  GET_TEMPLATES_PERMISSION_LEVEL,
  UPDATE_TEMPLATES_PERMISSION_LEVEL,
} from "./constants/permissions";
import { TemplatesList } from "./pages/TemplatesList";
import { TemplatesManager } from "./pages/TemplatesManager";

export function TemplatesModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_TEMPLATES_PERMISSION_LEVEL} />}
      >
        <Route path="/templatelist" element={<TemplatesList />} />
        <Route
          path="/viewtemplate/:uuid"
          element={<TemplatesManager isReadOnly />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute permission={CREATE_TEMPLATES_PERMISSION_LEVEL} />
        }
      >
        <Route path="/managetemplate" element={<TemplatesManager />} />
      </Route>
      <Route
        element={
          <PrivateRoute permission={UPDATE_TEMPLATES_PERMISSION_LEVEL} />
        }
      >
        <Route path="/managetemplate/:uuid" element={<TemplatesManager />} />
      </Route>
    </Routes>
  );
}
