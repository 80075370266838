import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import { IApi } from "../../../../types/main/api";
import { IBiller, IBillerData } from "../../../../types/main/biller";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/billers";
const APIS_BASE_URL = "/apis";

export const AllBillers = async (): Promise<IResponse<Array<IBiller>>> => {
  try {
    const response: AxiosResponse<IResponse<Array<IBiller>>> =
      await recordApi.get(MODULE_BASE_URL);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const AllApis = async (): Promise<IResponse<Array<IApi>>> => {
  try {
    const response: AxiosResponse<IResponse<Array<IApi>>> = await recordApi.get(
      APIS_BASE_URL
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const DeleteBiller = async (uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${uuid}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewBiller = async (billerFields: IBillerData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, billerFields);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const EditBiller = async (billerFields: IBillerData, uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, billerFields);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetBillerByUUID = async (
  uuid: string
): Promise<IResponse<IBiller>> => {
  try {
    const response: AxiosResponse<IResponse<IBiller>> = await recordApi.get(
      `${MODULE_BASE_URL}/${uuid}`
    );
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
