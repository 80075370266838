import { Button, ButtonGroup, Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

export const ButtonSubmit = ({
  paymentMethodUUID,
  isSubmitting,
  isDisabled,
}: {
  paymentMethodUUID?: string;
  isSubmitting?: boolean;
  isDisabled?: boolean;
}) => {
  return (
    <Flex w="100%" justify="flex-end" mb="4rem">
      <ButtonGroup spacing="58" alignItems="center">
        <Link to="/paymentmethodslist">
          <Button variant="link" color="gray.700">
            Cancelar
          </Button>
        </Link>
        {!isDisabled && (
          <Button
            backgroundColor="blue.500"
            type="submit"
            isLoading={isSubmitting}
            ml="32px"
          >
            {paymentMethodUUID
              ? "Editar método de pagamento"
              : "Cadastrar método de pagamento"}
          </Button>
        )}
      </ButtonGroup>
    </Flex>
  );
};
