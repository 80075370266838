/* eslint-disable @typescript-eslint/no-explicit-any */
export enum enumNameOfReports {
  BLOCKED_CUSTOMERS = "Clientes Inadimplentes",
  RECEIVED = "Recebimentos",
  RECEIVED_BY_REPICK = "Recebimento por repick",
  GENERATION_EFD_PIS_COFINS = "Geração EFD – PIS/COFINS",
  SEND_AUTOMATIC_DEBIT = "Arquivos de cobranças - Envio - Débito Automático",
  RECEIVE_AUTOMATIC_DEBIT = "Arquivos de cobranças - Recebimento - Débito Automático",
  SEND_BOLETO = "Arquivos de cobranças - Envio - Boleto",
  RECEIVE_BOLETO = "Arquivos de cobranças - Recebimento - Boleto",
  SEND_THIRD_PARTY_DEBIT = "Arquivos de cobranças - Envio - Débito Terceiros",
  SEND_GENERAL = "Arquivos de cobranças - Envio - Geral",
  RECEIVE_GENERAL = "Arquivos de cobranças - Recebimento - Geral",
  CREDIT_GRANTED = "Garantia de Crédito a Receber",
  BACKGROUND_LOGS = "Logs de Segundo Plano",
}

interface ITableColumns {
  Header: string;
  accessor: string;
  id?: string;
  startHidden?: boolean;
  disableFiltering?: boolean;
  Cell?: (data: any) => any;
  Footer?: (data?: any) => any;
  Filter?: (data?: any) => any;
  // prettier-ignore
  filter?: "multi" | "includes" | "text" | "date" | "currency" | "textCommaSeparated" | "equals";
  convertTo?: "date" | "number" | "currency";
  addButtonLink?: "nfe" | "processing";
}

interface IColumnDataTable {
  Header: string;
  hasFooter?: boolean;
  absoluteFooterLabel?: string;
  sticky?: "left" | "right";
  columns: ITableColumns[];
}

interface IResponseReports {
  data: any[];
}
interface IChargesReport {
  createdAt: Date;
  tariffId: number;
  invoiceId: number;
  service: string;
  channel: string;
  clients: string;
  product: string;
  paymentMethod: string;
  remittanceId: number;
  statusCar: string;
  processingRejectionMotive?: string;
  value: number;
  interest: number;
  fine: number;
  totalValue: number;
  nfse?: number;
  nfeLink?: string;
  processingFileLink: string;
}

export type {
  ITableColumns,
  IColumnDataTable,
  IResponseReports,
  IChargesReport,
};
