import { format } from "date-fns";

type DownloadFileLink = {
  url: string;
  filename: string;
};

export const downloadFileLink = ({ filename, url }: DownloadFileLink) => {
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export function downloadFileCsv(data: string, filename: string) {
  const blob = new Blob([data]);
  const url = window.URL.createObjectURL(blob);
  downloadFileLink({
    url,
    filename: `${filename}_${format(new Date(), "dd-MM-yyyy")}.csv`,
  });
}
