import { ViewIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Portal,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { CellContext, Table } from "@tanstack/react-table";
import { format } from "date-fns";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import { IconExport } from "../../../../main/common/assets";
import { ModalDelete } from "../../../../main/components/CustomModal/ModalDelete";
import { transformFilterStateToQueryParams } from "../../../../main/components/DataTableServer/helpers/transformStateObject";
import { ButtonDelete } from "../../../../main/components/Popover";
import { ButtonEdit } from "../../../../main/components/Popover/ButtonEdit";
import { HLargexs, HSmallmd } from "../../../../main/components/Tipography";
import { IContractsList } from "../../../../types/main/contract";
import {
  deleteContracts,
  GetContractCsvAllUUIDs,
  GetContractCsvByUUIDs,
} from "../service/ContractService";

type PopoverContractProps = {
  info?: CellContext<IContractsList, any>;
  table: Table<IContractsList>;
  refetch: (data?: any) => Promise<void>;
  onLoading: (loading: boolean) => void;
};

export const PopoverContract = ({
  info,
  refetch,
  table,
  onLoading,
}: PopoverContractProps) => {
  const contract = info?.row.original;

  const deleteContract = async (uuid: string) => {
    return deleteContracts(uuid);
  };

  function downloadFile(data: string, filename: string) {
    const blob = new Blob([data]);
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${filename}_${format(new Date(), "dd-MM-yyyy_hh:mm:ss")}.csv`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  const exportAll = useCallback(async () => {
    onLoading(true);
    const data = await GetContractCsvAllUUIDs();
    onLoading(false);
    downloadFile(data, "Contrato");
  }, [onLoading]);

  const exportFiltered = useCallback(async () => {
    const dataFiltered = transformFilterStateToQueryParams(
      table.getState().columnFilters
    );

    onLoading(true);
    const data = await GetContractCsvByUUIDs(dataFiltered);
    onLoading(false);
    downloadFile(data, "Contrato_filtrado");
  }, [table, onLoading]);

  return (
    <Popover placement="left">
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0">
                <Flex flexDir="column">
                  <HLargexs pl="24px" pt="12px" mb="6px">
                    EXPORTAR CSV
                  </HLargexs>
                  <Flex
                    pl="25.33px"
                    py="8px"
                    as="button"
                    alignItems="center"
                    _hover={{ bg: "gray.50" }}
                    onClick={exportAll}
                    width="100%"
                  >
                    <IconExport />
                    <HSmallmd ml="8.46px" color="gray.500">
                      Exportar todos
                    </HSmallmd>
                  </Flex>
                  <Flex
                    pl="25.33px"
                    py="8px"
                    as="button"
                    alignItems="center"
                    _hover={{ bg: "gray.50" }}
                    onClick={exportFiltered}
                    width="100%"
                  >
                    <IconExport />
                    <HSmallmd ml="8.46px" color="gray.500">
                      Tabela filtrada
                    </HSmallmd>
                  </Flex>
                  {!!contract && (
                    <>
                      <Divider my="5px" ml="12px" />

                      <ButtonEdit
                        urlEdit={`/managecontract/${contract.uuid}`}
                        module="Contracts"
                      />

                      <Flex
                        pl="25.33px"
                        py="8px"
                        as={Link}
                        alignItems="center"
                        _hover={{ bg: "gray.50" }}
                        mb="6px"
                        to={`/viewcontract/${contract.uuid}`}
                      >
                        <ViewIcon color="gray.500" />
                        <HSmallmd ml="8.46px" color="gray.500">
                          Visualizar informações
                        </HSmallmd>
                      </Flex>

                      <Divider my="5px" ml="12px" />
                      <ModalDelete
                        ButtonOpen={ButtonDelete}
                        uuid={contract.uuid}
                        callApi={deleteContract}
                        refreshTable={refetch}
                        module="Contracts"
                      />
                    </>
                  )}

                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
