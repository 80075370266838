import { AxiosResponse } from "axios";

import { IResponse } from "../../types/main/response";
import { accessApi } from "./AccessService";

interface IAuthResponse {
  permissionLevel: string;
  uuid: string;
}

export const authUser = async () => {
  try {
    const response: AxiosResponse<IResponse<IAuthResponse>> =
      await accessApi.get(`users/auth`);

    return (
      response.data.body ?? {
        permissionLevel: "",
        uuid: "",
      }
    );
  } catch (err: any) {
    throw err?.response?.data;
  }
};
