import { useMsal } from "@azure/msal-react";
import { useEffect, useState } from "react";

import { setDefaultAuthorizationHeaders } from "../common/utils/setDefaultHeaders";
import { accessApi } from "./AccessService";
import { jwtInterceptor } from "./interceptor";
import { jobsApi } from "./JobsService";
import { recordApi } from "./RecordService";
import { structureApi } from "./StrucureService";

const AxiosInterceptor = ({ children }: { children: any }) => {
  const [isSet, setIsSet] = useState(false);
  const { instance } = useMsal();

  useEffect(() => {
    setDefaultAuthorizationHeaders();

    const accessInterceptor = jwtInterceptor(accessApi, instance);
    const recordInterceptor = jwtInterceptor(recordApi, instance);
    const structureInterceptor = jwtInterceptor(structureApi, instance);
    const jobInterceptor = jwtInterceptor(jobsApi, instance);

    setIsSet(true);

    return () => {
      accessApi.interceptors.response.eject(accessInterceptor);
      recordApi.interceptors.response.eject(recordInterceptor);
      structureApi.interceptors.response.eject(structureInterceptor);
      jobsApi.interceptors.response.eject(jobInterceptor);
    };
  }, [instance]);

  return isSet && children;
};

export { AxiosInterceptor };
