import { Box, Flex } from "@chakra-ui/react";
import React, { ReactNode } from "react";

import { Navbar } from "../Navigation/Navbar";

export const Wrapper = ({
  children,
  asModal,
}: {
  children: ReactNode;
  asModal?: boolean;
}) => {
  const marginInline = asModal ? "40px" : "160px";
  const marginTop = asModal ? "40px" : "calc(100px + 32px)";

  return (
    <Flex>
      {!asModal && (
        <Box>
          <Navbar />
        </Box>
      )}
      <Box mt={marginTop} marginInline={marginInline} width="100vw">
        {children}
      </Box>
    </Flex>
  );
};
