import { format, isBefore, isEqual, parse, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

export const getMonthShortName = () => {
  return [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dec",
  ];
};

export const getMonthFullName = () => {
  return [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];
};

export const getFirstLetterOfDays = () => {
  return ["D", "S", "T", "Q", "Q", "S", "S"];
};

export const getDaysFullName = () => {
  return ["Domingo", "Segunda", "Terça", "Quarta", "Quinta", "Sexta", "Sábado"];
};

export const getTimeOptionsBetweenInterval = (interval: number) => {
  const timeOptions = [];
  let hourIterator;
  let minuteIterator;
  for (hourIterator = 0; hourIterator < 24; hourIterator++) {
    for (minuteIterator = 0; minuteIterator < 4; minuteIterator++) {
      timeOptions.push(
        `${String(hourIterator).padStart(2, "0")}:${
          minuteIterator === 0 ? "00" : interval * minuteIterator
        }`
      );
    }
  }

  return timeOptions;
};

export const isFirstDateBeforeOrEqualSecondDate = (
  date: string | Date | undefined,
  dateToCompare: Date
) => {
  if (!date) return true;

  const reducedDate = new Date(date).setHours(0, 0, 0, 0);
  const reducedDateToCompare = dateToCompare.setHours(0, 0, 0, 0);

  return (
    isBefore(reducedDate, reducedDateToCompare) ||
    isEqual(reducedDate, reducedDateToCompare)
  );
};

export const addHoursToTimeString = (
  time: string | undefined,
  hours: number
) => {
  if (!time) return "00:00";
  const timeArray = time.split(":");
  const hoursToAdd = parseInt(timeArray[0], 10) + hours;
  const minutes = timeArray[1];
  return `${String(hoursToAdd).padStart(2, "0")}:${minutes}`;
};

export const addHoursToISOString = (
  date: string | Date | undefined,
  hours: number
) => {
  if (!date) return undefined;
  const dateToAdd = new Date(date);
  dateToAdd.setHours(dateToAdd.getHours() + hours);
  return dateToAdd.toISOString();
};

export const getDateFormatted = (
  date: string | Date | undefined | null,
  mask = "dd/MM/yyyy"
) => {
  if (!date) return "";
  const dateToFormat = new Date(date);

  return format(dateToFormat, mask, {
    locale: ptBR,
  });
};

export const getDateFromBrazilianDateFormat = (date?: string) => {
  if (!date) return undefined;

  return parse(date, "dd/MM/yyyy", new Date());
};

export const isBusinessDay = (date: Date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

export const getDateInEndOfDay = (date: Date) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    23,
    59,
    59
  );
};

export const formatDate = (date: Date | string, mask = "dd/MM/yyyy") => {
  if (!date) return "";
  if (typeof date === "string") {
    const dateToFormat = mask.includes("HH:mm") ? date : date.replace("Z", "");
    return format(parseISO(dateToFormat), "dd/MM/yyyy");
  }

  return format(date, "dd/MM/yyyy");
};
