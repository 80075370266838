import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IContractsData } from "../../../../types/main/contract";
import { NewContract, UpdateContract } from "../service/ContractService";

export const useContract = (contractUUID?: string) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitToCreateContract = async (data: IContractsData) => {
    try {
      setIsLoading(true);
      const response = await NewContract(data);
      toast({
        title: "Criação efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      navigate("/contractlist");
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const onSubmitToUpdateContract = async (data: IContractsData) => {
    try {
      setIsLoading(true);

      const response = await UpdateContract({
        data,
        uuid: contractUUID ?? "",
      });

      toast({
        title: "Atualização efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      navigate("/contractlist");
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSubmitToCreateContract,
    onSubmitToUpdateContract,
  };
};
