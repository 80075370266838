import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import { structureApi } from "../../../../main/services/StrucureService";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

export interface IResponseClearData {
  qty: number;
  orn: string;
  msn: string;
  identifier: string;
}

export const clearDataRecords = async ({
  nameToClear,
}: {
  nameToClear: string;
}) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`cleardata/${nameToClear}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getQtyRecords = async () => {
  try {
    const response: AxiosResponse<IResponse<Array<IResponseClearData>>> =
      await recordApi.get(`cleardata`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const clearDataStructure = async ({
  nameToClear,
}: {
  nameToClear: string;
}) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await structureApi.delete(`cleardata/${nameToClear}`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getQtyStructure = async () => {
  try {
    const response: AxiosResponse<IResponse<Array<IResponseClearData>>> =
      await structureApi.get(`cleardata`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};
