import { useEffect, useState } from "react";

import { IServiceProvisionOption } from "../../../../types/main/serviceProvision";
import { getServiceProvisionOptions } from "../../ServiceProvision/service/ServiceProvisionService";

export const useTariffForm = () => {
  const [servicesList, setServicesList] = useState<IServiceProvisionOption[]>(
    []
  );
  useEffect(() => {
    const loadServices = async () => {
      const allServices = await getServiceProvisionOptions({});
      setServicesList(allServices?.body ?? []);
    };
    loadServices();
  }, []);

  return {
    servicesList,
  };
};
