import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IProcessingCarCancellation,
  IProcessingCarLow,
} from "../../../../types/main/processingCar";
import { IProcessingCarTypeOfLow } from "../../../../types/main/processingCarTypeOfLow";
import { IProcessingCarTypeReasonForCancellation } from "../../../../types/main/processingCarTypeReasonForCancellation";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/processingcar";

export const getAllProcessingCarCancellationReason = async (): Promise<
  IResponse<Array<IProcessingCarTypeReasonForCancellation>>
> => {
  try {
    const response: AxiosResponse<
      IResponse<Array<IProcessingCarTypeReasonForCancellation>>
    > = await recordApi.get(
      `${MODULE_BASE_URL}/getAllProcessingCarTypeReasonForCancellation`
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getAllProcessingCarPaymentConfirmationType = async (): Promise<
  IResponse<Array<IProcessingCarTypeOfLow>>
> => {
  try {
    const response: AxiosResponse<IResponse<Array<IProcessingCarTypeOfLow>>> =
      await recordApi.get(`${MODULE_BASE_URL}/getAllProcessingCarTypeOfLow`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const cancelCAR = async ({
  data,
}: {
  data: Array<IProcessingCarCancellation>;
}) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/cancelcar`, data);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const confirmPayment = async ({
  data,
}: {
  data: Array<IProcessingCarLow>;
}) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/lowmanually`, data);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
