import * as yup from "yup";

import { checkForSpecialChars } from "../../../../main/common/utils/checkStrings";

export const boletoSchema = yup
  .object({
    paymentLayoutUuid: yup.string().required("Esse campo é obrigatório"),
    agencyNumber: yup
      .string()
      .length(5, "Agência + DV deve conter 5 dígitos")
      .typeError("Esse campo é obrigatório")
      .test(
        "Avoid decimals",
        "Agência não pode conter pontos ou vírgulas",
        (value) => !value || (!/\./.test(value) && !/,/.test(value))
      )
      .required("Esse campo é obrigatório"),
    checkingAccount: yup
      .string()
      .length(6, "Conta + DV deve conter 6 dígitos")
      .typeError("Esse campo é obrigatório")
      .test(
        "Avoid decimals",
        "Conta não pode conter pontos ou vírgulas",
        (value) => !value || (!/\./.test(value) && !/,/.test(value))
      )
      .required("Esse campo é obrigatório"),
    description: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    instructions: yup.array().of(
      yup.object({
        text: yup.string().min(2, "Campo de instrução não pode ficar vazio"),
      })
    ),
    recipientCode: yup
      .string()
      .max(10, "Código do Beneficiário deve conter no máximo 10 dígitos")
      .test(
        "Avoid decimals",
        "Código do Beneficiário não pode conter pontos ou vírgulas",
        (value) => !value || (!/\./.test(value) && !/,/.test(value))
      )
      .required("Esse campo é obrigatório"),
    paymentTransmissionChannel: yup
      .mixed()
      .test("required", "Esse campo é obrigatório", (value) => {
        const [arrayOfUuid] = value;
        if (arrayOfUuid && arrayOfUuid.length > 0) return true;
        return false;
      }),
    templateUuid: yup
      .mixed()
      .nullable()
      .test({
        test: (templateUuid, schema) => {
          const paymentTransmissionChannelContainEmail =
            schema.parent.paymentTransmissionChannel[1]?.filter(
              (item: string) => item === "E-mail"
            ).length === 1;
          if (paymentTransmissionChannelContainEmail && !templateUuid) {
            return false;
          }
          return true;
        },
      }),
  })
  .required();
