import { CheckIcon, EditIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { customToast } from "../../../../../../../main/common/utils/customToast";
import { DatePicker } from "../../../../../../../main/components/DateTimePicker/DatePicker";
import { HSmallmd } from "../../../../../../../main/components/Tipography";
import { useAuth } from "../../../../../../../main/hooks/useAuth";
import { useInterstarTheme } from "../../../../../../../main/hooks/useInterstarTheme";
import { IInvoiceTariffUpdate } from "../../../../../../../types/main/invoice";
import { CAN_PERMISSION_UPDATE_DUE_DATE } from "../../../../../CARs/constants/permissions";
import { updateDueDateFromMultiTariffs } from "../../../../../Tariff/service/TariffService";

interface IInvoiceDueDateModalProps {
  date: string;
  ids: number[];
}

export const DueDateModal = ({ date, ids }: IInvoiceDueDateModalProps) => {
  const { permissionLevel } = useAuth();
  const { colors } = useInterstarTheme();
  const [isUpdated, setIsUpdated] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formMethods = useForm<IInvoiceTariffUpdate>({
    defaultValues: {
      ids,
    },
  });

  const onSubmit = async (data: IInvoiceTariffUpdate) => {
    try {
      await updateDueDateFromMultiTariffs(data);
      setIsUpdated(true);
    } catch (err) {
      customToast("error", "Erro ao atualizar data de vencimento");
    }
  };

  const dateSuggestions = [...new Set(date.split(","))].join(", ");
  const tariffIds = ids.join(", ");

  if (isUpdated) return <CheckIcon color="green.500" />;

  return (
    <>
      <HStack>
        {CAN_PERMISSION_UPDATE_DUE_DATE[permissionLevel] && (
          <Flex
            w="24px"
            h="24px"
            borderRadius="6px"
            onClick={onOpen}
            backgroundColor={colors.gray200}
            justify="center"
            align="center"
            transition="0.2s"
            _hover={{
              backgroundColor: colors.blueInterstar,
              color: !date ? "red" : "white",
              cursor: "pointer",
            }}
            color={!date ? "red" : "gray.800"}
          >
            <EditIcon name="editIcon">Editar</EditIcon>
          </Flex>
        )}
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atualizar data de vencimento</ModalHeader>
          <ModalCloseButton />
          <FormProvider {...formMethods}>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
              <ModalBody pb={6}>
                <span>
                  Sugestões:
                  <HSmallmd>{dateSuggestions}</HSmallmd>
                </span>
                <Divider marginBottom="18px" />
                <span>
                  Tarifas envolvidas:
                  <HSmallmd>{tariffIds}</HSmallmd>
                </span>
                <Divider marginBottom="18px" />

                <FormControl>
                  <FormLabel htmlFor="dueDate">
                    Nova data de vencimento:{" "}
                  </FormLabel>
                  <DatePicker fieldname="dueDate" isDisabled={false} />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  type="submit"
                  isLoading={formMethods.formState.isSubmitting}
                >
                  Atualizar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};
