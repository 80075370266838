/* eslint-disable no-useless-return */
/* eslint-disable consistent-return */
import axios, { AxiosResponse } from "axios";

// import { delayInterceptor } from "./delayInterceptor";

interface IBrasilAPIStates {
  id: number;
  nome: string;
  regiao: { id: number; sigla: string; nome: string };
  sigla: string;
}

interface IBrasilAPICities {
  nome: string;
  codigo_ibge: string;
}

interface IBrasilAPICep {
  cep: string;
  state: string;
  city: string;
  neighborhood: string;
  street: string;
  errors?: [];
}

export interface IBrasilAPIBanks {
  ispb: string;
  name: string;
  code: number;
  fullName: string;
}

const brasilAPI = axios.create({
  baseURL: "https://brasilapi.com.br/api",
});
// delayInterceptor(brasilAPI);

export const getAddressInfoFromCep = async (cep: string) => {
  try {
    const response: AxiosResponse<IBrasilAPICep> = await brasilAPI.get(
      `/cep/v2/${cep}`
    );
    return response.data;
  } catch (error: any) {
    return;
  }
};

export const getAllStates = async () => {
  try {
    const response: AxiosResponse<IBrasilAPIStates[]> = await brasilAPI.get(
      "/ibge/uf/v1"
      // { params: { delay: 5000 } }
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getCitiesByUF = async (UF: string) => {
  try {
    const response: AxiosResponse<IBrasilAPICities[]> = await brasilAPI.get(
      `/ibge/municipios/v1/${UF}`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const getBanks = async () => {
  try {
    const response: AxiosResponse<IBrasilAPIBanks[]> = await brasilAPI.get(
      `/banks/v1`
    );
    return response.data
      .filter(({ code }) => code !== null)
      .sort(
        (
          { code: code1, fullName: fullName1 },
          { code: code2, fullName: fullName2 }
        ) => {
          if (code1 && code2) {
            return Number(code1 - code2);
          }
          if (fullName1 > fullName2) {
            return 1;
          }
          if (fullName1 > fullName2) {
            return -1;
          }
          return 0;
        }
      );
    // return response.data;
  } catch (error) {
    return [];
  }
};
