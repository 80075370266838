import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { useMemo, useState } from "react";

import { transformFilterStateToQueryParams } from "./helpers/transformStateObject";

export type TableFilteredParams = Record<string, any>;

export type TableSortingParams = {
  sortColumn?: string;
  sortOrder?: "ASC" | "DESC";
};

export const getFiltersConsideringQuery = (
  searchParams: URLSearchParams,
  field: string,
  filters?: TableFilteredParams
) => {
  const value = searchParams.get(field);
  // remove query param from url
  if (value) {
    searchParams.delete(field);
  }

  return {
    ...filters,
    [field]: filters?.[field] ?? value,
  };
};

export const useTableServer = (props?: { pageCount: number }) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const [sorting, setSorting] = useState<SortingState>([]);

  const columnFiltersParams = useMemo<TableFilteredParams>(() => {
    setPagination((prev) => ({
      ...prev,
      pageIndex: 0,
    }));
    return transformFilterStateToQueryParams(columnFilters);
  }, [columnFilters]);

  const columnSortingParams = useMemo<TableSortingParams>(() => {
    const queryParamsSorting =
      sorting.length > 0
        ? {
            sortColumn: sorting[0].id,
            sortOrder: sorting[0].desc ? ("DESC" as const) : ("ASC" as const),
          }
        : {};

    return queryParamsSorting;
  }, [sorting]);

  return {
    columnFiltersParams,
    columnFiltersState: {
      columnFilters,
      setColumnFilters,
    },
    paginationParams: pagination,
    paginationState: {
      pagination,
      setPagination,
      pageCount: props?.pageCount || 1,
    },
    columnSortingParams,
    sortingState: {
      sorting,
      setSorting,
    },
  };
};
