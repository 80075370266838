// NOTE: Complete use Fetch helper around useSWR fully typed to receive response formatter and typed query params

import useSWR, { BareFetcher } from "swr";
import { PublicConfiguration } from "swr/_internal";

import { recordApi } from "../services/RecordService";

export type IQueryParam<
  T = Record<string, string | number | boolean | null | undefined>
> = T;

export const convertQueryObjectToString = (query: IQueryParam<any>) => {
  const queryArray = Object.entries(query);

  const removedEmptyQuery = queryArray.filter(
    ([, value]) => value !== undefined && value !== null
  );

  return removedEmptyQuery
    .map(([key, value]) => `${key}=${value}`)
    .join("&")
    .replace(/&$/, "");
};

export const setResponse = <T = any>(
  data: any,
  formatter?: (data: any) => any
): T => {
  if (formatter && data) {
    return formatter(data);
  }

  return data;
};

export type UseFetchOptions<Data = unknown, Error = unknown> = Partial<
  PublicConfiguration<Data, Error, BareFetcher<Data>>
>;

const useFetch = <
  Data = unknown,
  Error = unknown,
  DataFormatted = undefined,
  QueryParamType = any
>(
  url: string,
  options?: UseFetchOptions<Data, Error>,
  formatter?: (data: Data) => DataFormatted,
  query?: IQueryParam<QueryParamType>
) => {
  // NOTE: response is DataFormatted if exists, otherwise Data
  type Response = DataFormatted extends undefined ? Data : DataFormatted;

  const { data, error, mutate, isLoading, isValidating } = useSWR<Data, Error>(
    `${url}${query ? `?${convertQueryObjectToString(query)}` : ""}`,
    async (url) => {
      const res = await recordApi.get(url);

      return res?.data;
    },
    {
      revalidateOnFocus: true,
      ...options,
    }
  );

  return {
    data: setResponse<Response | undefined>(data, formatter),
    error,
    mutate,
    isLoading,
    isValidating,
  };
};

export { useFetch };
