import { Box, Button, ScaleFade } from "@chakra-ui/react";
import "filepond/dist/filepond.min.css";
import { FilePondFile } from "filepond";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
// import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
// import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { useRef, useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";

import { Backdrop } from "../../../../../../main/components/Backdrop";
import { RECORDS_API_BASE_URL } from "../../../../../../main/services/RecordService";
import { onProcessError, validateFileType } from "./functions/handlers";
import { useUploadFilePond } from "./useUploadFilePond";

// * Register the plugins
registerPlugin(FilePondPluginFileValidateType);

type ValidateReturn = {
  key: string;
  type: "CNAB" | "AUTOMATIC_DEBIT";
  filename: string;
};

export type UploadFileType = {
  file: FilePondFile;
  type?: "CNAB" | "AUTOMATIC_DEBIT";
};

type FileUploadProps = {
  onUploadFiles: () => Promise<void>;
};

const VALIDATE_FILE_ENDPOINT = "/processingremittances/file/validate";
const PERMITTED_FILE_EXTENSIONS = [".ret", ".RET", "text/plain"];

export const FileUpload = ({ onUploadFiles }: FileUploadProps) => {
  const [files, setFiles] = useState<UploadFileType[]>([]);
  const [fileIdError, setFileIdError] = useState<string | null>(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const hasInvalidFile = (files as UploadFileType[]).some(
    ({ file }) => file.id === fileIdError
  );

  const filepond = useRef<FilePond>(null);

  const { handleSendAllFiles, loading } = useUploadFilePond({
    files,
    setFiles,
    onUploadFiles,
    filepond,
  });

  return (
    <Box
      width="100%"
      display="grid"
      gridTemplateRows="50px 1fr"
      sx={{
        ".filepond--item": {
          width: "calc(50% - 0.5em)",
        },
      }}
    >
      <ScaleFade initialScale={0.1} in={!!files.length}>
        <Button
          variant="solid"
          bgColor="gray.100"
          colorScheme="gray"
          color="gray.700"
          width="100%"
          disabled={hasInvalidFile || isProcessing}
          onClick={handleSendAllFiles}
        >
          Enviar
        </Button>
      </ScaleFade>
      <FilePond
        onerror={(_, file) => {
          setFileIdError(file?.id ?? null);
        }}
        onaddfile={(_, file) => setFiles([...files, { file }])}
        onremovefile={(_, removedFile) =>
          setFiles((oldFiles) =>
            oldFiles.filter(({ file }) => removedFile.id !== file.id)
          )
        }
        ref={filepond}
        acceptedFileTypes={PERMITTED_FILE_EXTENSIONS}
        fileValidateTypeDetectType={validateFileType}
        allowMultiple
        allowProcess={false}
        name="files"
        labelIdle='Arraste e solte o arquivo ou <span class="filepond--label-action">busque</span>'
        labelFileTypeNotAllowed="Formato não permitido"
        fileValidateTypeLabelExpectedTypes="Formato aceito: .ret"
        labelFileLoadError="Erro ao validar arquivo"
        labelFileProcessingComplete="Arquivo válido"
        labelFileProcessing="Validando arquivo"
        labelTapToUndo="Desfazer"
        labelFileProcessingError={(error) => error.body}
        onaddfilestart={() => setIsProcessing(true)}
        onprocessfile={() => setIsProcessing(false)}
        credits={false}
        server={{
          url: RECORDS_API_BASE_URL,
          process: {
            url: VALIDATE_FILE_ENDPOINT,
            headers: {
              Authorization: `Bearer ${localStorage.getItem("idToken")}`,
            },
            onerror: onProcessError,
            onload: (response: string) => {
              const parsedResponse = JSON.parse(response) as ValidateReturn;
              setFiles((oldFiles) =>
                oldFiles.map((oldFile) => {
                  if (oldFile.file.filename === parsedResponse.filename)
                    return { ...oldFile, type: parsedResponse.type };

                  return oldFile;
                })
              );
              return parsedResponse.key;
            },
          },
          remove: null,
          revert: null,
        }}
      />
      {loading && <Backdrop />}
    </Box>
  );
};
