import { PERMISSION_LEVELS } from "../../../../main/common/constants/permissions";

type FilterProfileOptionsProps = {
  profile: string;
  permissionLevel: string;
  isReadOnly?: boolean;
};

export const filterProfileOptionsByPermission = ({
  isReadOnly,
  permissionLevel,
  profile,
}: FilterProfileOptionsProps) => {
  if (isReadOnly) return true;

  if (permissionLevel === PERMISSION_LEVELS.MASTER) return true;

  if (permissionLevel === PERMISSION_LEVELS.ADMIN) {
    const isOptionNotMasterOrAdmin =
      profile !== PERMISSION_LEVELS.MASTER &&
      profile !== PERMISSION_LEVELS.ADMIN;

    if (isOptionNotMasterOrAdmin) return true;
  }

  return false;
};
