import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import { GET_TARIFF_MOVEMENTS_PERMISSIONS_LEVEL } from "./constants/permissions";
import { TariffMovementlist } from "./pages/TariffMovementlist";

export function TariffMovementModule() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute permission={GET_TARIFF_MOVEMENTS_PERMISSIONS_LEVEL} />
        }
      >
        <Route path="/tariffmovementlist" element={<TariffMovementlist />} />
      </Route>
    </Routes>
  );
}
