import { Image, Flex, Box, Text } from "@chakra-ui/react";
import React from "react";

import { LogoColor } from "../common/assets";
import { Wrapper } from "../components/Wrapper";

export const Home: React.FC = ({ children }) => {
  return (
    <Wrapper>
      {children}
      <Flex
        width="100%"
        flexDir="row"
        marginTop="10%"
        justifyContent="center"
        gap="5rem"
      >
        {/* <Image
          src={HomeDraw}
          loading="lazy"
          alt="Interstar"
          width="20rem"
          alignSelf="flex-end"
          borderRadius="30% 30% 30% 0"
          boxShadow="-10px 10px 15px #687681;"
        /> */}
        <Flex flexDir="column" alignItems="center" gap="2rem">
          <Image src={LogoColor} width="16rem" loading="lazy" />
          <Box textAlign="center">
            <Text fontSize="1.875rem" color="#000" fontStyle="italic">
              <strong>Interconectando</strong> pessoas
            </Text>
            <Text fontSize="1.875rem" color="#000" fontStyle="italic">
              de forma eficiente com <strong>o mundo</strong>
            </Text>
          </Box>
        </Flex>
      </Flex>
      <Box
        top="0"
        left="0"
        // backgroundImage={`url(${HomeBackground})`}
        // backgroundSize="cover"
        backgroundColor="#c4c4c4"
        filter="blur(1px) brightness(1.4) grayscale(0.4) opacity(0.6)"
        position="absolute"
        width="100%"
        height="100vh"
        zIndex="-1"
        border="1px solid #000"
      />
    </Wrapper>
  );
};
