import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_REMITTANCE_PERMISSIONS_LEVEL,
  GET_REMITTANCE_PERMISSIONS_LEVEL,
} from "./constants/permissions";
import { RemittanceList } from "./pages/RemittanceList";
import { RemittanceManager } from "./pages/RemittanceManager";
import { RemittanceProcessing } from "./pages/RemittanceProcessing";
import { RemittanceSimulation } from "./pages/RemittanceSimulation";

export function RemittanceModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_REMITTANCE_PERMISSIONS_LEVEL} />}
      >
        <Route path="/remittancelist" element={<RemittanceList />} />
      </Route>
      <Route
        element={
          <PrivateRoute permission={CREATE_REMITTANCE_PERMISSIONS_LEVEL} />
        }
      >
        <Route path="/remittance" element={<RemittanceManager />} />
        <Route
          path="/processing-remittances"
          element={<RemittanceProcessing />}
        />
        <Route
          path="/remittance/simulation"
          element={<RemittanceSimulation />}
        />
      </Route>
    </Routes>
  );
}
