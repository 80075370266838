export enum WebhookTypeEnum {
  UPDATE_PAYMENT_STATUS = "UPDATE_PAYMENT_STATUS",
  UPDATE_CAR_STATUS = "UPDATE_CAR_STATUS",
}
interface IWebHooks {
  id?: number;
  url: string;
  status: boolean;
  headerKey: string;
  headerValue: string;
  webhookType: WebhookTypeEnum;
  createdId: string;
  createdAt: Date;
  timeOut: number;
  updatedId?: string;
  updatedAt?: Date;
  deletedAt?: Date;
  productUuid: string;
  queueMaxRetries: number;
  queueTimeOut: number;
}

interface IWebHooksCreate {
  status: boolean;
  timeOut: number;
  url: string;
  queueMaxRetries: number;
  queueTimeOut: number;
  headerKey: string;
  headerValue: string;
  webhookType: WebhookTypeEnum;
  productUuid: string;
}

interface IWebHooksProducts extends IWebHooks {
  product: string | undefined;
}

export type { IWebHooks, IWebHooksCreate, IWebHooksProducts };
