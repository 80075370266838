import { Box, Flex, GridItem } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { SelectColumnFilter } from "../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IChannelList } from "../../../../types/main/channel";
import { deleteChannel, getAllChannels } from "../services/ChannelService";

interface IChannelListProps extends Omit<IChannelList, "inheritance"> {
  inheritance: string;
}

export function ChannelsList() {
  const [channels, setChannels] = useState<Array<IChannelListProps>>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "ID do Canal",
            accessor: "id",
            filter: "equals",
          },
          {
            Header: "Descrição",
            accessor: "description",
          },
          {
            Header: "Canal Mãe",
            accessor: "parent",
          },
          {
            Header: "Prestador",
            accessor: "provider",
          },
          {
            Header: "Herda configurações?",
            accessor: "inheritance",
            Filter: SelectColumnFilter,
          },
        ],
      },
    ],
  });

  const handleChannelList = async () => {
    setIsLoading(true);
    const response = await getAllChannels({});
    if (response.type === "success" && response.body) {
      const channelList = response.body.map((channel) => {
        return {
          ...channel,
          inheritance: channel.inheritance ? "Sim" : "Não",
        };
      });
      setChannels(channelList);
    }
    setIsLoading(false);
  };

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Gerenciamento de canais", href: "" },
  ];

  useEffect(() => {
    handleChannelList();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de Canais
          </HLarge2xl>
          <Box>
            <SimpleMenu label="Exibir colunas">
              <SelectableColumns />
            </SimpleMenu>
            <CreateNewDataButton
              module={MODULES.CHANNELS}
              path="/managechannel"
              text="Cadastrar novo canal"
            />
          </Box>
        </Flex>
        <DataTable
          refreshTable={handleChannelList}
          module="Channels"
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={channels}
          popup={{
            callToDelete: deleteChannel,
            refreshTable: handleChannelList,
            urlEdit: "/managechannel/",
            urlView: "/viewchannel/",
          }}
          showCheckbox={false}
        />
      </GridItem>
    </Wrapper>
  );
}
