import {
  Button,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuList,
} from "@chakra-ui/react";
import { FaFilter } from "react-icons/fa";

interface ISimpleMenuProps extends MenuButtonProps {
  children: React.ReactNode;
  label: string;
}

export const SimpleMenu = ({ children, label, ...rest }: ISimpleMenuProps) => {
  return (
    <Menu placement="bottom-end">
      <MenuButton
        as={Button}
        leftIcon={<FaFilter />}
        marginRight="1rem"
        {...rest}
      >
        {label}
      </MenuButton>
      <MenuList padding="1rem" zIndex="overlay">
        {children}
      </MenuList>
    </Menu>
  );
};
