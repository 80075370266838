/* eslint-disable @typescript-eslint/no-unused-vars */
import { Flex } from "@chakra-ui/react";
import { exportmeExcel } from "excel-ent";
import { IconExport } from "../../common/assets";
import { HLargexs, HSmallmd } from "../Tipography";
import {
  formatDateToNameFile,
  replaceObjectKeys,
} from "./functions/exportHelper";

interface IExportFragmentProps {
  data?: any[];
  dataFiltered?: any[];
  headerObject: any;
}

export const ExportFragment = ({
  data,
  dataFiltered,
  headerObject,
}: IExportFragmentProps) => {
  function handleExportExcel() {
    const now = new Date();
    const now_str = formatDateToNameFile(now);

    const allData = data?.map(({ uuid, component, ...rest }) =>
      replaceObjectKeys(rest, headerObject)
    );

    exportmeExcel(allData || [], `Tabela inteira - ${now_str}`, {
      type: "download",
    });
  }

  function handleExportFilteredExcel() {
    const now = new Date();
    const now_str = formatDateToNameFile(now);

    const filtered = dataFiltered?.map(({ values }) => {
      const { uuid, component, ...rest } = values;

      return replaceObjectKeys(rest, headerObject);
    });

    exportmeExcel(filtered || [], `Tabela filtrada - ${now_str}`, {
      type: "download",
    });
  }

  return (
    <>
      <HLargexs pl="24px" pt="12px" mb="6px">
        EXPORTAR CSV
      </HLargexs>
      <Flex
        pl="25.33px"
        py="8px"
        as="button"
        alignItems="center"
        _hover={{ bg: "gray.50" }}
        onClick={handleExportExcel}
        width="100%"
      >
        <IconExport />
        <HSmallmd ml="8.46px" color="gray.500">
          Tabela inteira
        </HSmallmd>
      </Flex>
      <Flex
        pl="25.33px"
        py="8px"
        as="button"
        alignItems="center"
        _hover={{ bg: "gray.50" }}
        onClick={handleExportFilteredExcel}
        width="100%"
      >
        <IconExport />
        <HSmallmd ml="8.46px" color="gray.500">
          Tabela filtrada
        </HSmallmd>
      </Flex>
    </>
  );
};
