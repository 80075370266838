import { Flex, Image } from "@chakra-ui/react";

import { PendingDraw } from "../../../../../../main/common/assets";
import {
  TLargemd,
  TSmallmd,
} from "../../../../../../main/components/Tipography";

export const WarningStep = ({
  goTo = () => "",
  hasError,
}: {
  goTo?: () => void;
  hasError: boolean;
}) => {
  return (
    <Flex
      width="100%"
      justifyContent="center"
      alignItems="center"
      flexDir="column"
    >
      <Image src={PendingDraw} width="200px" />
      <TLargemd mt="2rem" textAlign="center">
        {hasError
          ? "Configurações dessa etapa não disponíveis no momento. Contate o suporte."
          : "Primeiro você precisar completar e confirmar as"}
        {!hasError && (
          <TSmallmd
            as="strong"
            fontWeight="bold"
            color="blue.600"
            cursor="pointer"
            onClick={goTo}
          >
            {" "}
            Configurações do Canal
          </TSmallmd>
        )}
      </TLargemd>
    </Flex>
  );
};
