import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { ITemplates } from "../../../../types/main/templates";
import { getTemplateById } from "../service/TemplateService";

export const useExistingTemplate = (uuid?: string) => {
  const toast = useToast();

  const [existingTemplate, setExistingTemplate] = useState<ITemplates>();

  const loadExistingTemplate = useCallback(
    async (uuid: string) => {
      try {
        const response = await getTemplateById(uuid);

        setExistingTemplate(response.body ?? undefined);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: DEFAULT_ERROR_MESSAGES.LIST_ACTION,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (uuid) loadExistingTemplate(uuid);
  }, [loadExistingTemplate, uuid]);

  return {
    existingTemplate,
  };
};
