import { Grid, GridItem } from "@chakra-ui/react";
import { useMemo, useRef } from "react";

import {
  TableForm,
  ITableFormRefProps,
} from "../../../../../../main/components/TableForm";
import { ITariffCarData } from "../../../../../../types/main/tariffCar";
import { useTariffForm } from "../../../hooks/useTariffForm";
import { ServiceRow } from "../../ServiceRow";

const CreateCarTableForm = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const tableForm = useRef<ITableFormRefProps>(null);
  const { servicesList } = useTariffForm();

  const tariffHeader = useMemo(
    () => [
      {
        name: "SERVIÇO",
      },
      {
        name: "VALOR",
      },
    ],
    []
  );

  const defaultTarifCar = useMemo<Partial<ITariffCarData>>(
    () => ({
      serviceUuid: undefined,
      tariffUuid: undefined,
      value: undefined,
    }),
    []
  );

  return (
    <Grid templateRows="(1, 1fr)" templateColumns="1fr" gap={4}>
      <GridItem colSpan={1}>
        <TableForm
          ref={tableForm}
          tableHeader={tariffHeader}
          rowReferenceName="tariffCarData"
          canDeleteRow={!isReadOnly}
          canAddRow={!isReadOnly}
          defaultNewRow={defaultTarifCar}
          renderProp={(index: number) => (
            <ServiceRow
              isReadOnly={isReadOnly}
              index={index}
              servicesList={servicesList}
            />
          )}
        />
      </GridItem>
    </Grid>
  );
};
export { CreateCarTableForm };
