interface IMap {
  [key: string]: readonly string[];
}

export const PERMITTED_REPORTS: IMap = {
  BLOCKED_CUSTOMERS: ["Master", "Admin", "Auditor", "Operador"],
  RECEIVED: ["Master", "Admin", "Auditor", "Operador"],
  RECEIVED_BY_REPICK: ["Master", "Admin", "Auditor", "Operador"],
  GENERATION_EFD_PIS_COFINS: ["Master", "Admin", "Auditor"],
  SEND_AUTOMATIC_DEBIT: ["Master", "Admin", "Auditor", "Operador"],
  RECEIVE_AUTOMATIC_DEBIT: ["Master", "Admin", "Auditor", "Operador"],
  SEND_BOLETO: ["Master", "Admin", "Auditor", "Operador"],
  RECEIVE_BOLETO: ["Master", "Admin", "Auditor", "Operador"],
  SEND_THIRD_PARTY_DEBIT: ["Master", "Admin", "Auditor", "Operador"],
  SEND_GENERAL: ["Master", "Admin", "Auditor", "Operador"],
  RECEIVE_GENERAL: ["Master", "Admin", "Auditor", "Operador"],
  CREDIT_GRANTED: ["Master", "Admin", "Auditor", "Operador"],
  BACKGROUND_LOGS: ["Master", "Admin"],
} as const;
