import { PlusSquareIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { ModulesType } from "../../common/types/modules";
import { useAuth } from "../../hooks/useAuth";
import { canCreate } from "./functions/permission";

interface ICreateNewDataButton {
  path: string;
  text: string;
  module: ModulesType;
}

export const CreateNewDataButton = ({
  path,
  text,
  module,
}: ICreateNewDataButton) => {
  const { permissionLevel } = useAuth();

  if (!canCreate(permissionLevel, module)) return null;

  return (
    <Link to={path}>
      <Button leftIcon={<PlusSquareIcon />}>{text}</Button>
    </Link>
  );
};
