/* eslint-disable eqeqeq */
import { Select, MenuPlacement } from "chakra-react-select";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

type Option = {
  value: any;
  label: string;
};

type Options = Option[];

interface IMultiSelectProps {
  name: string;
  option: Options;
  placeholder?: string;
  disabled?: boolean;
  isMulti?: boolean;
  menuPlacement?: MenuPlacement;
  isClearable?: boolean;
  onSelect?: (value: string) => void;
  isLoading?: boolean;
}

export const InputSelect = ({
  name,
  option,
  placeholder = "Selecione",
  disabled,
  isMulti = false,
  menuPlacement,
  isClearable,
  onSelect,
  isLoading,
  ...rest
}: IMultiSelectProps) => {
  const { control } = useFormContext<any>();

  const id = uuidv4();

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const value = isMulti
            ? option.filter((item) =>
                field.value.toString().includes(item.value)
              )
            : option.find((item) => item.value == field.value);

          return (
            <Select
              {...rest}
              {...field}
              id={id}
              options={option}
              isMulti={isMulti}
              isLoading={isLoading}
              instanceId={id}
              placeholder={placeholder}
              menuPortalTarget={document.body}
              menuPlacement={menuPlacement}
              isClearable={isClearable}
              chakraStyles={{
                control: (provided) => ({
                  ...provided,
                  _disabled: {
                    cursor: "not-allowed",
                    color: "inherit",
                    opacity: "inherit",
                  },
                }),
                container: () => ({
                  width: "100%",
                }),
                inputContainer: () => ({
                  width: "100%",
                }),

                dropdownIndicator: (provided) => ({
                  ...provided,
                  backgroundColor: "transparent",
                }),
              }}
              isDisabled={disabled}
              onChange={(newValue) => {
                if (isMulti) {
                  const value = newValue as Options;
                  field.onChange(value?.map((item) => item.value));
                } else {
                  const value = newValue as Option;
                  field.onChange(value?.value);
                  onSelect?.(value?.value);
                }
              }}
              value={value}
            />
          );
        }}
        defaultValue=""
      />
      {/* {hasError && <AlertError text={errorMessage} />} */}
    </div>
  );
};
