/* eslint-disable jsx-a11y/label-has-associated-control */

import { DeleteIcon, PlusSquareIcon } from "@chakra-ui/icons";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Box,
  Flex,
  TableContainer,
} from "@chakra-ui/react";
import { forwardRef, ReactNode, useCallback, useImperativeHandle } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

import { HLargexs } from "../Tipography/HeadingLarge";

export interface ITableHeaderDataTableForm {
  name: string;
  hidden?: boolean;
}

interface IDataTableRowFormProps {
  tableHeader: ITableHeaderDataTableForm[];
  rowReferenceName: string;
  renderProp: (index: number) => ReactNode;
  defaultNewRow?: any;
  canDeleteRow?: boolean;
  canAddRow?: boolean;
  isReadOnly?: boolean;
}

export interface ITableFormRefProps {
  addNewRow: () => void;
  removeAllRows: () => void;
  removeRowByIndex: (index: number) => void;
}

export const TableForm = forwardRef<ITableFormRefProps, IDataTableRowFormProps>(
  (
    {
      canAddRow = true,
      canDeleteRow = true,
      defaultNewRow = {},
      tableHeader,
      renderProp,
      rowReferenceName,
      isReadOnly,
    },
    ref
  ) => {
    const { control } = useFormContext();
    const { fields, append, remove } = useFieldArray({
      control, // control props comes from useForm (optional: if you are using FormContext)
      name: rowReferenceName, // unique name for your Field Array
    });

    const addNewRow = useCallback(() => {
      append(defaultNewRow);
    }, [append, defaultNewRow]);

    const removeAllRows = useCallback(() => {
      remove();
    }, [remove]);

    const removeRowByIndex = useCallback(
      (index: number) => {
        remove(index);
      },
      [remove]
    );

    useImperativeHandle(
      ref,
      () => ({
        addNewRow,
        removeAllRows,
        removeRowByIndex,
      }),
      [addNewRow, removeAllRows, removeRowByIndex]
    );

    return (
      <>
        <Box
          overflowX="auto"
          maxW="calc(100vw - (160px + 160px) )"
          borderRadius="10px"
        >
          <TableContainer maxHeight="600px" overflowY="auto">
            <Table
              variant="striped"
              colorScheme="gray"
              borderWidth="2px"
              borderColor={isReadOnly ? "gray.200" : "gray.300"}
              style={{
                borderCollapse: "separate",
                borderSpacing: "0",
              }}
            >
              <Thead position="sticky" top="0" zIndex="1">
                <Tr height="100%" background="white">
                  {canDeleteRow && (
                    <Th>
                      <DeleteIcon color="gray.100" fontSize="20px" />
                    </Th>
                  )}
                  {tableHeader.map((header) => {
                    return (
                      !header.hidden && (
                        <Th key={uuid()}>
                          <HLargexs>{header.name}</HLargexs>
                        </Th>
                      )
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody>
                {fields &&
                  fields.map((field, index) => {
                    return (
                      <Tr key={field.id}>
                        {canDeleteRow && (
                          <Td height="100%">
                            <DeleteIcon
                              cursor="pointer"
                              color="gray.500"
                              fontSize="20px"
                              transitionDuration="0.4s"
                              _hover={{ color: "red.500" }}
                              onClick={() => remove(index)}
                            />
                          </Td>
                        )}
                        {renderProp(index)}
                      </Tr>
                    );
                  })}
              </Tbody>
            </Table>
          </TableContainer>
        </Box>
        {canAddRow && (
          <Flex
            alignItems="center"
            justifyContent="center"
            gap="1rem"
            mt="2rem"
            padding="0.875rem 0"
            backgroundColor="green.50"
            color="green.600"
            borderStyle="dashed"
            borderWidth="2px"
            borderColor="green.300"
            borderRadius="10px"
            cursor="pointer"
            transitionDuration="0.4s"
            _hover={{ backgroundColor: "green.100" }}
            onClick={addNewRow}
          >
            <PlusSquareIcon />
            Adicionar novo
          </Flex>
        )}
      </>
    );
  }
);
