import { useEffect, useState } from "react";

import { getServiceProvisionOptions } from "../../../modules/Records/ServiceProvision/service/ServiceProvisionService";
import { IServiceProvisionOption } from "../../../types/main/serviceProvision";

export const useServicesOptions = () => {
  const [services, setServices] = useState<IServiceProvisionOption[]>([]);

  useEffect(() => {
    const fetch = async () => {
      const response = await getServiceProvisionOptions({
        return_even_if_deleted: [],
      });
      setServices(response.body || []);
    };

    fetch();
  }, []);

  return {
    servicesOptions: services.map((item) => ({
      value: item.uuid,
      label: item.name,
    })),
  };
};
