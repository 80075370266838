import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../../main/common/constants/defaultMessages";
import { IApi } from "../../../../../types/main/api";
import { IBiller, IBillerData } from "../../../../../types/main/biller";
import { AllApis, EditBiller, NewBiller } from "../../service/BillerService";

interface IUseBillerForm {
  isReadOnly: boolean | undefined;
  existingBiller?: IBiller;
  reset: UseFormReset<IBillerData>;
  setValue: UseFormSetValue<IBillerData>;
  billerUUID?: string | undefined;
}

export const useBillerForm = ({ billerUUID }: IUseBillerForm) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [apis, setApis] = useState<Array<IApi>>([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadApis = async () => {
      const allApis = await AllApis();
      setApis(allApis?.body ?? []);
    };

    loadApis();
  }, []);

  const onSubmit = useCallback(
    async (billerFields: IBillerData) => {
      try {
        setIsLoading(true);
        const billerData: IBillerData = {
          name: billerFields.name,
          securityCode: billerFields.securityCode,
          apis: billerFields.apis,
        };

        let response;

        if (billerUUID) {
          response = await EditBiller(billerFields, billerUUID);
        } else response = await NewBiller(billerData);

        toast({
          title: "Efetuado com sucesso.",
          description: response.body?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/billerlist");
        setIsLoading(false);
      } catch (error: any) {
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    },
    [billerUUID, navigate, toast]
  );

  return {
    onSubmit,
    isLoading,
    apis,
  };
};
