import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";

import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IContracts,
  IContractsData,
  IContractsList,
  IContractsOptions,
} from "../../../../types/main/contract";
import { IPaginateResponse } from "../../../../types/main/pagination";
import { IResponse } from "../../../../types/main/response";

const MODULE_BASE_URL = "/contract";

export interface IContractsDataTransformDateToString
  extends Omit<IContracts, "creationDate"> {
  creationDate: string;
}

type ContractListParams = {
  pagination?: PaginationState;
  filters?: TableFilteredParams;
  sorting?: TableSortingParams;
};

export const getAllContractsList = async ({
  pagination,
  filters,
  sorting,
}: ContractListParams) => {
  try {
    const page = pagination?.pageIndex;

    const {
      data,
    }: AxiosResponse<IResponse<IPaginateResponse<IContractsList>>> =
      await recordApi.get(MODULE_BASE_URL, {
        params: {
          page: page === undefined ? 1 : page + 1,
          take: pagination?.pageSize ?? 10,
          ...filters,
          ...sorting,
        },
      });
    return data;
  } catch (err: any) {
    return null;
  }
};

export const getContractOptions = async (
  idContract?: string,
  withDeletedUuid?: string
) => {
  try {
    const response: AxiosResponse<IResponse<Array<IContractsOptions>>> =
      await recordApi.get(`${MODULE_BASE_URL}/list/options`, {
        params: {
          idContract,
          withDeletedUuid,
        },
      });
    return response.data.body ?? [];
  } catch (err: any) {
    return [];
  }
};

export const deleteContracts = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<Array<IContracts>>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewContract = async (data: IContractsData) => {
  try {
    const response: AxiosResponse = await recordApi.post(MODULE_BASE_URL, {
      ...data,
      inclusionDate: new Date(data.inclusionDate),
    });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const UpdateContract = async ({
  data,
  uuid,
}: {
  data: IContractsData;
  uuid: string;
}) => {
  try {
    const response: AxiosResponse = await recordApi.patch(
      `${MODULE_BASE_URL}/${uuid}`,
      {
        ...data,
        inclusionDate: new Date(data.inclusionDate),
      }
    );

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetContractByUUID = async (
  uuid: string
): Promise<IResponse<IContracts>> => {
  try {
    const { data }: AxiosResponse<IResponse<IContracts>> = await recordApi.get(
      `${MODULE_BASE_URL}/${uuid}`
    );
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetContractCsvAllUUIDs = async () => {
  try {
    const { data } = await recordApi.get(`getContractCsvAllUuids`);
    return data;
  } catch (err: any) {
    return null;
  }
};

export const GetContractCsvByUUIDs = async (filters: TableFilteredParams) => {
  try {
    const { data } = await recordApi.post(`getContractCsvByUuids`, undefined, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};
