import * as yup from "yup";

import { checkForSpecialChars } from "../../../../main/common/utils/checkStrings";

export const productSchema = yup
  .object({
    name: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    category: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
  })
  .required();
