import { AxiosResponse } from "axios";

import { customToast } from "../../../../main/common/utils/customToast";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import {
  ITemplateOptions,
  ITemplates,
  ITemplatesData,
  ITemplatesList,
} from "../../../../types/main/templates";

const MODULE_BASE_URL = "/templates";

export const getAllTemplates = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string | null;
}) => {
  try {
    const response: AxiosResponse<IResponse<ITemplatesList[]>> =
      await recordApi.get(
        return_even_if_deleted
          ? `${MODULE_BASE_URL}?return_even_if_deleted=${return_even_if_deleted}`
          : MODULE_BASE_URL
      );

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getTemplateById = async (uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<ITemplates>> = await recordApi.get(
      `${MODULE_BASE_URL}/search/${uuid}`
    );

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getTemplateOptions = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string[];
}) => {
  try {
    const uuids = return_even_if_deleted?.filter((item) => !!item);

    const query =
      Array.isArray(uuids) && uuids.length > 0
        ? `?return_even_if_deleted=${uuids.join(";")}`
        : "";

    const response: AxiosResponse<IResponse<Array<ITemplateOptions>>> =
      await recordApi.get(`${MODULE_BASE_URL}/options${query}`);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const createNewTemplate = async (template: ITemplatesData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, template);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const updateTemplate = async (
  template: ITemplatesData,
  uuid: string
) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, template);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const deleteTemplate = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const dispatchEmail = async (
  emailType: "invoices" | "remittance" | "boleto" | "nfe"
) => {
  try {
    recordApi.get(`email/dispatch/${emailType}`, {
      timeout: 1000, // 1 second
    });

    customToast(
      "info",
      "Envio iniciado! Confira o relatorio de segundo plano e os status de envio nas listagens."
    );

    return null;
  } catch (error: any) {
    return null;
  }
};
