import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { MdErrorOutline } from "react-icons/md";
import { Link } from "react-router-dom";

import { Backdrop } from "../../../../../main/components/Backdrop";
import { InputSelect } from "../../../../../main/components/InputSelect";
import { InputSelectAsync } from "../../../../../main/components/InputSelectAsync";
import { TreeSelect } from "../../../../../main/components/TreeSelect";
import { IGetAllInvoicingTariffs } from "../../../../../types/main/invoicing";
import { getClientOptions } from "../../../Clients/service/ClientService";
import { useInvoicingSimulationFilters } from "../../hooks/useInvoicingSimulationFilters";
import { simulateInvoicesSchema } from "../../validations/simulateInvoices.schema";

export const InvoicingForm = () => {
  const formMethods = useForm<IGetAllInvoicingTariffs>({
    resolver: yupResolver(simulateInvoicesSchema),
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const {
    channels,
    clients,
    hasFetchError,
    isFetching,
    productsCategories,
    onSubmitSimulationRequest,
  } = useInvoicingSimulationFilters();

  if (isFetching) return <Backdrop />;

  if (hasFetchError) {
    return (
      <div>
        <MdErrorOutline />
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitSimulationRequest)}>
        <Flex
          w="100%"
          h="100%"
          justifyContent="flex-start"
          gap="15px"
          flexWrap="wrap"
        >
          <FormControl isInvalid={!!errors.channels}>
            {channels && (
              <TreeSelect
                data={channels}
                placeholder="Canais"
                name="channels"
                // onSelectChange={(value) =>
                //   setValue("channels", value as number[])
                // }
                // setTreeData={setTreeSelectData}
              />
            )}
          </FormControl>

          <FormControl isInvalid={!!errors.clients}>
            <FormLabel htmlFor="clients">Clientes</FormLabel>
            <InputSelectAsync
              name="clients"
              option={clients}
              placeholder="Clientes"
              fetchOptions={getClientOptions}
              isMulti
            />
          </FormControl>

          <FormControl isInvalid={!!errors.productCategories}>
            <FormLabel htmlFor="productCategories">
              Categorias de Produto
            </FormLabel>
            <InputSelect
              isMulti
              name="productCategories"
              option={productsCategories.map((item) => ({
                label: item.category,
                value: item.category,
              }))}
              placeholder="Produtos"
            />
          </FormControl>
        </Flex>
        <Flex width="100%" justifyContent="flex-end">
          <ButtonGroup
            spacing="58"
            alignItems="center"
            position="relative"
            mt="4rem"
          >
            <Link to="/invoicinglist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>

            <Button
              backgroundColor="blue.500"
              type="submit"
              isLoading={isSubmitting}
              ml="32px"
            >
              Simular de Faturas
            </Button>
          </ButtonGroup>
        </Flex>
      </form>
    </FormProvider>
  );
};
