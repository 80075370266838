/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import useSWR from "swr";
import { MODULES } from "../../../../main/common/constants/modules";
import { BadgeTooltip } from "../../../../main/components/BadgeTooltip";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import { CSVPopover } from "../../../../main/components/DataTableServer/Popovers/CSVPopover";
import { LogAlert } from "../../../../main/components/LogAlert";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IInvoicingLogList } from "../../../../types/main/invoicing";
import { countTariffMissingDueDate } from "../../Tariff/service/TariffService";
import { getAllInvoicing } from "../service/InvoicingService";

const exportHeaders = {
  username: "Usuário",
  createdAt: "Criado em",
  channels: "Canais",
  countChannels: "Qtd Canais",
  productCategories: "Categorias Produtos",
  clients: "Clientes",
  countClients: "Qtd Clientes",
  invoiceValueSum: "Valor Faturas",
  invoiceFineSum: "Valor Multas",
  invoiceInterestSum: "Valor Juros",
  invoiceDiscountValueSum: "Valor Descontos",
  invoiceTotalValueSum: "Valor Total",
};

const StatusBadge = ({ status }: { status: "GERANDO" | "GERADO" }) => {
  const color = status === "GERANDO" ? "yellow" : "green";

  return (
    <Flex alignItems="center" justifyContent="center">
      <BadgeTooltip colorScheme={color} label={status} />
    </Flex>
  );
};

export const InvoicingLogsList: React.FC = () => {
  const [allInvoicing, setAllInvoicing] = useState<Array<IInvoicingLogList>>(
    []
  );

  const { data: countMissingDueDate, isLoading: loadingMissingDueDate } =
    useSWR("/tariff/process/due-date/count", countTariffMissingDueDate, {
      revalidateOnFocus: false,
      refreshInterval: 1000 * 15, // 15 seconds
    });

  const [isLoading, setIsLoading] = useState(false);

  const columns = useMemo<ColumnDef<IInvoicingLogList, any>[]>(
    () => [
      {
        header: "Usuário",
        accessorKey: "username",
      },
      {
        header: "Situação",
        accessorKey: "status",
        enableColumnFilter: false,
        enableSorting: false,
        cell: (info) => <StatusBadge status={info.getValue()} />,
      },
      {
        header: "Faturas a gerar",
        enableColumnFilter: false,
        enableSorting: false,
        accessorKey: "pendingInvoices",
      },
      {
        header: "Criado em",
        id: "createdAt",
        accessorKey: "createdAt",
        filterFn: "date",
        cell: (info) => {
          const date = new Date(info.getValue());
          return format(date, "dd/MM/yyyy - HH'h':mm", {
            locale: ptBR,
          });
        },
      },
      {
        header: "Canais",
        id: "channels",
        accessorKey: "channels",
        cell: (info) => {
          const total = info.row.original.countChannels;
          if (total > 1) return `${total} canais`;
          return info.getValue();
        },
      },
      {
        header: "Categorias Produtos",
        id: "productCategories",
        accessorKey: "productCategories",
        cell: (info) => info.getValue()?.join(", "),
      },
      {
        header: "Qtd. Clientes",
        id: "countClients",
        accessorKey: "countClients",
        filterFn: "fuzzy",
      },
      {
        // eslint-disable-next-line react/no-unstable-nested-components
        header: (info) => (
          <CSVPopover table={info.table} headerObject={exportHeaders} />
        ),
        id: "actions",
        cell: "",
      },
    ],
    []
  );

  const handleInvoicingList = async () => {
    setIsLoading(true);
    const response = await getAllInvoicing();

    if (response.type === "success" && response.body)
      setAllInvoicing(response.body);

    setIsLoading(false);
  };

  useEffect(() => {
    handleInvoicingList();
  }, []);

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Faturamento",
      href: "/",
    },
    {
      description: "Faturamentos",
      href: "/invoicinglist",
    },
  ];

  const showMissingDueDateAlert =
    !loadingMissingDueDate && !!countMissingDueDate && countMissingDueDate > 0;

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <LogAlert
        text="Há tentativas de geração de NFe fora do intervalo permitido:"
        logType="NFE_BLOCK"
      />
      {showMissingDueDateAlert && (
        <Alert status="info" mb="32px" borderRadius={4}>
          <AlertIcon />
          <AlertTitle mr={2} fontWeight={500} color="#49505c">
            Tarifas sem Data de Vencimento a serem processadas:{" "}
            <b>{countMissingDueDate}</b>.
            <Link to="/invoice-remittance-logs">
              <Button variant="link" colorScheme="facebook" ml={2}>
                Mais detalhes.
              </Button>
            </Link>
          </AlertTitle>
        </Alert>
      )}
      <Flex justifyContent="space-between" alignItems="center">
        <HLarge2xl mb="32px" color="gray.600">
          Faturamentos
        </HLarge2xl>
        <Box>
          <CreateNewDataButton
            module={MODULES.INVOICES}
            path="/invoicing"
            text="Gravar novo faturamento"
          />
        </Box>
      </Flex>

      <TableServer
        refresh={handleInvoicingList}
        data={allInvoicing}
        defaultColumns={columns}
        isLoading={isLoading}
        minPaddingBlockTd={4}
      />
    </Wrapper>
  );
};
