import { FormControl, Switch } from "@chakra-ui/react";
import { useState } from "react";

import { Backdrop } from "../../../../../main/components/Backdrop";
import { IRemittanceView } from "../../../../../types/main/remittances";
import { recalculateRemittanceValue } from "../../service/remittanceService";

export const DiscardInterestAndFineCell = ({
  discardInterestAndFine,
  remittance,
  setRemittances,
}: {
  remittance: IRemittanceView;
  discardInterestAndFine: boolean;
  setRemittances: React.Dispatch<
    React.SetStateAction<{
      isFetching: boolean;
      data: IRemittanceView[];
    }>
  >;
}) => {
  const [isChecked, setIsChecked] = useState(discardInterestAndFine);

  const [isLoading, setIsLoading] = useState(false);

  const recalculateValue = async (
    discardInterestAndFine: boolean,
    remittance: IRemittanceView
  ) => {
    setIsLoading(true);
    const newRemittancesCalculate = await recalculateRemittanceValue(
      remittance.calculatePayload.map((item) => ({
        ...item,
        discardInterestAndFine,
      }))
    );
    setRemittances((oldState) => ({
      ...oldState,
      data: oldState.data.map((state) => {
        if (state.key === remittance.key) {
          return {
            ...state,
            calculatePayload: newRemittancesCalculate.calculatePayload,
            totalFine: newRemittancesCalculate.totalFine,
            totalInterest: newRemittancesCalculate.totalInterest,
            totalValue: newRemittancesCalculate.totalValue,
          };
        }
        return state;
      }),
    }));
    setIsLoading(false);
  };

  const handleChange = () => {
    const newStateChecked = !isChecked;

    setRemittances((oldState) => ({
      ...oldState,
      data: oldState.data.map((state) => {
        if (state.key === remittance.key) {
          return {
            ...state,
            discardInterestAndFine: newStateChecked,
            totalValue: newStateChecked
              ? state.totalValue - state.totalInterest - state.totalFine
              : state.totalValue + state.totalInterest + state.totalFine,
          };
        }
        return state;
      }),
    }));
    setIsChecked(newStateChecked);

    recalculateValue(newStateChecked, remittance);
  };

  return (
    <>
      <FormControl display="flex" alignItems="center">
        <Switch isChecked={isChecked} onChange={handleChange} />
      </FormControl>
      {isLoading && <Backdrop />}
    </>
  );
};
