import {
  Button,
  ButtonGroup,
  Flex,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { DateTimePicker } from "../DateTimePicker";

export interface IOnRangeChangeProps {
  startDate: string | undefined;
  endDate: string | undefined;
  startTime: string | undefined;
  endTime: string | undefined;
}
interface IRangeDatePicker {
  onRangeChange?: (rangeData: IOnRangeChangeProps) => void;
  onClearDate: () => void;
  onSearch: (rangeData: IOnRangeChangeProps) => void;
  disabled?: boolean;
}
export const RangeDatePicker = ({
  onRangeChange,
  onClearDate,
  onSearch,
  disabled,
}: IRangeDatePicker) => {
  const [startDate, setStartDate] = useState<string | undefined>("");
  const [endDate, setEndDate] = useState<string | undefined>("");
  const [startTime, setStartTime] = useState<string | undefined>("");
  const [endTime, setEndTime] = useState<string | undefined>("");

  const { setValue } = useFormContext();

  const handleStartDateChange = (date: Date | undefined) => {
    setStartDate(date?.toISOString());
  };
  const handleEndDateChange = (date: Date | undefined) => {
    setEndDate(date?.toISOString());
  };

  const handleClearDate = () => {
    setStartDate("");
    setEndDate("");
    setStartTime("");
    setEndTime("");

    setValue("startTime", undefined);
    setValue("endTime", undefined);

    onClearDate();
  };

  useEffect(() => {
    if (onRangeChange)
      onRangeChange({
        startDate,
        endDate,
        startTime,
        endTime,
      });
  }, [endDate, endTime, onRangeChange, startDate, startTime]);

  return (
    <Menu>
      <MenuButton as={Button} disabled={disabled}>
        Intervalo de Datas
      </MenuButton>
      <MenuList maxW="420px">
        <Flex flexDir="column" gap="2" padding="4">
          <DateTimePicker
            dateLabel="Data Inicial"
            timeLabel="Hora Inicial"
            timePickerProps={{
              fieldname: "startTime",
              onTimeChange: setStartTime,
            }}
            datePickerProps={{
              fieldname: "startDate",
              placement: "auto",
              onDateChange: handleStartDateChange,
            }}
          />
          <DateTimePicker
            dateLabel="Data Final"
            timeLabel="Hora Final"
            timePickerProps={{
              fieldname: "endTime",
              onTimeChange: setEndTime,
            }}
            datePickerProps={{
              fieldname: "endDate",
              placement: "auto",
              onDateChange: handleEndDateChange,
            }}
          />
          <ButtonGroup display="flex" justifyContent="flex-end">
            <Button
              onClick={handleClearDate}
              fontWeight={600}
              mt={3}
              variant="link"
              w="100px"
            >
              Limpar
            </Button>
            <Button
              onClick={() =>
                onSearch({
                  endDate,
                  endTime,
                  startDate,
                  startTime,
                })
              }
              fontWeight={600}
              mt={3}
              w="100px"
            >
              Buscar
            </Button>
          </ButtonGroup>
        </Flex>
      </MenuList>
    </Menu>
  );
};
