import { Box } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { useProductOptions } from "../../../hooks/useFetch/useProductsOptions";
import { useServicesOptions } from "../../../hooks/useFetch/useServicesOptions";
import { InputSelect } from "../../InputSelect";
import { CustomFilterProps } from "../TableHeader";

type Option = {
  value: any;
  label: string;
};

export const SelectFilter = ({ column }: CustomFilterProps) => {
  const methods = useForm<{ value: string }>();

  const onSubmit = (data: { value: string }) => {
    column.setFilterValue(data.value);
  };

  const options = (column.columnDef.meta ?? []) as Option[];

  return (
    <FormProvider {...methods}>
      <Box w="100%">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputSelect
            name="value"
            isClearable
            onSelect={(value) => {
              column.setFilterValue(value);
            }}
            placeholder=""
            option={options}
          />
        </form>
      </Box>
    </FormProvider>
  );
};

export const SelectProductFilter = ({ column }: CustomFilterProps) => {
  const { productsOptions } = useProductOptions();

  const methods = useForm<{ value: string }>();

  const onSubmit = (data: { value: string }) => {
    column.setFilterValue(data.value);
  };

  // const options = (column.columnDef.meta ?? []) as Option[];

  return (
    <FormProvider {...methods}>
      <Box w="100%">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputSelect
            name="value"
            isClearable
            onSelect={(value) => {
              column.setFilterValue(value);
            }}
            placeholder=""
            option={productsOptions}
          />
        </form>
      </Box>
    </FormProvider>
  );
};

export const SelectServicesFilter = ({ column }: CustomFilterProps) => {
  const { servicesOptions } = useServicesOptions();

  const methods = useForm<{ value: string }>();

  const onSubmit = (data: { value: string }) => {
    column.setFilterValue(data.value);
  };

  // const options = (column.columnDef.meta ?? []) as Option[];

  return (
    <FormProvider {...methods}>
      <Box w="100%">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <InputSelect
            name="value"
            isClearable
            onSelect={(value) => {
              column.setFilterValue(value);
            }}
            placeholder=""
            option={servicesOptions}
          />
        </form>
      </Box>
    </FormProvider>
  );
};
