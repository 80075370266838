import axios, { AxiosInstance } from "axios";

import { jwtRequestInterceptor } from "./interceptor";

const apiUri: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : "https://ENV_ACCESS_API_URL";

const accessApi: AxiosInstance = axios.create({
  baseURL: apiUri,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

jwtRequestInterceptor(accessApi);

export { accessApi };
