import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_CONTRACTS_PERMISSIONS_LEVEL,
  GET_CONTRACTS_PERMISSIONS_LEVEL,
  UPDATE_CONTRACTS_PERMISSIONS_LEVEL,
} from "./constants/permissions";
import { ContractList } from "./pages/ContractList";
import { ContractManagement } from "./pages/ContractManagement";

export function ContractModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_CONTRACTS_PERMISSIONS_LEVEL} />}
      >
        <Route path="/contractlist" element={<ContractList />} />
        <Route
          path="/viewcontract/:uuid"
          element={<ContractManagement isReadOnly />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute permission={CREATE_CONTRACTS_PERMISSIONS_LEVEL} />
        }
      >
        <Route path="/managecontract" element={<ContractManagement />} />
      </Route>
      <Route
        element={
          <PrivateRoute permission={UPDATE_CONTRACTS_PERMISSIONS_LEVEL} />
        }
      >
        <Route path="/managecontract/:uuid" element={<ContractManagement />} />
      </Route>
    </Routes>
  );
}
