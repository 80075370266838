import { Flex } from "@chakra-ui/react";
import React from "react";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { TariffForm } from "../components/TariffForm";

interface ITariffForm {
  isReadOnly?: boolean;
}

export const TariffManagement: React.FC<ITariffForm> = ({ isReadOnly }) => {
  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Tarifa", href: "/tarifflist" },
    {
      description: isReadOnly ? "Visualizar Tarifa" : "Criar nova tarifa",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="40px" color="gray.600">
          {isReadOnly ? "Visualizar tarifa" : "Criar nova tarifa"}
        </HLarge2xl>
      </Flex>
      <TariffForm isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
