import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import { IPaymentBanks } from "../../../../types/main/paymentBanks";
import { IPaymentLayout } from "../../../../types/main/paymentLayout";
import {
  IPaymentMethods,
  IPaymentMethodsData,
  IPaymentOptionsGroup,
} from "../../../../types/main/paymentMethods";
import { IPaymentTransmissionChannel } from "../../../../types/main/paymentTransmissionChannel";
import { IPaymentType } from "../../../../types/main/paymentType";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/paymentmethods";

export const allPaymentMethod = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string;
}): Promise<IResponse<Array<IPaymentMethods>>> => {
  try {
    const response: AxiosResponse<IResponse<Array<IPaymentMethods>>> =
      await recordApi.get(
        return_even_if_deleted
          ? `${MODULE_BASE_URL}?return_even_if_deleted=${return_even_if_deleted}`
          : MODULE_BASE_URL
      );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const deletePaymentMethod = async (uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${uuid}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewPaymentMethod = async (data: IPaymentMethodsData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, {
        ...data,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export interface IUpdatePaymentMethod extends IPaymentMethodsData {
  uuid?: string;
}

export const UpdatePaymentMethod = async ({
  uuid,
  ...rest
}: IUpdatePaymentMethod) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, rest);

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetPaymentMethodByUUID = async (
  uuid: string
): Promise<IResponse<IPaymentMethods>> => {
  try {
    const { data }: AxiosResponse<IResponse<IPaymentMethods>> =
      await recordApi.get(`${MODULE_BASE_URL}/search/${uuid}`);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const allBanksPaymentMethod = async (): Promise<
  IResponse<Array<IPaymentBanks>>
> => {
  try {
    const response: AxiosResponse<IResponse<Array<IPaymentBanks>>> =
      await recordApi.get(`${MODULE_BASE_URL}/banks`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const allLayoutPaymentMethod = async (): Promise<
  IResponse<Array<IPaymentLayout>>
> => {
  try {
    const response: AxiosResponse<IResponse<Array<IPaymentLayout>>> =
      await recordApi.get(`${MODULE_BASE_URL}/layouts`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const allTransmissionChannelPaymentMethod = async (): Promise<
  IResponse<Array<IPaymentTransmissionChannel>>
> => {
  try {
    const response: AxiosResponse<
      IResponse<Array<IPaymentTransmissionChannel>>
    > = await recordApi.get(`${MODULE_BASE_URL}/transmissionchannels`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const allTypesPaymentMethod = async (): Promise<
  IResponse<Array<IPaymentType>>
> => {
  try {
    const response: AxiosResponse<IResponse<Array<IPaymentType>>> =
      await recordApi.get(`${MODULE_BASE_URL}/types`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getPaymentMethodOptions = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string[];
}) => {
  try {
    const { data }: AxiosResponse<IResponse<Array<IPaymentOptionsGroup>>> =
      await recordApi.get(
        return_even_if_deleted
          ? `${MODULE_BASE_URL}/options?return_even_if_deleted=${return_even_if_deleted.join(
              ";"
            )}`
          : `${MODULE_BASE_URL}/options`
      );

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};
