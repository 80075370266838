import * as yup from "yup";

import {
  checkForNumericChars,
  checkForSpecialChars,
  checkForSpecialCharsOnUserCreation,
} from "../../../../main/common/utils/checkStrings";

export const userSchema = yup
  .object({
    fullName: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .test(
        "Numeric Characters validation",
        "Caracteres numéricos não permitidos.",
        (value) => !checkForNumericChars(value ?? "")
      )
      .required("Esse campo é obrigatório")
      .min(4, "Mínimo de 4 caracteres"),
    username: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialCharsOnUserCreation(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    profile: yup.string().required("Esse campo é obrigatório"),
    email: yup
      .string()
      .required("Esse campo é obrigatório")
      .email("Formato de email inválido"),
  })
  .required();
