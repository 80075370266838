import { FormLabel, Grid, Select } from "@chakra-ui/react";

import { getMonthFullName } from "../../../../common/utils/dateHelper";
import { TSmallmd } from "../../../Tipography";
import { fromMonth, toMonth } from "../constants";

type YearMonthFormProps = {
  handleYearMonthChange: (
    e: any,
    customMonth?: { year?: number; month?: number }
  ) => void;
  date: Date;
  name: string;
};

export const YearMonthForm = ({
  date,
  handleYearMonthChange,
  name,
}: YearMonthFormProps) => {
  const months = getMonthFullName();
  const years = [];

  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  return (
    <div className="DayPicker-Caption">
      <Grid p={0} templateColumns="1fr 42%" ml={2} mb={2}>
        <FormLabel htmlFor={`month${name}`} justifyContent="center">
          <TSmallmd mb={1}>Mês</TSmallmd>
          <Select
            id={`month${name}`}
            name={`month${name}`}
            onChange={handleYearMonthChange}
            value={date.getMonth()}
          >
            {months.map((month: string, i: number) => (
              <option key={month} value={i}>
                {month}
              </option>
            ))}
          </Select>
        </FormLabel>
        <FormLabel htmlFor={`year${name}`}>
          <TSmallmd mb={1}>Ano</TSmallmd>
          <Select
            id={`year${name}`}
            name={`year${name}`}
            onChange={handleYearMonthChange}
            value={date.getFullYear()}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormLabel>
      </Grid>
    </div>
  );
};
