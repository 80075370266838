import { useEffect, useState } from "react";

import { IChannelsOption } from "../../../../../types/main/channel";
import { IPaymentOptionsGroup } from "../../../../../types/main/paymentMethods";
import { IProductOption } from "../../../../../types/main/products";
import { IProviderOption } from "../../../../../types/main/providers";
import { IServiceProvisionOption } from "../../../../../types/main/serviceProvision";
import { getPaymentMethodOptions } from "../../../PaymentMethod/service/PaymentMethodService";
import { getProductOptions } from "../../../Products/service/ProductService";
import { getProvidersOptions } from "../../../ServiceProvider/services/ServiceProviderService";
import { getServiceProvisionOptions } from "../../../ServiceProvision/service/ServiceProvisionService";
import { getChannelOptions } from "../../services/ChannelService";

interface IUseFetchChannelConfigLists {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  waitForRequest: boolean;
  idListOnScreenProvider?: string | null;
  idListOnScreenServiceProvision?: string[];
  idListOnScreenProduct?: string[];
  idListOnScreenPaymentMethod?: string[];
}

export const useFetchChannelConfigLists = ({
  setIsLoading,
  waitForRequest,
  idListOnScreenProvider,
  idListOnScreenServiceProvision,
  idListOnScreenProduct,
  idListOnScreenPaymentMethod,
}: IUseFetchChannelConfigLists) => {
  const [parentChannelsList, setParentChannelsList] = useState<
    IChannelsOption[]
  >([]);
  const [providersList, setProvidersList] = useState<IProviderOption[]>([]);
  const [servicesList, setServicesList] = useState<IServiceProvisionOption[]>(
    []
  );
  const [productList, setProductList] = useState<IProductOption[]>([]);
  const [paymentMethodsList, setPaymentMethodsList] = useState<
    IPaymentOptionsGroup[]
  >([]);

  const [hasFetchError, setHasFetchError] = useState(false);

  const fetchParentChannels = async () => {
    const response = await getChannelOptions();
    return response.body ?? [];
  };

  const fetchProviders = async () => {
    const response = await getProvidersOptions({
      return_even_if_deleted: idListOnScreenProvider,
    });
    return response.body ?? [];
  };

  const fetchServices = async () => {
    const response = await getServiceProvisionOptions({
      return_even_if_deleted: idListOnScreenServiceProvision,
    });
    return response.body ?? [];
  };

  const fetchProducts = async () => {
    const response = await getProductOptions({
      return_even_if_deleted: idListOnScreenProduct,
    });
    return response.body ?? [];
  };

  const fetchPaymentMethod = async () => {
    const response = await getPaymentMethodOptions({
      return_even_if_deleted: idListOnScreenPaymentMethod,
    });
    return response.body ?? [];
  };

  useEffect(() => {
    let isCancelled = false;

    const loadAllDataLists = async () => {
      try {
        setIsLoading(true);
        const parentChannels = await fetchParentChannels();

        let providers: IProviderOption[];
        if (waitForRequest) {
          providers = await fetchProviders();
        } else {
          providers = [];
        }

        const services = await fetchServices();

        const products = await fetchProducts();

        const paymentMethods = await fetchPaymentMethod();
        if (!isCancelled) {
          setProvidersList(providers);
          setServicesList(services);
          setProductList(products);
          setParentChannelsList(parentChannels);
          setPaymentMethodsList(paymentMethods);
          setIsLoading(false);
        }
      } catch (e) {
        if (!isCancelled) {
          setHasFetchError(true);
          setIsLoading(false);
        }
      }
    };

    loadAllDataLists();

    return () => {
      isCancelled = true;
    };
  }, [setHasFetchError, setIsLoading, waitForRequest]);

  return {
    parentChannelsList,
    providersList,
    servicesList,
    productList,
    paymentMethodsList,
    hasFetchError,
  };
};
