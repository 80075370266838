import { AxiosResponse } from "axios";

import { TableFilteredParams } from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import { IResponse } from "../../../../types/main/response";
import { ITariffMovementsFields } from "../../../../types/main/tariffMovements";

type IProps =
  | {
      tariffId: number;
      tariffCarUuid?: string;
    }
  | {
      tariffId?: number;
      tariffCarUuid: string;
    };

const MODULE_BASE_URL = "/tariffMovements";

export const GetTariffMovementsByIdOrUuid = async ({
  tariffId,
  tariffCarUuid,
}: IProps): Promise<IResponse<ITariffMovementsFields[]>> => {
  try {
    const { data }: AxiosResponse<IResponse<ITariffMovementsFields[]>> =
      await recordApi.get(MODULE_BASE_URL, {
        params: {
          tariffId,
          tariffCarUuid,
        },
      });
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getMovementsCsv = async () => {
  try {
    const { data } = await recordApi.get(`getAllMovements`);
    return data;
  } catch (err: any) {
    return null;
  }
};

export const getAllMovementsWithFilters = async (
  filters: TableFilteredParams
) => {
  try {
    const { data } = await recordApi.get(`getAllMovementsWithFilters`, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};
