import { FormControl, Select, Td, Tooltip } from "@chakra-ui/react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { DEFAULT_REGEX_ONLY_NUMBERS_WITH_TWO_DECIMALS } from "../../../../../main/common/constants/defaultValidations";
import { NumberFilterInput } from "../../../../../main/components/NumberFilterInput";
import { useTableFormRowHandleErrors } from "../../../../../main/hooks/useTableFormRowHandleErrors";
import { IServiceProvisionOption } from "../../../../../types/main/serviceProvision";

interface IRowForm {
  servicesList: IServiceProvisionOption[];
  index: number;
  isReadOnly?: boolean;
}

export const ServiceRow = memo(
  ({ index, servicesList, isReadOnly }: IRowForm) => {
    const {
      register,
      formState: { errors },
    } = useFormContext();

    const { getErrorMessage, hasError } = useTableFormRowHandleErrors({
      errors,
      index,
    });

    return (
      <>
        <Td width="50%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl isInvalid={hasError("tariffCarData", `serviceUuid`)}>
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("tariffCarData", `serviceUuid`)}
              placement="bottom"
              fontSize="small"
            >
              <Select
                placeholder="-"
                disabled={isReadOnly}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                {...register(`tariffCarData[${index}].serviceUuid`)}
              >
                {servicesList &&
                  servicesList.map(({ name, uuid }) => (
                    <option key={uuid} value={uuid}>
                      {name}
                    </option>
                  ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Td>
        <Td width="50%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl isInvalid={hasError("tariffCarData", `value`)}>
            <Tooltip
              hasArrow
              bg="red.500"
              label={
                getErrorMessage("tariffCarData", `value`)
                  ? "Esse campo é obrigatório"
                  : ""
              }
              placement="bottom"
              fontSize="small"
            >
              <NumberFilterInput
                fieldname={`tariffCarData[${index}].value`}
                disabled={isReadOnly}
                type="monetary"
                regex={DEFAULT_REGEX_ONLY_NUMBERS_WITH_TWO_DECIMALS}
                onChange={(e) => {
                  return e.replaceAll(",", ".");
                }}
              />
            </Tooltip>
          </FormControl>
        </Td>
      </>
    );
  }
);
