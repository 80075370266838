import { format } from "date-fns";
import { useCallback, useState } from "react";

import { DocumentColumnFilter } from "../../../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { ReportsUnion } from "../../../pages/ReportList";
import { getSendCharges } from "../../../service/ReportService";
import { ButtonLinkNfe } from "../../ButtonLinkNfe";
import { ButtonLinkProcessing } from "../../ButtonLinkProcessing";
import { SendChargesFiltersPayload } from "./Filters";

export const useSendCharges = (nameReport: ReportsUnion) => {
  const [report, setReport] = useState<{
    data: Array<any>;
    isFetching: boolean;
  }>({
    data: [],
    isFetching: false,
  });

  const { columns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "DATA DE ENVIO",
            accessor: "sentDate",
            Cell: ({ value }) => {
              if (!value) return "";

              return format(new Date(value), "dd/MM/yyyy");
            },
          },
          {
            Header: "NSA",
            accessor: "nsa",
          },
          {
            Header: "TARIFA",
            accessor: "tariffId",
          },
          {
            Header: "REMESSA",
            accessor: "remittanceId",
          },
          {
            Header: "SERVIÇO",
            accessor: "service",
          },
          {
            Header: "CANAL",
            accessor: "channel",
          },
          {
            Header: "HIERARQUIA",
            accessor: "parentchannel",
          },
          {
            Header: "CLIENTE",
            accessor: "clients",
          },
          {
            Header: "CPF/CNPJ",
            accessor: "document",
            Filter: DocumentColumnFilter,
            Cell: ({ value }: { value: string }) => {
              const document =
                value.length > 11
                  ? value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g,
                      "$1.$2.$3/$4-$5"
                    )
                  : value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{1,2})/g,
                      "$1.$2.$3-$4"
                    );

              return document;
            },
          },
          {
            Header: "CONTRATO",
            accessor: "idContract",
          },
          {
            Header: "TIPO",
            accessor: "type",
          },
          {
            Header: "VENCIMENTO",
            accessor: "dueDate",
            Cell: ({ value }) => {
              if (!value) return "";

              return format(new Date(value), "dd/MM/yyyy");
            },
          },
          {
            Header: "MÉTODO DE PAGAMENTO",
            accessor: "paymentMethod",
          },
          {
            Header: "MOTIVO DA REJEIÇÃO (COD RETORNO)",
            accessor: "processingRejectionMotive",
          },
          {
            Header: "PRODUTO",
            accessor: "product",
          },
          {
            Header: "VALOR",
            accessor: "value",
            Cell: ({ value }) => {
              if (!value) return "";

              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          {
            Header: "JUROS",
            accessor: "interest",
            Cell: ({ value }) => {
              if (!value) return "";

              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          {
            Header: "MULTA",
            accessor: "fine",
            Cell: ({ value }) => {
              if (!value) return "";

              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          {
            Header: "DESCONTO",
            accessor: "discount",
            Cell: ({ value }) => {
              if (!value) return "";

              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          {
            Header: "TOTAL",
            accessor: "totalValue",
            Cell: ({ value }) => {
              if (!value) return "";

              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          // {
          //   Header: "FATURA",
          //   accessor: "invoiceId",
          // },
          {
            Header: "NOTA",
            accessor: "nfse",
          },
          {
            Header: "LINK DA NOTA",
            accessor: "nfeLink",
            Cell: ButtonLinkNfe,
          },
          {
            Header: "ARQUIVO",
            accessor: "processingFileLink",
            Cell: ButtonLinkProcessing,
          },
        ],
      },
    ],
  });

  const loadReport = useCallback(
    async (payload: SendChargesFiltersPayload) => {
      setReport((old) => ({
        ...old,
        isFetching: true,
      }));

      const response = await getSendCharges(nameReport, payload);

      setReport({
        data:
          response.type === "success" && response.body
            ? response.body.data
            : [],
        isFetching: false,
      });
    },
    [nameReport]
  );

  return {
    report,
    columns,
    loadReport,
  };
};
