// disable prettier
/* eslint-disable prettier/prettier */

export const getBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const b64toBlob = (base64: string, type = "image/png") =>
  fetch(`data:${type};base64,${base64}`)
    .then((res) => res.blob())
    .catch(() => console.log("%cNova imagem adicionada!", "color:pink"));
