import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import { CREATE_TARIFF_PERMISSION_LEVEL } from "./constants/permissions";
import { TariffDetails } from "./pages/TariffDetails";
import { TariffList } from "./pages/TariffList";
import { TariffManagement } from "./pages/TariffManagement";

export function TariffModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={CREATE_TARIFF_PERMISSION_LEVEL} />}
      >
        <Route path="/managetariff" element={<TariffManagement />} />
      </Route>
      <Route path="/tarifflist" element={<TariffList />} />
      <Route
        path="/viewtariff/:tariffId"
        element={<TariffDetails isReadOnly />}
      />
      <Route path="/managetariff/:tariffId" element={<TariffDetails />} />
    </Routes>
  );
}
