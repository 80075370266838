import { Table } from "@tanstack/react-table";
import { useCallback } from "react";

import { downloadFileCsv } from "../../../../main/common/utils/downloadFile";
import { transformFilterStateToQueryParams } from "../../../../main/components/DataTableServer/helpers/transformStateObject";
import { ITariffList } from "../pages/TariffList";
import {
  GetTariffCsvAllIds,
  GetTariffCsvByIds,
} from "../service/TariffService";

export const useExportTariff = (
  table: Table<ITariffList>,
  onLoading: (loading: boolean) => void
) => {
  const exportAll = useCallback(async () => {
    onLoading(true);
    const data = await GetTariffCsvAllIds();
    onLoading(false);
    downloadFileCsv(data, "Tarifa");
  }, [onLoading]);

  const exportFiltered = useCallback(async () => {
    const dataFiltered = transformFilterStateToQueryParams(
      table.getState().columnFilters
    );

    onLoading(true);
    const data = await GetTariffCsvByIds(dataFiltered);
    onLoading(false);
    downloadFileCsv(data, "Tarifa_filtrada");
  }, [table, onLoading]);

  return { exportAll, exportFiltered };
};
