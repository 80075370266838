import { AxiosResponse } from "axios";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import { IWebHooks } from "../../../../types/main/webHooks";
import { IWebhooks } from "../types";

const MODULE_BASE_URL = "/webhooks";
export const getAllWebHooks = async (active: boolean) => {
  try {
    const response: AxiosResponse<IResponse<Array<IWebHooks>>> =
      await recordApi.get(`${MODULE_BASE_URL}?active=${active}`);
    console.log(response.data);
    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const createWebHooks = async (data: IWebhooks) => {
  try {
    const response: AxiosResponse<IResponse<Array<IWebHooks>>> =
      await recordApi.post(MODULE_BASE_URL, data);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const deleteWebHook = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const getWebHooksById = async (id: string) => {
  try {
    const response: AxiosResponse<IResponse<IWebHooks>> = await recordApi.get(
      `${MODULE_BASE_URL}/${id}`
    );

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const updateWebHooks = async (id: string, data: IWebhooks) => {
  try {
    const response: AxiosResponse<IResponse<IWebHooks>> = await recordApi.patch(
      `${MODULE_BASE_URL}/${id}`,
      data
    );

    return response.data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};
