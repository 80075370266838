/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/naming-convention */

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  FormLabel,
  Icon,
  Skeleton,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { RankingInfo } from "@tanstack/match-sorter-utils";
import {
  Column,
  ColumnDef,
  ColumnFiltersState,
  ColumnPinningState,
  FilterFn,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import { MdFindInPage } from "react-icons/md";
import { SimpleMenu } from "../SimpleMenu";
import { TLargemd, TSmallmd } from "../Tipography";
import { CustomTableContainer } from "./CustomTableContainer";
import { fuzzyFilter } from "./Filters/functions";
import { getCellTitle } from "./helpers/getCellTitle";
import { getOnChanges, getStates } from "./helpers/getStates";
import {
  TableFilteringState,
  TablePaginationState,
  TableSortingState,
} from "./IDataTableServer";
import { TableServerPagination } from "./Pagination";
import "./styles.css";
import { TableHeader } from "./TableHeader";

declare module "@tanstack/table-core" {
  interface FilterFns {
    fuzzy: FilterFn<unknown>;
    document: FilterFn<unknown>;
    date: FilterFn<unknown>;
    select: FilterFn<unknown>;
    selectProduct: FilterFn<unknown>;
    selectService: FilterFn<unknown>;
  }
  interface FilterMeta {
    itemRank: RankingInfo;
  }
}

type TableServerProps = {
  defaultColumns: ColumnDef<any>[];
  data: any[];
  isLoading: boolean;
  paginationState?: TablePaginationState;
  columnFiltersState?: TableFilteringState;
  sortingState?: TableSortingState;
  minPaddingBlockTd?: number;
  refresh?: () => void;
  hidePerPageControl?: boolean;
  hidePageSkipControl?: boolean;
  hideColumnVisibilityControl?: boolean;
  hideAllFilters?: boolean;
};

const isValidHeaderColumnName = (column: Column<any, unknown>) => {
  if (typeof column.columnDef.header === "string") {
    return true;
  }

  return false;
};

export const TableServer = ({
  defaultColumns,
  data,
  isLoading,
  paginationState,
  columnFiltersState,
  sortingState,
  minPaddingBlockTd = 1,
  refresh,
  hideColumnVisibilityControl,
  hidePageSkipControl,
  hidePerPageControl,
  hideAllFilters,
}: TableServerProps) => {
  const [columns] = useState<typeof defaultColumns>(() => [...defaultColumns]);

  const [clientColumnFilters, setClientColumnFilters] =
    useState<ColumnFiltersState>([]);

  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({
    right: ["actions"],
    left: [],
  });

  const table = useReactTable({
    data,
    pageCount: paginationState?.pageCount,
    columns,
    filterFns: {
      fuzzy: fuzzyFilter,
      document: fuzzyFilter,
      date: fuzzyFilter,
      select: fuzzyFilter,
      selectProduct: fuzzyFilter,
      selectService: fuzzyFilter,
    },
    globalFilterFn: fuzzyFilter,
    enablePinning: true,
    state: {
      columnPinning,
      columnFilters: columnFiltersState?.columnFilters ?? clientColumnFilters,
      ...getStates({
        pagination: paginationState?.pagination,
        sorting: sortingState?.sorting,
      }),
    },

    // NOTE: CUSTOM RENDERERS
    ...getOnChanges({
      onSortingChange: sortingState?.setSorting,
      onPaginationChange: paginationState?.setPagination,
    }),

    onColumnPinningChange: setColumnPinning,
    onColumnFiltersChange:
      columnFiltersState?.setColumnFilters ?? setClientColumnFilters,

    // NOTE: MANUAL CONFIGURATIONS
    manualPagination: !!paginationState,
    manualFiltering: !!columnFiltersState,
    manualSorting: !!sortingState,

    // NOTE: DEFAULT REACT TABLE CONFIGURATIONS
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getPaginationRowModel: !paginationState
      ? getPaginationRowModel()
      : undefined,
  });

  return (
    <Flex flexDir="column" maxW="calc(100vw - 300px)">
      <Flex justifyContent="flex-end" alignItems="center" gap={8} mb={4}>
        <Button onClick={refresh} variant="outline" hidden={!refresh}>
          Atualizar
        </Button>

        <SimpleMenu
          hidden={hideColumnVisibilityControl}
          label="Exibir colunas"
          maxW="fit-content"
          alignSelf="flex-end"
        >
          <Flex flexDir="column" gap={4}>
            <Flex alignItems="center" gap={2}>
              <Checkbox
                id="toggleAll"
                onChange={table.getToggleAllColumnsVisibilityHandler()}
                isChecked={table.getIsAllColumnsVisible()}
              />
              <TSmallmd
                cursor="pointer"
                onClick={table.getToggleAllColumnsVisibilityHandler()}
              >
                Selecionar Todos
              </TSmallmd>
            </Flex>

            {table.getAllLeafColumns().map((column) => {
              if (isValidHeaderColumnName(column))
                return (
                  <FormLabel
                    htmlFor={column.id}
                    display="flex"
                    gap={2}
                    key={column.id}
                    padding={0}
                    margin={0}
                  >
                    <Checkbox
                      id={column.id}
                      onChange={column.getToggleVisibilityHandler()}
                      isChecked={column.getIsVisible()}
                    />

                    <TSmallmd cursor="pointer">
                      {column.columnDef.header}
                    </TSmallmd>
                  </FormLabel>
                );

              return null;
            })}
          </Flex>
        </SimpleMenu>
      </Flex>
      <CustomTableContainer border="1px solid #e2e8f0" borderRadius="0.5rem">
        <Table variant="striped" colorScheme="gray" size="md">
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHeader
                headerGroup={headerGroup}
                table={table}
                key={headerGroup.id}
                hideAllFilters={hideAllFilters}
              />
            ))}
          </Thead>
          <Tbody>
            {isLoading && (
              <Tr>
                <Td colSpan={24} padding="0">
                  <Skeleton height="112px" />
                </Td>
              </Tr>
            )}
            {!isLoading &&
              table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      paddingInlineEnd={2}
                      paddingBlock={minPaddingBlockTd}
                      minW={
                        cell.column.id === "actions" ? "fit-content" : "100px"
                      }
                      w={
                        cell.column.id === "actions" ? "fit-content" : undefined
                      }
                      maxW="350px"
                      fontSize="sm"
                      position={
                        cell.column.id === "actions" ? "sticky" : undefined
                      }
                      right={cell.column.id === "actions" ? "0" : undefined}
                      bgColor={
                        cell.column.id === "actions" ? "white" : undefined
                      }
                      title={getCellTitle(cell.getValue())}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
          </Tbody>
          <Tfoot>
            {table.getFooterGroups().map((footerGroup) => (
              <Tr key={footerGroup.id}>
                {footerGroup.headers.map((header) => (
                  <Th key={header.id} colSpan={header.colSpan}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.footer,
                          header.getContext()
                        )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Tfoot>
        </Table>
      </CustomTableContainer>
      {data.length === 0 && !isLoading && (
        <Box
          bgColor="#edf2f7"
          border="1px solid #ddd"
          borderRadius="5px"
          mt={1}
        >
          <Flex
            height="56px"
            textAlign="center"
            justifyContent="center"
            alignItems="center"
            gap="0.5rem"
          >
            <Icon as={MdFindInPage} fontSize="20px" color="gray.600" />
            <TLargemd>Nenhum registro encontrado</TLargemd>
          </Flex>
        </Box>
      )}
      <Divider marginTop="1rem" />
      <TableServerPagination
        table={table}
        hidePageSkipControl={hidePageSkipControl}
        hidePerPageControl={hidePerPageControl}
      />
    </Flex>
  );
};
