import { Checkbox, Container, Stack } from "@chakra-ui/react";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";

export interface IMultiSelectOptions {
  label: string;
  value: string | number;
  checked?: boolean;
  visible?: boolean;
}

interface IUseMultiCheckboxSelect {
  options: IMultiSelectOptions[];
  isAbleToSelectAll: boolean;
  onCustomChange?: (data: any) => void;
}

export const useMultiCheckboxSelect = ({
  options,
  isAbleToSelectAll,
  onCustomChange,
}: IUseMultiCheckboxSelect) => {
  const [optionsCheckState, setCheckableOptions] = useState<
    IMultiSelectOptions[]
  >(
    options.map((option) => ({
      ...option,
      visible: !!option.visible ? option.visible : true,
      checked: option.checked ?? false,
    }))
  );

  const allChecked = optionsCheckState.every((item) => item.checked);

  const isIndeterminate =
    optionsCheckState.some((item) => item.checked) && !allChecked;

  const placeholder = useMemo(() => {
    const selected = optionsCheckState
      .filter((item) => item.checked)
      .map((item) => item.label);

    if (optionsCheckState.length > 0 && selected.length > 0) {
      return selected.join();
    }
    return "";
  }, [optionsCheckState]);

  const MultiSelectCheckbox = useCallback(() => {
    const handleCheckboxAll = (e: ChangeEvent<HTMLInputElement>) => {
      setCheckableOptions((oldState) =>
        oldState.map((option) => ({
          ...option,
          checked: e.target.checked,
        }))
      );

      if (onCustomChange) {
        onCustomChange(
          optionsCheckState.map((option) => ({
            ...option,
            checked: e.target.checked,
          }))
        );
      }
    };

    const handleCheckbox = (
      e: ChangeEvent<HTMLInputElement>,
      index: number
    ) => {
      const newCheckableItems = optionsCheckState;

      newCheckableItems[index] = {
        ...newCheckableItems[index],
        checked: e.target.checked,
      };

      setCheckableOptions([...newCheckableItems]);

      if (onCustomChange) {
        onCustomChange([...newCheckableItems]);
      }
    };
    if (!options.length) return <div />;

    return (
      <Container mt={3} pl={2}>
        {isAbleToSelectAll && (
          <Checkbox
            isChecked={allChecked}
            isIndeterminate={isIndeterminate}
            onChange={(e) => handleCheckboxAll(e)}
          >
            Selecionar todos
          </Checkbox>
        )}
        <Stack pl={0} mt={4} spacing={4}>
          {optionsCheckState.length &&
            optionsCheckState
              .filter((item) => !!item.visible)
              .map((item, index) => {
                return (
                  <Checkbox
                    key={uuidv4()}
                    isChecked={optionsCheckState[index]?.checked}
                    onChange={(e) => handleCheckbox(e, index)}
                  >
                    {item.label}
                  </Checkbox>
                );
              })}
        </Stack>
      </Container>
    );
  }, [
    allChecked,
    isAbleToSelectAll,
    isIndeterminate,
    onCustomChange,
    options,
    optionsCheckState,
  ]);

  return {
    MultiSelectCheckbox,
    optionsCheckState,
    setCheckableOptions,
    placeholder,
  };
};
