import { useCallback, useState } from "react";

interface IUseDatePicker {
  name: string;
}

export const useDatePicker = ({ name }: IUseDatePicker) => {
  const [isOpen, setIsOpen] = useState(false);
  const [month, setMonth] = useState(new Date());

  const handleYearMonthChange = useCallback(
    (e: any, customMonth?: { year?: number; month?: number }) => {
      const year = customMonth?.year ?? e.target.form[`year${name}`].value;
      const month = customMonth?.month ?? e.target.form[`month${name}`].value;
      setMonth(new Date(year, month));
    },
    [name]
  );

  return {
    isOpen,
    setIsOpen,
    month,
    handleYearMonthChange,
  };
};
