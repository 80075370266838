/* eslint-disable react/no-unstable-nested-components */
import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
} from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { TableServer } from "../../../../../../main/components/DataTableServer";
import { IRemittanceTariffCars } from "../../../../../../types/main/remittances";

type DivergentDueDateProps = {
  remittanceSimulation: IRemittanceTariffCars[];
  isLoading: boolean;
};

export const EmptyPaymentMethods = ({
  remittanceSimulation,
  isLoading,
}: DivergentDueDateProps) => {
  const columns: ColumnDef<any, any>[] = useMemo(
    () => [
      {
        header: "TARIFA",
        accessorKey: "id",
        filterFn: "fuzzy",
      },
      {
        header: "Contrato",
        accessorKey: "idContract",
        cell: (info) => {
          return info.getValue();
        },
        filterFn: "fuzzy",
        enableSorting: false,
      },
      {
        header: "Modificar",
        cell: (info) => {
          const { id } = info.row.original;
          return (
            <Flex
              w="24px"
              h="24px"
              borderRadius="6px"
              backgroundColor="gray.200"
              justify="center"
              align="center"
              transition="0.2s"
              _hover={{
                backgroundColor: "blue.700",
                color: "white",
                cursor: "pointer",
              }}
            >
              <Link
                to={`/managetariff/${id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <ArrowForwardIcon />
              </Link>
            </Flex>
          );
        },
      },
    ],
    []
  );

  const rows = useMemo(() => {
    const groupedByTariff = remittanceSimulation.reduce((acc, remittance) => {
      const { tariffId, tariff } = remittance;
      if (!acc[tariffId]) {
        acc[tariffId] = [];
      }
      acc[tariffId].push(tariff.contract.idContract);
      return acc;
    }, {} as Record<string, number[]>);

    const tariffRow = Object.entries(groupedByTariff).map(
      ([id, idContracts]) => ({
        id,
        idContract: idContracts[0],
      })
    );

    return tariffRow;
  }, [remittanceSimulation]);

  return (
    <Accordion allowToggle pb={4}>
      <AccordionItem>
        <h2>
          <AccordionButton>
            <Box flex="1" textAlign="left">
              ⚠️ Há Contas a Receber sem vínculo com Métodos de Pagamento, e por
              isso ficaram fora das Remessas.
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </h2>
        <AccordionPanel pb={4}>
          <TableServer
            data={rows}
            defaultColumns={columns}
            isLoading={isLoading}
            minPaddingBlockTd={2}
            hideColumnVisibilityControl
            hidePerPageControl
            hidePageSkipControl
          />
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
