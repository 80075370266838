import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Checkbox,
  Container,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface IOptions {
  label: string;
  uuid: string;
  checked: boolean;
}
interface IMultiSelectProps {
  defaultOptions: IOptions[];
  buttonLabel: string;
  fieldName: string;
  setPlaceHolderWith?: "label" | "uuid";
  isDisabled?: boolean;
}

// * Set the value as an Array of two arrays:
// * 1º Position is the Array of UUID chosen - 2º Position is the Array of Label chosen
export const SimpleMultiSelect = ({
  defaultOptions,
  buttonLabel,
  fieldName,
  setPlaceHolderWith = "label",
  isDisabled,
}: IMultiSelectProps) => {
  const { setValue, trigger } = useFormContext();

  const [optionState, setOptionState] = useState<IOptions[]>(defaultOptions);
  const [placeholderSelectedOptions, setPlaceholderSelectedOptions] = useState<
    string[]
  >([]);

  useEffect(() => {
    const checkedItems = optionState.filter((item) => item.checked);

    setPlaceholderSelectedOptions(
      checkedItems.map((item) => String(item[setPlaceHolderWith]))
    );
    setValue(fieldName, [
      checkedItems.map((item) => String(item.uuid)),
      checkedItems.map((item) => String(item.label)),
    ]);
  }, [fieldName, optionState, setPlaceHolderWith, setValue, trigger]);

  const handleCheckbox = (uuidToChange: string) => {
    setOptionState((old) => {
      return old.map(({ uuid, label, checked }) => ({
        uuid,
        label,
        checked: uuidToChange === uuid ? !checked : checked,
      }));
    });
  };

  return (
    <Menu matchWidth>
      <MenuButton borderRadius="50" variant="solid" as={InputGroup}>
        <Input
          bg="gray.200"
          _placeholder={{ color: "blue.500" }}
          _disabled={{ color: "gray.700" }}
          isDisabled
          placeholder={
            placeholderSelectedOptions.length
              ? placeholderSelectedOptions.join()
              : buttonLabel
          }
        />
        {!isDisabled && (
          <InputRightElement>
            <ChevronDownIcon color="gray.700" />
          </InputRightElement>
        )}
      </MenuButton>
      {!isDisabled && (
        <MenuList padding={3} maxWidth="282px">
          <Box overflowY="scroll" maxH="200px" pl={3}>
            {optionState.length > 1 && (
              <Checkbox
                isChecked={optionState.every((item) => item.checked)}
                isIndeterminate={
                  optionState.some((item) => item.checked) &&
                  !optionState.every((item) => item.checked)
                }
                onChange={(e) =>
                  setOptionState((oldState) =>
                    oldState.map((option) => ({
                      ...option,
                      checked: e.target.checked,
                    }))
                  )
                }
              >
                Selecionar todos
              </Checkbox>
            )}
            <Container mt={3} pl={2}>
              <Stack pl={0} mt={4} spacing={4}>
                {optionState.map(({ uuid, checked, label }) => {
                  return (
                    <Checkbox
                      key={uuid}
                      isChecked={checked}
                      onChange={() => handleCheckbox(uuid)}
                      value={uuid}
                    >
                      {label}
                    </Checkbox>
                  );
                })}
              </Stack>
            </Container>
          </Box>
        </MenuList>
      )}
    </Menu>
  );
};
