import { Route, Routes } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_USER_PERMISSION_LEVELS,
  GET_USER_PERMISSION_LEVELS,
  UPDATE_USER_PERMISSION_LEVELS,
} from "./constants/userPagePermissionLevel";
import { UserList } from "./pages/UserList";
import { UserManagement } from "./pages/UserManagement";

export function UsersModule() {
  return (
    <Routes>
      <Route
        path="/userlist"
        element={<PrivateRoute permission={GET_USER_PERMISSION_LEVELS} />}
      >
        <Route path="/userlist" element={<UserList />} />
      </Route>

      <Route
        path="/manageuser"
        element={<PrivateRoute permission={CREATE_USER_PERMISSION_LEVELS} />}
      >
        <Route path="/manageuser" element={<UserManagement />} />
      </Route>

      <Route
        path="/manageuser/:uuid"
        element={<PrivateRoute permission={UPDATE_USER_PERMISSION_LEVELS} />}
      >
        <Route path="/manageuser/:uuid" element={<UserManagement />} />
      </Route>

      <Route
        path="/viewuser/:uuid"
        element={<PrivateRoute permission={GET_USER_PERMISSION_LEVELS} />}
      >
        <Route path="/viewuser/:uuid" element={<UserManagement isReadOnly />} />
      </Route>
    </Routes>
  );
}
