type ICARMap = {
  [key: string]: boolean;
};

export const CAN_PERMISSION_EDIT: ICARMap = {
  Master: true,
  Admin: true,
  Auditor: true,
  Operador: false,
} as const;

export const CAN_PERMISSION_CREATE: ICARMap = {
  Master: true,
  Admin: true,
  Auditor: true,
  Operador: false,
} as const;

export const CAN_PERMISSION_UPDATE_DUE_DATE: ICARMap = {
  Master: true,
  Admin: true,
  Auditor: true,
  Operador: false,
} as const;
