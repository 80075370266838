import { Box, Flex } from "@chakra-ui/react";

import { TLargesm } from "../Tipography";
import { DatePicker } from "./DatePicker";
import { TimePicker } from "./TimePicker";

interface IDateTimePicker {
  dateLabel: string;
  timeLabel: string;
  datePickerProps: {
    fieldname: string;
    placement: "top" | "right" | "bottom" | "left" | "auto" | undefined;
    onDateChange?: (date: Date | undefined) => void;
  };
  timePickerProps: {
    fieldname: string;
    onTimeChange?: (time: string | undefined) => void;
  };
}

export const DateTimePicker = ({
  dateLabel = "",
  timeLabel = "",
  datePickerProps,
  timePickerProps,
}: IDateTimePicker) => {
  return (
    <Flex flexDirection="row" gap={4}>
      <Box fontWeight={500}>
        <TLargesm>{dateLabel}</TLargesm>
        <DatePicker {...datePickerProps} />
      </Box>
      <Box fontWeight={500}>
        <TLargesm>{timeLabel}</TLargesm>
        <TimePicker {...timePickerProps} />
      </Box>
    </Flex>
  );
};
