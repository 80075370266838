import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_CLIENTS_PERMISSION_LEVEL,
  GET_CLIENTS_PERMISSION_LEVEL,
  UPDATE_CLIENTS_PERMISSION_LEVEL,
} from "./constants/permissions";
import { ClientList } from "./pages/ClientList";
import { ClientManagement } from "./pages/ClientManagement";

export function ClientsModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={CREATE_CLIENTS_PERMISSION_LEVEL} />}
      >
        <Route path="/manageclient" element={<ClientManagement />} />
      </Route>
      <Route
        element={<PrivateRoute permission={UPDATE_CLIENTS_PERMISSION_LEVEL} />}
      >
        <Route path="/manageclient/:uuid" element={<ClientManagement />} />
      </Route>
      <Route
        element={<PrivateRoute permission={GET_CLIENTS_PERMISSION_LEVEL} />}
      >
        <Route path="/clientlist" element={<ClientList />} />
        <Route
          path="/viewclient/:uuid"
          element={<ClientManagement isReadOnly />}
        />
      </Route>
    </Routes>
  );
}
