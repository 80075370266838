import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import {
  IBlobError,
  useDownloadFile,
} from "../../../../../../main/hooks/useDownloadFile";
import { IProviderOption } from "../../../../../../types/main/providers";
import { getProvidersOptions } from "../../../../ServiceProvider/services/ServiceProviderService";
import { handleError } from "./functions/handleError";

export const useEFD = () => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const [providersList, setProvidersList] = useState<IProviderOption[]>([]);

  const fetchProviders = async () => {
    const response = await getProvidersOptions({});
    return response.body ?? [];
  };

  const preDownloading = () => {
    setIsLoading(true);
  };
  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage = await handleError(error);

    toast({
      title: "Serviço indisponível.",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const { downloadFile } = useDownloadFile({
    apiDefinition: {
      endpoint: ``,
      responseType: "text",
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    extension: "txt",
    filename: `Arquivo EFD - ${new Date().toLocaleDateString()}`,
  });

  useEffect(() => {
    let isCancelled = false;

    const loadAllDataLists = async () => {
      try {
        setIsLoading(true);

        const providers = await fetchProviders();

        if (!isCancelled) {
          setProvidersList(providers);
          setIsLoading(false);
        }
      } catch (e) {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    loadAllDataLists();

    return () => {
      isCancelled = true;
    };
  }, [setIsLoading]);

  return {
    downloadFile,
    isLoading,
    providersList,
  };
};
