import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IWebHooks } from "../../../../types/main/webHooks";
import { getWebHooksById } from "../services/WebHooksService";

export const useExistingWebHooks = (id: string | undefined) => {
  const toast = useToast();

  const [existingWebHooks, setExistingWebHooks] = useState<
    IWebHooks | undefined
  >();

  const getExistingWebHook = useCallback(
    async (id: string) => {
      try {
        const response = await getWebHooksById(id);
        setExistingWebHooks(response?.body);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (id) {
      getExistingWebHook(id);
    }
  }, [getExistingWebHook, id]);

  return { existingWebHooks };
};
