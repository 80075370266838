import { Box, Flex, GridItem } from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { SelectColumnFilter } from "../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import {
  deleteServiceProvider,
  getAllServiceProviders,
} from "../services/ServiceProviderService";

const breadcrumb: IBreadcrumbProps[] = [
  { description: "Cadastros e consultas", href: "/serviceproviderlist" },
  { description: "Prestadores", href: "" },
];

export const ServiceProviderList = () => {
  const [serviceProviders, setServicesProviders] = useState([{}]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "CNPJ",
            accessor: "document",
          },
          {
            Header: "Inscrição Estadual",
            accessor: "ie",
          },
          {
            Header: "Simples Nacional",
            accessor: "simple_national",
            Filter: SelectColumnFilter,
          },
          {
            Header: "ID do Prestador",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const handleServiceProviderList = async () => {
    setIsLoading(true);
    const response = await getAllServiceProviders();
    if (response.type === "success" && response.body) {
      const serviceProvidersList = response.body.map(
        ({ name, document, ie, simple_national, uuid }) => {
          return {
            name,
            document: document.replace(
              /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g,
              "$1.$2.$3/$4-$5"
            ),
            ie,
            simple_national: simple_national ? "SIM" : "NÃO",
            uuid,
          };
        }
      );
      setServicesProviders(serviceProvidersList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleServiceProviderList();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Prestadores
          </HLarge2xl>
          <Box>
            <SimpleMenu label="Exibir colunas">
              <SelectableColumns />
            </SimpleMenu>
            <CreateNewDataButton
              module={MODULES.PROVIDER}
              path="/manageserviceprovider"
              text="Cadastrar novo prestador"
            />
          </Box>
        </Flex>
        <DataTable
          refreshTable={handleServiceProviderList}
          module="Provider"
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={serviceProviders}
          popup={{
            callToDelete: deleteServiceProvider,
            refreshTable: handleServiceProviderList,
            urlEdit: "/manageserviceprovider/",
            urlView: "/viewserviceprovider/",
          }}
          showCheckbox={false}
        />
      </GridItem>
    </Wrapper>
  );
};
