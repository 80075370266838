import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IServiceProvision } from "../../../../types/main/serviceProvision";
import { GetServiceProvision } from "../service/ServiceProvisionService";

interface IUseServiceProvisionForm {
  uuid: string | undefined;
}

export const useExistingServiceProvision = ({
  uuid,
}: IUseServiceProvisionForm) => {
  const toast = useToast();
  const [existingServiceProvision, setExistingServiceProvision] = useState<
    IServiceProvision | undefined
  >();

  const loadExistingServiceProvision = useCallback(
    async (uuid: string) => {
      try {
        const response = await GetServiceProvision(uuid);

        setExistingServiceProvision(response.body ?? undefined);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: DEFAULT_ERROR_MESSAGES.LIST_ACTION,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (uuid) {
      loadExistingServiceProvision(uuid);
    }
  }, [uuid, loadExistingServiceProvision]);

  return { existingServiceProvision };
};
