import { Box, FormControl, Input, Select, Td, Tooltip } from "@chakra-ui/react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { useTableFormRowHandleErrors } from "../../../../../../../main/hooks/useTableFormRowHandleErrors";
import { ITemplateOptions } from "../../../../../../../types/main/templates";

interface ICategoryConfigRow {
  index: number;
  isReadOnly?: boolean;
  templateOptions: ITemplateOptions[];
}

export const CategoryConfigRow = memo(
  ({ index, isReadOnly, templateOptions }: ICategoryConfigRow) => {
    const {
      getValues,
      register,
      formState: { errors },
    } = useFormContext();

    const { getErrorMessage, hasError } = useTableFormRowHandleErrors({
      errors,
      index,
    });

    return (
      <>
        <Td width="30%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <Input
            {...register(`emailConfigs[${index}].productCategory`)}
            readOnly
            _readOnly={{
              borderColor: "gray.300",
              color: "blue.700",
              cursor: "default",
            }}
          />
        </Td>
        <Td width="40%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl isInvalid={hasError("emailConfigs", `templateUuid`)}>
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage("emailConfigs", `templateUuid`)}
              placement="bottom"
              fontSize="small"
            >
              <Select
                placeholder="-"
                disabled={isReadOnly}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                {...register(`emailConfigs[${index}].templateUuid`)}
              >
                {templateOptions &&
                  templateOptions.map(({ name, uuid, deletedAt }) => (
                    <Box
                      as="option"
                      key={uuid}
                      value={uuid}
                      color={deletedAt ? "red.500" : "black"}
                    >
                      {deletedAt ? `(inativo) ${name}` : name}
                    </Box>
                  ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Td>

        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <Input
            value={getValues(
              `emailConfigs[${index}].emailObjective.description`
            )}
            readOnly
            _readOnly={{
              borderColor: "gray.300",
              color: "blue.700",
              cursor: "default",
            }}
          />
        </Td>
      </>
    );
  }
);
