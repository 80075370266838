import { useEffect, useState } from "react";
import { TreeData } from "react-dropdown-tree-select";
import { useNavigate } from "react-router-dom";

import { IClientOptions } from "../../../../types/main/client";
import { IGetAllInvoicingTariffs } from "../../../../types/main/invoicing";
import { IProductCategories } from "../../../../types/main/products";
import { getChannelOptionsTree } from "../../Channels/services/ChannelService";
import { getClientOptions } from "../../Clients/service/ClientService";
import { allCategoryProducts } from "../../Products/service/ProductService";

export const useInvoicingSimulationFilters = () => {
  const navigate = useNavigate();

  const [clients, setClients] = useState<IClientOptions[]>([]);
  const [channels, setChannels] = useState<TreeData[]>([]);
  const [productsCategories, setProductsCategories] = useState<
    IProductCategories[]
  >([]);

  const [hasFetchError, setHasFetchError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitSimulationRequest = async (
    filters: IGetAllInvoicingTariffs
  ) => {
    navigate("/invoicing/simulation", { state: { filters } });
  };

  const loadClients = async () => {
    const response = await getClientOptions();
    setClients(response);
  };

  const loadChannels = async () => {
    const response = await getChannelOptionsTree();
    setChannels(response.body ?? []);
  };

  const loadProductCategories = async () => {
    const response = await allCategoryProducts();
    setProductsCategories(response.body ?? []);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await loadClients();
        await loadChannels();
        await loadProductCategories();
        setIsLoading(false);
      } catch (error) {
        setHasFetchError(true);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    clients,
    channels,
    productsCategories,
    hasFetchError,
    isFetching: isLoading,
    onSubmitSimulationRequest,
  };
};
