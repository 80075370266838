/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Button,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";
import { exportmeExcel } from "excel-ent";
import React, { useEffect, useState } from "react";
import { IconExport } from "../../../../main/common/assets";
import { IPopoverListProps } from "../../../../main/components/DataTable/IDataTable";
import {
  formatDateToNameFile,
  replaceObjectKeys,
} from "../../../../main/components/Popover/functions/exportHelper";
import { HLargexs, HSmallmd } from "../../../../main/components/Tipography";

export type PopoverReportListProps = IPopoverListProps & {
  exportFileName?: string;
};

export const PopoverReportList: React.FC<PopoverReportListProps> = ({
  dataFiltered,
  data,
  excelColumnHeaders,
  isHeader,
  exportFileName,
}) => {
  const [allData, setAllData] = useState(data);
  const [filtered, setFiltered] = useState(data);

  function handleExportAllData() {
    const now = new Date();
    const now_str = formatDateToNameFile(now);

    exportmeExcel(allData || [], `${exportFileName} - ${now_str}`, {
      type: "download",
    });
  }

  function handleExportFilteredData() {
    const now = new Date();
    const now_str = formatDateToNameFile(now);

    exportmeExcel(filtered || [], `${exportFileName}_Filtrado - ${now_str}`, {
      type: "download",
    });
  }

  useEffect(() => {
    const removeUuidAndComponent = data?.map(({ uuid, component, ...rest }) =>
      replaceObjectKeys(rest, excelColumnHeaders)
    );

    setAllData(removeUuidAndComponent);
  }, [data, excelColumnHeaders]);

  useEffect(() => {
    const removeUuidAndComponent = dataFiltered?.map(({ values }) => {
      const { uuid, component, ...rest } = values;

      return replaceObjectKeys(rest, excelColumnHeaders);
    });

    setFiltered(removeUuidAndComponent);
  }, [dataFiltered, excelColumnHeaders]);

  if (!isHeader) {
    return null;
  }

  return (
    <Popover placement="left">
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0">
                <Flex flexDir="column">
                  <HLargexs pl="24px" pt="12px" mb="6px">
                    EXPORTAR CSV
                  </HLargexs>
                  <Flex
                    pl="25.33px"
                    py="8px"
                    as="button"
                    alignItems="center"
                    _hover={{ bg: "gray.50" }}
                    onClick={handleExportAllData}
                  >
                    <IconExport />
                    <HSmallmd ml="8.46px" color="gray.500">
                      Tabela inteira
                    </HSmallmd>
                  </Flex>
                  <Flex
                    pl="25.33px"
                    py="8px"
                    as="button"
                    alignItems="center"
                    _hover={{ bg: "gray.50" }}
                    onClick={handleExportFilteredData}
                  >
                    <IconExport />
                    <HSmallmd ml="8.46px" color="gray.500">
                      Tabela filtrada
                    </HSmallmd>
                  </Flex>
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
