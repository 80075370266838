import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";

import { TSmallmd } from "../../../../../../../../main/components/Tipography";
import { IProcessingCarCancellation } from "../../../../../../../../types/main/processingCar";
import { useAllProcessingCarTypeReasonForCancellationRequest } from "../../../../../hooks/useAllProcessingCarTypeReasonForCancellationRequest";

interface IProps {
  arrayuuidTariff: string[];
  refreshTable?: () => void;
  reloadCar?: () => void;
  onClosePreceding: () => void;
}

const CancelCarConfirm = ({
  arrayuuidTariff,
  refreshTable,
  reloadCar,
  onClosePreceding,
}: IProps) => {
  const { onSubmitCarCancellation } =
    useAllProcessingCarTypeReasonForCancellationRequest();
  const { watch } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const watchProcessingCarCancellationUuid = watch(
    "processingCarCancellationUuid"
  );
  const watchReasonForCancellation = watch("reasonForCancellation");

  const handleSubmit = useCallback(() => {
    const prepareToSubmit: Array<IProcessingCarCancellation> =
      arrayuuidTariff.map((item: string) => {
        return {
          uuidCar: item,
          reasonForCancellation: watchReasonForCancellation,
          processingCarCancellationUuid: watchProcessingCarCancellationUuid,
        };
      });
    if (prepareToSubmit && prepareToSubmit.length > 0) {
      onSubmitCarCancellation({
        data: prepareToSubmit,
        onClose,
        refreshTable,
        reloadCar,
      });
    }
    onClose();
    onClosePreceding();
  }, [
    arrayuuidTariff,
    onClose,
    onClosePreceding,
    onSubmitCarCancellation,
    refreshTable,
    reloadCar,
    watchProcessingCarCancellationUuid,
    watchReasonForCancellation,
  ]);

  return (
    <>
      <Button
        isDisabled={
          !(watchReasonForCancellation && watchReasonForCancellation.length > 0)
        }
        onClick={onOpen}
      >
        Confirmar
      </Button>

      <Modal onClose={onClose} isOpen={isOpen} isCentered size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Tem certeza que quer cancelar a tarifa?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TSmallmd>Essa ação não pode ser desfeita posteriormente</TSmallmd>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr="12px" onClick={onClose}>
              Cancelar ação
            </Button>
            <Button colorScheme="red" onClick={handleSubmit}>
              Cancelar CAR
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export { CancelCarConfirm };
