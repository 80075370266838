import { Flex } from "@chakra-ui/react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { DataTable } from "../../../../../main/components/DataTable";
import {
  DatePickerFilter,
  MultiSelectFilter,
} from "../../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { IProcessingRemittanceView } from "../../../../../types/main/processingRemittance";
import { getProcessingRemittances } from "../../service/processingRemittancesService";
import { ConfirmSending } from "./ConfirmSending";
import { FileUpload } from "./FileUpload";
import { ProcessingPopover } from "./ProcessingPopover";
import { StatusDescriptionCell } from "./StatusBadge";

type ProcessingRemittanceProps = {
  remittanceId?: string;
};

export const ProcessingRemittance = ({
  remittanceId,
}: ProcessingRemittanceProps) => {
  const [processing, setProcessing] = useState<
    Array<IProcessingRemittanceView>
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();

  const handleProcessingRemittances = useCallback(async () => {
    setIsLoading(true);
    const response = await getProcessingRemittances();

    if (response.type === "success" && response.body) {
      const processing = response.body;

      setProcessing(processing);
    }
    setIsLoading(false);
  }, []);

  const { columns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Status",
            accessor: "processingStatusPtBr",
            Cell: StatusDescriptionCell,
            filter: "multi",
            Filter: MultiSelectFilter({ isAbleToSelectAll: true }),
          },
          {
            Header: "Tipo de Remessa",
            accessor: "paymentTypePtBr",
            filter: "multi",
            Filter: MultiSelectFilter({ isAbleToSelectAll: true }),
          },
          {
            Header: "ID Remessa",
            accessor: "remittances",
            Cell: ({ cell }: { cell: { value: number[] } }) => {
              const { value } = cell;

              return value.join(", ");
            },
            filter: "textCommaSeparated",
          },
          {
            Header: "ID Processamento",
            accessor: "id",
            filter: "equals",
          },
          {
            Header: "Tipo de Envio",
            accessor: "typePtBr",
            filter: "multi",
            Filter: MultiSelectFilter({ isAbleToSelectAll: true }),
          },
          {
            Header: "Iniciado em",
            accessor: "createdAt",
            Cell: ({ cell: { value } }) =>
              value
                ? format(new Date(value), "dd/MM/yyyy - HH'h':mm", {
                    locale: ptBR,
                  })
                : "",
            filter: "date",
            Filter: DatePickerFilter,
          },
          {
            Header: "Canal de Transmissão",
            accessor: "payment_transmission_channel",
            filter: "multi",
            Filter: MultiSelectFilter({ isAbleToSelectAll: true }),
          },
          {
            Header: "Iniciado por",
            accessor: "username",
          },
          {
            Header: "Email Enviado?",
            accessor: "emailSent",
            Cell: ({ cell: { value } }) => (value ? "Sim" : "Não"),
          },
          {
            Header: "Confirmar envio",
            accessor: "",
            Cell: ConfirmSending,
            Filter: () => null,
          },
        ],
      },
    ],
  });

  useEffect(() => {
    handleProcessingRemittances();
  }, [handleProcessingRemittances, location.key]);

  return (
    <Flex flexDir="column">
      <DataTable
        module="Remittance"
        isLoading={isLoading}
        columns={columns}
        data={processing}
        popup={{
          RenderComponent: ProcessingPopover,
          hideInHeader: true,
        }}
        showCheckbox={false}
        startFiltered={{
          column: "remittances",
          value: remittanceId ?? "",
        }}
        refreshTable={handleProcessingRemittances}
      />
      <Flex justifyContent="flex-start" width="100%">
        <FileUpload onUploadFiles={handleProcessingRemittances} />
      </Flex>
    </Flex>
  );
};
