/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, Flex } from "@chakra-ui/react";
import { forwardRef, useCallback, useEffect, useRef, useState } from "react";
import DropdownTreeSelect, {
  TreeData,
  TreeNode,
} from "react-dropdown-tree-select";
import "react-dropdown-tree-select/dist/styles.css";
import { useFormContext, useWatch } from "react-hook-form";
import { HiFilter, HiOutlineFilter } from "react-icons/hi";
import "./style.css";

type TreeSelectProps = {
  placeholder?: string;
  data: TreeData[];
  name: string;
  label?: string;
};

type TreeDataValue = TreeData & { value?: any; children?: TreeDataValue[] };

const checkSelectedTreeData = (
  data: TreeDataValue[],
  selectedValues: string[]
) => {
  if (!data) return [];

  return data.map((item) => {
    if (selectedValues.includes(item.value)) {
      return {
        ...item,
        checked: true,
      };
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const children = checkSelectedTreeData(
      item.children ?? [],
      selectedValues
    ) as TreeDataValue[];

    return {
      ...item,
      children,
      checked: false,
    };
  });
};

export const TreeSelect = forwardRef<DropdownTreeSelect, TreeSelectProps>(
  ({ placeholder, data, name, label = "Canais" }, _) => {
    const [options, setOptions] = useState<TreeData[]>(data);

    const { setValue, control } = useFormContext();
    const internalRef = useRef<DropdownTreeSelect>(null);

    const onBlur = (selectedNodes: TreeNode[]) => {
      const selectedValues = selectedNodes.flatMap((node) => [
        node.value,
        // eslint-disable-next-line no-underscore-dangle
        ...node._children,
      ]);
      setValue(name, selectedValues);
    };

    const uncheckAll = useCallback(() => {
      setOptions((prevState) => {
        const newState = prevState?.map((channel) => ({
          ...channel,
          checked: false,
        }));

        return newState;
      });

      setValue(name, []);
    }, [name, setValue]);

    const checkAll = useCallback(() => {
      setOptions((prevState) => {
        const newState = prevState?.map((channel) => ({
          ...channel,
          checked: true,
        }));

        return newState;
      });
    }, []);

    const allChecked = useWatch({
      control,
      name,
    });

    useEffect(() => {
      const newData = checkSelectedTreeData(data, allChecked ?? []);
      setOptions(newData);
    }, [allChecked, data]);

    return (
      <Flex
        flexDir="column"
        width="100%"
        gap={1}
        transform="translateY(-5%)"
        position="relative"
        zIndex={1}
      >
        <Flex justifyContent="space-between">
          <Box>{label}</Box>
          <Box>
            <Button
              leftIcon={<HiFilter />}
              onClick={checkAll}
              variant="outline"
              size="sm"
              mr="8px"
            >
              todos
            </Button>
            <Button
              leftIcon={<HiOutlineFilter />}
              onClick={uncheckAll}
              variant="outline"
              size="sm"
            >
              nenhum
            </Button>
          </Box>
        </Flex>
        <DropdownTreeSelect
          data={options}
          clearSearchOnChange={false}
          keepTreeOnSearch
          keepChildrenOnSearch
          keepOpenOnSelect
          mode="multiSelect"
          ref={internalRef}
          onBlur={() => {
            onBlur(internalRef.current?.state.tags ?? []);
          }}
          className="mdl-demo"
          texts={{
            placeholder: placeholder || "Digite ou selecione...",
          }}
        />
      </Flex>
    );
  }
);
