import { IInvoiceDataList } from "../../../../types/main/invoice";
import { WarningType } from "../../Tariff/constants/tariff.constants";

export const getColumnValuesSumAsCurrency = (
  rows: any[] | undefined,
  field: string
) => {
  if (!rows) return 0;
  const sumValues = rows.reduce(
    (sum: number, row: any) =>
      Number(Number(row.values[field]).toFixed(2)) + sum,
    0
  );
  return sumValues.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

export const getColumnInterestAndFineSumAsCurrency = (
  rows: any[] | undefined
) => {
  if (!rows) return 0;
  const sumInterestValues = rows.reduce(
    (sum: number, row: any) =>
      Number(Number(row.values.invoicesInterest).toFixed(2)) + sum,
    0
  );
  const sumFineValues = rows.reduce(
    (sum: number, row: any) =>
      Number(Number(row.original.invoicesFine).toFixed(2)) + sum,
    0
  );
  const sumFineAndInterest = sumInterestValues + sumFineValues;
  return sumFineAndInterest.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
};

type WarningKeys = keyof typeof WarningType;

export const getWarningFromInvoiceList = (
  invoice: IInvoiceDataList
): WarningKeys | undefined => {
  if (invoice.hasDifferentDueDates) return "divergent";
  if (invoice.hasMissingDueDate) return "missing";

  return undefined;
};
