import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import { ModalConfirmDelete } from ".";
import { IResponse } from "../../../types/main/response";
import { ModulesType } from "../../common/types/modules";
import { useAuth } from "../../hooks/useAuth";
import { TSmallmd } from "../Tipography";
import { canDelete } from "./functions/permission";

interface IButtonOpen {
  ButtonOpen: React.FC<any>;
  uuid: string;
  callApi: (uuid: string) => Promise<IResponse<any>>;
  refreshTable: () => void;
  selectedUserProfile?: string;
  module: ModulesType;
  setTextModal?: {
    modalHeader: string;
    modalBody: string;
    modalButtonCancell: string;
  };

  setTextModalConfirmation?: {
    modalHeaderModalConfirmation: string;
    modalButtonCancelModalConfirmation: string;
    modalButtonConfirmModalConfirmation: string;
  };
}

export const ModalDelete: React.FC<IButtonOpen> = ({
  ButtonOpen,
  uuid,
  callApi,
  refreshTable,
  selectedUserProfile,
  module,
  setTextModal = {
    modalHeader: "Deletar entrada",
    modalBody: "Você selecionou a ação de deletar a entrada selecionada",
    modalButtonCancell: "Cancelar",
  },
  setTextModalConfirmation,
}) => {
  const { currentUuid, permissionLevel } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleCloseModalDelete = () => {
    onClose();
  };

  if (
    !canDelete({
      loggedUserUuid: currentUuid,
      permissionLevel,
      userProfile: selectedUserProfile,
      userUuid: uuid,
      module,
    })
  )
    return null;

  return (
    <>
      <ButtonOpen onClick={onOpen} />

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{setTextModal.modalHeader}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TSmallmd>{setTextModal.modalBody}</TSmallmd>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              {setTextModal.modalButtonCancell}
            </Button>
            <ModalConfirmDelete
              uuid={uuid}
              closeModal={handleCloseModalDelete}
              callApi={callApi}
              refreshTable={refreshTable}
              isUser={!!selectedUserProfile}
              setTextModalConfirmation={setTextModalConfirmation}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
