import { useCallback, useEffect, useState } from "react";

import { customToast } from "../../../../main/common/utils/customToast";
import { IContractsOptions } from "../../../../types/main/contract";
import { getContractOptions } from "../../Contract/service/ContractService";

export const useGetAllContracts = () => {
  const [allContractsState, setAllContractsState] =
    useState<Array<IContractsOptions>>();

  const refreshList = useCallback(async () => {
    try {
      const response = await getContractOptions();

      setAllContractsState(response);
    } catch (error: any) {
      customToast("error", error.message);
    }
  }, []);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  return { allContractsState };
};
