import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { IApi } from "../../../../../types/main/api";

interface IApiRow {
  api: IApi;
}

export const useApiRow = ({ api }: IApiRow) => {
  const [apisWithPermission, setApisWithPermission] = useState("");

  const { setValue, watch } = useFormContext();

  const apis = watch("apis");

  function handleApiPermission(uuid: string, isChecked: boolean) {
    if (isChecked) {
      setApisWithPermission(uuid);
      setValue("apis", apis ? [...apis, uuid] : [uuid]);
    } else {
      setApisWithPermission("");
      setValue(
        "apis",
        apis ? apis.filter((element: string) => element !== uuid) : []
      );
    }
  }

  useEffect(() => {
    if (apis) {
      setApisWithPermission(
        apis.find((element: string) => element === api.uuid)
      );
    }
  }, [apis, api.uuid]);

  return {
    apisWithPermission,
    handleApiPermission,
  };
};
