import { Box, Flex, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { MultiSelectFilter } from "../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IProductList } from "../../../../types/main/products";
import { allProducts, deleteProducts } from "../service/ProductService";

export const ProductList: React.FC = () => {
  const [products, setProducts] = useState<Array<IProductList>>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Categoria",
            accessor: "category",
            Filter: MultiSelectFilter({}),
            filter: "multi",
          },
          {
            Header: "Identificador de tarifa",
            accessor: "productMask",
          },
          {
            Header: "Identificador",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const handleProductList = async () => {
    setIsLoading(true);
    const response = await allProducts({});
    if (response.type === "success" && response.body) {
      const productList = response.body.map(
        ({ category, name, uuid, productMask }) => ({
          category,
          name,
          uuid,
          productMask,
        })
      );
      setProducts(productList);
    }
    setIsLoading(false);
  };

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Produtos", href: "/productlist" },
    { description: "Gerenciamento de produtos", href: "" },
  ];

  useEffect(() => {
    handleProductList();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de produtos
          </HLarge2xl>

          <Box>
            <SimpleMenu label="Exibir colunas">
              <SelectableColumns />
            </SimpleMenu>
            <CreateNewDataButton
              module={MODULES.PRODUCTS}
              path="/manageproduct"
              text="Cadastrar novo produto"
            />
          </Box>
        </Flex>
        <DataTable
          refreshTable={handleProductList}
          module="Products"
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={products}
          popup={{
            callToDelete: deleteProducts,
            refreshTable: handleProductList,
            urlEdit: "/manageproduct/",
            urlView: "/viewproduct/",
          }}
          showCheckbox={false}
        />
      </GridItem>
    </Wrapper>
  );
};
