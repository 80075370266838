import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { IChannelEmailConfigWithInheritanceDetail } from "../../../../../types/main/channelEmailConfig";
import { ITemplateOptions } from "../../../../../types/main/templates";
import { getTemplateOptions } from "../../../Templates/service/TemplateService";
import { getChannelEmailConfig } from "../../services/ChannelEmailService";

interface IUseFetchChannelEmailLists {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  channelId?: string;
}

export const useFetchChannelEmailLists = ({
  setIsLoading,
  channelId,
}: IUseFetchChannelEmailLists) => {
  const location = useLocation();

  const [templateOptions, setTemplateOptions] = useState<ITemplateOptions[]>(
    []
  );

  const [hasFetchError, setHasFetchError] = useState(false);

  const [emailConfig, setEmailConfig] =
    useState<IChannelEmailConfigWithInheritanceDetail | null>();

  const loadEmailConfig = async (channelId: string) => {
    const response = await getChannelEmailConfig(channelId);
    return response;
  };

  const fetchTemplates = async ({
    idListOnScreenTemplates,
  }: {
    idListOnScreenTemplates?: string[];
  }) => {
    const response = await getTemplateOptions({
      return_even_if_deleted: idListOnScreenTemplates,
    });
    return response.body ?? [];
  };

  useEffect(() => {
    let isCancelled = false;

    const loadAllDataLists = async () => {
      if (!channelId) return;
      try {
        setIsLoading(true);
        const emailConfig = await loadEmailConfig(channelId);
        const templates = await fetchTemplates({
          idListOnScreenTemplates: emailConfig?.emailConfigs?.map(
            ({ templateUuid }) => templateUuid
          ),
        });

        if (!isCancelled) {
          setTemplateOptions(templates);
          setEmailConfig(emailConfig);
          setIsLoading(false);
        }
      } catch (e) {
        if (!isCancelled) {
          setHasFetchError(true);
          setIsLoading(false);
        }
      }
    };

    loadAllDataLists();

    return () => {
      isCancelled = true;
    };
  }, [channelId, setIsLoading, location.key]);

  return {
    templateOptions,
    emailConfig,
    hasFetchError,
  };
};
