import { Flex, GridItem } from "@chakra-ui/react";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";

import { ControlledDataTable } from "../../../../main/components/ControlledDataTable";
import {
  ControlledDataTableColumn,
  IColumnControlledDataTable,
} from "../../../../main/components/ControlledDataTable/IControlledDataTable";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DatePickerFilter } from "../../../../main/components/DataTable/Filters";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { RequestOrigin } from "../../../../types/main/response";
import { getAllLogs } from "../services/LogsService";

interface ILogsList {
  username: string;
  event: string;
  action_over_uuid: string | undefined;
  createdAt: string;
  origin: RequestOrigin;
}

export const LogsList: React.FC = () => {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [filterValue, setFilterValue] = useState("");
  const [logsData, setLogsData] = useState<{
    data: ILogsList[];
    totalElements: number;
  }>({ data: [], totalElements: 0 });
  const [isLoading, setIsLoading] = useState(true);

  function changePage(page: number) {
    setPage(page);
    setIsLoading(true);
  }

  function changeAmountPerPage(pageSize: number) {
    setPageSize(pageSize);
    setIsLoading(true);
  }

  const handleLogsList = useCallback(async () => {
    setIsLoading(true);
    const response = await getAllLogs(page, pageSize, filterValue);

    if (response.type === "success" && response.body) {
      const logsList = response.body[0].map(
        ({ event, username, action_over_uuid, createdAt, origin }) => {
          const createdAtFormatted = createdAt
            ? format(new Date(createdAt), "dd/MM/yyyy - HH'h'mm", {
                locale: ptBR,
              })
            : "";
          return {
            username,
            event,
            action_over_uuid,
            createdAt: createdAtFormatted,
            origin,
          };
        }
      );
      setIsLoading(false);
      setLogsData({
        data: logsList,
        totalElements: Number(response.body[1]),
      });
    }
  }, [filterValue, page, pageSize]);

  const columns: IColumnControlledDataTable[] = React.useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "USUÁRIO / TARIFADOR",
            accessor: "username",
            setValue: setFilterValue,
            value: filterValue,
          },
          {
            Header: "ORIGEM",
            accessor: "origin",
            setValue: setFilterValue,
            value: filterValue,
            filter: "multi",
            multiOptions: ["USUÁRIO", "API", "SISTEMA"],
          },
          {
            Header: "DATA/HORA",
            accessor: "createdAt",
            setValue: setFilterValue,
            value: filterValue,
            Filter: DatePickerFilter,
            filter: "date",
          },
          {
            Header: "EVENTO",
            accessor: "event",
            setValue: setFilterValue,
            value: filterValue,
          },
          {
            Header: "IDENTIFICADOR AFETADO",
            accessor: "action_over_uuid",
            setValue: setFilterValue,
            value: filterValue,
          },
        ] as ControlledDataTableColumn[],
      },
    ],
    [filterValue]
  );

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Log de atividade", href: "" },
  ];

  useEffect(() => {
    handleLogsList();
  }, [handleLogsList]);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Log de atividade
          </HLarge2xl>
        </Flex>
        <ControlledDataTable
          columns={columns}
          data={logsData.data}
          totalElements={logsData.totalElements}
          pageIndex={page}
          changePage={changePage}
          changeAmountPerPage={changeAmountPerPage}
          isLoading={isLoading}
        />
      </GridItem>
    </Wrapper>
  );
};
