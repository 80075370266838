import { Flex, GridItem, Select } from "@chakra-ui/react";
import { useState } from "react";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl, HLargesm } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { useAuth } from "../../../../main/hooks/useAuth";
import { enumNameOfReports } from "../../../../types/main/reports";
import { BackgroundLogs } from "../components/reports/BackgroundLogs";
import { BlockedClients } from "../components/reports/BlockedClients";
import { CreditGranted } from "../components/reports/CreditGranted";
import { EFD } from "../components/reports/EFD";
import { Receipts } from "../components/reports/Receipts";
import { ReceivedCharges } from "../components/reports/ReceivedCharges";
import { RepickReceipts } from "../components/reports/RepickReceipts";
import { SendCharges } from "../components/reports/SendCharges";
import { canSeeReport } from "../functions/permissions";

const SEND_CHARGES_ENUM = [
  "SEND_BOLETO",
  "SEND_AUTOMATIC_DEBIT",
  "SEND_THIRD_PARTY_DEBIT",
  "SEND_GENERAL",
];

const RECEIVED_CHARGES_ENUM = [
  "RECEIVE_BOLETO",
  "RECEIVE_AUTOMATIC_DEBIT",
  "RECEIVE_GENERAL",
];

export type ReportsUnion = keyof typeof enumNameOfReports | "BACKGROUND_LOGS";

export const ReportList = () => {
  const { permissionLevel } = useAuth();

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Relatórios", href: "" },
  ];

  const [nameReport, setNameReport] = useState<ReportsUnion>();

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Relatórios
          </HLarge2xl>
        </Flex>
        <Flex alignItems="center" mb="16px">
          <HLargesm color="gray.500">Selecione o relatório</HLargesm>
          <Select
            ml="32px"
            minWidth="400px"
            placeholder="Selecione o relatório"
            w="75px"
            onChange={(e) => {
              setNameReport(e.target.value as keyof typeof enumNameOfReports);
            }}
          >
            {Object.entries(enumNameOfReports)
              .sort((a, b) => a[1].localeCompare(b[1]))
              .filter(([key]) => canSeeReport(permissionLevel, key))
              .map(([key, value]) => (
                <option key={key} value={key}>
                  {value}
                </option>
              ))}
          </Select>
        </Flex>
        {nameReport === "BLOCKED_CUSTOMERS" && <BlockedClients />}
        {nameReport === "RECEIVED" && <Receipts />}
        {nameReport === "GENERATION_EFD_PIS_COFINS" && <EFD />}
        {nameReport === "RECEIVED_BY_REPICK" && <RepickReceipts />}
        {nameReport === "CREDIT_GRANTED" && <CreditGranted />}
        {nameReport &&
          SEND_CHARGES_ENUM.includes(nameReport as enumNameOfReports) && (
            <SendCharges nameReport={nameReport} />
          )}

        {nameReport &&
          RECEIVED_CHARGES_ENUM.includes(nameReport as enumNameOfReports) && (
            <ReceivedCharges nameReport={nameReport} />
          )}

        {nameReport === "BACKGROUND_LOGS" && <BackgroundLogs />}
      </GridItem>
    </Wrapper>
  );
};
