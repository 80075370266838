import { ArrowForwardIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightElement,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { customToast } from "../../../../../../main/common/utils/customToast";
import { NFeModal } from "../../../../../../main/components/NFeModal";
import {
  HSmallxl,
  HSmallmd,
  HLargexs,
} from "../../../../../../main/components/Tipography";
import { useAnchorDownload } from "../../../../../../main/hooks/useAnchorDownload";
import { IBlobError } from "../../../../../../main/hooks/useDownloadFile";
import { handleError } from "../../../../../../main/hooks/useDownloadFile/functions/handleError";
import { BadgeTariffKpi } from "../BadgeTariffKpi";
import { IInvoiceProps } from "./invoice.interface";

const Invoice = ({
  invoiceId,
  nfeGenerated,
  invoiceValue,
  invoiceInterest,
  invoiceFine,
  invoiceTotal,
  invoiceNumber,
  invoiceStatus,
  tariffId,
}: IInvoiceProps) => {
  const [label, setLabel] = useState("");
  const [colorScheme, setColorScheme] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const preDownloading = () => {
    setIsLoading(true);
  };

  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage = await handleError(error);

    customToast("error", errorMessage);

    setIsLoading(false);
  };

  const {
    downloadFile: downloadInvoice,
    ref: refInvoice,
    url: urlInvoice,
    name: nameInvoice,
    viewInNewTab: viewInvoiceInNewTab,
  } = useAnchorDownload({
    apiDefinition: {
      endpoint: `/invoicepdf/invoiceDetailedTariff/${invoiceId}/${tariffId}`,
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Tarifa-${tariffId}-Detalhada`,
    viewUrlRequest: `/viewinvoicepdf/invoiceDetailedTariff/${invoiceId}/${tariffId}`,
  });

  useEffect(() => {
    if (invoiceStatus?.includes("OPEN")) {
      setLabel("Aberto");
      setColorScheme("orange");
    } else if (invoiceStatus?.every((element) => element === "CANCELED")) {
      setLabel("Cancelada");
      setColorScheme("red");
    } else if (
      invoiceStatus?.every((element) => {
        return element === "CANCELED" || element === "CLOSED";
      })
    ) {
      setLabel("Concluída");
      setColorScheme("green");
    }
  }, [invoiceStatus]);

  return (
    <>
      <HSmallxl mt="32px" mb="20px">
        Fatura
      </HSmallxl>
      <Grid
        borderRadius="6px"
        border="1px"
        borderColor="gray.200"
        backgroundColor="gray.50"
        h="228px"
      >
        <Flex
          h="52px"
          justifyContent="space-between"
          backgroundColor="white"
          w="100%"
          alignItems="center"
          px="24px"
        >
          <Flex>
            <HSmallmd mr="32px">{invoiceNumber}</HSmallmd>
            <BadgeTariffKpi label={label} colorScheme={colorScheme} />
          </Flex>
          <Link to={`/invoicelist?id=${invoiceNumber}`}>
            <Button
              rightIcon={<ArrowForwardIcon />}
              colorScheme="blue"
              size="sm"
            >
              Acessar fatura
            </Button>
          </Link>
        </Flex>
        <Grid
          mt="12px"
          templateRows="repeat(3, 1fr)"
          templateColumns="repeat(3, 1fr)"
          mx="24px"
          gap="12px 17px"
        >
          <GridItem>
            <a
              href={urlInvoice}
              download={nameInvoice}
              hidden
              ref={refInvoice}
            />
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Gerou PDF
            </HLargexs>
            <InputGroup size="md">
              <Input
                defaultValue={!!invoiceNumber ? "Sim" : "Não"}
                isReadOnly
                backgroundColor="white"
              />
              <InputRightElement width="8rem">
                <Button
                  size="sm"
                  mr="2"
                  variant="link"
                  colorScheme="facebook"
                  p="2"
                  isLoading={isLoading}
                  onClick={viewInvoiceInNewTab}
                >
                  Visualizar
                </Button>
                <Button
                  size="sm"
                  mr="2"
                  variant="link"
                  colorScheme="facebook"
                  p="2"
                  isLoading={isLoading}
                  onClick={downloadInvoice}
                >
                  Baixar
                </Button>
              </InputRightElement>
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Gerou NFe
            </HLargexs>
            <InputGroup size="md">
              <Input
                value={nfeGenerated ? "Sim" : "Não"}
                onChange={() => (nfeGenerated ? "Sim" : "Não")}
                isReadOnly
                backgroundColor="white"
              />
              <InputRightElement width="4.5rem">
                {nfeGenerated && (
                  <NFeModal invoiceId={invoiceId ?? null} type="button" />
                )}
              </InputRightElement>
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Valor
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                value={Number(invoiceValue).toLocaleString("pt-br", {
                  currency: "BRL",
                  style: "decimal",
                  minimumFractionDigits: 2,
                })}
                onChange={() =>
                  Number(invoiceValue).toLocaleString("pt-br", {
                    currency: "BRL",
                    style: "decimal",
                    minimumFractionDigits: 2,
                  })
                }
                isReadOnly
              />
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Juros
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                value={Number(invoiceInterest).toLocaleString("pt-br", {
                  currency: "BRL",
                  style: "decimal",
                  minimumFractionDigits: 2,
                })}
                onChange={() =>
                  Number(invoiceInterest).toLocaleString("pt-br", {
                    currency: "BRL",
                    style: "decimal",
                    minimumFractionDigits: 2,
                  })
                }
              />
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Multa
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                value={Number(invoiceFine).toLocaleString("pt-br", {
                  currency: "BRL",
                  style: "decimal",
                  minimumFractionDigits: 2,
                })}
                onChange={() =>
                  Number(invoiceFine).toLocaleString("pt-br", {
                    currency: "BRL",
                    style: "decimal",
                    minimumFractionDigits: 2,
                  })
                }
              />
            </InputGroup>
          </GridItem>

          <GridItem>
            <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
              Valor Total
            </HLargexs>
            <InputGroup>
              <InputLeftAddon children="R$" />
              <Input
                value={Number(invoiceTotal).toLocaleString("pt-br", {
                  currency: "BRL",
                  style: "decimal",
                  minimumFractionDigits: 2,
                })}
                onChange={() =>
                  Number(invoiceTotal).toLocaleString("pt-br", {
                    currency: "BRL",
                    style: "decimal",
                    minimumFractionDigits: 2,
                  })
                }
              />
            </InputGroup>
          </GridItem>
        </Grid>
      </Grid>
    </>
  );
};

export { Invoice };
