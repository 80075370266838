import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { customToast } from "../../../../main/common/utils/customToast";
import { downloadFileCsv } from "../../../../main/common/utils/downloadFile";
import {
  IGetAllInvoicingTariffs,
  IInvoicingSimulation,
} from "../../../../types/main/invoicing";
import { createInvoices } from "../service/InvoiceService";
import {
  generateInvoicingSimulateCsv,
  simulateInvoices,
} from "../service/InvoicingService";

export const useSimulation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [invoicesSimulated, setInvoicesSimulates] =
    useState<IInvoicingSimulation>();

  const [isMountingTable, setIsMountingTable] = useState(false);

  const getFiltersFromLocalStorage = useCallback(() => {
    const { state } = location as {
      state: { filters: IGetAllInvoicingTariffs };
    };
    if (state.filters) {
      return state.filters;
    }
    return null;
  }, [location]);

  const handleInvoiceSimulation = useCallback(
    (invoices: IInvoicingSimulation) => {
      if (
        Array.isArray(invoices.invoicesGroup) &&
        invoices.invoicesGroup.length > 0
      ) {
        setIsMountingTable(true);

        setTimeout(() => {
          setInvoicesSimulates(invoices);
          setIsLoading(false);
          setIsMountingTable(false);
          customToast("success", "Confira os dados das faturas.", 5000);
        }, 3000);
      } else {
        customToast(
          "info",
          "Nenhuma Tarifa encontrada com os filtros especificados.",
          5000
        );

        navigate(-1);
      }
    },
    [navigate]
  );

  const loadTariffsFromFilters = useCallback(async () => {
    try {
      setIsLoading(true);
      setInvoicesSimulates(undefined);

      const filters = getFiltersFromLocalStorage();
      const invoicingSimulation = await simulateInvoices(filters);
      handleInvoiceSimulation(invoicingSimulation);
    } catch (error: any) {
      customToast("error", error?.body?.message, 5000);

      setIsLoading(false);
      setIsMountingTable(false);
    }
  }, [getFiltersFromLocalStorage, handleInvoiceSimulation]);

  const saveInvoices = useCallback(async () => {
    if (
      !invoicesSimulated ||
      !Array.isArray(invoicesSimulated.tariffsIdPerInvoice)
    )
      return;

    const response = await createInvoices({
      tariffsIdPerInvoiceGroup: invoicesSimulated.tariffsIdPerInvoice,
    });

    customToast("success", response.body?.message);
    navigate("/invoicinglist");
  }, [invoicesSimulated, navigate]);

  const exportSimulation = useCallback(async (onLoading) => {
    onLoading(true);

    const filters = getFiltersFromLocalStorage();

    const data = await generateInvoicingSimulateCsv(filters);
    onLoading(false);
    downloadFileCsv(data, "Simulacao");
  }, []);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) loadTariffsFromFilters();

    return () => {
      isMounted = false;
    };
  }, [loadTariffsFromFilters]);

  return {
    saveInvoices,
    invoicesSimulated,
    isLoading,
    loadTariffsFromFilters,
    isMountingTable,
    exportSimulation,
  };
};
