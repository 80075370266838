import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_PRODUCTS_PERMISSION_LEVEL,
  GET_PRODUCTS_PERMISSION_LEVEL,
  UPDATE_PRODUCTS_PERMISSION_LEVEL,
} from "./constants/permissions";
import { ProductList } from "./pages/ProductList";
import { ProductManagement } from "./pages/ProductManagement";

export function ProductsModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_PRODUCTS_PERMISSION_LEVEL} />}
      >
        <Route path="/productlist" element={<ProductList />} />
        <Route
          path="/viewproduct/:uuid"
          element={<ProductManagement isReadOnly />}
        />
      </Route>
      <Route
        element={<PrivateRoute permission={CREATE_PRODUCTS_PERMISSION_LEVEL} />}
      >
        <Route path="/manageproduct" element={<ProductManagement />} />
      </Route>
      <Route
        element={<PrivateRoute permission={UPDATE_PRODUCTS_PERMISSION_LEVEL} />}
      >
        <Route path="/manageproduct/:uuid" element={<ProductManagement />} />
      </Route>
    </Routes>
  );
}
