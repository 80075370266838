import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IBiller } from "../../../../types/main/biller";
import { IResponse } from "../../../../types/main/response";
import { AllBillers } from "../../Biller/service/BillerService";

export const useGetAllBillers = () => {
  const toast = useToast();

  const [allBillerState, setAllBillerState] = useState<Array<IBiller>>();

  const refreshList = useCallback(async () => {
    try {
      const response: IResponse<Array<IBiller>> = await AllBillers();
      if (response.type === "success" && response.body) {
        setAllBillerState(response.body);
      }
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    refreshList();
  }, [refreshList]);

  return { allBillerState };
};
