import { useIsAuthenticated } from "@azure/msal-react";
import { createContext, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const RouteContext = createContext({});

type RouteProviderProps = {
  children: React.ReactNode;
};

const INITIAL_ROUTE = "/";

export const RouteProvider = ({ children }: RouteProviderProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isAuthenticated && pathname !== INITIAL_ROUTE) {
      navigate(INITIAL_ROUTE);
    }
  }, [isAuthenticated, navigate, pathname]);

  const context = useMemo(() => ({}), []);

  return (
    <RouteContext.Provider value={context}>{children}</RouteContext.Provider>
  );
};
