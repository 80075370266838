import { ColumnFiltersState } from "@tanstack/react-table";

export const transformFilterStateToQueryParams = (
  state: ColumnFiltersState
) => {
  const queryParamsFilters = state
    ?.map((filter) => ({
      [filter.id]: filter.value,
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {});

  return queryParamsFilters ?? {};
};
