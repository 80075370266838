export const ClientFormTextPerType = {
  PF: {
    header: "Pessoa Física",
    labelName: "Nome",
    labelDate: "Data de nascimento",
    labelDocument: "CPF",
    documentMask: "###.###.###-##",
  },
  PJ: {
    header: "Pessoa Jurídica",
    labelName: "Razão social",
    labelDate: "Data da fundação",
    labelDocument: "CNPJ",
    documentMask: "##.###.###/####-##",
  },
};
