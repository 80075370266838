import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";

import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IClient,
  IClientData,
  IClientOptions,
} from "../../../../types/main/client";
import { IMaritalStatus } from "../../../../types/main/maritalStatus";
import { IPaginateResponse } from "../../../../types/main/pagination";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/clients";
const MARITAL_BASE_URL = "/marital";

type ClientListParams = {
  pagination?: PaginationState;
  filters?: TableFilteredParams;
  sorting?: TableSortingParams;
  return_even_if_deleted?: string | null;
};

export const allClient = async ({
  return_even_if_deleted,
  pagination,
  filters,
  sorting,
}: ClientListParams): Promise<IResponse<IPaginateResponse<IClient>>> => {
  try {
    const page = pagination?.pageIndex;

    const response: AxiosResponse<IResponse<IPaginateResponse<IClient>>> =
      await recordApi.get(MODULE_BASE_URL, {
        params: {
          return_even_if_deleted: return_even_if_deleted ?? undefined,
          page: !page ? 1 : page + 1,
          take: pagination?.pageSize ?? 10,
          ...filters,
          ...sorting,
        },
      });
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const getClientOptions = async (
  fullName?: string,
  withDeletedUuid?: string
) => {
  try {
    const response: AxiosResponse<IResponse<Array<IClientOptions>>> =
      await recordApi.get(`${MODULE_BASE_URL}/options`, {
        params: {
          fullName,
          withDeletedUuid,
        },
      });
    return response.data.body ?? [];
  } catch (err: any) {
    return [];
  }
};

export const deleteClient = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewClient = async (clientData: IClientData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, {
        ...clientData,
        birthDate: clientData.birthDate
          ? new Date(clientData.birthDate)
          : undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const EditClient = async (clientData: IClientData, uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, {
        ...clientData,
        birthDate: clientData.birthDate
          ? new Date(clientData.birthDate)
          : undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetMaritalStatus = async () => {
  try {
    const response: AxiosResponse<IResponse<Array<IMaritalStatus>>> =
      await recordApi.get(MARITAL_BASE_URL);

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetClientByUUID = async (uuid: string) => {
  try {
    const response: AxiosResponse<IResponse<IClient>> = await recordApi.get(
      `${MODULE_BASE_URL}/search/${uuid}`
    );

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetClientCsvByUUIDs = async (filters: TableFilteredParams) => {
  try {
    const { data } = await recordApi.post(`getClientCsvByUuids`, undefined, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};

export const GetClientCsvAllUUIDs = async () => {
  try {
    const { data } = await recordApi.get(`getClientCsvAllUuids`);
    return data;
  } catch (err: any) {
    return null;
  }
};
