import { useEffect, useState } from "react";

import { IManageInvoiceConfig } from "../../../../../types/main/channelInvoiceConfig";
import { IFontOptions } from "../../../../../types/main/fonts";
import {
  getChannelInvoiceConfig,
  getFontsOptions,
} from "../../services/ChannelInvoiceService";

interface IUseFetchChannelInvoice {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  channelId: string | undefined;
}

export const useFetchChannelInvoice = ({
  setIsLoading,
  channelId,
}: IUseFetchChannelInvoice) => {
  const [fontsList, setFontsList] = useState<IFontOptions[]>([]);
  const [existingInvoiceConfig, setExistingInvoiceConfig] = useState<
    IManageInvoiceConfig | undefined
  >();

  const [hasFetchError, setHasFetchError] = useState(false);

  const loadFonts = async () => {
    const fonts = await getFontsOptions();
    return fonts.body ?? [];
  };

  const loadExistingInvoiceConfig = async (channelId: string) => {
    const invoiceConfig = await getChannelInvoiceConfig(channelId);
    return invoiceConfig.body ?? undefined;
  };

  useEffect(() => {
    let isCancelled = false;

    const loadDataLists = async () => {
      if (!channelId) return;
      try {
        setIsLoading(true);
        const fonts = await loadFonts();
        const invoiceConfig = await loadExistingInvoiceConfig(channelId);

        if (!isCancelled) {
          setFontsList(fonts);
          setExistingInvoiceConfig(invoiceConfig);
          setIsLoading(false);
        }
      } catch (error) {
        if (!isCancelled) setHasFetchError(true);
      }
    };

    loadDataLists();

    return () => {
      isCancelled = true;
    };
  }, [channelId, setIsLoading]);

  return {
    fontsList,
    existingInvoiceConfig,
    hasFetchError,
  };
};
