import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";

import { TSmallsm } from "../../Tipography";

type TableServerPaginationProps = {
  table: Table<any>;
  hidePerPageControl?: boolean;
  hidePageSkipControl?: boolean;
};

export const TableServerPagination = ({
  table,
  hidePageSkipControl,
  hidePerPageControl,
}: TableServerPaginationProps) => {
  return (
    <Flex justifyContent="space-between" m={4} alignItems="center" mb="2rem">
      <Flex
        alignItems="center"
        visibility={hidePerPageControl ? "hidden" : "visible"}
      >
        <TSmallsm as="span" mr="8px">
          Exibir linhas por página
        </TSmallsm>
        <Select
          w="75px"
          value={table.getState().pagination.pageSize}
          onChange={(e: any) => {
            table.setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 40, 50].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems="center">
        {!hidePageSkipControl && (
          <>
            <TSmallsm flexShrink="inherit" mr="8px">
              Ir para a página
            </TSmallsm>
            <NumberInput
              colorScheme="gray"
              w={100}
              min={1}
              focusBorderColor="gray.100"
              defaultValue={table.getState().pagination.pageIndex + 1}
              onChange={(valueString) => {
                const page = valueString ? Number(valueString) - 1 : 0;
                table.setPageIndex(page);
              }}
            >
              <NumberInputField />
            </NumberInput>
          </>
        )}
        <Flex>
          <Tooltip label="Primeira Página">
            <IconButton
              variant="unstyled"
              _focus={{ borderColor: "white" }}
              aria-label="First"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              icon={<ArrowLeftIcon h={3} w={3} />}
              mr={4}
            />
          </Tooltip>
          <Tooltip label="Pagina Anterior">
            <IconButton
              _focus={{ borderColor: "white" }}
              variant="unstyled"
              aria-label="Previous"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              icon={<ChevronLeftIcon h={6} w={6} />}
            />
          </Tooltip>
        </Flex>
        <Flex alignItems="center">
          <TSmallsm flexShrink="inherit" mr={8}>
            <TSmallsm as="span">
              {table.getState().pagination.pageIndex + 1}
            </TSmallsm>{" "}
            de <TSmallsm as="span">{table.getPageCount() || 1}</TSmallsm>
          </TSmallsm>
        </Flex>
        <Flex>
          <Tooltip label="Próxima Página">
            <IconButton
              _focus={{ borderColor: "white" }}
              variant="unstyled"
              aria-label="Next"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              icon={<ChevronRightIcon h={6} w={6} />}
            />
          </Tooltip>
          <Tooltip label="Última Página">
            <IconButton
              _focus={{ borderColor: "white" }}
              variant="unstyled"
              aria-label="last"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              icon={<ArrowRightIcon h={3} w={3} />}
              ml={4}
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Flex>
  );
};
