import { useEffect, useState } from "react";
import { TreeData } from "react-dropdown-tree-select";

import { IProductOption } from "../../../../../../types/main/products";
import { getChannelOptionsTree } from "../../../../Channels/services/ChannelService";
import { getProductOptions } from "../../../../Products/service/ProductService";

export const useBlockedClientsFilters = () => {
  const [channels, setChannels] = useState<TreeData[]>([]);
  const [products, setProducts] = useState<IProductOption[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const loadChannels = async () => {
    const response = await getChannelOptionsTree();
    setChannels(response.body ?? []);
  };

  const loadProducts = async () => {
    const response = await getProductOptions({});
    setProducts(response.body ?? []);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await loadChannels();
        await loadProducts();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    channels,
    products,
    isFetching: isLoading,
    setChannels,
  };
};
