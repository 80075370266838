import * as yup from "yup";

import { isFirstDateBeforeOrEqualSecondDate } from "../../../../main/common/utils/dateHelper";

export const prePaidSchema = yup.object({
  idContract: yup.string().required("Esse campo é obrigatório"),
  clientUuid: yup.string().required("Esse campo é obrigatório"),
  channelId: yup.string().required("Esse campo é obrigatório"),
  productUuid: yup.string().required("Esse campo é obrigatório"),
  inclusionDate: yup
    .string()
    .test("Future Date Validation", "Data não pode ser futura.", (value) =>
      isFirstDateBeforeOrEqualSecondDate(value, new Date())
    )
    .required("Esse campo é obrigatório"),
});
