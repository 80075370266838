import { Button } from "@chakra-ui/react";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";
import { Link } from "react-router-dom";

export const ButtonLinkProcessing = ({ value }: { value: string }) => {
  const url = new URL(value);

  if (!url.pathname) return <> </>;

  return (
    <Link to={`../../../${url.pathname}`} target="_blank">
      <Button
        iconSpacing={0}
        leftIcon={<BsFillArrowUpRightCircleFill />}
        colorScheme="blue"
      />
    </Link>
  );
};
