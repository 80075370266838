import {
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { debounceMyFunction } from "debounce-my-function";
import { FormProvider, useForm } from "react-hook-form";

import { addHoursToISOString } from "../../common/utils/dateHelper";
import {
  IMultiSelectOptions,
  useMultiCheckboxSelect,
} from "../MultiSelect/useMultiSelect";
import { IOnRangeChangeProps, RangeDatePicker } from "../RangeDatePicker";

type ServerFilterProps = {
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  accessor: string;
  filterType?: "multi" | "includes" | "text" | "date";
  multiOptions?: string[];
};

const ServerMultiSelectFilter = ({
  value,
  setValue,
  accessor,
  multiOptions,
}: ServerFilterProps) => {
  if (!multiOptions) {
    throw new Error(
      "multiOptions is required when using filter type multi in ControlledDataTable"
    );
  }

  const onCheckboxChange = (options: IMultiSelectOptions[]) => {
    const newFilters = options
      .filter((option) => option.checked)
      .map((option) => option.value);
    setValue(`${accessor}=${newFilters.join()}`);
  };

  const { MultiSelectCheckbox, placeholder } =
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useMultiCheckboxSelect({
      options: multiOptions.map((option: string) => ({
        label: option,
        value: option,
        checked: true,
      })),
      isAbleToSelectAll: true,
      onCustomChange: onCheckboxChange,
    });

  return (
    <Menu strategy="fixed" placement="left-start">
      <MenuButton
        borderRadius="50"
        variant="solid"
        as={InputGroup}
        disabled={value.length > 0 && !value.includes(`${accessor}=`)}
      >
        <Input
          bg="white"
          _placeholder={{ color: "gray.800" }}
          _disabled={{ color: "gray.800" }}
          isDisabled
          placeholder={placeholder}
        />
      </MenuButton>
      <MenuList
        zIndex="99"
        paddingInline="20px"
        paddingBottom="20px"
        fontWeight="normal"
      >
        <MultiSelectCheckbox />
      </MenuList>
    </Menu>
  );
};

const ServerDateFilter = ({ value, setValue, accessor }: ServerFilterProps) => {
  const formMethods = useForm<{ date: Date }>();

  const clearFilter = () => {
    formMethods.reset();
    setValue("");
  };

  const handleRangeDateChange = ({
    endDate,
    startDate,
    startTime,
    endTime,
  }: IOnRangeChangeProps) => {
    const startDateFormatted = startDate
      ? `${format(parseISO(startDate), "yyyy-MM-dd")}T${startTime}:00.000Z`
      : undefined;

    const endDateFormatted = endDate
      ? `${format(parseISO(endDate), "yyyy-MM-dd")}T${endTime}:59.000Z`
      : undefined;

    const startDatePlus3Hours = addHoursToISOString(startDateFormatted, 3);
    const endDatePlus3Hours = addHoursToISOString(endDateFormatted, 3);

    setValue(`${accessor}=${startDatePlus3Hours}_${endDatePlus3Hours}`);
  };

  return (
    <FormProvider {...formMethods}>
      <form>
        <RangeDatePicker
          disabled={value.length > 0 && !value.includes(`${accessor}=`)}
          onClearDate={clearFilter}
          onSearch={handleRangeDateChange}
        />
      </form>
    </FormProvider>
  );
};

export function ServerColumnFilter(props: ServerFilterProps) {
  const { value, setValue, accessor, filterType = "text" } = props;

  const handleChange = (e: any) => {
    setValue(e.target.value ? `${accessor}=${e.target.value}` : "");
  };

  const debounceHandleChange = debounceMyFunction(handleChange, 500);

  if (filterType === "multi") {
    return <ServerMultiSelectFilter {...props} />;
  }

  if (filterType === "date") {
    return <ServerDateFilter {...props} />;
  }

  return (
    <Input
      disabled={value.length > 0 && !value.includes(`${accessor}=`)}
      width="100%"
      onChange={debounceHandleChange}
    />
  );
}
