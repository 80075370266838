/* eslint-disable prettier/prettier */
import { IChannel } from "./channel";
import { IClient } from "./client";
import { IFieldDefault } from "./fieldDefault";
import { IProducts } from "./products";

export enum enumTypeOfPayment {
  "AUTOMATIC_DEBIT" = "Débito automático",
  "BOLETO" = "Boleto",
  "THIRD_PARTY_DEBIT" = "Débito de terceiros",
  "PRE_PAID" = "Pré-pago",
}

interface IContractsFields {
  idContract: number;
  channelId: number;
  channel: IChannel;
  productUuid: string;
  product: IProducts;
  clientUuid: string;
  client: IClient;
  typeOfPayment: keyof typeof enumTypeOfPayment;
  inclusionDate: Date;
  juros: number;
  multa: number;
  bankNumber?: number;
  agencyNumber?: number;
  currentAccount?: number;
  registrationNumber: string;
}

interface IContractsData
  extends Omit<
    IContractsFields,
    "product" | "client" | "channel" | "registrationNumber" | "multa" | "juros"
  > {
  productUuid: string;
  clientUuid: string;
  registrationNumber?: string;
  multa?: number;
  juros?: number;
}

type IContractsList = {
  paymentType: string;
  idContract: number;
  registrationNumber: string;
  channelId: number;
  productName: string;
  clientName: string;
  document: string;
  bankNumber: number | undefined;
  agencyNumber: number | undefined;
  currentAccount: number | undefined;
  createdAt: Date;
  inclusionDate: Date;
  juros: number;
  multa: number;
  uuid: string;
};

type IContractsOptions = {
  idContract: number;
  uuid: string;
  productName: string;
  value: string;
  label: string;
};

type IContracts = IFieldDefault & IContractsFields;

export type {
  IContracts,
  IContractsFields,
  IContractsData,
  IContractsList,
  IContractsOptions,
};
