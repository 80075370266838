import { Routes, Route } from "react-router-dom";

import { CARsList } from "./pages/CARsList";

export function CARsModule() {
  return (
    <Routes>
      <Route path="/carslist" element={<CARsList />} />
    </Routes>
  );
}
