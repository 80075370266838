import { useToast } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { TreeData } from "react-dropdown-tree-select";
import { IBoletoFilters } from "../../../../types/main/boleto";
import { IProductOption } from "../../../../types/main/products";
import { getChannelOptionsTree } from "../../Channels/services/ChannelService";
import { getProductOptions } from "../../Products/service/ProductService";

type Props = {
  handleSearch: (filters?: IBoletoFilters) => Promise<void>;
};

export const useBoletosFilters = ({ handleSearch }: Props) => {
  const toast = useToast();
  const [channels, setChannels] = useState<TreeData[]>([]);
  const [products, setProducts] = useState<IProductOption[]>([]);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmitFilters = async (filters: IBoletoFilters) => {
    try {
      await handleSearch(filters);
    } catch (error) {
      toast({
        title: "Erro ao filtrar.",
        description: "Tivemos um erro ao aplicar os filtros.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const loadChannels = async () => {
    const response = await getChannelOptionsTree();
    setChannels(response.body ?? []);
  };

  const loadProducts = async () => {
    const response = await getProductOptions({});
    setProducts(response.body ?? []);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await Promise.all([loadChannels(), loadProducts()]);
      } catch (error) {
        setHasFetchError(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    channels,
    products,
    hasFetchError,
    isFetching: isLoading,
    onSubmitFilters,
    setChannels,
  };
};
