import { AxiosResponse } from "axios";

import { structureApi } from "../../../../main/services/StrucureService";
import { ILogs } from "../../../../types/main/logs";
import { IResponse } from "../../../../types/main/response";

export const getAllLogs = async (
  page: number,
  take: number,
  filter?: string
): Promise<IResponse<Array<Array<ILogs>>>> => {
  let urlQuery;
  try {
    if (filter && filter !== "createdAt=undefined_undefined") {
      urlQuery = `/logs?page=${page}&take=${take}&${filter}`;
    } else {
      urlQuery = `/logs?page=${page}&take=${take}`;
    }
    const response: AxiosResponse<IResponse<Array<Array<ILogs>>>> =
      await structureApi.get(urlQuery);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};
