import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Select,
  Grid,
  ButtonGroup,
  FormErrorMessage,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";

import { PERMISSION_LEVELS } from "../../../../../main/common/constants/permissions";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { useAuth } from "../../../../../main/hooks/useAuth";
import { filterProfileOptionsByPermission } from "../../functions";
import { useExistingUser } from "../../hooks/useExistingUser";
import { useFetchProfiles } from "../../hooks/useFetchProfiles";
import { useUserForm } from "../../hooks/useUserForm";
import { userSchema } from "../../validation/userSchema";

interface IUserForm {
  userUUID?: string;
  isReadOnly?: boolean;
}

interface IUserData {
  fullName: string;
  username: string;
  profile: string;
  email: string;
}

export const UserForm: React.FC<IUserForm> = ({ userUUID, isReadOnly }) => {
  const navigate = useNavigate();

  const { existingUserData } = useExistingUser(userUUID);

  const { permissionLevel } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors, isSubmitting },
  } = useForm<IUserData>({
    resolver: yupResolver(userSchema),
  });

  const {
    getUsernameFromEmailFirstPart,
    isLoading,
    onSubmitToCreateUser,
    onSubmitToUpdateUser,
    setIsLoading,
  } = useUserForm({ getValues, setValue, userUuid: existingUserData?.uuid });

  const { profiles } = useFetchProfiles({ setIsLoading });

  useEffect(() => {
    if (existingUserData && profiles) {
      const isExistingUserAdminOrMaster =
        existingUserData.profile.name === PERMISSION_LEVELS.MASTER ||
        existingUserData.profile.name === PERMISSION_LEVELS.ADMIN;

      const isLoggedUserAdmin = permissionLevel === PERMISSION_LEVELS.ADMIN;

      if (isExistingUserAdminOrMaster && isLoggedUserAdmin)
        navigate("/userlist");

      setValue("fullName", existingUserData.fullName);
      setValue("email", existingUserData.email);
      setValue("username", existingUserData.username);
      setValue("profile", existingUserData.profile.uuid);
    }
  }, [
    existingUserData,
    getValues,
    navigate,
    permissionLevel,
    profiles,
    setValue,
  ]);

  return (
    <>
      <form
        onSubmit={handleSubmit(
          existingUserData ? onSubmitToUpdateUser : onSubmitToCreateUser
        )}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid templateRows="(2, 1fr)" templateColumns="repeat(4, 1fr)" gap={5}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.profile}>
              <FormLabel htmlFor="type">Tipo de usuário</FormLabel>
              <Select
                isDisabled={isReadOnly}
                id="type"
                placeholder="Selecione o tipo de usuário"
                {...register("profile", {
                  required: "This is required",
                })}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
              >
                {profiles
                  .filter((profile) =>
                    filterProfileOptionsByPermission({
                      profile: profile.name,
                      permissionLevel,
                      isReadOnly,
                    })
                  )
                  .map((profile) => (
                    <option key={profile.uuid} value={profile.uuid}>
                      {profile.name}
                    </option>
                  ))}
              </Select>
              <FormErrorMessage>
                {errors.profile && errors.profile.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.fullName}>
              <FormLabel htmlFor="fullName">Nome Completo</FormLabel>
              <Input
                id="fullName"
                placeholder="Escreva seu nome"
                {...register("fullName")}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.fullName && errors.fullName.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.email}>
              <FormLabel htmlFor="email">E-mail</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Escreva seu e-mail"
                {...register("email", {
                  onChange: getUsernameFromEmailFirstPart,
                  minLength: {
                    value: 10,
                    message: "Minimun length should be 10",
                  },
                })}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.email && errors.email.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.username}>
              <FormLabel htmlFor="username">Username</FormLabel>
              <Input
                id="username"
                type="text"
                placeholder="Escreva seu username"
                {...register("username")}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.username && errors.username.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Flex w="100%" justify="flex-end">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/userlist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                {existingUserData ? "Editar usuário" : "Cadastrar usuário"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </>
  );
};
