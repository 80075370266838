import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import { IManageInvoiceConfig } from "../../../../types/main/channelInvoiceConfig";
import { IFontOptions } from "../../../../types/main/fonts";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

interface IPayloadManageInfoConfig {
  channelId: string;
  invoiceConfigData: IManageInvoiceConfig;
}

const MODULE_BASE_URL = "/channelinvoice";
const FONTS_BASE_URL = "/fonts";

export const getFontsOptions = async () => {
  try {
    const { data }: AxiosResponse<IResponse<Array<IFontOptions>>> =
      await recordApi.get(`${FONTS_BASE_URL}/options`);

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const manageInvoiceConfig = async ({
  invoiceConfigData,
  channelId,
}: IPayloadManageInfoConfig) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(`${MODULE_BASE_URL}/${channelId}`, {
        ...invoiceConfigData,
        invoiceConfigs: invoiceConfigData.invoiceConfigs ?? undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getChannelInvoiceConfig = async (channelId: string) => {
  try {
    const { data }: AxiosResponse<IResponse<IManageInvoiceConfig>> =
      await recordApi.get(`${MODULE_BASE_URL}/${channelId}`);

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};
