import axios, { AxiosInstance } from "axios";

import { jwtRequestInterceptor } from "./interceptor";

export const RECORDS_API_BASE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8001"
    : "https://ENV_RECORDS_API_URL";

const apiUri: string = RECORDS_API_BASE_URL;

const recordApi: AxiosInstance = axios.create({
  baseURL: apiUri,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
    Expires: "0",
  },
});

jwtRequestInterceptor(recordApi);

export { recordApi };
