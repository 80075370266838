import * as yup from "yup";

export const cancelCarSchema = yup
  .object({
    uuidCar: yup.string().required("Esse campo é obrigatório"),
    processingCarCancellationUuid: yup
      .string()
      .required("Esse campo é obrigatório"),
  })
  .required();
