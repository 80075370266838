import { EditIcon, InfoIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";

import { DatePicker } from "../../../../main/components/DateTimePicker/DatePicker";
import { TLargesm } from "../../../../main/components/Tipography";
import { useAuth } from "../../../../main/hooks/useAuth";
import { interstarFigmaTheme } from "../../../../styles/theme";
import { ITariffDataUpdate } from "../../../../types/main/tariff";
import { CAN_PERMISSION_UPDATE_DUE_DATE } from "../constants/permissions";
import { UseDueDateUpdate } from "../hooks/useDueDateUpdate";

interface ICARsModal {
  date: string;
  fieldName: string;
  id: number;
  refreshTable?: (data?: any) => Promise<void>;
  isInRemittance: boolean;
  redirectOnSave?: boolean;
}

export const CARsDueDateModal = ({
  date,
  fieldName,
  id,
  refreshTable,
  isInRemittance,
  redirectOnSave = true,
}: ICARsModal) => {
  const { permissionLevel } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formMethods = useForm<ITariffDataUpdate>({
    defaultValues: {
      id,
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = formMethods;

  const { onSubmitToUpdate } = UseDueDateUpdate(
    onClose,
    refreshTable,
    redirectOnSave
  );

  return (
    <>
      <HStack>
        <TLargesm>{!!date ? date : "Não definida"}</TLargesm>

        {isInRemittance && (
          <Tooltip hasArrow label="Em Remessa aberta" placement="top">
            <InfoIcon color="blue.600" cursor="pointer" />
          </Tooltip>
        )}
        {CAN_PERMISSION_UPDATE_DUE_DATE[permissionLevel] && !isInRemittance && (
          <Flex
            w="24px"
            h="24px"
            borderRadius="6px"
            onClick={onOpen}
            backgroundColor={interstarFigmaTheme.colors.gray200}
            justify="center"
            align="center"
            transition="0.2s"
            _hover={{
              backgroundColor: interstarFigmaTheme.colors.blueInterstar,
              color: !date ? "red" : "white",
              cursor: "pointer",
            }}
            color={!date ? "red" : "gray.800"}
          >
            <EditIcon name="editIcon">Editar</EditIcon>
          </Flex>
        )}
      </HStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Atualizar data de vencimento</ModalHeader>
          <ModalCloseButton />
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmitToUpdate)}>
              <ModalBody pb={6}>
                <span>Data de vencimento: {date}</span>
                <Divider marginBottom="18px" />

                <FormControl>
                  <FormLabel htmlFor={fieldName}>
                    Nova data de vencimento:{" "}
                  </FormLabel>
                  <DatePicker fieldname={fieldName} isDisabled={false} />
                </FormControl>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="blue"
                  mr={3}
                  type="submit"
                  isLoading={isSubmitting}
                >
                  Atualizar
                </Button>
                <Button onClick={onClose}>Cancelar</Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};
