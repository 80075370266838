import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Backdrop } from "../../../../main/components/Backdrop";
import { ConfirmationPopover } from "../../../../main/components/ConfirmationPopover";
import { BooleanRadioGroup } from "../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { EnumRadioGroup } from "../../../../main/components/CustomRadioGroup/EnumRadioGroup";
import { DatePicker } from "../../../../main/components/DateTimePicker/DatePicker";
import { HLargexs, TLargemd } from "../../../../main/components/Tipography";
import { useAuth } from "../../../../main/hooks/useAuth";
import { IProcessingCarTypeOfLow } from "../../../../types/main/processingCarTypeOfLow";
import { CAN_PERMISSION_SET_MANUAL_LOW } from "../../Tariff/constants/permissions";
import { useAllProcessingCarTypeOfLowRequest } from "../../Tariff/hooks/useAllProcessingCarTypeOfLowRequest";
import {
  IMassManualWriteOff,
  IMassManualWritOffForm,
} from "../interfaces/mass-manual-write-off.interface";
import { massManualWriteOffSchema } from "../schemas/mass-manual-write-off.schema";
import { getAllProcessingCarPaymentConfirmationType } from "../service/ProcessingCarService";

export const MassManualWriteOff = ({
  isOpen,
  onClose,
}: IMassManualWriteOff) => {
  const { permissionLevel } = useAuth();
  const toast = useToast();
  const { onSubmitCarLow } = useAllProcessingCarTypeOfLowRequest();

  const methods = useForm<IMassManualWritOffForm>({
    resolver: yupResolver(massManualWriteOffSchema),
  });

  const [allProcessingCarTypeOfLowList, setAllProcessingCarTypeOfLowList] =
    useState<IProcessingCarTypeOfLow[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = useCallback(async () => {
    const response = await getAllProcessingCarPaymentConfirmationType();
    if (response.body?.length) {
      setAllProcessingCarTypeOfLowList(response.body);
      methods.setValue("processingCarTypeOfLowUuid", response.body[0].uuid);
    }
  }, [methods, setAllProcessingCarTypeOfLowList]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const writeOffReasons = useMemo(() => {
    return allProcessingCarTypeOfLowList.map(({ uuid, labelTypeOfLow }) => {
      return {
        label: labelTypeOfLow,
        value: uuid,
      };
    });
  }, [allProcessingCarTypeOfLowList]);

  if (!CAN_PERMISSION_SET_MANUAL_LOW[permissionLevel]) {
    return null;
  }

  const tariffCarIds = methods.watch("tariffCarIds");
  const processingCarTypeOfLowUuid = methods.watch(
    "processingCarTypeOfLowUuid"
  );
  const dateOfLow = methods.watch("dateOfLow");
  const reasonForManualLow = methods.watch("reasonForManualLow");
  const ignoreFineAndInterest = methods.watch("ignoreFineAndInterest");

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const ids = tariffCarIds.trim().split("\n");
      const data = ids.map((id: string) => ({
        tariffCarId: +id,
        processingCarTypeOfLowUuid,
        dateOfLow,
        reasonForManualLow,
        ignoreFineAndInterest,
      }));

      await onSubmitCarLow({
        data,
        onClose,
      });

      setIsLoading(false);
      onClose();
    } catch (error) {
      setIsLoading(false);
      onClose();
      toast({
        title: "Ocorreu um erro.",
        description:
          "Por favor, tente novamente mais tarde. Se o erro persistir, entre em contato com o suporte.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Baixa manual em massa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={onSubmit}>
              <Flex justifyContent="space-between">
                <EnumRadioGroup
                  fieldname="processingCarTypeOfLowUuid"
                  options={writeOffReasons}
                />
                <Flex flexDirection="column">
                  <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
                    Data da baixa
                  </HLargexs>
                  <DatePicker fieldname="dateOfLow" />
                </Flex>
              </Flex>
              <TLargemd mb="15px">Descreva o motivo da baixa manual</TLargemd>
              <Textarea
                {...methods.register("reasonForManualLow")}
                placeholder="Insira aqui o motivo"
                size="sm"
              />
              <TLargemd my="15px">
                Ids para baixa{" "}
                <Tooltip
                  label="Insira um id embaixo do outro. Não utilize vírgulas, apenas quebra de linha."
                  fontSize="md"
                >
                  <InfoOutlineIcon />
                </Tooltip>
              </TLargemd>
              <Textarea
                {...methods.register("tariffCarIds")}
                placeholder="insira aqui os ids."
                size="sm"
              />
              <FormLabel htmlFor="ignoreFineAndInterest" my="15px">
                Ignorar multa e juros?
              </FormLabel>
              <BooleanRadioGroup
                defaultCustomValue={false}
                fieldname="ignoreFineAndInterest"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
            </form>
            {isLoading && <Backdrop />}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <ConfirmationPopover
              disabled={
                !(
                  tariffCarIds &&
                  processingCarTypeOfLowUuid &&
                  dateOfLow &&
                  reasonForManualLow
                )
              }
              triggerButtonText="Enviar"
              onPrimaryButtonClick={onSubmit}
              title="Confirmação"
              text="Tem certeza que deseja enviar os dados para baixa manual?"
              primaryButtonText="Sim"
              secondaryButtonText="Não"
            />
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};
