import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { useState } from "react";
import { TSmallmd, TSmallsm } from "../../../../main/components/Tipography";
import { useAnchorDownload } from "../../../../main/hooks/useAnchorDownload";
import { IBlobError } from "../../../../main/hooks/useDownloadFile";
import { handleError } from "../../Invoice/components/invoicePopover/functions/handleError";

type PopoverBoletoProps = {
  info?: CellContext<any, any>;
};
export const BoletoPopover = ({ info }: PopoverBoletoProps) => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);

  const boleto = info?.row.original;
  const invoice = boleto.charge?.tariffCars?.[0]?.tariff?.invoice;
  const invoiceId = invoice?.id;
  const invoiceBlobName = invoice?.blobName;
  const ourNumber = boleto.nossoNumero;

  const preDownloading = () => {
    setIsLoading(true);
    onClose();
  };
  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage = await handleError(error);

    toast({
      title: "Serviço indisponível.",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const { viewInNewTab } = useAnchorDownload({
    apiDefinition: {
      endpoint: `/invoicepdf/reducedInvoice/${invoiceId}`,
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Fatura-${invoiceId}-Resumida`,
    viewUrlRequest: `/viewinvoicepdf/reducedInvoice/${invoiceId}`,
  });

  const { viewInNewTab: viewBoletoInNewTab } = useAnchorDownload({
    apiDefinition: {
      endpoint: "",
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Boleto-${ourNumber}`,
    viewUrlRequest: `/boletos/view/${ourNumber}`,
  });

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
              disabled={isLoading}
              _disabled={{ bg: "gray.200" }}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "..."
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody>
                <Flex flexDir="column">
                  <TSmallsm color="#4a5568" fontWeight="bold">
                    VISUALIZAR
                  </TSmallsm>
                  <Flex
                    mt="2"
                    flexDir="row"
                    justifyContent="flex-start"
                    gap="2"
                    alignItems="center"
                    color="#718096"
                    as={Button}
                    variant="unstyled"
                    padding="0"
                    onClick={viewBoletoInNewTab}
                    disabled={!boleto.blobName}
                  >
                    <ExternalLinkIcon />
                    <TSmallmd fontWeight="medium">Boleto (.pdf)</TSmallmd>
                  </Flex>
                  {invoiceId && (
                    <Flex
                      mt="2"
                      flexDir="row"
                      justifyContent="flex-start"
                      gap="2"
                      alignItems="center"
                      color="#718096"
                      as={Button}
                      variant="unstyled"
                      padding="0"
                      onClick={viewInNewTab}
                      disabled={!invoiceBlobName}
                    >
                      <ExternalLinkIcon />
                      <TSmallmd fontWeight="medium">Fatura (.pdf)</TSmallmd>
                    </Flex>
                  )}
                  <Divider my="5px" />
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
