/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  AlertIcon,
  Button,
  Flex,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GridLoader } from "react-spinners";

import { Backdrop } from "../../../../main/components/Backdrop";
import { DataTable } from "../../../../main/components/DataTable";
import { DueDateCell } from "../../../../main/components/DataTable/DueDateCell";
import { DatePickerFilter } from "../../../../main/components/DataTable/Filters";
import { HLarge2xl, TSmallmd } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IInvoiceDataList } from "../../../../types/main/invoice";
import {
  IColumnDataTable,
  ITableColumns,
} from "../../../../types/main/reports";
import { ConfirmationModal } from "../components/Simulation/ConfirmationModal";
import { DivergentDueDate } from "../components/Simulation/DivergentDueDate";
import {
  getColumnInterestAndFineSumAsCurrency,
  getColumnValuesSumAsCurrency,
} from "../functions/cells";
import { useSimulation } from "../hooks/useSimulation";

export const InvoicingSimulation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isExporting, setIsExporting] = useState(false);

  const {
    invoicesSimulated,
    isLoading,
    saveInvoices,
    loadTariffsFromFilters,
    isMountingTable,
    exportSimulation,
  } = useSimulation();

  const columns: IColumnDataTable[] = useMemo(
    () => [
      {
        Header: " ",
        columns: [
          {
            Header: "Cliente",
            accessor: "clientName",
          },
          {
            Header: "Categoria de Produto",
            accessor: "productCategoryName",
          },
          {
            Header: "Produto",
            accessor: "productsNameList",
          },
          {
            Header: "Vencimento",
            accessor: "dueDate",
            Filter: DatePickerFilter,
            filter: "date",
            Cell: (cell: {
              row: { original: IInvoiceDataList };
              value: string | null;
            }) => {
              const { row, value } = cell;
              const warning = row.original.hasDifferentDueDates
                ? "divergent"
                : row.original.hasMissingDueDate
                ? "missing"
                : undefined;

              return <DueDateCell dueDate={value} warning={warning} />;
            },
          },
          {
            Header: "Projeto",
            accessor: "projectId",
          },
          {
            Header: "Contrato",
            accessor: "contract",
            Cell: (cell: any) => {
              const { value } = cell;
              const total = value?.length ?? 0;
              if (total > 1) return `${total} Contratos`;
              return value;
            },
          },
          {
            Header: "Canais",
            accessor: "channel",
            Cell: (cell: any) => {
              const { value } = cell;
              const total = value?.length ?? 0;
              if (total > 1) return `${total} Canais`;
              return value;
            },
          },
          {
            Header: "Prestador",
            accessor: "provider",
          },
        ] as ITableColumns[],
      },
      {
        Header: " ",
        hasFooter: true,
        sticky: "right",
        absoluteFooterLabel: "Valor total",
        columns: [
          {
            Header: "Valor da Fatura",
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "invoicesValue"),
            accessor: "invoicesValue",
            filter: "currency",
            Cell: (cell: any) =>
              Number(cell.value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            Header: "Juros e Multa",
            Footer: (values: any) =>
              getColumnInterestAndFineSumAsCurrency(values.rows),
            accessor: "invoicesInterest",
            filter: "currency",
            Cell: (cell: any) => {
              const invoiceFine = cell.row.original?.invoicesFine ?? 0;
              const invoiceInterest = cell.value;
              const interestAndFine =
                Number(invoiceFine) + Number(invoiceInterest);

              return interestAndFine.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          {
            Header: "Desconto",
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "invoicesDiscount"),
            accessor: "invoicesDiscount",
            filter: "currency",
            Cell: (cell: any) =>
              Number(cell.value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
          },
          {
            Header: "Valor Total",
            Footer: (values: any) =>
              getColumnValuesSumAsCurrency(values.rows, "invoicesTotalValue"),

            accessor: "invoicesTotalValue",
            filter: "currency",
            Cell: (cell: any) =>
              Number(cell.value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              }),
          },
        ] as ITableColumns[],
      },
    ],
    []
  );

  return (
    <Wrapper>
      <HLarge2xl mb="32px" color="gray.600">
        Simulação de Faturamento
      </HLarge2xl>

      {invoicesSimulated?.hasDivergentDueDatesAndShouldBlockSaving && (
        <DivergentDueDate
          invoicesSimulated={invoicesSimulated}
          isLoading={isLoading}
        />
      )}
      {isMountingTable && (
        <Alert status="info" my="2rem">
          <AlertIcon />
          <TSmallmd mr={4}>
            Dados obtidos, estamos montando a tabela para você.
          </TSmallmd>
          <GridLoader color="#2c58a4" size={6} />
        </Alert>
      )}
      <DataTable
        module="Invoices"
        isLoading={isLoading}
        columns={columns}
        data={invoicesSimulated?.invoicesGroup ?? []}
        showCheckbox={false}
        refreshTable={loadTariffsFromFilters}
        exportFunction={() => {
          exportSimulation(setIsExporting);
        }}
      />

      <Flex justifyContent="flex-end" mb="2rem" gap="1rem">
        <Link to="/invoicing">
          <Button variant="ghost">Voltar aos Filtros</Button>
        </Link>
        <Tooltip
          label="Resolva as divergências antes de Gravar."
          bg="red.500"
          isDisabled={
            !invoicesSimulated?.hasDivergentDueDatesAndShouldBlockSaving
          }
        >
          <span>
            <Button
              width="200px"
              onClick={onOpen}
              disabled={
                invoicesSimulated?.hasDivergentDueDatesAndShouldBlockSaving ||
                isLoading
              }
            >
              Gravar
            </Button>
          </span>
        </Tooltip>
        <ConfirmationModal
          isOpen={isOpen}
          onClose={onClose}
          saveInvoices={saveInvoices}
        />
      </Flex>
      {isExporting && <Backdrop />}
    </Wrapper>
  );
};
