import {
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
  Select,
} from "@chakra-ui/react";
import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { matchSorter } from "match-sorter";
import React, { useCallback, useEffect, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { NumberFormatProps } from "react-number-format";
import { useLocation } from "react-router-dom";
import { useAsyncDebounce } from "react-table";
import { v4 as uuid } from "uuid";

import { ClientFormTextPerType } from "../../../modules/Records/Clients/constants/ClientType.constant";
import { DatePicker } from "../DateTimePicker/DatePicker";
import { InputMask } from "../InputMask";
import {
  IMultiSelectOptions,
  useMultiCheckboxSelect,
} from "../MultiSelect/useMultiSelect";
import { useTableContext } from "./context/useTableContext";

// Define a default UI for filtering
export function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}: any) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span>
      Search:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} records...`}
        style={{
          fontSize: "1.1rem",
          border: "0",
        }}
      />
    </span>
  );
}

// Define a default UI for filtering
export function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}: any) {
  const count = preFilteredRows.length;

  return (
    <Input
      w="calc(100% - 10px)"
      fontWeight="medium"
      fontSize="sm"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

export function DocumentColumnFilter({
  column: { filterValue, setFilter },
}: any) {
  const countFilterValue = filterValue?.length;
  const formMethods = useForm<{ document: string }>();

  const handleChange = ({ value }: NumberFormatProps) => {
    setFilter(value);
  };

  const formatMask = useMemo(() => {
    if (countFilterValue && countFilterValue <= 11)
      return `${ClientFormTextPerType.PF.documentMask}#`;

    return ClientFormTextPerType.PJ.documentMask;
  }, [countFilterValue]);

  return (
    <FormProvider {...formMethods}>
      <InputMask
        fieldname="document"
        format={formatMask}
        mask=""
        width="calc(100% - 10px)"
        fontSize="sm"
        onInputChange={handleChange}
      />
    </FormProvider>
  );
}

// This is a custom filter UI for selecting
// a unique option from a list
export function SelectColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = React.useMemo<any[]>(() => {
    const options = new Set();
    preFilteredRows.forEach((row: any) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <Select
      value={filterValue}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
    >
      <option value="">Todos</option>
      {options.map((option) => (
        <option key={option ?? uuid()} value={option}>
          {option}
        </option>
      ))}
    </Select>
  );
}

// This is a custom filter UI that uses a
// slider to set the filter value between a column's
// min and max values
export function SliderColumnFilter({
  column: { filterValue, setFilter, preFilteredRows, id },
}: any) {
  // Calculate the min and max
  // using the preFilteredRows

  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <>
      <input
        type="range"
        min={min}
        max={max}
        value={filterValue || min}
        onChange={(e) => {
          setFilter(parseInt(e.target.value, 10));
        }}
      />
      <button type="button" onClick={() => setFilter(undefined)}>
        Off
      </button>
    </>
  );
}

// This is a custom UI for our 'between' or number range
// filter. It uses two number boxes and filters rows to
// ones that have values between the two
export function NumberRangeColumnFilter({
  column: { filterValue = [], preFilteredRows, setFilter, id },
}: any) {
  const [min, max] = React.useMemo(() => {
    let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
    preFilteredRows.forEach((row: any) => {
      min = Math.min(row.values[id], min);
      max = Math.max(row.values[id], max);
    });
    return [min, max];
  }, [id, preFilteredRows]);

  return (
    <div
      style={{
        display: "flex",
      }}
    >
      <input
        value={filterValue[0] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            val ? parseInt(val, 10) : undefined,
            old[1],
          ]);
        }}
        placeholder={`Min (${min})`}
        style={{
          width: "70px",
          marginRight: "0.5rem",
        }}
      />
      to
      <input
        value={filterValue[1] || ""}
        type="number"
        onChange={(e) => {
          const val = e.target.value;
          setFilter((old = []) => [
            old[0],
            val ? parseInt(val, 10) : undefined,
          ]);
        }}
        placeholder={`Max (${max})`}
        style={{
          width: "70px",
          marginLeft: "0.5rem",
        }}
      />
    </div>
  );
}
export const MultiSelectFilter =
  ({ isAbleToSelectAll = false }: { isAbleToSelectAll?: boolean }) =>
  ({ column: { preFilteredRows, setFilter, id } }: any) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const options = useMemo<any[]>(() => {
      const options = new Set();

      preFilteredRows.forEach((row: any) => {
        if (Array.isArray(row.values[id])) {
          row.values[id].forEach((option: any) => {
            options.add(option);
          });
        } else options.add(row.values[id]);
      });
      return [...options.values()];
    }, [id, preFilteredRows]);

    const onCheckboxChange = (options: IMultiSelectOptions[]) => {
      const newFilters = options
        .filter((option) => option.checked)
        .map((option) => option.value);

      setFilter([...newFilters]);
    };

    const { MultiSelectCheckbox, placeholder } =
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useMultiCheckboxSelect({
        options: options.map((option: string) => ({
          label: option || "(vazio)",
          value: option ?? "",
          checked: true,
        })),
        isAbleToSelectAll,
        onCustomChange: onCheckboxChange,
      });
    return (
      <Menu strategy="fixed" placement="left-start">
        <MenuButton borderRadius="50" variant="solid" as={InputGroup}>
          <Input
            bg="white"
            _placeholder={{ color: "gray.800" }}
            _disabled={{ color: "gray.800" }}
            isDisabled
            placeholder={placeholder}
          />
        </MenuButton>
        <MenuList
          zIndex="99"
          paddingInline="20px"
          paddingBottom="20px"
          fontWeight="normal"
        >
          <MultiSelectCheckbox />
        </MenuList>
      </Menu>
    );
  };
export function DatePickerFilter({ column: { setFilter } }: any) {
  const formMethods = useForm<{ date: Date }>();
  const location = useLocation();
  const { open, close } = useTableContext();

  const onChange = useCallback((date: Date | undefined) => {
    setFilter(
      date
        ? format(date, "dd/MM/yyyy", {
            locale: ptBR,
          })
        : undefined
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onChange(formMethods.getValues("date"));
  }, [formMethods, location.key, onChange]);

  return (
    <FormProvider {...formMethods}>
      <form>
        <DatePicker
          fieldname="date"
          placement="auto"
          onDateChange={onChange}
          onOpen={open}
          onClose={close}
        />
      </form>
    </FormProvider>
  );
}

export function fuzzyTextFilterFn(rows: any, id: any, filterValue: any) {
  return matchSorter(rows, filterValue, {
    keys: [(row: any) => row.values[id]],
  });
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val: any) => !val;

// Define a custom filter filter function!
export function filterGreaterThan(rows: any, id: any, filterValue: any) {
  return rows.filter((row: any) => {
    const rowValue = row.values[id];
    return rowValue >= filterValue;
  });
}

// This is an autoRemove method on the filter function that
// when given the new filter value and returns true, the filter
// will be automatically removed. Normally this is just an undefined
// check, but here, we want to remove the filter if it's not a number
filterGreaterThan.autoRemove = (val: any) => typeof val !== "number";
