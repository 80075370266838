import { Routes, Route } from "react-router-dom";

import { ReportList } from "./pages/ReportList";

export function ReportsModule() {
  return (
    <Routes>
      <Route path="/reports" element={<ReportList />} />
    </Routes>
  );
}
