import { useEffect, useState } from "react";

import { IChannelERPConfig } from "../../../../../types/main/channelERPConfig";
import { IClientOptions } from "../../../../../types/main/client";
import { getClientOptions } from "../../../Clients/service/ClientService";
import { getChannelERPConfig } from "../../services/ChannelERPService";

interface IUseFetchChannelERP {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  channelId: string | undefined;
}

export const useFetchChannelERP = ({
  setIsLoading,
  channelId,
}: IUseFetchChannelERP) => {
  const [clientList, setClientList] = useState<IClientOptions[]>([]);
  const [existingERPConfig, setExistingERPConfig] = useState<
    IChannelERPConfig | undefined
  >();

  const [hasFetchError, setHasFetchError] = useState(false);

  const loadClients = async ({
    idListOnScreenClients,
  }: {
    idListOnScreenClients?: string | null;
  }) => {
    const clients = await getClientOptions(
      undefined,
      idListOnScreenClients ?? undefined
    );
    return clients;
  };

  const loadExistingERPConfig = async (channelId: string) => {
    const erpConfig = await getChannelERPConfig(channelId);
    return erpConfig.body ?? undefined;
  };

  useEffect(() => {
    let isCancelled = false;

    const loadDataLists = async () => {
      if (!channelId) return;
      try {
        setIsLoading(true);
        const erpConfig = await loadExistingERPConfig(channelId);
        const clients = await loadClients({
          idListOnScreenClients: erpConfig?.holderUuid,
        });

        if (!isCancelled) {
          setClientList(clients ?? []);
          setExistingERPConfig(erpConfig);
          setIsLoading(false);
        }
      } catch (error) {
        if (!isCancelled) setHasFetchError(true);
      }
    };

    loadDataLists();

    return () => {
      isCancelled = true;
    };
  }, [channelId, setIsLoading]);

  return {
    clientList,
    existingERPConfig,
    hasFetchError,
  };
};
