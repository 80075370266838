import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_PAYMENT_METHODS_PERMISSION_LEVEL,
  GET_PAYMENT_METHODS_PERMISSION_LEVEL,
  UPDATE_PAYMENT_METHODS_PERMISSION_LEVEL,
} from "./constants/permissions";
import { PaymentMethodList } from "./pages/PaymentMethodList";
import { PaymentMethodManagement } from "./pages/PaymentMethodManagement";
// import { ProductManagement } from "./pages/ProductManagement";

export function PaymentMethodModule() {
  return (
    <Routes>
      <Route
        element={
          <PrivateRoute permission={GET_PAYMENT_METHODS_PERMISSION_LEVEL} />
        }
      >
        <Route path="/paymentmethodslist" element={<PaymentMethodList />} />
        <Route
          path="/viewpaymentmethods/:uuid"
          element={<PaymentMethodManagement isReadOnly />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute permission={CREATE_PAYMENT_METHODS_PERMISSION_LEVEL} />
        }
      >
        <Route
          path="/managepaymentmethods"
          element={<PaymentMethodManagement />}
        />
      </Route>
      <Route
        element={
          <PrivateRoute permission={UPDATE_PAYMENT_METHODS_PERMISSION_LEVEL} />
        }
      >
        <Route
          path="/managepaymentmethods/:uuid"
          element={<PaymentMethodManagement />}
        />
      </Route>
    </Routes>
  );
}
