import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IUser } from "../../../../types/main/user";
import { GetUserByUUID } from "../service/UserService";

export const useExistingUser = (uuid: string | undefined) => {
  const toast = useToast();

  const [existingUserData, setExistingUserData] = useState<IUser | undefined>();

  const getExistingUser = useCallback(
    async (uuid: string) => {
      try {
        const response = await GetUserByUUID(uuid);
        setExistingUserData(response.body ?? undefined);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (uuid) {
      getExistingUser(uuid);
    }
  }, [getExistingUser, uuid]);

  return { existingUserData };
};
