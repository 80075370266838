import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DEFAULT_REGEX_ESPECIAL_CHARACTERS } from "../../../../../../main/common/constants/defaultValidations";
import { Backdrop } from "../../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputSelect } from "../../../../../../main/components/InputSelect";
import { SimpleMultiSelect } from "../../../../../../main/components/MultiSelect/SimpleMultiSelect";
import { NumberFilterInput } from "../../../../../../main/components/NumberFilterInput";
import { IPaymentBanks } from "../../../../../../types/main/paymentBanks";
import { IPaymentLayout } from "../../../../../../types/main/paymentLayout";
import {
  IPaymentMethods,
  IPaymentMethodsData,
} from "../../../../../../types/main/paymentMethods";
import { IPaymentTransmissionChannel } from "../../../../../../types/main/paymentTransmissionChannel";
import { IPaymentType } from "../../../../../../types/main/paymentType";
import { ITemplates } from "../../../../../../types/main/templates";
import { PaymentMethodTypesEnum } from "../../../enums/paymentMethodTypes.enum";
import { automaticDebitSchema } from "../../../validations/automaticDebitSchema";
import { ButtonSubmit } from "../ButtonSubmit";

interface IProps {
  isReadOnly?: boolean;
  paymentMethodUUID?: string;
  onSubmitToCreatePaymentMethod: (data: IPaymentMethodsData) => Promise<void>;
  onSubmitToUpdatePaymentMethod: (data: IPaymentMethodsData) => Promise<void>;
  allTypes: Array<IPaymentType>;
  existingPaymentMethod?: IPaymentMethods;
  allBanks?: Array<IPaymentBanks>;
  allLayout?: Array<IPaymentLayout>;
  allTemplates?: Array<ITemplates>;
  allTransmissionChannel?: Array<IPaymentTransmissionChannel>;
}

interface IFormData
  extends Omit<IPaymentMethodsData, "paymentTransmissionChannel"> {
  paymentTransmissionChannel: string[][];
}

export const AutomaticDebit = ({
  paymentMethodUUID,
  isReadOnly,
  onSubmitToCreatePaymentMethod,
  onSubmitToUpdatePaymentMethod,
  allTypes,
  existingPaymentMethod,
  allBanks,
  allLayout,
  allTemplates,
  allTransmissionChannel,
}: IProps) => {
  const methods = useForm<IFormData>({
    resolver: yupResolver(automaticDebitSchema),
  });

  const [emailSelected, setEmailSelected] = useState<boolean>(false);

  const watchPaymentTransmissionChannel = methods.watch(
    "paymentTransmissionChannel"
  );

  useEffect(() => {
    if (
      existingPaymentMethod &&
      allBanks &&
      allLayout &&
      allTemplates &&
      allTransmissionChannel
    ) {
      methods.setValue(
        "paymentLayoutUuid",
        existingPaymentMethod.paymentLayoutUuid
      );
      methods.setValue("name", existingPaymentMethod.name);
      methods.setValue("description", existingPaymentMethod.description);
      methods.setValue("optingRequires", existingPaymentMethod.optingRequires);
      methods.setValue(
        "paymentBankUuid",
        existingPaymentMethod.paymentBankUuid
      );
      methods.setValue("nsa", existingPaymentMethod.nsa);
      methods.setValue("agreementCode", existingPaymentMethod.agreementCode);
      methods.setValue("paymentTransmissionChannel", [
        [
          ...existingPaymentMethod.paymentTransmissionChannel.map(
            ({ uuid }) => uuid
          ),
        ],
        [
          ...existingPaymentMethod.paymentTransmissionChannel.map(
            ({ name }) => name
          ),
        ],
      ]);
      methods.setValue("templateUuid", existingPaymentMethod.templateUuid);
      methods.setValue(
        "internalAccount",
        existingPaymentMethod.internalAccount
      );
    }
    if (allTypes.length > 0) {
      methods.setValue(
        "paymentTypeUuid",
        allTypes.filter(({ name }) => name === "AUTOMATIC_DEBIT")[0].uuid
      );
    }
  }, [
    allBanks,
    allLayout,
    allTemplates,
    allTransmissionChannel,
    existingPaymentMethod,
    methods,
    allTypes,
  ]);

  useEffect(() => {
    const paymentTransmissionChannelContainEmail =
      watchPaymentTransmissionChannel &&
      watchPaymentTransmissionChannel[1]?.filter((item) => item === "E-mail")
        .length === 1;
    setEmailSelected(paymentTransmissionChannelContainEmail);
    if (
      !paymentTransmissionChannelContainEmail &&
      watchPaymentTransmissionChannel?.length > 0
    ) {
      methods.setValue("templateUuid", "");
    }
  }, [methods, watchPaymentTransmissionChannel, paymentMethodUUID]);

  if (
    (!existingPaymentMethod && paymentMethodUUID) ||
    !allBanks ||
    !allLayout ||
    !allTemplates ||
    !allTransmissionChannel
  )
    return <Backdrop />;
  return (
    <FormProvider {...methods}>
      <Flex
        mt="32px"
        as="form"
        onSubmit={methods.handleSubmit(
          paymentMethodUUID
            ? (data) => {
                const [firstArray] = data.paymentTransmissionChannel;
                onSubmitToUpdatePaymentMethod({
                  ...data,
                  paymentTransmissionChannel: firstArray,
                });
              }
            : (data) => {
                const [firstArray] = data.paymentTransmissionChannel;
                onSubmitToCreatePaymentMethod({
                  ...data,
                  paymentTransmissionChannel: firstArray,
                });
              }
        )}
        h="100%"
        flexDirection="column"
        justifyContent="space-between"
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Grid templateRows="(4, 1fr)" templateColumns="repeat(12, 1fr)" gap={8}>
          <GridItem colSpan={4}>
            <FormControl
              isInvalid={!!methods.formState.errors.paymentLayoutUuid}
            >
              <FormLabel htmlFor="paymentLayoutUuid">Layout</FormLabel>

              <InputSelect
                disabled={isReadOnly}
                name="paymentLayoutUuid"
                placeholder="Layout"
                isClearable
                option={
                  allLayout
                    ?.filter(
                      ({ paymentType }) =>
                        paymentType.name ===
                        PaymentMethodTypesEnum.AUTOMATIC_DEBIT
                    )
                    .map(({ uuid: value, name: label }) => {
                      return { label, value };
                    }) ?? []
                }
              />

              <FormErrorMessage>
                {methods.formState.errors.paymentLayoutUuid &&
                  methods.formState.errors.paymentLayoutUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.description}>
              <FormLabel htmlFor="description">
                Nome do método de pagamento
              </FormLabel>
              <Input
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "not-allowed",
                  color: "inherit",
                  opacity: "inherit",
                }}
                id="description"
                placeholder="Texto"
                {...methods.register("description", {
                  onChange: (event: any) => {
                    methods.setValue(
                      "description",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS,
                        ""
                      )
                    );
                    methods.trigger();
                  },
                })}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.description &&
                  methods.formState.errors.description.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.agreementCode}>
              <FormLabel htmlFor="agreementCode">Código de convênio</FormLabel>
              <Input
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "not-allowed",
                  color: "inherit",
                  opacity: "inherit",
                }}
                maxLength={20}
                id="agreementCode"
                placeholder="Texto"
                {...methods.register("agreementCode", {
                  onChange: (event: any) => {
                    methods.setValue(
                      "agreementCode",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS,
                        ""
                      )
                    );
                    methods.trigger();
                  },
                })}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.agreementCode &&
                  methods.formState.errors.agreementCode.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.name}>
              <FormLabel htmlFor="name">Nome da empresa</FormLabel>
              <Input
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "not-allowed",
                  color: "inherit",
                  opacity: "inherit",
                }}
                id="name"
                placeholder="Texto"
                {...methods.register("name", {
                  onChange: (event: any) => {
                    methods.setValue(
                      "name",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS,
                        ""
                      )
                    );
                    methods.trigger();
                  },
                })}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.name &&
                  methods.formState.errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.optingRequires}>
              <FormLabel htmlFor="optingRequires">
                Requer cadastro de optante
              </FormLabel>
              <BooleanRadioGroup
                fieldname="optingRequires"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {methods.formState.errors.optingRequires &&
                  methods.formState.errors.optingRequires.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.nsa}>
              <FormLabel htmlFor="nsa">Próximo NSA</FormLabel>
              <NumberFilterInput
                fieldname="nsa"
                disabled={isReadOnly}
                type="number"
                maxLength={15}
              />

              <FormErrorMessage>
                {methods.formState.errors.nsa &&
                  methods.formState.errors.nsa.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.paymentBankUuid}>
              <FormLabel htmlFor="paymentBankUuid">Banco</FormLabel>
              <InputSelect
                disabled={isReadOnly}
                name="paymentBankUuid"
                option={
                  allBanks?.map(({ uuid, name, bankCode }) => {
                    return { label: `${bankCode} - ${name}`, value: uuid };
                  }) ?? []
                }
              />
              <FormErrorMessage>
                {methods.formState.errors.paymentBankUuid &&
                  methods.formState.errors.paymentBankUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isInvalid={!!methods.formState.errors.internalAccount}>
              <FormLabel htmlFor="internalAccount">Conta Interna</FormLabel>
              <Input
                isDisabled={isReadOnly}
                _disabled={{
                  cursor: "not-allowed",
                  color: "inherit",
                  opacity: "inherit",
                }}
                id="internalAccount"
                placeholder="Texto"
                {...methods.register("internalAccount")}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.internalAccount &&
                  methods.formState.errors.internalAccount.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4}>
            <FormControl
              isInvalid={!!methods.formState.errors.paymentTransmissionChannel}
            >
              <FormLabel htmlFor="paymentTransmissionChannel">
                Canal de transmissão
              </FormLabel>
              <SimpleMultiSelect
                buttonLabel="Selecionar"
                defaultOptions={
                  allTransmissionChannel
                    ? allTransmissionChannel
                        .filter(
                          ({ paymentType }) =>
                            paymentType.name === "AUTOMATIC_DEBIT"
                        )
                        .map(({ uuid, name }) => {
                          return {
                            label: name,
                            uuid,
                            checked: existingPaymentMethod
                              ? existingPaymentMethod.paymentTransmissionChannel.some(
                                  ({ uuid: search }) => search === uuid
                                )
                              : false,
                          };
                        })
                    : []
                }
                fieldName="paymentTransmissionChannel"
                setPlaceHolderWith="label"
                isDisabled={isReadOnly}
              />
              <FormErrorMessage>
                {methods.formState.errors.paymentTransmissionChannel &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={4} hidden={!emailSelected}>
            <FormControl isInvalid={!!methods.formState.errors.templateUuid}>
              <FormLabel htmlFor="templateUuid">Template de e-mail</FormLabel>
              <InputSelect
                disabled={isReadOnly || !emailSelected}
                name="templateUuid"
                placeholder="Template"
                isClearable
                option={
                  allTemplates.map(({ uuid, name, deletedAt }) => {
                    return {
                      label: deletedAt ? `(inativo) ${name}` : name,
                      value: uuid,
                      deletedAt,
                    };
                  }) ?? []
                }
              />

              <FormErrorMessage>
                {methods.formState.errors.templateUuid &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={12}>
            <ButtonSubmit
              paymentMethodUUID={paymentMethodUUID}
              isDisabled={isReadOnly}
              isSubmitting={methods.formState.isSubmitting}
            />
          </GridItem>
        </Grid>
      </Flex>
    </FormProvider>
  );
};
