import { Flex, Image, Input } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";

import { IconPaint } from "../../common/assets";
import { TSmallsm } from "../Tipography";

interface IInputColor {
  fieldname: string;
  isReadOnly?: boolean;
}

export const InputColor = ({ fieldname, isReadOnly }: IInputColor) => {
  const { setValue, clearErrors, watch } = useFormContext();
  const input = useRef<HTMLInputElement>(null);

  const [hexColor, setHexColor] = useState("Hexa");

  const handleChange = (event: any) => {
    clearErrors(fieldname);
    setHexColor(event.target.value);
    setValue(fieldname, event.target.value);
  };

  const watchFieldname = watch(fieldname);

  useEffect(() => {
    if (watchFieldname) {
      setHexColor(watchFieldname);
      if (input.current) input.current.value = watchFieldname;
    }
  }, [watchFieldname]);

  return (
    <Flex
      height="2.5rem"
      justifyContent="space-around"
      alignItems="center"
      border="1px solid"
      borderRadius="0.375rem"
      borderColor="inherit"
      overflow="hidden"
      paddingInline="5%"
    >
      <Flex width="100%" alignItems="center">
        <Input
          ref={input}
          disabled={isReadOnly}
          border="inherit"
          padding={0}
          marginInlineEnd="10px"
          maxWidth="min(50%, 100px)"
          maxHeight="25px"
          type="color"
          _disabled={{
            cursor: "not-allowed",
            color: "inherit",
            opacity: "inherit",
          }}
          _focus={{
            outline: "none",
          }}
          onChange={handleChange}
        />
        <TSmallsm>{hexColor.toUpperCase()}</TSmallsm>
      </Flex>

      <Image
        src={IconPaint}
        cursor={isReadOnly ? "default" : "pointer"}
        onClick={() => input.current?.click()}
        transition="all 0.2s"
        _hover={{
          filter: "saturate(8)",
        }}
      />
    </Flex>
  );
};
