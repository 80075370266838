import {
  ArrowDownIcon,
  ArrowRightIcon,
  ExternalLinkIcon,
  ViewIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { CellContext, Table } from "@tanstack/react-table";
import { useState } from "react";
import { Link } from "react-router-dom";
import { IconEdit, IconExport } from "../../../../../main/common/assets";
import { customToast } from "../../../../../main/common/utils/customToast";
import { NFeModal } from "../../../../../main/components/NFeModal";
import {
  HLargexs,
  HSmallmd,
  TSmallmd,
  TSmallsm,
} from "../../../../../main/components/Tipography";
import { useAnchorDownload } from "../../../../../main/hooks/useAnchorDownload";
import { IBlobError } from "../../../../../main/hooks/useDownloadFile";
import { CarManually } from "../../../shared/Components/Tariff/CarManually";
import { TariffMovementDetailsModal } from "../../../TariffMovements/components/TariffMovementDetailsModal";
import { useExportTariff } from "../../hooks/useExportInvoice";
import { ITariffList } from "../../pages/TariffList";
import { CancelCar } from "../TariffDetails/Car/CardCar/CancelCar";
import { handleError } from "./functions/handleError";
import { GoToProcessingRemittance } from "./GoToProcessingRemittance";

type PopoverContractProps = {
  info?: CellContext<ITariffList, any>;
  table: Table<ITariffList>;
  refetch: (data?: any) => Promise<void>;
  onLoading: (loading: boolean) => void;
};

export const PopoverTariff = ({
  info,
  table,
  refetch,
  onLoading,
}: PopoverContractProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const tariff = info?.row.original;

  const [isLoading, setIsLoading] = useState(false);

  const { exportAll, exportFiltered } = useExportTariff(table, onLoading);

  const preDownloading = () => {
    setIsLoading(true);
    onClose();
  };
  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage = await handleError(error);
    customToast("error", errorMessage);

    setIsLoading(false);
  };

  const {
    downloadFile: downloadInvoice,
    ref: refInvoice,
    url: urlInvoice,
    name: nameInvoice,
    viewInNewTab: viewInvoiceInNewTab,
  } = useAnchorDownload({
    apiDefinition: {
      endpoint: `/invoicepdf/invoiceDetailedTariff/${tariff?.invoiceId}/${tariff?.tariffId}`,
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Tarifa-${tariff?.tariffId}-Detalhada`,
    viewUrlRequest: `/viewinvoicepdf/invoiceDetailedTariff/${tariff?.invoiceId}/${tariff?.tariffId}`,
  });

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
              disabled={isLoading}
              _disabled={{ bg: "gray.200" }}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "..."
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody>
                {!tariff && (
                  <Flex flexDir="column">
                    <HLargexs pl="24px" pt="12px" mb="6px">
                      EXPORTAR CSV
                    </HLargexs>
                    <Flex
                      pl="25.33px"
                      py="8px"
                      as="button"
                      alignItems="center"
                      _hover={{ bg: "gray.50" }}
                      onClick={exportAll}
                      width="100%"
                    >
                      <IconExport />
                      <HSmallmd ml="8.46px" color="gray.500">
                        Tabela inteira
                      </HSmallmd>
                    </Flex>
                    <Flex
                      pl="25.33px"
                      py="8px"
                      as="button"
                      alignItems="center"
                      _hover={{ bg: "gray.50" }}
                      onClick={exportFiltered}
                      width="100%"
                    >
                      <IconExport />
                      <HSmallmd ml="8.46px" color="gray.500">
                        Tabela filtrada
                      </HSmallmd>
                    </Flex>
                  </Flex>
                )}
                {!!tariff && (
                  <Flex flexDir="column">
                    <>
                      <TSmallsm color="#4a5568" fontWeight="bold">
                        FAZER DOWNLOAD
                      </TSmallsm>
                      <a
                        href={urlInvoice}
                        download={nameInvoice}
                        hidden
                        ref={refInvoice}
                      />

                      <Flex
                        mt="2"
                        flexDir="row"
                        justifyContent="flex-start"
                        gap="2"
                        alignItems="center"
                        as={Button}
                        variant="unstyled"
                        color="gray.500"
                        _hover={{ color: "#444" }}
                        transition="all 0.2s ease-in-out"
                        padding="0"
                        py="6px"
                        onClick={downloadInvoice}
                        hidden={!tariff.invoiceId}
                      >
                        <ArrowDownIcon />
                        <TSmallmd fontWeight="medium">
                          Fatura Detalhada (.pdf)
                        </TSmallmd>
                      </Flex>
                      {tariff.invoiceId && (
                        <NFeModal invoiceId={tariff.invoiceId} />
                      )}

                      <Divider my="5px" />
                      <TSmallsm color="#4a5568" fontWeight="bold">
                        VISUALIZAR
                      </TSmallsm>

                      <Flex
                        mt="2"
                        flexDir="row"
                        justifyContent="flex-start"
                        gap="2"
                        alignItems="center"
                        as={Button}
                        variant="unstyled"
                        color="gray.500"
                        _hover={{ color: "#444" }}
                        transition="all 0.2s ease-in-out"
                        padding="0"
                        py="6px"
                        onClick={viewInvoiceInNewTab}
                        hidden={!tariff.invoiceId}
                      >
                        <ExternalLinkIcon />
                        <TSmallmd fontWeight="medium">
                          Fatura Detalhada (.pdf)
                        </TSmallmd>
                      </Flex>
                      <Divider my="5px" />

                      <TariffMovementDetailsModal
                        tariffId={Number(tariff.tariffId)}
                        setIsLoading={onLoading}
                        onCloseMenu={onClose}
                      />

                      <Flex
                        py="8px"
                        as={Link}
                        alignItems="center"
                        color="gray.500"
                        _hover={{ color: "#444" }}
                        transition="all 0.2s ease-in-out"
                        to={`/viewtariff/${tariff.tariffId}`}
                      >
                        <ViewIcon color="gray.500" />
                        <HSmallmd ml="8.46px">Visualizar detalhes</HSmallmd>
                      </Flex>

                      <Flex
                        py="8px"
                        as={Link}
                        alignItems="center"
                        color="gray.500"
                        _hover={{ color: "#444" }}
                        transition="all 0.2s ease-in-out"
                        to={`/managetariff/${tariff.tariffId}`}
                      >
                        <IconEdit />
                        <HSmallmd ml="8.46px">Editar</HSmallmd>
                      </Flex>

                      <Divider my="5px" />

                      <CancelCar
                        uuidTariff={Number(tariff.tariffId)}
                        typeButton="withIcon"
                        refreshTable={refetch}
                      />

                      <CarManually
                        typeButton="withIcon"
                        refreshTable={refetch}
                        uuidTariff={Number(tariff.tariffId)}
                      />
                      {tariff.hasBoleto && (
                        <>
                          <Divider my="5px" />
                          <Flex
                            marginBlock="2"
                            flexDir="row"
                            justifyContent="flex-start"
                            gap="2"
                            alignItems="center"
                            color="#718096"
                            as={Link}
                            padding="0"
                            to={`/boletos?tariffId=${tariff.tariffId}`}
                          >
                            <ArrowRightIcon />
                            <TSmallmd fontWeight="medium">
                              Ver boletos relacionados
                            </TSmallmd>
                          </Flex>
                        </>
                      )}

                      {!!tariff?.remittanceIds && (
                        <GoToProcessingRemittance
                          processingRemittanceIds={tariff.remittanceIds}
                        />
                      )}
                    </>
                    <PopoverCloseButton />
                  </Flex>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
