import { Button, Flex, FormControl, FormLabel } from "@chakra-ui/react";
import { memo, useMemo } from "react";
import {
  FormProvider,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";

import { DatePicker } from "../../../../../../main/components/DateTimePicker/DatePicker";
import { InputMask } from "../../../../../../main/components/InputMask";
import { TreeSelect } from "../../../../../../main/components/TreeSelect";
import { ClientFormTextPerType } from "../../../../Clients/constants/ClientType.constant";
import { useReceiptsFilters } from "./useReceiptsFilters";

export type ReceiptsFiltersPayload = {
  channels: number[];
  startDate: string;
  endDate: string;
  document: string;
};

type Props = {
  loadReport: (payload: ReceiptsFiltersPayload) => void;
};

const DynamicInputMask = () => {
  const { control } = useFormContext<ReceiptsFiltersPayload>();

  const document = useWatch({
    control,
    name: "document",
  });

  const formatMask = useMemo(() => {
    const length = document?.length ?? 0;

    if (length <= 11) return `${ClientFormTextPerType.PF.documentMask}#`;

    return ClientFormTextPerType.PJ.documentMask;
  }, [document]);

  return (
    <InputMask
      fieldname="document"
      format={formatMask}
      mask=""
      width="calc(100% - 10px)"
      fontSize="sm"
    />
  );
};

export const ReceiptsFilters = memo(({ loadReport }: Props) => {
  const formMethods = useForm<ReceiptsFiltersPayload>();

  const {
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const { channels, isFetching } = useReceiptsFilters();

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(loadReport)}>
        <Flex
          w="100%"
          justifyContent="flex-start"
          alignItems="flex-end"
          flexWrap="wrap"
          gap="15px"
          marginBlock="2rem"
        >
          {!isFetching && (
            <>
              <FormControl isInvalid={!!errors.channels} maxWidth="500px">
                <TreeSelect
                  data={channels}
                  placeholder="Canais"
                  name="channels"
                />
              </FormControl>

              <FormControl
                maxWidth="300px"
                isInvalid={!!errors.startDate}
                mb={2}
              >
                <FormLabel htmlFor="startDate">Data Inicio</FormLabel>
                <DatePicker fieldname="startDate" />
              </FormControl>

              <FormControl maxWidth="300px" isInvalid={!!errors.endDate} mb={2}>
                <FormLabel htmlFor="endDate">Data Fim</FormLabel>
                <DatePicker fieldname="endDate" />
              </FormControl>

              <FormControl isInvalid={!!errors.document} maxWidth="200px">
                <FormLabel htmlFor="document">Documento</FormLabel>
                <DynamicInputMask />
              </FormControl>
            </>
          )}

          <Button isLoading={isFetching} alignSelf="flex-end" type="submit">
            Buscar
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
});
