import { TimeIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormControl,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { TimePicker } from "../../../../../main/components/DateTimePicker/TimePicker";
import { TSmallmd, TSmallsm } from "../../../../../main/components/Tipography";
import { IRemittanceJobsData } from "../../../../../types/main/jobs";
import { updateRemittanceSchedule } from "../../service/remittanceService";
import { loadSchedule } from "./functions";

const DEFAULT_RE_SCHEDULE_UPDATE_MESSAGE = "Agendamento atualizado com sucesso";
const DEFAULT_ERROR_MESSAGE = "Não foi possível salvar os dados no momento.";

export const AutoRemittance = () => {
  const toast = useToast();

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const defaultSchedule = useRef<IRemittanceJobsData>({
    isActive: true,
    newSchedule: "",
  });

  const formMethods = useForm<IRemittanceJobsData>();

  const { register, handleSubmit, watch } = formMethods;

  const { isOpen, onOpen, onClose } = useDisclosure();

  const isActive = watch("isActive");

  const onSubmit = async (data: IRemittanceJobsData) => {
    try {
      setIsLoading(true);
      const response = await updateRemittanceSchedule(data);
      defaultSchedule.current.newSchedule = data.newSchedule;
      defaultSchedule.current.isActive = data.isActive;
      toast({
        title: "Sucesso",
        description:
          response.body?.message ?? DEFAULT_RE_SCHEDULE_UPDATE_MESSAGE,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (err) {
      toast({
        title: "Ocorreu um problema.",
        description: DEFAULT_ERROR_MESSAGE,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const handleOnClose = () => {
    formMethods.setValue("newSchedule", defaultSchedule.current.newSchedule);
    formMethods.setValue("isActive", defaultSchedule.current.isActive);
    onClose();
  };

  useEffect(() => {
    let isCancelled = false;

    const loadJob = async () => {
      const response = await loadSchedule();
      if (response && !isCancelled) {
        formMethods.setValue("isActive", !response.deletedAt);
        formMethods.setValue("newSchedule", response.schedule);
        defaultSchedule.current.newSchedule = response.schedule;
        defaultSchedule.current.isActive = !response.deletedAt;
      } else {
        setHasError(true);
      }
    };

    loadJob();

    return () => {
      isCancelled = true;
    };
  }, [formMethods]);

  return (
    <>
      <Button
        variant="outline"
        color="gray.700"
        display="flex"
        gap="10px"
        onClick={onOpen}
      >
        <TimeIcon />
        Definir envio automático
      </Button>
      <Modal isOpen={isOpen} onClose={handleOnClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <ModalHeader>Definir envio automático</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {hasError && (
                  <TSmallsm color="red.500">
                    Houve um erro ao carregar o Job.
                  </TSmallsm>
                )}
                {!hasError && (
                  <>
                    <FormControl
                      display="flex"
                      flexDir="column"
                      gap="10px"
                      mb="20px"
                    >
                      <TSmallmd>Geração automática de remessas</TSmallmd>
                      <Flex gap="10px" alignItems="center">
                        <Switch
                          id="isActive"
                          width="fit-content"
                          {...register("isActive")}
                        />
                        <TSmallsm>
                          {isActive ? "HABILITADO" : "DESABILITADO"}
                        </TSmallsm>
                      </Flex>
                    </FormControl>
                    <FormControl
                      display="flex"
                      flexDir="column"
                      gap="10px"
                      isDisabled={!isActive}
                    >
                      <TSmallmd opacity={!isActive ? 0.5 : 1}>
                        Horário de geração automática
                      </TSmallmd>
                      <Flex gap="10px" alignItems="center">
                        <TimePicker
                          fieldname="newSchedule"
                          readonly={!isActive}
                        />
                      </Flex>
                    </FormControl>
                  </>
                )}
              </ModalBody>

              <ModalFooter>
                <Button colorScheme="blue" mr={3} onClick={handleOnClose}>
                  Fechar
                </Button>
                <Button
                  variant="ghost"
                  type="submit"
                  hidden={hasError}
                  isLoading={isLoading}
                >
                  Salvar
                </Button>
              </ModalFooter>
            </form>
          </FormProvider>
        </ModalContent>
      </Modal>
    </>
  );
};
