import { useCallback, useEffect, useRef, useState } from "react";
import { UseFormWatch } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../../main/common/constants/defaultMessages";
import { customToast } from "../../../../../main/common/utils/customToast";
import { ITableFormRefProps } from "../../../../../main/components/TableForm";
import { IChannelData } from "../../../../../types/main/channel";
import { createNewChannel, updateChannel } from "../../services/ChannelService";

interface IUseChannelConfig {
  channelId?: string;
  watch: UseFormWatch<IChannelData>;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  isReadOnly?: boolean;
}

export const useChannelConfig = ({
  channelId,
  watch,
  setIsLoading,
  isReadOnly,
}: IUseChannelConfig) => {
  const navigate = useNavigate();

  const [canAddNewServiceProduct, setCanAddNewServiceProduct] = useState(true);

  const tableForm = useRef<ITableFormRefProps>(null);

  const inheritance = watch("inheritance");

  const currentServiceProducts = watch("servicesProducts");

  const onSubmit = async (clientData: IChannelData) => {
    try {
      setIsLoading(true);

      let response;

      if (channelId) response = await updateChannel(clientData, channelId);
      else response = await createNewChannel(clientData);
      customToast("success", response.body?.message);

      navigate(`/managechannel/${clientData.id}`);
    } catch (error: any) {
      customToast(
        "error",
        error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION
      );
    }
    setIsLoading(false);
  };

  const setNewRowIfPossible = useCallback(
    (isReadOnly: boolean | undefined) => {
      if (Array.isArray(currentServiceProducts) && isReadOnly)
        setCanAddNewServiceProduct(false);
      else setCanAddNewServiceProduct(true);
    },
    [currentServiceProducts]
  );

  useEffect(() => {
    if (currentServiceProducts) setNewRowIfPossible(isReadOnly);
  }, [currentServiceProducts, isReadOnly, setNewRowIfPossible]);

  useEffect(() => {
    if (!channelId) tableForm.current?.addNewRow();
  }, [channelId]);

  return {
    onSubmit,
    tableForm,
    inheritance,
    canAddNewServiceProduct,
  };
};
