import { createStandaloneToast } from "@chakra-ui/react";

const toast = createStandaloneToast();

const getTitleFromType = (type: "info" | "warning" | "success" | "error") => {
  switch (type) {
    case "info":
      return "Informação";
    case "warning":
      return "Atenção";
    case "success":
      return "Sucesso";
    case "error":
      return "Erro";
    default:
      return "Informação";
  }
};

const getDescriptionFromType = (
  type: "info" | "warning" | "success" | "error",
  description?: string | null
) => {
  if (description) return description;

  switch (type) {
    case "info":
      return "";
    case "warning":
      return "";
    case "success":
      return "Operação efetuada com sucesso.";
    case "error":
      return "Tente novamente ou contate um suporte.";
    default:
      return "";
  }
};

export const customToast = (
  type: "info" | "warning" | "success" | "error",
  description: string | undefined | null,
  duration?: number
) => {
  const descriptionText = getDescriptionFromType(type, description);

  toast({
    title: getTitleFromType(type),
    description: descriptionText,
    status: type,
    duration: duration || 2000,
    isClosable: true,
  });
};
