import { DataTable } from "../../../../../../main/components/DataTable";
import {
  PopoverReportList,
  PopoverReportListProps,
} from "../../PopoverReportList";
import { CreditGrantedFilters } from "./Filters";
import { useCreditGranted } from "./useCreditGranted";

const Popover = (props: PopoverReportListProps) => (
  <PopoverReportList
    {...props}
    exportFileName="Garantia de Crédito a Receber"
  />
);

export const CreditGranted = () => {
  const { columns, report, loadReport } = useCreditGranted();

  return (
    <>
      <CreditGrantedFilters loadReport={loadReport} />
      <DataTable
        module="Channels" // ! mudar
        isLoading={report.isFetching}
        columns={columns}
        data={report.data}
        popup={{
          RenderComponent: Popover,
          urlEdit: "not-required",
          urlView: "not-required",
          hideInHeader: true,
        }}
      />
    </>
  );
};
