import { HStack, Box, StackProps } from "@chakra-ui/react";
import { memo, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

interface IOption {
  label: string;
  value: boolean;
}

interface IBooleanRadioGroup extends StackProps {
  fieldname: string;
  options: IOption[];
  isReadOnly?: boolean;
  onCheckChange?: (value: boolean) => void | (() => void);
  defaultCustomValue?: boolean;
}

export const BooleanRadioGroup = memo(
  ({
    fieldname,
    options,
    isReadOnly,
    onCheckChange,
    defaultCustomValue,
    ...rest
  }: IBooleanRadioGroup) => {
    const { setValue, watch, clearErrors } = useFormContext();
    const [optionSelected, setOptionSelected] = useState<boolean | undefined>(
      defaultCustomValue
    );

    const watchFieldname = watch(fieldname);

    useEffect(() => {
      if (watchFieldname !== undefined) setOptionSelected(watchFieldname);
    }, [watchFieldname]);

    useEffect(() => {
      setValue(fieldname, optionSelected);
    }, [fieldname, optionSelected, setValue]);

    const handleOnCheckChange = (e: any) => {
      clearErrors(fieldname);

      !isReadOnly && setOptionSelected(e.target.value === "true");
      if (onCheckChange) onCheckChange(e.target.value === "true");
    };

    return (
      <HStack {...rest}>
        {options.map((option) => {
          return (
            <Box
              as="option"
              key={option.label}
              value={option.value ? "true" : "false"}
              px={4}
              py={1}
              borderRadius="10px"
              borderWidth="1px"
              borderColor="blue.300"
              onClick={handleOnCheckChange}
              bg={optionSelected === option.value ? "blue.500" : "white"}
              color={optionSelected === option.value ? "white" : "black"}
              cursor={isReadOnly ? "default" : "pointer"}
            >
              {option.label}
            </Box>
          );
        })}
      </HStack>
    );
  }
);
