import { Text, useToast } from "@chakra-ui/react";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Backdrop } from "../components/Backdrop";
import { IBlobError, useDownloadFile } from "../hooks/useDownloadFile";
import { Home } from "./Home";

export const Download: React.FC = () => {
  const { id, path } = useParams();

  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const [text, setText] = useState<string>();

  const preDownloading = () => setIsLoading(true);

  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage =
      error.response?.data?.message ?? "Houve um problema ao baixar arquivo.";

    toast({
      title: "Serviço indisponível.",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const { downloadFile: downloadProcessingRemittance } = useDownloadFile({
    apiDefinition: {
      endpoint: `/processingremittances/processing/list/${id}`,
      responseType: "text",
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Rem_${id}_INTERSTAR_${format(new Date(), "dd-MM-yyyy")}`,
    extension: "txt",
  });

  useEffect(() => {
    if (id && path === "processing_remittance") {
      downloadProcessingRemittance();
      setText(`Baixando arquivo de remessa de processamento nº ${id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, path]);

  return (
    <Home>
      <Text>
        <Text fontSize="1.875rem" color="#000">
          {text}
        </Text>
      </Text>
      {isLoading && <Backdrop />}
    </Home>
  );
};
