import { useCallback } from "react";

import { DataTable } from "../../../../../../main/components/DataTable";
import { enumNameOfReports } from "../../../../../../types/main/reports";
import { ReportsUnion } from "../../../pages/ReportList";
import {
  PopoverReportList,
  PopoverReportListProps,
} from "../../PopoverReportList";
import { ReceivedChargesFilters } from "./Filters";
import { useReceivedCharges } from "./useReceivedCharges";

type ReceivedChargesProps = {
  nameReport: ReportsUnion;
};

export const ReceivedCharges = ({ nameReport }: ReceivedChargesProps) => {
  const { columns, report, loadReport } = useReceivedCharges(nameReport);

  const Popover = useCallback(
    (props: PopoverReportListProps) => (
      <PopoverReportList
        {...props}
        exportFileName={enumNameOfReports[nameReport]}
      />
    ),
    [nameReport]
  );

  return (
    <>
      <ReceivedChargesFilters loadReport={loadReport} />
      <DataTable
        module="Channels" // ! mudar
        isLoading={report.isFetching}
        columns={columns}
        data={report.data}
        popup={{
          RenderComponent: Popover,
          urlEdit: "not-required",
          urlView: "not-required",
          hideInHeader: true,
        }}
      />
    </>
  );
};
