import * as yup from "yup";

export const channemEmailSchema = yup
  .object({
    isEmailConfigInherited: yup.boolean().required("Esse campo é obrigatório"),
    emailConfigs: yup.array().when("isEmailConfigInherited", {
      is: true,
      then: yup.array().notRequired(),
      otherwise: yup.array().of(
        yup.object().shape({
          productCategory: yup.string().required("Esse campo é obrigatório"),
          templateUuid: yup.string().required("Esse campo é obrigatório"),
          emailObjectiveUuid: yup.string().required("Esse campo é obrigatório"),
        })
      ),
    }),
  })
  .required();
