import { useMemo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { ClientFormTextPerType } from "../../../modules/Records/Clients/constants/ClientType.constant";
import { InputMask } from "../InputMask";

export const DynamicDocumentInputMask = () => {
  const { control } = useFormContext<any>();

  const document = useWatch({
    control,
    name: "document",
  });

  const formatMask = useMemo(() => {
    const length = document?.length ?? 0;

    if (length <= 11) return `${ClientFormTextPerType.PF.documentMask}#`;

    return ClientFormTextPerType.PJ.documentMask;
  }, [document]);

  return (
    <InputMask
      fieldname="document"
      format={formatMask}
      mask=""
      width="calc(100% - 10px)"
      fontSize="sm"
    />
  );
};
