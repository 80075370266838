import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import { IBoletoFilters, IBoletoList } from "../../../../types/main/boleto";
import { IPaginateResponse } from "../../../../types/main/pagination";
import { IResponse } from "../../../../types/main/response";

const MODULE_PATH = "/boletos";
type InvoiceListParams = {
  pagination?: PaginationState;
  columnFilters?: TableFilteredParams;
  sorting?: TableSortingParams;
  filters?: IBoletoFilters;
};
export const BoletoService = {
  async getAllBoletos({
    columnFilters,
    filters,
    pagination,
    sorting,
  }: InvoiceListParams) {
    try {
      const page = pagination?.pageIndex;

      const { data }: AxiosResponse<IResponse<IPaginateResponse<IBoletoList>>> =
        await recordApi.post(MODULE_PATH, filters, {
          params: {
            page: page === undefined ? 1 : page + 1,
            take: pagination?.pageSize ?? 10,
            ...columnFilters,
            ...sorting,
          },
        });
      return data;
    } catch (err: any) {
      throw err?.response?.data;
    }
  },
};
