import * as yup from "yup";

export const carManuallySchema = yup
  .object({
    uuidCar: yup.string().required("Esse campo é obrigatório"),
    processingCarTypeOfLowUuid: yup
      .string()
      .required("Esse campo é obrigatório"),
    dateOfLow: yup.string().required("Esse campo é obrigatório"),
  })
  .required();
