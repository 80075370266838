/* eslint-disable react/no-children-prop */
import {
  Box,
  Input,
  InputAddon,
  InputGroup,
  InputProps,
} from "@chakra-ui/react";
import { memo } from "react";
import { useFormContext } from "react-hook-form";

import { validateNumberInput } from "../../common/utils/validateNumberInput";
import { TLargemd } from "../Tipography";

interface INumberFilterInput {
  type: "monetary" | "number" | "percentage";
  label?: string;
  fieldname: string;
  size?: "sm" | "md" | "lg" | "xs";
  isReadOnly?: boolean;
  disabled?: boolean;
  maxLength?: number;
  regex?: RegExp;
  onChange?: (value: string) => string;
  InputProps?: InputProps;
}

enum Type {
  "monetary" = "R$",
  "percentage" = "%",
}

export const NumberFilterInput: React.FC<INumberFilterInput> = memo(
  ({
    type,
    label,
    fieldname,
    size,
    isReadOnly,
    disabled,
    maxLength,
    regex,
    onChange,
    InputProps,
  }) => {
    const { register, setValue } = useFormContext();

    const placement = type === "monetary" ? "left" : "right";
    const borderRadius =
      type === "monetary"
        ? "0 6px 6px 0"
        : type === "percentage"
        ? "6px 0 0 6px"
        : "6px";
    const flexDirection = type === "monetary" ? "row" : "row-reverse";
    return (
      <Box>
        {label && <TLargemd marginBottom="8px">{label}</TLargemd>}
        <InputGroup flexDirection={flexDirection} size={size ?? "sm"}>
          {type !== "number" && (
            <InputAddon
              borderRadius="6px"
              placement={placement}
              children={Type[type]}
              height="2.5rem"
            />
          )}
          <Input
            disabled={disabled}
            readOnly={isReadOnly}
            borderRadius={borderRadius}
            placeholder="-"
            height="2.5rem"
            maxLength={maxLength ?? 16}
            {...register(fieldname, {
              onChange: (event) => {
                setValue(
                  fieldname,
                  validateNumberInput(event.target.value, regex)
                    ? onChange
                      ? onChange(event.target.value)
                      : event.target.value
                    : undefined
                );
              },
            })}
            _disabled={{
              cursor: "not-allowed",
              color: "inherit",
              opacity: "inherit",
            }}
            {...InputProps}
          />
        </InputGroup>
      </Box>
    );
  }
);
