import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IBrasilAPIBanks, getBanks } from "../services/BrasilAPIService";

export const useGetBanksBrasilApi = () => {
  const toast = useToast();

  const [allBanksList, setAllBanksList] = useState<Array<IBrasilAPIBanks>>();

  const updateBankList = useCallback(async () => {
    try {
      const response = await getBanks();

      setAllBanksList(response);
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    updateBankList();
  }, [updateBankList]);

  return { allBanksList, updateBankList };
};
