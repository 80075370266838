/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Portal,
  Flex,
  useDisclosure,
} from "@chakra-ui/react";

import { IPaymentOptionsGroup } from "../../../../../../../types/main/paymentMethods";
import { enumProcessingStatusCar } from "../../../../../../../types/main/processingStatusCar";
import { CarManually } from "../../../../../shared/Components/Tariff/CarManually";
import { CancelCar } from "./CancelCar";
import { EditCardModal } from "./EditCardModal";

interface IProps {
  uuid: string;
  paymentMethodUuid?: string;
  invoiceValue: number;
  invoiceInterest: number;
  invoiceFine: number;
  invoiceDiscount: number;
  invoiceTotal: number;
  service: string;
  allMethods?: IPaymentOptionsGroup[];
  discardInterestAndFine: boolean;
  reloadCar: () => void;
  enumProcessing: keyof typeof enumProcessingStatusCar;
  serviceInaactive?: boolean;
  dueDate: Date;
  idContract: number;
}

export const PopoverCar = ({
  uuid,
  invoiceInterest,
  invoiceFine,
  invoiceValue,
  invoiceDiscount,
  invoiceTotal,
  paymentMethodUuid,
  service,
  allMethods,
  discardInterestAndFine,
  reloadCar,
  enumProcessing,
  serviceInaactive,
  dueDate,
  idContract,
}: IProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          colorScheme="gray"
          variant="outline"
          border="0"
          color="gray.700"
          _hover={{ bg: "gray.200" }}
          size="xs"
        >
          ...
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent zIndex="1" width="242px">
          <PopoverBody px="0">
            <Flex flexDir="column" pt="14px" px="4">
              <>
                <EditCardModal
                  uuid={uuid}
                  invoiceInterest={invoiceInterest}
                  invoiceFine={invoiceFine}
                  invoiceValue={invoiceValue}
                  invoiceDiscount={invoiceDiscount}
                  invoiceTotal={invoiceTotal}
                  paymentMethodUuid={paymentMethodUuid}
                  service={service}
                  allMethods={allMethods}
                  discardInterestAndFine={discardInterestAndFine}
                  reloadCar={reloadCar}
                  enumProcessing={enumProcessing}
                  serviceInaactive={serviceInaactive}
                  dueDate={dueDate}
                  idContract={idContract}
                />

                <CancelCar
                  typeButton="withIcon"
                  uuidTariffCar={uuid}
                  reloadCar={reloadCar}
                />

                <CarManually
                  typeButton="withIcon"
                  uuidTariffCar={uuid}
                  reloadCar={reloadCar}
                />
              </>
              <PopoverCloseButton />
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
