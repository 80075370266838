import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Grid, GridItem, Divider, Tooltip } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { format_CPF_CNPJ } from "../../../../../main/common/utils/format_CPF_CNPJ";
import {
  HLargexs,
  HSmallxl,
  HSmallmd,
} from "../../../../../main/components/Tipography";
import { enumTypeOfPaymentType } from "../../../../../types/main/paymentType";
import { WarningType } from "../../constants/tariff.constants";
import { ITariffProps } from "../../interface/tariff.interface";
import { BadgeTariff } from "./BadgeTariff";

const Tariff = ({ tariff }: { tariff: ITariffProps }) => {
  return (
    <>
      <Flex mt="35px" justifyContent="space-between" alignItems="end">
        <Flex justifyContent="start" alignItems="baseline" gap="12px">
          <HLargexs color="gray.500" fontSize="12px">
            MÉTODO DE PAGAMENTO
          </HLargexs>
          <HSmallxl>{enumTypeOfPaymentType[tariff.paymentType]} </HSmallxl>
        </Flex>
        <BadgeTariff
          colorScheme={tariff.badge.colorScheme}
          label={tariff.badge.label}
        />
      </Flex>
      <Grid
        mt="32px"
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(5, 1fr)"
        gap="32px 60px"
      >
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            DATA DE TARIFAÇÃO
          </HLargexs>
          <HSmallmd mt="8px">{tariff.createdAt}</HSmallmd>
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            DATA DE ENVIO
          </HLargexs>
          <HSmallmd mt="8px">-</HSmallmd>
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            DATA DE VENCIMENTO
          </HLargexs>
          {!tariff.warning && <HSmallmd mt="8px">{tariff.dueDate}</HSmallmd>}
          {!!tariff.warning && (
            <Flex flexDir="row" alignItems="center" mt="8px" gap="10px">
              <HSmallmd>Inconsistente</HSmallmd>
              <Tooltip
                as="span"
                label={WarningType[tariff.warning].message}
                aria-label="Aviso de dados desiguais"
              >
                <WarningTwoIcon
                  color={WarningType[tariff.warning].color}
                  cursor="pointer"
                />
              </Tooltip>
            </Flex>
          )}
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            DATA DE RECEBIMENTO
          </HLargexs>
          <HSmallmd mt="8px">{tariff.receiveDate}</HSmallmd>
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            VALOR
          </HLargexs>
          <HSmallmd>
            {Number(tariff.tariffValue).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            JUROS RECEBIDO
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.totalInterestReceived).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            MULTA RECEBIDA
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.totalFineReceived).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            VALOR RECEBIDO
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.totalReceived).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            DESCONTO CONCEDIDO
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.discountReceived).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>
        <GridItem />

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            JUROS PREVISTO
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.totalInterestProvided).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            MULTA PREVISTA
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.totalFineProvided).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            VALOR RECEBIDO PREVISTO
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.totalProvided).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            DESCONTO CONCEDIDO PREVISTO
          </HLargexs>
          <HSmallmd mt="8px">
            {Number(tariff.discountProvided).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })}
          </HSmallmd>
        </GridItem>
      </Grid>
      <Divider mt="24px" color="gray.300" />
      <Grid
        mt="24px"
        templateRows="repeat(3, 1fr)"
        templateColumns="repeat(4, 1fr)"
        gap="32px 60px"
      >
        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            CONTRATO
          </HLargexs>
          <Link to={`/viewcontract/${tariff.contract.uuid}`}>
            <HSmallmd mt="8px" textDecoration="underline" color="blue.600">
              {tariff.contractActive ? "" : "(inativo) "}
              {tariff.contract.idContract}
            </HSmallmd>
          </Link>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            PROJETO
          </HLargexs>
          <HSmallmd mt="8px">{tariff.projectDescription}</HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            PRESTADOR
          </HLargexs>
          <HSmallmd mt="8px">
            {tariff.providerActive ? "" : "(inativo) "}
            {tariff.providerName}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            IDENTIFICADOR DA TARIFA
          </HLargexs>
          <HSmallmd mt="8px">{tariff.tariffIdentifier}</HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            PRODUTO
          </HLargexs>
          <HSmallmd mt="8px">
            {tariff.productActive ? "" : "(inativo) "}
            {tariff.productName}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            TARIFADOR
          </HLargexs>
          <HSmallmd mt="8px">
            {tariff.billerActive ? "" : "(inativo) "}
            {tariff.billerName}
          </HSmallmd>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            FATURA
          </HLargexs>
          <Link to={`/tarifflist?invoiceId=${tariff.invoice}`}>
            <HSmallmd mt="8px" textDecoration="underline" color="blue.600">
              {tariff.invoice}
            </HSmallmd>
          </Link>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            CLIENTE
          </HLargexs>
          <Link to={`/viewclient/${tariff.contract.clientUuid}`}>
            <HSmallmd mt="8px" textDecoration="underline" color="blue.600">
              {`${tariff.clientName} | ${format_CPF_CNPJ(
                tariff.contract.client.document
              )}`}
            </HSmallmd>
          </Link>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            CANAL
          </HLargexs>
          <Link to={`/viewchannel/${tariff.channel.id}`}>
            <HSmallmd mt="8px" textDecoration="underline" color="blue.600">
              {`[${tariff.channel.id}] ${tariff.channel.description}`}
            </HSmallmd>
          </Link>
        </GridItem>

        <GridItem>
          <HLargexs whiteSpace="nowrap" color="gray.500">
            CANAL MÃE
          </HLargexs>
          <Link to={`/viewchannel/${tariff.channel.parentId}`}>
            <HSmallmd mt="8px" textDecoration="underline" color="blue.600">
              {`[${tariff.channel.parentId}] ${tariff.channel.parent?.description}`}
            </HSmallmd>
          </Link>
        </GridItem>
      </Grid>
    </>
  );
};

export { Tariff };
