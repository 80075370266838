import { Heading, HeadingProps } from "@chakra-ui/react";
import React from "react";

const HLarge4xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="60px" lineHeight="60px" {...rest}>
      {children}
    </Heading>
  );
};

const HLarge3xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="48px" lineHeight="48px" {...rest}>
      {children}
    </Heading>
  );
};

const HLarge2xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="36px" lineHeight="43.2px" {...rest}>
      {children}
    </Heading>
  );
};

const HLargexl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="30px" lineHeight="39.9px" {...rest}>
      {children}
    </Heading>
  );
};

const HLargelg: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="24px" lineHeight="31.92px" {...rest}>
      {children}
    </Heading>
  );
};

const HLargemd: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="20px" lineHeight="24px" {...rest}>
      {children}
    </Heading>
  );
};

const HLargesm: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="16px" lineHeight="19.2px" {...rest}>
      {children}
    </Heading>
  );
};

const HLargexs: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="700" fontSize="14px" lineHeight="16.8px" {...rest}>
      {children}
    </Heading>
  );
};

export {
  HLarge4xl,
  HLarge3xl,
  HLarge2xl,
  HLargexl,
  HLargelg,
  HLargemd,
  HLargesm,
  HLargexs,
};
