import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { Backdrop } from "../../../../main/components/Backdrop";
import { ConfirmationPopover } from "../../../../main/components/ConfirmationPopover";
import { EnumRadioGroup } from "../../../../main/components/CustomRadioGroup/EnumRadioGroup";
import { DatePicker } from "../../../../main/components/DateTimePicker/DatePicker";
import {
  HLargexs,
  TLargelg,
  TLargemd,
  TLargesm,
} from "../../../../main/components/Tipography";
import { useAuth } from "../../../../main/hooks/useAuth";
import { IProcessingCarTypeOfLow } from "../../../../types/main/processingCarTypeOfLow";
import { CAN_PERMISSION_EDIT } from "../constants/permissions";
import {
  IUniqueWriteOff,
  IUniqueWriteOffForm,
} from "../interfaces/unique-write-off.interface";
import { uniqueManualWriteOffSchema } from "../schemas/unique-manual-write-off.schema";
import { getAllProcessingCarPaymentConfirmationType } from "../service/ProcessingCarService";
import { writeOffReceivable } from "../service/write-off-receivable.service";

export const UniqueWriteOffCar = ({
  isOpen,
  onClose,
  receivable,
  refetch,
}: IUniqueWriteOff) => {
  const [isLoading, setIsLoading] = useState(false);
  const { permissionLevel } = useAuth();
  const toast = useToast();

  const [allProcessingCarTypeOfLowList, setAllProcessingCarTypeOfLowList] =
    useState<IProcessingCarTypeOfLow[]>([]);

  const fetchAllProcessingCarTypeOfLow = useCallback(async () => {
    const response = await getAllProcessingCarPaymentConfirmationType();
    if (response.body) {
      setAllProcessingCarTypeOfLowList(response.body);
    }
  }, []);

  useEffect(() => {
    if (allProcessingCarTypeOfLowList.length === 0) {
      fetchAllProcessingCarTypeOfLow();
    }
  }, [
    allProcessingCarTypeOfLowList.length,
    allProcessingCarTypeOfLowList,
    fetchAllProcessingCarTypeOfLow,
  ]);

  const methods = useForm<IUniqueWriteOffForm>({
    resolver: yupResolver(uniqueManualWriteOffSchema),
  });

  if (!CAN_PERMISSION_EDIT[permissionLevel] || !receivable) {
    return null;
  }

  const [discount, fine, interest, value, total, reason, type, date] =
    methods.watch([
      "discountReceived",
      "fineReceived",
      "interestReceived",
      "valueReceived",
      "totalReceived",
      "reasonForManualLow",
      "processingCarTypeOfLowUuid",
      "dateOfLow",
    ]);

  const totalValue =
    Number(receivable.carValue) +
    Number(fine) +
    Number(interest) -
    Number(discount);

  const isSubmitButtonDisabled =
    !discount ||
    !fine ||
    !interest ||
    !value ||
    !total ||
    !reason ||
    !type ||
    !date;
  const onSubmit = async () => {
    setIsLoading(true);
    try {
      const data = methods.getValues();
      await writeOffReceivable(
        { ...data, totalReceived: totalValue },
        receivable.uuid
      );
      toast({
        position: "bottom",
        title: "Sucesso",
        description: "Ação realizada com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      await refetch();
    } catch (error: any) {
      toast({
        position: "bottom",
        title: "Ocorreu um erro",
        description: error?.message ?? DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      console.error("Error on submit", error);
    } finally {
      setIsLoading(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size="4xl">
      <FormProvider {...methods}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Baixa manual em massa</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form onSubmit={onSubmit}>
              <Flex justifyContent="space-between">
                <EnumRadioGroup
                  fieldname="processingCarTypeOfLowUuid"
                  options={allProcessingCarTypeOfLowList.map(
                    ({ labelTypeOfLow, uuid }) => {
                      return {
                        label: labelTypeOfLow,
                        value: uuid,
                      };
                    }
                  )}
                />
                <Flex flexDirection="column">
                  <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
                    Data da baixa
                  </HLargexs>
                  <DatePicker fieldname="dateOfLow" />
                </Flex>
              </Flex>
              <TLargemd mb="15px">Descreva o motivo da baixa manual</TLargemd>
              <Textarea
                {...methods.register("reasonForManualLow")}
                placeholder="Insira aqui o motivo"
                size="sm"
              />

              <TLargelg
                marginTop={4}
                borderBottom="4px"
                borderBottomColor="blue.700"
              >
                Verifique os valores. Se necessário, insira desconto, multa e
                juros.
              </TLargelg>
              <TLargesm color="red">
                {" "}
                Atenção os valores de juros e multa não serão recalculados. Para
                efetuar baixa com cálculo automático utilize a tela de tarifas.
              </TLargesm>
              <Flex justifyContent="space-between" gap={8} marginTop={4}>
                <Box>
                  <TLargemd>Desconto</TLargemd>
                  <InputGroup>
                    <InputLeftAddon children="R$" />
                    <Input
                      {...methods.register("discountReceived")}
                      backgroundColor="white"
                      defaultValue={
                        Number(receivable?.remittanceDiscount)?.toFixed(2) ??
                        0.0
                      }
                    />
                  </InputGroup>
                </Box>
                <Box>
                  <TLargemd>Multa</TLargemd>
                  <InputGroup>
                    <InputLeftAddon children="R$" />
                    <Input
                      {...methods.register("fineReceived")}
                      backgroundColor="white"
                      defaultValue={receivable.invoiceFine}
                    />
                  </InputGroup>
                </Box>
                <Box>
                  <TLargemd>Juros</TLargemd>
                  <InputGroup>
                    <InputLeftAddon children="R$" />
                    <Input
                      {...methods.register("interestReceived")}
                      backgroundColor="white"
                      defaultValue={receivable.invoiceInterest}
                      type="number"
                    />
                  </InputGroup>
                </Box>
              </Flex>
              <Flex justifyContent="space-between" marginTop={4} gap={8}>
                <Box>
                  <TLargemd>Valor original</TLargemd>
                  <InputGroup>
                    <InputLeftAddon children="R$" />
                    <Input
                      {...methods.register("valueReceived")}
                      isReadOnly
                      backgroundColor="gray.100"
                      value={receivable.carValue}
                      defaultValue={receivable.carValue}
                      cursor="not-allowed"
                    />
                  </InputGroup>
                </Box>
                <Box>
                  <TLargemd>Total</TLargemd>
                  <InputGroup>
                    <InputLeftAddon children="R$" />
                    <Input
                      {...methods.register("totalReceived")}
                      isReadOnly
                      backgroundColor="gray.100"
                      value={totalValue.toFixed(2)}
                      defaultValue={receivable.invoiceTotal}
                      cursor="not-allowed"
                    />
                  </InputGroup>
                </Box>
              </Flex>
            </form>
            {isLoading && <Backdrop />}
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancelar
            </Button>
            <ConfirmationPopover
              disabled={isSubmitButtonDisabled}
              triggerButtonText="Enviar"
              onPrimaryButtonClick={onSubmit}
              title="Confirmação"
              text="Tem certeza que deseja enviar os dados para baixa manual?"
              primaryButtonText="Sim"
              secondaryButtonText="Não"
            />
          </ModalFooter>
        </ModalContent>
      </FormProvider>
    </Modal>
  );
};
