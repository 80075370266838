/* eslint-disable react/no-unstable-nested-components */
import { Box, Flex, GridItem } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { MultiSelectFilter } from "../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { EmailBody } from "../../../../main/components/EmailBody";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import {
  IPaymentMethods,
  IPaymentMethodsData,
} from "../../../../types/main/paymentMethods";
import { enumTypeOfPaymentType } from "../../../../types/main/paymentType";
import { IResponse } from "../../../../types/main/response";
import {
  allPaymentMethod,
  deletePaymentMethod,
} from "../service/PaymentMethodService";

interface IList
  extends Omit<
    IPaymentMethodsData,
    // eslint-disable-next-line prettier/prettier
    | "paymentTypeUuid"
    | "paymentLayoutUuid"
    | "paymentBankUuid"
    | "paymentTransmissionChannel"
    | "templateUuid"
  > {
  paymentTypeName: string;
  paymentLayoutName?: string;
  paymentBankName?: string;
  paymentTransmissionChannelName: string;
  templateName?: string;
  bankCode?: number;
}

export const PaymentMethodList: React.FC = () => {
  const [products, setProducts] = useState<Array<IList>>([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleList = async () => {
    setIsLoading(true);
    const response: IResponse<Array<IPaymentMethods>> = await allPaymentMethod(
      {}
    );
    if (response.type === "success" && response.body) {
      const list = response.body.map(
        ({
          uuid,
          name,
          description,
          paymentType,
          paymentLayout,
          nsa,
          paymentBank,
          agencyNumber,
          agreementCode,
          paymentTransmissionChannel,
          template,
          checkingAccount,
          recipientCode,
        }) => ({
          paymentTypeName: enumTypeOfPaymentType[paymentType?.name],
          paymentLayoutName: paymentLayout?.name,
          description,
          agreementCode,
          nsa,
          paymentTransmissionChannelName: paymentTransmissionChannel
            .map(({ name }) => name)
            .toString(),
          bankCode: paymentBank?.bankCode,
          paymentBankName: paymentBank?.name,
          agencyNumber,
          checkingAccount,
          templateName: template?.name,
          recipientCode,
          component: (
            <EmailBody data={template?.emailBody} templateName={name ?? ""} />
          ),
          uuid,
        })
      );
      setProducts(list);
    }
    setIsLoading(false);
  };

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Tipo",
            accessor: "paymentTypeName",
            Filter: MultiSelectFilter({}),
            filter: "multi",
          },
          {
            Header: "Layout",
            accessor: "paymentLayoutName",
          },
          {
            Header: "Descrição",
            accessor: "description",
          },
          {
            Header: "Código de Convênio",
            accessor: "agreementCode",
          },
          {
            Header: "Código Beneficiário",
            accessor: "recipientCode",
          },
          {
            Header: "Próximo  NSA",
            accessor: "nsa",
          },
          {
            Header: "Canal de Transmissão",
            accessor: "paymentTransmissionChannelName",
          },
          {
            Header: "Código do Banco",
            accessor: "bankCode",
          },
          {
            Header: "Banco",
            accessor: "paymentBankName",
          },
          {
            Header: "Agência",
            accessor: "agencyNumber",
          },
          {
            Header: "Conta Corrente",
            accessor: "checkingAccount",
          },
          {
            Header: "Template",
            accessor: "templateName",
          },
          {
            Header: "Corpo do E-Mail",
            accessor: "component",
          },
          {
            Header: "Identificador",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Métodos de pagamento", href: "" },
    {
      description: "Gerenciamento de métodos de pagamento",
      href: "../paymentmethodslist",
    },
  ];

  useEffect(() => {
    handleList();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        <CustomBreadcrumb breadcrumb={breadcrumb} />
        <Flex justifyContent="space-between" alignItems="center">
          <HLarge2xl mb="32px" color="gray.600">
            Gerenciamento de Métodos de Pagamento
          </HLarge2xl>
          <Box>
            <SimpleMenu label="Exibir colunas">
              <SelectableColumns />
            </SimpleMenu>
            <CreateNewDataButton
              module={MODULES.PAYMENT_METHODS}
              path="/managepaymentmethods"
              text="Cadastrar novo método"
            />
          </Box>
        </Flex>
        <DataTable
          refreshTable={handleList}
          module="PaymentMethods"
          isLoading={isLoading}
          hiddenColumns={hiddenColumns}
          columns={columns}
          data={products}
          popup={{
            callToDelete: deletePaymentMethod,
            refreshTable: handleList,
            urlEdit: "/managepaymentmethods/",
            urlView: "/viewpaymentmethods/",
          }}
        />
      </GridItem>
    </Wrapper>
  );
};
