import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  useToast,
} from "@chakra-ui/react";
import axios from "axios";
import { useCallback, useEffect, useState } from "react";

import { RECORDS_API_BASE_URL } from "../../../../../main/services/RecordService";

interface ILayoutViewProps {
  isOpen: boolean;
  onClose: () => void;
  channelId: string;
}

export const LayoutView = ({
  isOpen,
  onClose,
  channelId,
}: ILayoutViewProps) => {
  const toast = useToast();

  const [srcIframe, setSrcIframe] = useState("");

  const getSrc = useCallback(async () => {
    try {
      const res = await axios.get(
        `${RECORDS_API_BASE_URL}/channelinvoice/visualize/${channelId}`,
        {
          responseType: "blob",
          headers: {
            authorization: `Bearer ${localStorage.getItem("idToken")}`,
          },
        }
      );
      setSrcIframe(URL.createObjectURL(res.data));
    } catch (err) {
      toast({
        title: "Serviço indisponível.",
        description: "Houve um problema ao carregar o template.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [channelId, toast]);

  useEffect(() => {
    isOpen && getSrc();
  }, [getSrc, isOpen]);

  return (
    <Drawer onClose={onClose} isOpen={isOpen} size="xl">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Exemplo do Layout para visualização</DrawerHeader>
        <DrawerBody height="100%">
          <iframe
            src={srcIframe}
            frameBorder="0"
            title="Layout"
            width="100%"
            height={`${window.innerHeight - 100}px`}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};
