import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { UseFormReset, UseFormSetValue } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import {
  IServiceProvision,
  IServiceProvisionData,
} from "../../../../types/main/serviceProvision";
import {
  EditServiceProvision,
  NewServiceProvision,
} from "../service/ServiceProvisionService";

interface IUseServiceProvisionForm {
  isReadOnly: boolean | undefined;
  existingServiceProvision?: IServiceProvision;
  reset: UseFormReset<IServiceProvisionData>;
  setValue: UseFormSetValue<IServiceProvisionData>;
  serviceProvisionUUID?: string | undefined;
}

export const useServiceProvisionForm = ({
  serviceProvisionUUID,
}: IUseServiceProvisionForm) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (serviceProvisionData: IServiceProvisionData) => {
      try {
        setIsLoading(true);

        let response;

        if (serviceProvisionUUID) {
          response = await EditServiceProvision(
            serviceProvisionData,
            serviceProvisionUUID
          );
        } else response = await NewServiceProvision(serviceProvisionData);

        toast({
          title: "Efetuado com sucesso.",
          description: response.body?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/serviceprovisionlist");
      } catch (error: any) {
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    },
    [serviceProvisionUUID, navigate, toast]
  );

  return {
    onSubmit,
    isLoading,
  };
};
