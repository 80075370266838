import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  IChannelCommissionType,
  IChannelDueDateType,
} from "../../../../../../../types/main/channelServicesProducts";

interface IUseFormatState {
  index: number;
}

export const useShowFormattedState = ({ index }: IUseFormatState) => {
  const { watch, clearErrors, resetField } = useFormContext();

  const [commissionValueFormatted, setCommissionValueFormatted] = useState("");
  const [dueDateRuleFormatted, setDueDateRuleFormatted] = useState("");

  const thisCreditGuarantor = watch(
    `servicesProducts[${index}].creditGuarantor`
  );

  const thisDueDateRule = watch(`servicesProducts[${index}].dueDateRule`);
  const thisDueDateRuleType: IChannelDueDateType | undefined = watch(
    `servicesProducts[${index}].dueDateType`
  );

  const thisCommission = watch(`servicesProducts[${index}].commissionValue`);
  const thisCommissionType: IChannelCommissionType | undefined = watch(
    `servicesProducts[${index}].commissionType`
  );

  const getCommissionValueFormatted = useCallback(() => {
    if (thisCommission === undefined) return setCommissionValueFormatted("");

    if (thisCommissionType === "PERCENTAGE") {
      return setCommissionValueFormatted(`${thisCommission}%`);
    }
    return setCommissionValueFormatted(
      `${Number(thisCommission).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })}`
    );
  }, [thisCommission, thisCommissionType]);

  const getDueDateRuleFormatted = useCallback(() => {
    if (thisDueDateRule === undefined) return setDueDateRuleFormatted("");

    if (thisDueDateRuleType === "FIXED") {
      return setDueDateRuleFormatted(`Dia ${thisDueDateRule}`);
    }
    return setDueDateRuleFormatted(`D+ ${thisDueDateRule}`);
  }, [thisDueDateRule, thisDueDateRuleType]);

  const resetCreditGuarantorPaymentMethod = (value: boolean) => {
    if (!value) {
      resetField(`servicesProducts[${index}].paymentMethodGuarantorUuid`);
      clearErrors(`servicesProducts[${index}].paymentMethodGuarantorUuid`);
    }
  };

  useEffect(() => {
    getCommissionValueFormatted();
  }, [getCommissionValueFormatted]);

  useEffect(() => {
    getDueDateRuleFormatted();
  }, [getDueDateRuleFormatted]);

  return {
    commissionValueFormatted,
    dueDateRuleFormatted,
    thisCreditGuarantor,
    resetCreditGuarantorPaymentMethod,
  };
};
