import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";

import { DatePicker } from "../../../../../../main/components/DateTimePicker/DatePicker";
import { InputSelect } from "../../../../../../main/components/InputSelect";
import { RECORDS_API_BASE_URL } from "../../../../../../main/services/RecordService";
import { useEFD } from "./useEFD";
import { efdScheme } from "./validation/efdScheme";

type EFDFilters = {
  provider: string;
  startDate: Date;
  endDate: Date;
};

export const EFD = () => {
  const { downloadFile, isLoading, providersList } = useEFD();

  const methods = useForm<EFDFilters>({
    resolver: yupResolver(efdScheme),
  });

  const onSubmit = ({ endDate, provider, startDate }: EFDFilters) => {
    const link = `${RECORDS_API_BASE_URL}/reports/efd?document=${provider}&str_dt_ini=${new Date(
      startDate
    ).toISOString()}&str_dt_fin=${new Date(endDate).toISOString()}`;

    downloadFile(undefined, link);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Flex w="100%" justifyContent="flex-start" gap="5px" marginBlock="2rem">
          {!isLoading && (
            <>
              <FormControl
                isInvalid={!!methods.formState.errors.startDate}
                maxW="200px"
              >
                <FormLabel htmlFor="startDate">Data de Inicio</FormLabel>
                <DatePicker fieldname="startDate" />
                <FormErrorMessage>
                  {methods.formState.errors.startDate &&
                    methods.formState.errors.startDate.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!methods.formState.errors.endDate}
                maxW="200px"
              >
                <FormLabel htmlFor="endDate">Data Fim</FormLabel>
                <DatePicker fieldname="endDate" />
                <FormErrorMessage>
                  {methods.formState.errors.endDate &&
                    methods.formState.errors.endDate.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                isInvalid={!!methods.formState.errors.provider}
                maxW="500px"
              >
                <FormLabel htmlFor="provider">Prestador</FormLabel>
                <InputSelect
                  name="provider"
                  placeholder="Prestador"
                  isClearable
                  option={
                    providersList.map(({ name, deletedAt, document }) => {
                      return {
                        label: deletedAt ? `(inativo) ${name}` : name,
                        value: document,
                        deletedAt,
                      };
                    }) ?? []
                  }
                />

                <FormErrorMessage>
                  {methods.formState.errors.provider &&
                    methods.formState.errors.provider.message}
                </FormErrorMessage>
              </FormControl>
            </>
          )}

          <Button isLoading={isLoading} alignSelf="flex-end" type="submit">
            Buscar
          </Button>
        </Flex>
      </form>
    </FormProvider>
  );
};
