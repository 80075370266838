import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";

import { ClientManagement } from "../../pages/ClientManagement";

interface IClientModal {
  uuid: string;
}

export function ClientModal({ uuid }: IClientModal) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        iconSpacing={0}
        leftIcon={<BsFillArrowUpRightCircleFill />}
        colorScheme="blue"
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ClientManagement clientUUID={uuid} isReadOnly isModal />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
