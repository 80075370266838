import { Badge, Flex, ThemeTypings } from "@chakra-ui/react";

import { TSmallsm } from "../../../../../main/components/Tipography";

export interface IBadgeTariffKpi {
  label: string;
  colorScheme: ThemeTypings["colorSchemes"];
}
const BadgeTariffKpi = ({ colorScheme, label }: IBadgeTariffKpi) => {
  return (
    <Flex alignItems="center" h="24px" p="8px">
      <Badge colorScheme={colorScheme} h="16px" w="16px" borderRadius="50%" />
      <TSmallsm ml="8px" color="gray.500">
        {" "}
        {label}
      </TSmallsm>
    </Flex>
  );
};
export { BadgeTariffKpi };
