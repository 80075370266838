import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IProductsData } from "../../../../types/main/products";
import { allCategoryProducts } from "../service/ProductService";

export const useGetAllCategory = () => {
  const toast = useToast();

  const [allcategory, setAllcategory] =
    useState<Array<Omit<IProductsData, "name">>>();

  const updateProductList = useCallback(async () => {
    try {
      const response = await allCategoryProducts();
      if (response.type === "success" && response.body) {
        const categoryList = response.body.map(({ category }) => {
          return { category };
        });
        setAllcategory(categoryList);
      }
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    updateProductList();
  }, [updateProductList]);

  return { allcategory };
};
