import { ArrowDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import Lottie from "react-lottie-player";

import { getInvoiceNFe } from "../../../modules/Records/Invoice/service/InvoiceService";
import { IInvoiceNFeResponse } from "../../../types/main/invoice";
import searchingLottie from "../../common/assets/lottie/searchingLottie.json";
import { useInterstarTheme } from "../../hooks/useInterstarTheme";
import { TSmallmd, TSmallsm } from "../Tipography";

type NFeModalProps = {
  invoiceId: number | null;
  type?: "button" | "icon";
};

export const NFeModal = ({ invoiceId, type = "icon" }: NFeModalProps) => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { colors } = useInterstarTheme();

  const [nfeResponse, setNfeResponse] = useState<
    { isFetching: boolean; hasError: boolean } & IInvoiceNFeResponse
  >({
    isFetching: true,
    hasError: false,
    nfes: [],
    message: "Buscando NFe. Aguarde...",
  });

  const handleOpen = async () => {
    if (invoiceId) {
      try {
        onOpen();
        const response = await getInvoiceNFe(invoiceId);
        setNfeResponse({ ...response, isFetching: false, hasError: false });
      } catch (err: any) {
        setNfeResponse({
          isFetching: false,
          hasError: true,
          nfes: [],
          message: err?.body?.message ?? "❌ Erro ao buscar NFe",
        });
      }
    }
  };

  if (!invoiceId) return null;

  return (
    <>
      <Flex
        mt={type === "icon" ? "2" : "0"}
        flexDir="row"
        justifyContent="flex-start"
        gap="2"
        alignItems="center"
        color="#718096"
        as={Button}
        variant="unstyled"
        padding="0"
        onClick={handleOpen}
        _hover={{ color: "#444" }}
        transition="all 0.2s ease-in-out"
      >
        {type === "icon" && (
          <>
            <ArrowDownIcon />
            <TSmallmd fontWeight="medium">NFe</TSmallmd>
          </>
        )}
        {type === "button" && (
          <TSmallsm
            fontWeight="bold"
            mx="2"
            color="facebook.500"
            _hover={{ textDecoration: "underline" }}
          >
            Ver NFe
          </TSmallsm>
        )}
      </Flex>
      <Modal
        blockScrollOnMount={false}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader mb="8">NFe - Fatura {invoiceId}</ModalHeader>
          <ModalCloseButton />
          <ModalBody
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            py="4"
            zIndex={99999}
          >
            {nfeResponse.isFetching && (
              <Lottie
                loop
                animationData={searchingLottie}
                play
                style={{ width: 150, height: 100 }}
              />
            )}
            <TSmallmd fontWeight="medium" mb="1rem">
              {nfeResponse.message}
            </TSmallmd>
            {!nfeResponse.isFetching &&
              !nfeResponse.hasError &&
              nfeResponse.nfes.map((nfe) => (
                <Flex
                  key={nfe.nfeUuid}
                  flexDir="row"
                  justifyContent="space-between"
                  alignItems="center"
                  gap="1"
                  mb="1rem"
                  padding={2}
                  px={4}
                  w="100%"
                  borderRadius="md"
                  border="1px solid #e2e8f0"
                  backgroundColor={colors.gray100}
                >
                  <TSmallsm fontWeight="medium">NFe {nfe.serviceName}</TSmallsm>
                  <Button
                    as="a"
                    variant="outline"
                    href={nfe.nfeLink}
                    fontSize="sm"
                    height="fit-content"
                    p={2}
                  >
                    Baixar
                  </Button>
                </Flex>
              ))}
          </ModalBody>

          <ModalFooter>
            {!nfeResponse.isFetching && (
              <Button
                colorScheme="gray"
                mr={3}
                onClick={onClose}
                variant="ghost"
              >
                Cancelar
              </Button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
