import { IBlobError } from "../../../../../../main/hooks/useDownloadFile";

const parseError = (errorText: any) => {
  try {
    return JSON.parse(errorText)?.body?.message ?? "Erro desconhecido";
  } catch (e) {
    return errorText.toString() ?? "Erro desconhecido";
  }
};

export const handleError = async (error: IBlobError) => {
  try {
    const errorText = await error.response?.data.toString();

    return parseError(errorText);
  } catch (err) {
    return "Erro inesperado ao tentar buscar dados da Fatura";
  }
};
