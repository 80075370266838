import { Input, InputProps } from "@chakra-ui/react";
import { isDate } from "date-fns";
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import NumberFormat, {
  NumberFormatValues,
  SourceInfo,
} from "react-number-format";

import { getDateFormatted } from "../../../common/utils/dateHelper";

interface IInputMask extends InputProps {
  isReadOnly?: boolean;
  format: string;
  name: string;
  onInputChange?: (values: NumberFormatValues, reason?: SourceInfo) => void;
  mask?: string;
  handleAllowance?: (values: NumberFormatValues) => boolean;
}

export interface IRefProps {
  focus: () => void;
}

export const getTextMaskWhenDate = (date: string | undefined) => {
  if (!date) return "";

  try {
    if (typeof date === "string" && date.includes("-")) {
      const newDate = new Date(date);
      return isDate(newDate) ? getDateFormatted(newDate) : date;
    }

    if (typeof date !== "string") return getDateFormatted(date);

    return date;
  } catch (err) {
    return date.toString();
  }
};

export const TextInputMask = forwardRef(
  (
    {
      isReadOnly,
      format,
      name,
      onInputChange,
      mask = "_",
      handleAllowance,
      ...rest
    }: IInputMask,
    ref: ForwardedRef<IRefProps>
  ) => {
    const { clearErrors, control } = useFormContext();

    const inputRef = useRef<HTMLInputElement>(null);

    const handleChange = (
      inputValues: NumberFormatValues,
      reason?: SourceInfo
    ) => {
      if (onInputChange) onInputChange(inputValues, reason);
    };

    useImperativeHandle(ref, () => ({
      focus: () => {
        if (inputRef.current) inputRef.current.focus();
      },
    }));

    return (
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => (
          <Input
            isDisabled={isReadOnly}
            _disabled={{
              cursor: "default",
              color: "inherit",
              opacity: "inherit",
            }}
            onClick={() => clearErrors("document")}
            as={NumberFormat}
            format={format}
            mask={mask}
            value={getTextMaskWhenDate(value)}
            onValueChange={(
              values: NumberFormatValues,
              reason?: SourceInfo
            ) => {
              if (reason?.source === "event") {
                onChange(values.value);
                handleChange(values);
              }
            }}
            getInputRef={inputRef}
            isAllowed={handleAllowance}
            // ref={ref}
            {...rest}
          />
        )}
      />
    );
  }
);
