import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { getProductOptions } from "../../Products/service/ProductService";
import { createWebHooks, updateWebHooks } from "../services/WebHooksService";
import { ISelectOptions, IWebhooks } from "../types";

export const useWebHooksForm = ({
  webHookId,
}: {
  webHookId: string | undefined;
}) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);
  const [productsOptions, setProductsOptions] = useState<ISelectOptions[]>();

  const getProductsOptions = async () => {
    setIsLoading(true);
    const response = await getProductOptions({
      return_even_if_deleted: undefined,
    });
    const formattedProductsOptions =
      response?.body &&
      response?.body.map((product) => ({
        label: product.name,
        value: product.uuid,
      }));
    setIsLoading(false);

    setProductsOptions(formattedProductsOptions);
  };

  const onSubmit = useCallback(
    async (webHook: IWebhooks) => {
      try {
        setIsLoading(true);
        let response;

        if (webHookId) {
          response = await updateWebHooks(webHookId, webHook);
        } else {
          response = await createWebHooks(webHook);
        }

        toast({
          title: "Efetuado com sucesso.",
          description: response?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/webhooks");
      } catch (error: any) {
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    },
    [webHookId, navigate, toast]
  );

  useEffect(() => {
    getProductsOptions();
  }, []);

  return {
    onSubmit,
    isLoading,
    productsOptions,
  };
};
