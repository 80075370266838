import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";

import { IClientData } from "../../../../../../types/main/client";

type ClientPJProps = {
  isReadOnly: boolean;
};

export const ClientPJ = ({ isReadOnly }: ClientPJProps) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<IClientData>();

  return (
    <GridItem colSpan={1}>
      <FormControl isInvalid={!!errors.ie}>
        <FormLabel
          htmlFor="ie"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          IE
        </FormLabel>
        <Input
          isDisabled={isReadOnly}
          _disabled={{
            cursor: "default",
            color: "inherit",
            opacity: "inherit",
          }}
          id="ie"
          minLength={8}
          maxLength={16}
          {...register("ie")}
        />
        <FormErrorMessage>{errors.ie && errors.ie.message}</FormErrorMessage>
      </FormControl>
    </GridItem>
  );
};
