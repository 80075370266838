/* eslint-disable react/no-unstable-nested-components */
import { Select, MultiValue, MenuPlacement } from "chakra-react-select";
import { Controller, useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

type Option = {
  value: any;
  label: string;
};

type GroupOption = {
  label: string;
  options: Option[];
};

type Options = GroupOption[];

interface IMultiSelectProps {
  name: string;
  option: Options;
  placeholder?: string;
  onChange?: (newValue: MultiValue<Option>) => void;
  disabled?: boolean;
  menuPlacement?: MenuPlacement;
  menuBodyPosition?: boolean;
}

export const InputSelectGroup = ({
  name,
  option,
  placeholder = "Selecione",
  disabled,
  menuPlacement,
  menuBodyPosition,
  ...rest
}: IMultiSelectProps) => {
  const { control } = useFormContext<any>();

  const id = uuidv4();

  const optionUngrouped = option.reduce((acc, group) => {
    return [...acc, ...group.options];
  }, [] as Option[]);

  return (
    <div>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              {...rest}
              {...field}
              id={id}
              options={option}
              isMulti={false}
              instanceId={id}
              placeholder={placeholder}
              menuPortalTarget={menuBodyPosition ? document.body : undefined}
              menuPlacement={menuPlacement}
              hasStickyGroupHeaders
              chakraStyles={{
                control: (provided) => ({
                  ...provided,
                  _disabled: {
                    cursor: "not-allowed",
                    color: "inherit",
                    opacity: "inherit",
                  },
                }),
                container: () => ({
                  width: "100%",
                }),
                inputContainer: () => ({
                  width: "100%",
                }),

                dropdownIndicator: (provided) => ({
                  ...provided,
                  backgroundColor: "transparent",
                }),
              }}
              isDisabled={disabled}
              onChange={(newValue) => {
                const value = newValue as unknown as Option;
                field.onChange(value?.value);
              }}
              value={optionUngrouped.find(
                (option) => option.value === field.value
              )}
              defaultValue={optionUngrouped.find(
                (option) => option.value === field.value
              )}
            />
          );
        }}
      />
      {/* {hasError && <AlertError text={errorMessage} />} */}
    </div>
  );
};
