import { IPublicClientApplication } from "@azure/msal-browser";
import axios, { AxiosInstance } from "axios";

import { authRequest } from "../context/LoginConfig";

let token = "";

export const updateToken = () => {
  Object.keys(window.localStorage).forEach((key) => {
    if (
      key.indexOf("idtoken") !== -1 ||
      key.indexOf("IdToken") !== -1 ||
      key.indexOf("idToken") !== -1
    ) {
      token = window.localStorage.getItem(key) || "{}";
      try {
        token = JSON.parse(token).secret;
        window.localStorage.setItem("idToken", token);
      } catch (error) {
        window.localStorage.setItem("idToken", token);
      }
    }
  });
};

export const jwtRequestInterceptor = (instance: AxiosInstance) => {
  updateToken();
  instance.interceptors.request.use((request) => {
    if (!token) {
      updateToken();
    }
    if (request.headers && token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  });
};

export const jwtInterceptor = (
  api: AxiosInstance,
  instance: IPublicClientApplication
) => {
  const interceptor = api.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      console.log("Refreshing token");

      if (
        error?.response?.status === 401 ||
        error?.response?.data?.body?.status === 401
      ) {
        return instance
          .ssoSilent(authRequest)
          .then(() => {
            updateToken();
            return axios.request(error.config);
          })
          .catch((err) => {
            throw err;
          });
      }
      if (error?.response?.status === 403) {
        console.warn(`Forbidden`);
      } else if (error?.response?.status === 503) {
        return axios.request(error.config);
      }
      throw error;
    }
  );

  return interceptor;
};
