import { CloseIcon } from "@chakra-ui/icons";
import { Badge, Box, Input, InputProps, Tooltip } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuid } from "uuid";

interface IInputTag extends InputProps {
  fieldname: string;
}

export const InputTag = ({ fieldname, ...rest }: IInputTag) => {
  const {
    setValue,
    watch,
    formState: { errors },
  } = useFormContext();

  const [inputCurrentValue, setInputCurrentValue] = useState("");

  const [currentTags, setCurrentTags] = useState<string[]>([]);

  const handleOnKeyDown = (event: any, isBlur?: boolean) => {
    const updateTags = Array.from(
      new Set([...currentTags, event.target.value.toLowerCase()])
    );

    if ((isBlur || event.key === "Enter") && inputCurrentValue) {
      setCurrentTags(updateTags);
      setValue(fieldname, updateTags);
      setInputCurrentValue("");
    }
  };

  const removeBadge = (badgeIndex: number) => {
    if (rest.isReadOnly || rest.isDisabled) return;

    const updateTags = currentTags.filter((_, index) => index !== badgeIndex);
    setCurrentTags(updateTags);
    setValue(fieldname, updateTags);
  };

  const watchFieldname = watch(fieldname);

  const hasError = (index: number) => {
    const error = !!errors ? (errors[fieldname] as any) : [];

    if (Array.isArray(error) && error.length > 0) {
      return !!error[index]?.message;
    }

    return false;
  };

  useEffect(() => {
    if (watchFieldname) setCurrentTags(watchFieldname);
  }, [watchFieldname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        border: "",
        outlineOffset: "2px",
        height: "100%",
      }}
    >
      <Tooltip
        hasArrow
        bg="blue.600"
        placement="top-end"
        label="Pressione Enter para adicionar"
      >
        <Input
          {...rest}
          autoComplete="off"
          placeholder="Pressione Enter para adicionar"
          value={inputCurrentValue}
          onChange={(event) => setInputCurrentValue(event.target.value)}
          onBlur={(event) => handleOnKeyDown(event, true)}
          onKeyDownCapture={handleOnKeyDown}
          onKeyDown={(e) => {
            if (e.key === "Enter") e.preventDefault();
          }}
        />
      </Tooltip>
      <Box width="100%" display="flex" flexDir="row" gap="5px" flexWrap="wrap">
        {!!currentTags &&
          currentTags.map((field: string, index: number) => {
            return (
              <Badge
                colorScheme={`${hasError(index) ? "red" : "telegram"}`}
                key={uuid()}
                height="20px"
                mt="5px"
                borderRadius="5px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                gap="5px"
                textTransform="none"
              >
                {field}
                <CloseIcon
                  cursor={rest.isReadOnly ? "not-allowed" : "pointer"}
                  fontSize="10px"
                  onClick={() => removeBadge(index)}
                />
              </Badge>
            );
          })}
      </Box>
    </div>
  );
};
