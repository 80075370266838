import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { FilePond } from "react-filepond";

import { UploadFileType } from ".";
import {
  sendAutomaticDebitFiles,
  sendCNABFiles,
  SendFileReturn,
} from "../../../service/processingRemittancesService";

type UseUploadFilePondProps = {
  setFiles: React.Dispatch<React.SetStateAction<UploadFileType[]>>;
  files: UploadFileType[];
  filepond: React.RefObject<FilePond>;
  onUploadFiles?: () => Promise<void>;
};

export const useUploadFilePond = ({
  setFiles,
  files,
  onUploadFiles,
  filepond,
}: UseUploadFilePondProps) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);

  const handleMultipleErrorMessages = (response: SendFileReturn) => {
    const { message } = response;
    if (Array.isArray(message)) {
      message.forEach((error) => {
        toast({
          title: error,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    }
  };

  const handleSendingResponse = (
    type: "CNAB" | "AUTOMATIC_DEBIT",
    response: SendFileReturn | undefined
  ) => {
    let shouldRemoveFiles = true;

    if (response?.type === "success") {
      toast({
        title: response.message,
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    }

    if (response?.type === "error") {
      if (response.allFailed) shouldRemoveFiles = false;
      handleMultipleErrorMessages(response);
    }

    if (shouldRemoveFiles) {
      setFiles((oldFiles) => oldFiles.filter((file) => file.type !== type));
      filepond.current?.removeFiles();
    }
  };

  const sendCNAB = async (cnabFiles: UploadFileType[]) => {
    try {
      const response = await sendCNABFiles(cnabFiles);
      handleSendingResponse("CNAB", response.body);
    } catch (err: any) {
      toast({
        title: err.message ?? "Erro ao enviar arquivos",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      throw err;
    }
  };

  const sendAutomaticDebit = async (febrabanFiles: UploadFileType[]) => {
    try {
      const response = await sendAutomaticDebitFiles(febrabanFiles);
      handleSendingResponse("AUTOMATIC_DEBIT", response.body);
    } catch (err: any) {
      toast({
        title: err.message ?? "Erro ao enviar arquivos",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      throw err;
    }
  };

  const handleSendAllFiles = async () => {
    try {
      setLoading(true);
      const cnabFiles = files.filter(({ type }) => type === "CNAB");
      const febrabanFiles = files.filter(
        ({ type }) => type === "AUTOMATIC_DEBIT"
      );

      if (cnabFiles.length > 0) {
        await sendCNAB(cnabFiles);
      }

      if (febrabanFiles.length > 0) {
        await sendAutomaticDebit(febrabanFiles);
      }

      if (onUploadFiles) onUploadFiles();
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  return {
    handleSendAllFiles,
    loading,
  };
};
