import { getRemittanceSchedule } from "../../service/remittanceService";

export const loadSchedule = async () => {
  try {
    const response = await getRemittanceSchedule();
    return response.body ?? null;
  } catch (err) {
    return null;
  }
};
