import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";

import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IRemittanceJobs,
  IRemittanceJobsData,
} from "../../../../types/main/jobs";
import { IPaginateResponse } from "../../../../types/main/pagination";
import {
  IFilterRemittancesSimulation,
  IRecalculateRemittanceRequest,
  IRecalculateRemittanceResponse,
  IRemittanceData,
  IRemittanceListView,
  IRemittanceSimulated,
} from "../../../../types/main/remittances";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/remittances";

export const simulateRemittances = async (
  filters: IFilterRemittancesSimulation | null
) => {
  try {
    const payload = filters ?? null;

    const { data }: AxiosResponse<IRemittanceSimulated, IRemittanceSimulated> =
      await recordApi.post(`${MODULE_BASE_URL}/simulate`, payload);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const createRemittances = async (
  remittancesPayload: IRemittanceData[]
) => {
  try {
    const { data }: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(`${MODULE_BASE_URL}/save`, remittancesPayload);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

type RemittanceListParams = {
  pagination?: PaginationState;
  columnFilters?: TableFilteredParams;
  sorting?: TableSortingParams;
};

export const getAllRemittances = async ({
  columnFilters,
  pagination,
  sorting,
}: RemittanceListParams) => {
  try {
    const page = pagination?.pageIndex;

    const {
      data,
    }: AxiosResponse<IResponse<IPaginateResponse<IRemittanceListView>>> =
      await recordApi.get(`${MODULE_BASE_URL}/list`, {
        params: {
          page: page === undefined ? 1 : page + 1,
          take: pagination?.pageSize ?? 10,
          ...columnFilters,
          ...sorting,
        },
      });
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const recalculateRemittanceValue = async (
  body: IRecalculateRemittanceRequest[]
) => {
  try {
    const { data }: AxiosResponse<IRecalculateRemittanceResponse> =
      await recordApi.post(`${MODULE_BASE_URL}/recalculate`, body);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const validateDueDate = async (dueDate: string | Date) => {
  try {
    const { data }: AxiosResponse = await recordApi.post(
      `${MODULE_BASE_URL}/validate/dueDate`,
      { dueDate }
    );
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getRemittanceSchedule = async () => {
  try {
    const { data }: AxiosResponse<IResponse<IRemittanceJobs>> =
      await recordApi.get(`${MODULE_BASE_URL}/schedule`);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const updateRemittanceSchedule = async (
  payload: IRemittanceJobsData
) => {
  try {
    const { data }: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.put(`${MODULE_BASE_URL}/schedule`, payload);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const cancelRemittanceByIdentifier = async (
  identifier: string
): Promise<IResponse<ISuccessfulResponseMessage>> => {
  try {
    const { data }: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(
        `${MODULE_BASE_URL}/cancelRemittanceByIdentifier/${identifier}`
      );
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const countPreSavedRemittances = async () => {
  try {
    const { data }: AxiosResponse<IResponse<number>> = await recordApi.get(
      `${MODULE_BASE_URL}/pre-saved-count`
    );
    return data.body ?? 0;
  } catch (err: any) {
    return 0;
  }
};

export const getRemittanceCSV = async (filters?: TableFilteredParams) => {
  try {
    const { data } = await recordApi.get(`getRemittancesCSV`, {
      params: {
        ...(filters ?? {}),
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};
