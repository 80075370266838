import { isBefore } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { customToast } from "../../../../main/common/utils/customToast";
import { getDateInEndOfDay } from "../../../../main/common/utils/dateHelper";
import { ITariffDataUpdate } from "../../../../types/main/tariff";
import { UpdatePaymentMethod } from "../../Tariff/service/TariffService";

export const UseDueDateUpdate = (
  onClose: () => void,
  refreshTable?: () => Promise<void>,
  redirectOnSave?: boolean
) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const onSubmitToUpdate = async (data: ITariffDataUpdate) => {
    const {
      billerUuid,
      contractUuid,
      dueDate,
      endDate,
      id,
      startDate,
      tariffCarData,
      idProject,
      invoiceId,
      projectDescription,
      tariffIdentifier,
    } = data;
    if (!dueDate) {
      customToast("error", "Data de vencimento não informada");
      return;
    }

    const dueDateEndDay = getDateInEndOfDay(dueDate);

    if (isBefore(dueDateEndDay, new Date())) {
      customToast(
        "error",
        "A data de vencimento deve ser superior a data atual"
      );
    } else {
      try {
        setIsLoading(true);

        const response = await UpdatePaymentMethod({
          billerUuid,
          contractUuid,
          dueDate,
          endDate,
          id,
          startDate,
          tariffCarData,
          idProject,
          invoiceId,
          projectDescription,
          tariffIdentifier,
        });

        customToast("success", response.body?.message);
        onClose();
        await refreshTable?.();
        if (redirectOnSave) navigate("/carslist");
      } catch (error: any) {
        customToast(
          "error",
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION
        );
      }
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    onSubmitToUpdate,
  };
};
