type IMap = {
  [key: string]: {
    description: string;
    color: string;
  };
};

export const REMITTANCE_STATUS_LABEL: IMap = {
  WAITING: { description: "Aguardando envio/processamento", color: "orange" },
  PROCESSING: { description: "Processando", color: "yellow" },
  PROCESSED: { description: "Enviado/Processado", color: "green" },
  MANUAL_PROCESSED: {
    description: "Enviado Manualmente",
    color: "messenger",
  },
  PARTIAL_PROCESSED: {
    description: "Processado Parcialmente",
    color: "teal",
  },
  ERROR: {
    description: "Erro",
    color: "red",
  },
};
