export const defaultServiceProduct = {
  dueDateType: undefined,
  dueDateRule: undefined,
  commissionType: undefined,
  commissionOrder: undefined,
  commissionValue: undefined,
  creditGuarantor: undefined,
  service: undefined,
  product: undefined,
  paymentMethod: undefined,
  paymentMethodGuarantor: undefined,
};

export const serviceProductsHeader = [
  {
    name: "SERVIÇO",
  },
  {
    name: "PRODUTO",
  },
  {
    name: "MÉTODO DE PAGAMENTO",
  },
  {
    name: "DATA DE VENCIMENTO",
  },
  {
    name: "COMISSIONAMENTO",
  },
  {
    name: "GARANTIDORA DE CRÉDITO",
  },
  {
    name: "MÉTODO DE PAGAMENTO (GARANTIDORA)",
  },
];
