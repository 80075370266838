import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

const TSmall6xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="60px" lineHeight="90px" {...rest}>
      {children}
    </Text>
  );
};

const TSmall5xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="48px" lineHeight="72px" {...rest}>
      {children}
    </Text>
  );
};

const TSmall4xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="36px" lineHeight="54px" {...rest}>
      {children}
    </Text>
  );
};

const TSmall3xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="30px" lineHeight="45px" {...rest}>
      {children}
    </Text>
  );
};

const TSmall2xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="24px" lineHeight="36px" {...rest}>
      {children}
    </Text>
  );
};

const TSmallxl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="20px" lineHeight="30px" {...rest}>
      {children}
    </Text>
  );
};

const TSmalllg: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="18px" lineHeight="27qpx" {...rest}>
      {children}
    </Text>
  );
};

const TSmallmd: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="16px" lineHeight="24px" {...rest}>
      {children}
    </Text>
  );
};

const TSmallsm: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="14px" lineHeight="21px" {...rest}>
      {children}
    </Text>
  );
};

const TSmallxs: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="400" fontSize="12px" lineHeight="18px" {...rest}>
      {children}
    </Text>
  );
};

export {
  TSmall6xl,
  TSmall5xl,
  TSmall4xl,
  TSmall3xl,
  TSmall2xl,
  TSmallxl,
  TSmalllg,
  TSmallmd,
  TSmallsm,
  TSmallxs,
};
