import { Flex } from "@chakra-ui/react";
import { AsyncSelect } from "chakra-react-select";
import { FC, useState } from "react";
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
} from "react-hook-form";
import { MultiValue, SingleValue } from "react-select";
import { v4 as uuidv4 } from "uuid";

/**
 * Components
 * ref: https://stackoverflow.com/questions/66597232/properly-use-nooptionsmessage-in-react-select-to-change-the-no-options-text
 */

type Option = {
  value: any;
  label: string;
};

type Options = Option[];

interface ISelectAsyncProps {
  name: string;
  option: Options;
  placeholder?: string;
  onChange?: (newValue: MultiValue<Option> | SingleValue<Option>) => void;
  defaultValue?: Options;
  disabled?: boolean;
  fetchOptions: (inputValue: any) => Promise<Options>;
  isMulti?: boolean;
}

const InputSelectAsync: FC<ISelectAsyncProps> = ({
  name,
  option,
  placeholder,
  defaultValue = "",
  disabled,
  fetchOptions,
  onChange,
  isMulti = false,
  ...rest
}) => {
  const { control } = useFormContext<any>();

  const [dynamicOption, setDynamicOption] = useState<Options>(option);

  const promiseOptions = async (inputValue: any): Promise<Options> => {
    const newOptions = await fetchOptions(inputValue);

    return newOptions ?? [];
  };

  const handleOnChange = (
    newValue: Options | Option,
    field: ControllerRenderProps<any, string>
  ) => {
    if (isMulti) {
      const newOptions = newValue as Options;

      field.onChange(newOptions?.map((item: Option) => item.value));

      newOptions.forEach((item) => {
        if (!dynamicOption.some((option) => option.value === item.value))
          setDynamicOption((old) => [...old, item]);
      });
    } else {
      const newOption = newValue as Option;

      field.onChange(newOption?.value);

      if (!dynamicOption.some((option) => option.value === newOption.value))
        setDynamicOption((old) => [...old, newOption]);
    }

    onChange?.(newValue);
  };

  const id = uuidv4();

  return (
    <Flex w="100%">
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <AsyncSelect
            {...rest}
            {...field}
            id={id}
            isMulti={isMulti}
            loadOptions={promiseOptions}
            cacheOptions
            options={dynamicOption}
            menuPortalTarget={document.body}
            chakraStyles={{
              container: () => ({
                width: "100%",
              }),
              inputContainer: () => ({
                width: "100%",
              }),
              dropdownIndicator: (provided) => ({
                ...provided,
                backgroundColor: "transparent",
              }),
            }}
            defaultOptions={option}
            instanceId={id}
            placeholder={placeholder}
            isDisabled={disabled}
            onChange={(newValue) => {
              handleOnChange(newValue as Options | Option, field);
            }}
            value={dynamicOption.filter((item) =>
              field.value.includes(item.value)
            )}
          />
        )}
        defaultValue={defaultValue}
      />
      {/* {hasError && <AlertError text={errorMessage} />} */}
    </Flex>
  );
};

export { InputSelectAsync };
