import {
  CircularProgress,
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import {
  getAddressInfoFromCep,
  getAllStates,
} from "../../services/BrasilAPIService";
import { getCitiesByUF } from "../../services/IbgeAPIService";
import { InputMask } from "../InputMask";
import { InputSelect } from "../InputSelect";
import { HSmallxl } from "../Tipography/HeadingSmall";

interface IAdressProps {
  isDisabled?: boolean;
  uuidToEdit?: string;
}

export const AddressForm = ({ isDisabled, uuidToEdit }: IAdressProps) => {
  const {
    register,
    setValue,
    trigger,
    watch,
    formState: { errors },
  } = useFormContext();

  const watchState = watch("state");
  const [loadingState, setLoadingState] = useState(false);
  const [ufList, setUfList] =
    useState<Array<{ label: string; value: string }>>();
  const [citiesList, setCitiesList] = useState<
    Array<{ label: string; value: string }>
  >([]);
  const [firstLoad, setFirstLoad] = useState(false);
  const [isLoadingCity, setIsLoadingCity] = useState(false);
  const [isLoadingDistrict, setIsLoadingDistrict] = useState(false);
  const [isLoadingAddress, setIisLoadingAddress] = useState(false);

  // LOAD STATE ONLY ONE TIME
  useEffect(() => {
    async function handle() {
      if (!firstLoad) {
        setFirstLoad(true);
        const allStates = await getAllStates();

        setUfList(
          allStates
            .map((stateInfo) => ({
              label: stateInfo.sigla,
              value: stateInfo.sigla,
            }))
            .sort()
        );
        setLoadingState(true);
      }
    }
    handle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCities = useCallback(
    async (uf: string) => {
      setIsLoadingCity(true);
      const cities = await getCitiesByUF(uf);
      setCitiesList(
        cities.map((cityInfo) => ({
          label: cityInfo.nome,
          value: cityInfo.nome,
        }))
      );
      setIsLoadingCity(false);
      trigger();
    },
    [trigger]
  );

  const handleAddress = useCallback(
    async (cep) => {
      setIsLoadingCity(true);
      setIsLoadingDistrict(true);
      setIisLoadingAddress(true);
      const adress = await getAddressInfoFromCep(cep);
      if (adress && adress.errors) return;
      if (!adress) {
        setIsLoadingCity(false);
        setIsLoadingDistrict(false);
        setIisLoadingAddress(false);
        return;
      }
      await loadCities(adress.state);
      setValue("state", adress.state, { shouldDirty: true });
      setValue("city", adress.city, { shouldDirty: true });
      setValue("district", adress.neighborhood, { shouldDirty: true });
      setValue("address", adress.street, { shouldDirty: true });
      setIsLoadingCity(false);
      setIsLoadingDistrict(false);
      setIisLoadingAddress(false);

      trigger();
    },
    [loadCities, setValue, trigger]
  );

  useEffect(() => {
    if (uuidToEdit && watchState && loadingState) {
      setLoadingState(false);
      loadCities(watchState);
    }
  }, [loadCities, loadingState, uuidToEdit, watchState]);

  return (
    <>
      <GridItem mt="40px" colSpan={12} textAlign="left">
        <HSmallxl>Endereço</HSmallxl>
      </GridItem>

      <GridItem colSpan={2}>
        <FormControl isInvalid={!!errors.cep}>
          <FormLabel htmlFor="cep">CEP</FormLabel>
          <InputMask
            fieldname="cep"
            format="#####-###"
            isReadOnly={isDisabled}
            onChange={(event) => {
              if (
                /^((?!_).)*$/g.test(event.target.value) &&
                event.target.value
              ) {
                handleAddress(event.target.value);
              }
            }}
          />
          <FormErrorMessage>
            {errors.cep && errors.cep.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem colSpan={2}>
        <FormControl isInvalid={!!errors.state}>
          <FormLabel htmlFor="state">Estado</FormLabel>
          <InputSelect
            disabled={isDisabled}
            name="state"
            placeholder="UF"
            isClearable
            option={ufList ?? []}
            onSelect={(uf) => loadCities(uf ?? "")}
          />

          <FormErrorMessage>
            {errors.state && errors.state.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem colSpan={4}>
        <FormControl isInvalid={!!errors.city}>
          <FormLabel htmlFor="city">Cidade</FormLabel>
          <InputSelect
            disabled={isDisabled}
            name="city"
            placeholder="Cidade"
            isClearable
            isLoading={isLoadingCity}
            option={citiesList ?? []}
          />

          <FormErrorMessage>
            {errors.city && errors.city.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem colSpan={4}>
        <FormControl isInvalid={!!errors.district}>
          <FormLabel htmlFor="district">Bairro</FormLabel>
          <InputGroup>
            <Input
              isDisabled={isDisabled}
              _disabled={{
                cursor: "not-allowed",
                color: "inherit",
                opacity: "inherit",
              }}
              id="district"
              {...register("district", { onChange: () => trigger() })}
            />
            <InputRightElement
              zIndex={1}
              children={
                isLoadingDistrict && (
                  <CircularProgress
                    isIndeterminate
                    color="gray.300"
                    size="15px"
                  />
                )
              }
            />
          </InputGroup>
          <FormErrorMessage>
            {errors.district && errors.district.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem colSpan={6}>
        <FormControl isInvalid={!!errors.address}>
          <FormLabel htmlFor="address">Logradouro</FormLabel>
          <InputGroup>
            <Input
              isDisabled={isDisabled}
              _disabled={{
                cursor: "not-allowed",
                color: "inherit",
                opacity: "inherit",
              }}
              id="address"
              type="text"
              {...register("address", {
                onChange: () => trigger(),
                minLength: {
                  value: 10,
                  message: "Minimun length should be 10",
                },
              })}
            />
            <InputRightElement
              zIndex={1}
              children={
                isLoadingAddress && (
                  <CircularProgress
                    isIndeterminate
                    color="gray.300"
                    size="15px"
                  />
                )
              }
            />
          </InputGroup>

          <FormErrorMessage>
            {errors.address && errors.address.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>

      <GridItem colSpan={2}>
        <FormControl isInvalid={!!errors.addressNumber}>
          <FormLabel htmlFor="addressNumber">Número</FormLabel>
          <InputGroup>
            <Input
              isDisabled={isDisabled}
              _disabled={{
                cursor: "not-allowed",
                color: "inherit",
                opacity: "inherit",
              }}
              id="addressNumber"
              type="text"
              {...register("addressNumber", {
                onChange: () => trigger(),
                maxLength: {
                  value: 10,
                  message: "Minimun length should be 10",
                },
              })}
            />
            <InputRightElement
              zIndex={1}
              children={
                isLoadingAddress && (
                  <CircularProgress
                    isIndeterminate
                    color="gray.300"
                    size="15px"
                  />
                )
              }
            />
          </InputGroup>

          <FormErrorMessage>
            {errors.addressNumber && errors.addressNumber.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={4}>
        <FormControl isInvalid={!!errors.additionalInformation}>
          <FormLabel htmlFor="additionalInformation">Complemento</FormLabel>
          <Input
            isDisabled={isDisabled}
            _disabled={{
              cursor: "not-allowed",
              color: "inherit",
              opacity: "inherit",
            }}
            id="additionalInformation"
            type="text"
            {...register("additionalInformation", {
              onChange: () => trigger(),
            })}
          />
          <FormErrorMessage>
            {errors.additionalInformation &&
              errors.additionalInformation.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>
    </>
  );
};
