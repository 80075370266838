import { useAccount, useMsal } from "@azure/msal-react";
import { Alert, AlertIcon, Box, Flex, Grid } from "@chakra-ui/react";
import { useEffect } from "react";

import { Logo } from "../components";
import { Backdrop } from "../components/Backdrop";
import { HLarge2xl, TSmallmd } from "../components/Tipography";
import { useAuth } from "../hooks/useAuth";
import { useInterstarTheme } from "../hooks/useInterstarTheme";

export const Refresh = () => {
  const { colors } = useInterstarTheme();
  const { accounts } = useMsal();

  const accountSignedIn = useAccount(accounts[0] || {});

  const { currentUuid, handleLogin } = useAuth();

  useEffect(() => {
    if (accountSignedIn) handleLogin();
  }, [currentUuid, handleLogin, accountSignedIn]);

  return (
    <Grid gridTemplateColumns="40% 1fr" h="100vh">
      <Flex
        bgColor={colors.blueInterstar}
        justifyContent="center"
        alignItems="center"
      >
        <Logo color="white" width="200px" height="300px" />
      </Flex>
      <Flex
        flexDir="column"
        justifyContent="space-evenly"
        alignItems="center"
        p="0 2rem"
      >
        <Box w="50%">
          <HLarge2xl>Sessão expirada</HLarge2xl>
        </Box>
        <Box w="50%">
          <Alert status="warning" borderRadius="10px" mt="10px">
            <AlertIcon />
            Aguarde enquanto a sessão é atualizada.
          </Alert>
        </Box>
        <Flex flexDir="column" alignItems="center" gap="1rem">
          <TSmallmd>INTERSTAR</TSmallmd>
        </Flex>
      </Flex>
      <Backdrop />
    </Grid>
  );
};
