import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import { IProcessingRemittanceView } from "../../../../types/main/processingRemittance";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import { UploadFileType } from "../components/Processings/FileUpload";

const MODULE_BASE_URL = "/processingremittances";

export const getProcessingRemittances = async () => {
  try {
    const { data }: AxiosResponse<IResponse<IProcessingRemittanceView[]>> =
      await recordApi.get(`${MODULE_BASE_URL}/processing/list`);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export type SendFileReturn =
  | {
      type: "error";
      allFailed: boolean;
      message: string[];
    }
  | {
      type: "success";
      message: string;
    };

export const sendCNABFiles = async (files: UploadFileType[]) => {
  try {
    const formData = new FormData();
    files.forEach(({ file: filePond }) => {
      formData.append("cnab", filePond.file as Blob);
    });

    const { data }: AxiosResponse<IResponse<SendFileReturn>> =
      await recordApi.post(`${MODULE_BASE_URL}/cnab/receive`, formData);

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const confirmManualSending = async (id: number) => {
  try {
    const { data }: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(`${MODULE_BASE_URL}/processing/confirmsending`, {
        id,
      });

    return data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const sendAutomaticDebitFiles = async (files: UploadFileType[]) => {
  try {
    const formData = new FormData();
    files.forEach(({ file: filePond }) => {
      formData.append("automaticdebit", filePond.file as Blob);
    });

    const { data }: AxiosResponse<IResponse<SendFileReturn>> =
      await recordApi.post(
        `${MODULE_BASE_URL}/automaticdebit/receive`,
        formData
      );

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
