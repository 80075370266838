import { Flex } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ProductForm } from "../components/ProductForm";

interface IProductForm {
  isReadOnly?: boolean;
}

export const ProductManagement: React.FC<IProductForm> = ({ isReadOnly }) => {
  const { uuid } = useParams();

  const isEditing = !!uuid;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Produtos", href: "/productList" },
    {
      description: isReadOnly
        ? "Visualizar produto"
        : uuid
        ? "Editar produto"
        : "Cadastrar produto",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="64px" color="gray.600">
          {isReadOnly
            ? "Visualizar Produto"
            : isEditing
            ? "Editar Produto"
            : "Cadastrar Produto"}
        </HLarge2xl>
      </Flex>
      <ProductForm productUUID={uuid} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
