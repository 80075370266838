import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IChannelEmailConfigWithInheritanceDetail,
  IManageEmailConfig,
} from "../../../../types/main/channelEmailConfig";
import { IEmailObjectiveOptions } from "../../../../types/main/emailObjective";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/channelEmailConfig";
const EMAIL_OBJECTIVES_BASE_URL = "/emailObjective";

export const getEmailObjectiveOptions = async () => {
  try {
    const { data }: AxiosResponse<IResponse<Array<IEmailObjectiveOptions>>> =
      await recordApi.get(`${EMAIL_OBJECTIVES_BASE_URL}/options`);

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};

export const manageEmailConfig = async (
  emailConfigData: IManageEmailConfig,
  channelId: string
) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.put(`${MODULE_BASE_URL}/${channelId}`, emailConfigData);

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getChannelEmailConfig = async (id: string) => {
  try {
    const {
      data,
    }: AxiosResponse<IResponse<IChannelEmailConfigWithInheritanceDetail>> =
      await recordApi.get(`${MODULE_BASE_URL}/${id}`);

    return data.body ?? null;
  } catch (error: any) {
    throw error?.response?.data;
  }
};
