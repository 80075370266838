import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  GridItem,
  Input,
  Grid,
  ButtonGroup,
  FormErrorMessage,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useRef, useMemo, useEffect } from "react";
import { FieldError, FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUTH_MINUS_SIGN } from "../../../../../main/common/constants/defaultValidations";
import { customToast } from "../../../../../main/common/utils/customToast";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { DatePicker } from "../../../../../main/components/DateTimePicker/DatePicker";
import { InputSelect } from "../../../../../main/components/InputSelect";
import { InputSelectAsync } from "../../../../../main/components/InputSelectAsync";
import {
  TableForm,
  ITableFormRefProps,
} from "../../../../../main/components/TableForm";
import { TLargexl } from "../../../../../main/components/Tipography";
import { ITariffData } from "../../../../../types/main/tariff";
import { ITariffCarData } from "../../../../../types/main/tariffCar";
import { getContractOptions } from "../../../Contract/service/ContractService";
import { useGetAllBillers } from "../../hooks/useGetAllBillers";
import { useGetAllContracts } from "../../hooks/useGetAllContracts";
import { useTariff } from "../../hooks/useTariff";
import { useTariffForm } from "../../hooks/useTariffForm";
import { tariffSchema } from "../../validation/tariffSchema";
import { ServiceRow } from "../ServiceRow";

export const TariffForm = ({ isReadOnly }: { isReadOnly?: boolean }) => {
  const { allContractsState } = useGetAllContracts();
  const { servicesList } = useTariffForm();
  const { allBillerState } = useGetAllBillers();
  const { isLoading, onSubmitToCreate } = useTariff();

  const tableForm = useRef<ITableFormRefProps>(null);

  const methods = useForm<ITariffData>({
    resolver: yupResolver(tariffSchema),
  });

  const watchContractUuid = methods.watch("contractUuid");

  const productFromContract =
    allContractsState && watchContractUuid
      ? allContractsState.find(({ uuid }) => uuid === watchContractUuid)
          ?.productName
      : "";

  const tariffHeader = useMemo(
    () => [
      {
        name: "SERVIÇO",
      },
      {
        name: "VALOR",
      },
    ],
    []
  );

  const defaultTarifCar = useMemo<Partial<ITariffCarData>>(
    () => ({
      serviceUuid: undefined,
      tariffUuid: undefined,
      value: undefined,
    }),
    []
  );

  useEffect(() => {
    if (methods.formState.errors) {
      if (
        (methods.formState.errors.tariffCarData as unknown as FieldError)
          ?.type === "tariffCarDataRequired"
      ) {
        customToast(
          "error",
          "É necessário inserir ao menos um contas a receber"
        );
      }
    }
  }, [methods.formState.errors]);

  return (
    <FormProvider {...methods}>
      <Flex
        as="form"
        onSubmit={methods.handleSubmit(onSubmitToCreate)}
        h="100%"
        flexDirection="column"
        justifyContent="space-between"
        onKeyDown={(e) => {
          e.key === "Enter" && e.preventDefault();
        }}
      >
        <Grid templateRows="(4, 1fr)" templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!methods.formState.errors.dueDate}>
              <FormLabel htmlFor="dueDate">Data de vencimento</FormLabel>
              <DatePicker fieldname="dueDate" isDisabled={isReadOnly} />
              <FormErrorMessage>
                {methods.formState.errors.dueDate &&
                  methods.formState.errors.dueDate.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormControl isInvalid={!!methods.formState.errors.contractUuid}>
              <FormLabel htmlFor="contractUuid">Contrato</FormLabel>
              {allContractsState && (
                <InputSelectAsync
                  fetchOptions={getContractOptions}
                  disabled={isReadOnly}
                  name="contractUuid"
                  placeholder="Contrato"
                  isMulti={false}
                  option={allContractsState ?? []}
                />
              )}

              <FormErrorMessage>
                {methods.formState.errors.contractUuid &&
                  methods.formState.errors.contractUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={2}>
            <FormLabel htmlFor="product">Produto</FormLabel>
            <Input
              id="product"
              isDisabled
              placeholder="Produto"
              value={productFromContract}
              _disabled={{ opacity: 1, cursor: "not-allowed" }}
            />
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors.billerUuid}>
              <FormLabel htmlFor="billerUuid">Tarifador</FormLabel>

              <InputSelect
                disabled={isReadOnly}
                name="billerUuid"
                placeholder="Tarifador"
                isClearable
                option={
                  allBillerState?.map(({ uuid, name }) => {
                    return { label: name, value: uuid };
                  }) ?? []
                }
              />

              <FormErrorMessage>
                {methods.formState.errors.billerUuid &&
                  methods.formState.errors.billerUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl
              isInvalid={!!methods.formState.errors.tariffIdentifier}
            >
              <FormLabel htmlFor="tariffIdentifier">
                Identificador da tarifa
              </FormLabel>
              <Input
                id="tariffIdentifier"
                placeholder="Texto"
                {...(methods.register("tariffIdentifier"),
                {
                  onChange: (event: any) => {
                    methods.setValue(
                      "tariffIdentifier",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUTH_MINUS_SIGN,
                        ""
                      )
                    );
                  },
                  value: methods.watch("tariffIdentifier"),
                })}
                isReadOnly={isReadOnly}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.tariffIdentifier &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors.startDate}>
              <FormLabel htmlFor="startDate">
                Início do período de vigência
              </FormLabel>
              <DatePicker fieldname="startDate" isDisabled={isReadOnly} />
              <FormErrorMessage>
                {methods.formState.errors.startDate &&
                  methods.formState.errors.startDate.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={3}>
            <FormControl isInvalid={!!methods.formState.errors.endDate}>
              <FormLabel htmlFor="endDate">
                Fim do período de vigência
              </FormLabel>
              <DatePicker fieldname="endDate" isDisabled={isReadOnly} />
              <FormErrorMessage>
                {methods.formState.errors.endDate &&
                  methods.formState.errors.endDate.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <TLargexl alignSelf="flex-start">Contas a receber</TLargexl>

        <Grid templateRows="(1, 1fr)" templateColumns="1fr" gap={4}>
          <GridItem colSpan={1}>
            <TableForm
              ref={tableForm}
              tableHeader={tariffHeader}
              rowReferenceName="tariffCarData"
              canDeleteRow={!isReadOnly}
              canAddRow={!isReadOnly}
              defaultNewRow={defaultTarifCar}
              renderProp={(index: number) => (
                <ServiceRow
                  isReadOnly={isReadOnly}
                  index={index}
                  servicesList={servicesList}
                />
              )}
            />
          </GridItem>
        </Grid>

        <TLargexl alignSelf="flex-start">Projeto (opcional)</TLargexl>

        <Grid templateRows="(1, 1fr)" templateColumns="repeat(12, 1fr)" gap={4}>
          <GridItem colSpan={6}>
            <FormControl isInvalid={!!methods.formState.errors.idProject}>
              <FormLabel htmlFor="idProject">ID do projeto</FormLabel>
              <Input
                id="idProject"
                placeholder="Texto"
                {...(methods.register("idProject"),
                {
                  onChange: (event: any) => {
                    methods.setValue(
                      "idProject",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUTH_MINUS_SIGN,
                        ""
                      )
                    );
                  },
                  value: methods.watch("idProject"),
                })}
                isReadOnly={isReadOnly}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.idProject &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={6}>
            <FormControl
              isInvalid={!!methods.formState.errors.projectDescription}
            >
              <FormLabel htmlFor="projectDescription">Descrição</FormLabel>
              <Input
                id="projectDescription"
                placeholder="Texto"
                {...(methods.register("projectDescription"),
                {
                  onChange: (event: any) => {
                    methods.setValue(
                      "projectDescription",
                      event.target.value.replaceAll(
                        DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUTH_MINUS_SIGN,
                        ""
                      )
                    );
                  },
                  value: methods.watch("projectDescription"),
                })}
                isReadOnly={isReadOnly}
                autoComplete="off"
              />
              <FormErrorMessage>
                {methods.formState.errors.projectDescription &&
                  "Esse campo é obrigatório"}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <Flex mb="50px" w="100%" justify="flex-end">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/tarifflist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={methods.formState.isSubmitting}
                ml="32px"
              >
                Cadastrar tarifa
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </Flex>
      {isLoading && <Backdrop />}
      {/* <ToastContainer /> */}
    </FormProvider>
  );
};
