import { PaginationState } from "@tanstack/react-table";
import { AxiosResponse } from "axios";
import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import { IInvoiceTariffUpdate } from "../../../../types/main/invoice";
import { IPaginateResponse } from "../../../../types/main/pagination";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import {
  ITariffData,
  ITariffDataUpdate,
  ITariffWithInvoice,
  ITariffWithPaymentMethod,
} from "../../../../types/main/tariff";
import { ITariffCarWithCalculate } from "../../../../types/main/tariffCar";

interface IInvoiceFileResponse {
  base64: string;
  blobUrl: string;
}

const MODULE_BASE_URL = "/tariff";

type TariffsListParams = {
  pagination?: PaginationState;
  filters?: TableFilteredParams;
  sorting?: TableSortingParams;
};

export const allTariff = async ({
  filters,
  pagination,
  sorting,
}: TariffsListParams): Promise<
  IResponse<IPaginateResponse<ITariffWithPaymentMethod>>
> => {
  try {
    const page = pagination?.pageIndex;

    const response: AxiosResponse<
      IResponse<IPaginateResponse<ITariffWithPaymentMethod>>
    > = await recordApi.get(MODULE_BASE_URL, {
      params: {
        page: page === undefined ? 1 : page + 1,
        take: pagination?.pageSize ?? 10,
        ...filters,
        ...sorting,
      },
    });
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const calculateTotalValues = async (
  filters?: TableFilteredParams | undefined
): Promise<{
  totalValue: string;
  totalDiscount: string;
}> => {
  try {
    const response = await recordApi.get(`${MODULE_BASE_URL}/totalvalues`, {
      params: filters,
    });

    return response.data.body;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const deleteTariff = async (id: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${id}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewTariff = async (data: ITariffData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, {
        ...data,
        dueDate: data.dueDate ? new Date(data.dueDate) : null,
        endDate: new Date(data.endDate),
        startDate: new Date(data.startDate),
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const UpdatePaymentMethod = async (data: ITariffDataUpdate) => {
  const { id, ...rest } = data;
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${id}`, {
        ...rest,
        dueDate: data.dueDate ? new Date(data.dueDate) : undefined,
        endDate: data.endDate ? new Date(data.endDate) : undefined,
        startDate: data.startDate ? new Date(data.startDate) : undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export interface ITariffByID
  extends Omit<ITariffWithPaymentMethod, "tariffCar"> {
  tariffCar: Array<ITariffCarWithCalculate>;
}

export const GetTariffByID = async (
  id: number
): Promise<IResponse<ITariffWithInvoice>> => {
  try {
    const { data }: AxiosResponse<IResponse<ITariffWithInvoice>> =
      await recordApi.get(`${MODULE_BASE_URL}/${id}`);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetTariffCsvAllIds = async () => {
  try {
    const { data } = await recordApi.get(`getTariffCsvAllIds`);
    return data;
  } catch (err: any) {
    return null;
  }
};

export const GetTariffCsvByIds = async (filters: TableFilteredParams) => {
  try {
    const { data } = await recordApi.post(`getTariffCsvByids`, undefined, {
      params: {
        ...filters,
      },
    });
    return data;
  } catch (err: any) {
    return null;
  }
};

export const getBlobTariffUrl = async ({
  tariffId,
  invoiceId,
}: {
  tariffId: number;
  invoiceId: number;
}) => {
  try {
    const { data } = await recordApi.get<IInvoiceFileResponse>(
      `/invoicepdf/invoiceDetailedTariff/${invoiceId}/${tariffId}`
    );

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getBlobTariffUrlBoleto = async ({
  nossoNumero,
}: {
  nossoNumero: number;
}) => {
  try {
    const { data } = await recordApi.get<IInvoiceFileResponse>(
      `boleto/${nossoNumero}`
    );

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const countTariffMissingDueDate = async () => {
  try {
    const { data }: AxiosResponse<IResponse<number>> = await recordApi.get(
      `${MODULE_BASE_URL}/process/due-date/count`
    );
    return data.body ?? 0;
  } catch (err: any) {
    return 0;
  }
};

export const updateDueDateFromMultiTariffs = async (
  data: IInvoiceTariffUpdate
) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/due-date/multi`, data);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
