import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import { CREATE_INVOICES_PERMISSIONS_LEVEL } from "./constants/permissions";
import { InvoiceList } from "./pages/InvoiceList";
import { InvoicingLogsList } from "./pages/InvoicingLogsList";
import { InvoicingManager } from "./pages/InvoicingManager";
import { InvoicingSimulation } from "./pages/InvoicingSimulation";

export function InvoiceModule() {
  return (
    <Routes>
      <Route path="/invoicelist" element={<InvoiceList />} />
      <Route
        element={
          <PrivateRoute permission={CREATE_INVOICES_PERMISSIONS_LEVEL} />
        }
      >
        <Route path="/invoicing" element={<InvoicingManager />} />
        <Route path="/invoicing/simulation" element={<InvoicingSimulation />} />
      </Route>
      <Route path="/invoicinglist" element={<InvoicingLogsList />} />
    </Routes>
  );
}
