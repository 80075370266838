import { Heading, HeadingProps } from "@chakra-ui/react";
import React from "react";

const HSmall6xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="60px" lineHeight="60px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmall5xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="48px" lineHeight="48px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmall4xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="36px" lineHeight="40px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmall3xl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="30px" lineHeight="36px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmallxl: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="20px" lineHeight="28px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmallmd: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="16px" lineHeight="24px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmallsm: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="14px" lineHeight="20px" {...rest}>
      {children}
    </Heading>
  );
};

const HSmallxs: React.FC<HeadingProps> = ({ children, ...rest }) => {
  return (
    <Heading fontWeight="600" fontSize="14px" lineHeight="16.8px" {...rest}>
      {children}
    </Heading>
  );
};

export {
  HSmall6xl,
  HSmall5xl,
  HSmall4xl,
  HSmall3xl,
  HSmallxl,
  HSmallmd,
  HSmallsm,
  HSmallxs,
};
