import { Routes, Route } from "react-router-dom";

import { Backdrop } from "../../../main/components/Backdrop";
import { useAuth } from "../../../main/hooks/useAuth";
import { ClearData } from "./page/ClearData";

export function ClearDataModule() {
  const { permissionLevel, isLoginLoading } = useAuth();
  if (isLoginLoading) return <Backdrop />;
  return (
    <Routes>
      <Route
        path="/clearData"
        element={<ClearData permited={["Master"].includes(permissionLevel)} />}
      />
    </Routes>
  );
}
