import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IProductsData } from "../../../../types/main/products";
import { NewProduct, UpdateProduct } from "../service/ProductService";

export const useProducts = (productUUID?: string) => {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitToCreateProduct = async (data: IProductsData) => {
    try {
      setIsLoading(true);
      const response = await NewProduct(data);
      toast({
        title: "Criação efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/productlist");
    } catch (error: any) {
      setIsLoading(false);
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const onSubmitToUpdateProduct = async (data: IProductsData) => {
    const { category, name, productMask } = data;
    try {
      setIsLoading(true);

      const response = await UpdateProduct({
        category,
        name,
        productMask,
        uuid: productUUID,
      });

      toast({
        title: "Atualização efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    onSubmitToCreateProduct,
    onSubmitToUpdateProduct,
  };
};
