import {
  Button,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import { CellContext, Table } from "@tanstack/react-table";
import { GiFiles } from "react-icons/gi";
import { Link } from "react-router-dom";
import { IconExport } from "../../../../../main/common/assets";
import { ModalDelete } from "../../../../../main/components/CustomModal/ModalDelete";
import {
  HLargexs,
  HSmallmd,
  TSmallmd,
} from "../../../../../main/components/Tipography";
import { IRemittanceListView } from "../../../../../types/main/remittances";
import { useExportRemittance } from "../../hooks/useExportRemittance";
import { cancelRemittanceByIdentifier } from "../../service/remittanceService";
import { ButtonCancel } from "../ButtonCancel";

type PopoverRemittanceProps = {
  info?: CellContext<IRemittanceListView, any>;
  table: Table<IRemittanceListView>;
  refetch: (data?: any) => Promise<void>;
  onLoading: (loading: boolean) => void;
};

async function cancelRemittance(e: string) {
  const response = await cancelRemittanceByIdentifier(e);
  return response;
}

export const RemittancePopover = ({
  onLoading,
  refetch,
  table,
  info,
}: PopoverRemittanceProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const thisRemittance = info?.row.original;

  const canCancel = thisRemittance?.status === "AGUARDANDO ENVIO";

  const { exportAll, exportFiltered } = useExportRemittance(table, onLoading);

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="4" mt="10px">
                <Flex flexDir="column">
                  <>
                    {!thisRemittance && (
                      <>
                        <HLargexs pl="24px" pt="12px" mb="6px">
                          EXPORTAR CSV
                        </HLargexs>
                        <Flex
                          pl="25.33px"
                          py="8px"
                          as="button"
                          alignItems="center"
                          _hover={{ bg: "gray.50" }}
                          onClick={exportAll}
                          width="100%"
                        >
                          <IconExport />
                          <HSmallmd ml="8.46px" color="gray.500">
                            Tabela inteira
                          </HSmallmd>
                        </Flex>
                        <Flex
                          pl="25.33px"
                          py="8px"
                          as="button"
                          alignItems="center"
                          _hover={{ bg: "gray.50" }}
                          onClick={exportFiltered}
                          width="100%"
                        >
                          <IconExport />
                          <HSmallmd ml="8.46px" color="gray.500">
                            Tabela filtrada
                          </HSmallmd>
                        </Flex>
                      </>
                    )}
                    {!!thisRemittance && (
                      <>
                        <Flex
                          marginBlock="2"
                          flexDir="row"
                          justifyContent="flex-start"
                          gap="2"
                          alignItems="center"
                          color="#718096"
                          as={Link}
                          padding="0"
                          to="/processing-remittances"
                          state={{
                            remittanceId: thisRemittance?.uuid,
                          }}
                        >
                          <GiFiles />
                          <TSmallmd fontWeight="medium">
                            Ver Envios/Retornos
                          </TSmallmd>
                        </Flex>
                        {canCancel && !!thisRemittance && (
                          <>
                            <Divider my="5px" />
                            <ModalDelete
                              ButtonOpen={ButtonCancel}
                              uuid={thisRemittance.uuid}
                              callApi={cancelRemittance}
                              refreshTable={refetch}
                              module="Remittance"
                              setTextModal={{
                                modalHeader: "Cancelar remessa",
                                modalBody:
                                  "Você selecionou a ação de cancelar a remessa. Deseja continuar?",
                                modalButtonCancell: "Voltar",
                              }}
                              setTextModalConfirmation={{
                                modalHeaderModalConfirmation: "",
                                modalButtonCancelModalConfirmation: "Voltar",
                                modalButtonConfirmModalConfirmation:
                                  "Sim cancelar remessa",
                              }}
                            />
                          </>
                        )}
                      </>
                    )}
                  </>
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
