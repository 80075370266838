import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { validateNumberInput } from "../../../../../../../../main/common/utils/validateNumberInput";
import { TSmallxs } from "../../../../../../../../main/components/Tipography";

export const ComissionForm = ({
  index,
  onCancel,
  isReadOnly,
}: {
  index: number;
  onCancel: () => void;
  isReadOnly?: boolean;
}) => {
  const { setValue, watch } = useFormContext();

  const [hasMissingFieldMessage, setHasMissingFieldMessage] = useState("");

  const [commissionOrder, setCommissionOrder] = useState("");
  const [commissionType, setCommissionType] = useState("");
  const [commissionValue, setCommissionValue] = useState("");

  const thisComissionOrder = watch(
    `servicesProducts[${index}].commissionOrder`
  );
  const thisComissionType = watch(`servicesProducts[${index}].commissionType`);
  const thisComissionValue = watch(
    `servicesProducts[${index}].commissionValue`
  );

  const handleOnConfirm = () => {
    if (
      (commissionOrder && commissionType && commissionValue) ||
      (!commissionOrder && !commissionType && !commissionValue)
    ) {
      setHasMissingFieldMessage("");
      setValue(`servicesProducts[${index}].commissionOrder`, commissionOrder);
      setValue(`servicesProducts[${index}].commissionType`, commissionType);
      setValue(
        `servicesProducts[${index}].commissionValue`,
        Number(commissionValue)
      );
      onCancel();
    } else {
      setHasMissingFieldMessage("Termine de preencher os campos");
    }
  };

  useEffect(() => {
    if (thisComissionOrder) setCommissionOrder(thisComissionOrder);
  }, [thisComissionOrder]);

  useEffect(() => {
    if (thisComissionType) setCommissionType(thisComissionType);
  }, [thisComissionType]);

  useEffect(() => {
    if (thisComissionValue) setCommissionValue(thisComissionValue);
  }, [thisComissionValue]);

  return (
    <Stack spacing={4} padding="1rem">
      <FormControl display="flex" flexDir="column" alignItems="flex-start">
        <FormLabel htmlFor="commissionType">Comissionamento</FormLabel>
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <RadioGroup
            onChange={setCommissionType}
            value={commissionType}
            isDisabled={isReadOnly}
          >
            <Stack direction="column">
              <Radio value="UNIT">Valor unitário</Radio>
              <Radio value="PERCENTAGE">Percentual</Radio>
            </Stack>
          </RadioGroup>

          <Input
            isReadOnly={isReadOnly}
            maxW="100px"
            size="md"
            id="commissionValue"
            type="number"
            value={commissionValue}
            onChange={(event) =>
              validateNumberInput(event.target.value)
                ? setCommissionValue(String(event.target.value))
                : null
            }
          />
        </Flex>
        <FormLabel htmlFor="commissionOrder" marginTop="2rem">
          Transferência por
        </FormLabel>
        <Flex
          flexDir="row"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <RadioGroup
            onChange={setCommissionOrder}
            value={commissionOrder}
            isDisabled={isReadOnly}
          >
            <Stack direction="column">
              <Radio value="DEDUCTION">Dedução</Radio>
              <Radio value="ADDITION">Acréscimo</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
      </FormControl>
      <ButtonGroup d="flex" justifyContent="space-between">
        <TSmallxs color="red">{hasMissingFieldMessage}</TSmallxs>
        <Button
          colorScheme={hasMissingFieldMessage ? "red" : "blue"}
          size="xs"
          onClick={handleOnConfirm}
        >
          Fechar
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
