import * as yup from "yup";

import { DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED } from "../../../../main/common/constants/defaultValidations";
import {
  checkForNumericChars,
  checkForSpecialChars,
} from "../../../../main/common/utils/checkStrings";
import { validateDocumentPerSize } from "../../../../main/common/utils/validateDocument";

export const providerSchema = yup
  .object({
    name: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .test(
        "Numeric Characters validation",
        "Caracteres numéricos não permitidos.",
        (value) => !checkForNumericChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    document: yup
      .string()
      .required("Esse campo é obrigatório")
      .test("CNPJ Válido", "CNPJ informado inválido.", (value) =>
        validateDocumentPerSize(value ?? "")
      ),
    ie: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    taxes_description: yup
      .string()
      .test(
        "Numeric Characters validation",
        "Caracteres numéricos não permitidos.",
        (value) => !checkForNumericChars(value ?? "")
      ),
    simple_national: yup.boolean().required("Esse campo é obrigatório"),
    nfePassword: yup.string().nullable(),
    nfeUsername: yup.string().nullable(),
    cep: yup.string().required("Esse campo é obrigatório"),
    state: yup.string().required("Esse campo é obrigatório"),
    city: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .test(
        "Numeric Characters validation",
        "Caracteres numéricos não permitidos.",
        (value) => !checkForNumericChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    district: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .test(
        "Numeric Characters validation",
        "Caracteres numéricos não permitidos.",
        (value) => !checkForNumericChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    address: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    addressNumber: yup
      .string()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) =>
          !checkForSpecialChars(
            value ?? "",
            DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED
          )
      )
      .required("Esse campo é obrigatório"),
    additionalInformation: yup.string().nullable(),
    servicesPerProvider: yup.array().of(
      yup.object().shape(
        {
          serviceProvisionUuid: yup
            .string()
            .required("Esse campo é obrigatório"),
          cofins: yup.string().when("unique_aliquot", {
            is: undefined,
            then: yup
              .string()
              .required("Campo obrigatório fora do Simples Nacional"),
            otherwise: yup.string().nullable(),
          }),
          csll: yup.string().when("unique_aliquot", {
            is: undefined,
            then: yup
              .string()
              .required("Campo obrigatório fora do Simples Nacional"),
            otherwise: yup.string().nullable(),
          }),
          ir: yup.string().when("unique_aliquot", {
            is: undefined,
            then: yup
              .string()
              .required("Campo obrigatório fora do Simples Nacional"),
            otherwise: yup.string().nullable(),
          }),
          iss: yup.string().when("unique_aliquot", {
            is: undefined,
            then: yup
              .string()
              .required("Campo obrigatório fora do Simples Nacional"),
            otherwise: yup.string().nullable(),
          }),
          pis: yup.string().when("unique_aliquot", {
            is: undefined,
            then: yup
              .string()
              .required("Campo obrigatório fora do Simples Nacional"),
            otherwise: yup.string().nullable(),
          }),
          unique_aliquot: yup
            .string()
            .when(["cofins", "csll", "ir", "iss", "pis"], {
              is: (
                cofins: number | undefined,
                csll: number | undefined,
                ir: number | undefined,
                iss: number | undefined,
                pis: number | undefined
              ) => !cofins && !csll && !ir && !iss && !pis,
              then: yup
                .string()
                .required("Campo obrigatório em Simples Nacional"),
              otherwise: yup.string().nullable(),
            }),
        },
        [
          ["pis", "unique_aliquot"],
          ["iss", "unique_aliquot"],
          ["ir", "unique_aliquot"],
          ["csll", "unique_aliquot"],
          ["cofins", "unique_aliquot"],
        ]
      )
    ),
  })
  .required();
