import { Flex } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { IconEdit } from "../../common/assets";
import { ModulesType } from "../../common/types/modules";
import { useAuth } from "../../hooks/useAuth";
import { HSmallmd } from "../Tipography";
import { canEdit } from "./functions/permission";

type ButtonEditProps = {
  urlEdit?: string;
  uuid?: string;
  selectedUserProfile?: string;
  module: ModulesType;
};

export const ButtonEdit = ({
  urlEdit,
  uuid,
  selectedUserProfile,
  module,
}: ButtonEditProps) => {
  const { permissionLevel } = useAuth();

  if (
    !canEdit({
      module,
      permissionLevel,
      userProfile: selectedUserProfile,
    })
  )
    return null;

  return (
    <Flex
      pl="25.33px"
      py="8px"
      as={Link}
      alignItems="center"
      _hover={{ bg: "gray.50" }}
      to={`${urlEdit}${uuid ?? ""}`}
    >
      <IconEdit />
      <HSmallmd ml="8.46px" color="gray.500">
        Editar informações
      </HSmallmd>
    </Flex>
  );
};
