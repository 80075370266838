import {
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { IconCancel } from "../../../../../../../../main/common/assets";
import { EnumRadioGroup } from "../../../../../../../../main/components/CustomRadioGroup/EnumRadioGroup";
import {
  HSmallmd,
  TLargemd,
  TSmallsm,
} from "../../../../../../../../main/components/Tipography";
import { useAuth } from "../../../../../../../../main/hooks/useAuth";
import { IProcessingCarCancellation } from "../../../../../../../../types/main/processingCar";
import { IProcessingCarTypeReasonForCancellation } from "../../../../../../../../types/main/processingCarTypeReasonForCancellation";
import { getTariffCarById } from "../../../../../../CARs/service/CARsService";
import { getAllProcessingCarCancellationReason } from "../../../../../../CARs/service/ProcessingCarService";
import { CAN_PERMISSION_CANCEL } from "../../../../../constants/permissions";
import { DEFAULT_VALIDATION_TARIFF_OPEN } from "../../../../../constants/tariff.constants";
import { GetTariffByID } from "../../../../../service/TariffService";
import { cancelCarSchema } from "../../../../../validation/cancelCarSchema";
import { CancelCarConfirm } from "./CancelCarConfirm";

const CancelCar = ({
  typeButton,
  uuidTariff,
  uuidTariffCar,
  refreshTable,
  reloadCar,
}: {
  typeButton: "withIcon" | "button";
  uuidTariff?: number;
  uuidTariffCar?: string;
  refreshTable?: () => void;
  reloadCar?: () => void;
}) => {
  const { permissionLevel } = useAuth();

  const methods = useForm<IProcessingCarCancellation>({
    resolver: yupResolver(cancelCarSchema),
  });

  const [
    allProcessingCarTypeReasonForCancellationList,
    setAllProcessingCarTypeReasonForCancellationList,
  ] = useState<IProcessingCarTypeReasonForCancellation[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [uuidsTariff, setUuidsTariff] = useState<string[]>();

  useEffect(() => {
    if (allProcessingCarTypeReasonForCancellationList.length > 0) {
      methods.setValue(
        "processingCarCancellationUuid",
        allProcessingCarTypeReasonForCancellationList[0].uuid
      );
    }
  }, [allProcessingCarTypeReasonForCancellationList, methods]);

  const handleOnOpen = useCallback(() => {
    onOpen();
    async function fetchData() {
      const response = await getAllProcessingCarCancellationReason();
      if (response.body) {
        setAllProcessingCarTypeReasonForCancellationList(response.body ?? []);
      }
      if (uuidTariff) {
        const response = await GetTariffByID(uuidTariff);
        if (response.body) {
          const uuidTariff = response.body.tariffCar
            .filter(({ processingCar }) =>
              DEFAULT_VALIDATION_TARIFF_OPEN.includes(
                processingCar.processingStatusCar.enum
              )
            )
            .map(({ uuid }) => uuid);
          if (uuidTariff.length > 0) {
            setUuidsTariff(uuidTariff);
          }
        }
      } else if (uuidTariffCar) {
        const response = await getTariffCarById(uuidTariffCar);
        if (response.body) {
          if (
            DEFAULT_VALIDATION_TARIFF_OPEN.includes(
              response.body.processingCar.processingStatusCar.enum
            )
          ) {
            setUuidsTariff([uuidTariffCar]);
          }
        }
      }
    }
    fetchData();
  }, [uuidTariff, uuidTariffCar, onOpen]);

  const clear = useCallback(() => {
    setUuidsTariff(undefined);
    methods.setValue("processingCarCancellationUuid", "");
    methods.setValue("reasonForCancellation", undefined);
    methods.setValue("uuidCar", "");
  }, [methods]);

  const handleClose = useCallback(() => {
    onClose();
    clear();
  }, [clear, onClose]);

  if (!CAN_PERMISSION_CANCEL[permissionLevel]) {
    return null;
  }

  return (
    <>
      <FormProvider {...methods}>
        {typeButton === "withIcon" && (
          <Flex
            py="8px"
            as="button"
            alignItems="center"
            color="red.500"
            _hover={{ color: "red.600" }}
            transition="all 0.2s ease-in-out"
            onClick={handleOnOpen}
          >
            <IconCancel />
            <HSmallmd ml="8.46px">Cancelar</HSmallmd>
          </Flex>
        )}

        {typeButton === "button" && (
          <Button
            onClick={handleOnOpen}
            colorScheme="red"
            variant="outline"
            size="sm"
          >
            Cancelar
          </Button>
        )}

        <Modal isOpen={isOpen} onClose={handleClose} isCentered size="2xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>
              CAR - Cancelar
              <TSmallsm color="red.500">
                CARs com Remessa em aberto serão mantidos.
              </TSmallsm>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <EnumRadioGroup
                // isReadOnly={isReadOnly}
                fieldname="processingCarCancellationUuid"
                options={allProcessingCarTypeReasonForCancellationList.map(
                  ({ labelTypeOfCancellation, uuid }) => {
                    return {
                      label: labelTypeOfCancellation,
                      value: uuid,
                    };
                  }
                )}
              />
              <TLargemd mb="20px">Descreva o motivo do cancelamento</TLargemd>
              <Textarea
                {...methods.register("reasonForCancellation")}
                placeholder="Insira aqui o motivo"
                size="sm"
              />
            </ModalBody>

            <ModalFooter>
              {!uuidsTariff && (
                <Text mr="30px" color="red.500" fontWeight="bold">
                  Não há CAR para cancelar
                </Text>
              )}
              <Button
                colorScheme="gray"
                variant="outline"
                mr="12px"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              {uuidsTariff && uuidsTariff.length > 0 && (
                <CancelCarConfirm
                  reloadCar={reloadCar}
                  refreshTable={refreshTable}
                  arrayuuidTariff={uuidsTariff}
                  onClosePreceding={handleClose}
                />
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </FormProvider>
      <Divider my="5px" hidden={typeButton === "button"} />
    </>
  );
};
export { CancelCar };
