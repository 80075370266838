import {
  OnChangeFn,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";

type States = {
  sorting?: SortingState;
  pagination?: PaginationState;
};

type OnChanges = {
  onSortingChange?: OnChangeFn<SortingState> | undefined;
  onPaginationChange?: OnChangeFn<PaginationState> | undefined;
};

export const getStates = ({ pagination, sorting }: States) => {
  return {
    ...(pagination && { pagination }),
    ...(sorting && { sorting }),
  };
};

export const getOnChanges = ({
  onSortingChange,
  onPaginationChange,
}: OnChanges) => {
  return {
    ...(onSortingChange && { onSortingChange }),
    ...(onPaginationChange && { onPaginationChange }),
  };
};
