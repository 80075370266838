import axios, { AxiosResponse } from "axios";

interface IIbgeAPICities {
  id: string;
  nome: string;
}

const ibgeAPI = axios.create({
  baseURL: "https://servicodados.ibge.gov.br/api",
});

export const getCitiesByUF = async (UF: string) => {
  try {
    const response: AxiosResponse<IIbgeAPICities[]> = await ibgeAPI.get(
      `/v1/localidades/estados/${UF}/municipios?orderBy=nome`
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
