import { useEffect, useState } from "react";
import { TreeData } from "react-dropdown-tree-select";
import { useNavigate } from "react-router-dom";

import { IClientOptions } from "../../../../types/main/client";
import { IProductCategories } from "../../../../types/main/products";
import { IFilterRemittancesSimulation } from "../../../../types/main/remittances";
import { getChannelOptionsTree } from "../../Channels/services/ChannelService";
import { getClientOptions } from "../../Clients/service/ClientService";
import { allCategoryProducts } from "../../Products/service/ProductService";

export const useRemittanceSimulationFilters = () => {
  const navigate = useNavigate();

  const [clients, setClients] = useState<IClientOptions[]>([]);
  const [channels, setChannels] = useState<TreeData[]>([]);
  const [productsCategories, setProductsCategories] = useState<
    IProductCategories[]
  >([]);

  const [hasFetchError, setHasFetchError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitSimulationRequest = async (
    filters: IFilterRemittancesSimulation
  ) => {
    navigate("/remittance/simulation", { state: { filters } });
  };

  const loadClients = async () => {
    const response = await getClientOptions();
    return response;
  };

  const loadChannels = async () => {
    const response = await getChannelOptionsTree();
    return response.body ?? [];
  };

  const loadProductCategories = async () => {
    const response = await allCategoryProducts();
    return response.body ?? [];
  };

  useEffect(() => {
    let isCancelled = false;

    const fetchData = async () => {
      try {
        setIsLoading(true);
        const clients = await loadClients();
        const channels = await loadChannels();
        const productCategories = await loadProductCategories();

        if (!isCancelled) {
          setClients(clients);
          setChannels(channels);
          setProductsCategories(productCategories);
          setIsLoading(false);
        }
      } catch (error) {
        if (!isCancelled) {
          setHasFetchError(true);
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, []);

  return {
    clients,
    channels,
    productsCategories,
    hasFetchError,
    isFetching: isLoading,
    onSubmitSimulationRequest,
  };
};
