/* eslint-disable no-useless-return */
import { Box, Button, Flex, Grid } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import { Backdrop } from "../../../../main/components/Backdrop";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl, HSmallxl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IResponse } from "../../../../types/main/response";
import { ITariffWithInvoice } from "../../../../types/main/tariff";
import {
  CardCar,
  ICardCarProps,
} from "../components/TariffDetails/Car/CardCar";
import { CreateCar } from "../components/TariffDetails/Car/CreateCar";
import { Invoice } from "../components/TariffDetails/Invoice";
import { IInvoiceProps } from "../components/TariffDetails/Invoice/invoice.interface";
import { Tariff } from "../components/TariffDetails/Tariff";
import {
  fetchReceivablesPaymentMethods,
  getCardReceivableState,
  getTariffState,
} from "../functions/handleTariffDetails";
import { getInvoiceState } from "../functions/handleTariffDetails/invoiceState";
import { ITariffProps } from "../interface/tariff.interface";
import { GetTariffByID } from "../service/TariffService";

interface ITariffForm {
  isReadOnly?: boolean;
  id?: number;
}

export type ICardReceivable = Omit<ICardCarProps, "reloadCar" | "refreshTable">;

const breadcrumb: IBreadcrumbProps[] = [
  { description: "Faturamento", href: "" },
  { description: "Tarifas", href: "/tarifflist" },
  { description: "Gerenciamento de Tarifa", href: "" },
];

export const TariffDetails: React.FC<ITariffForm> = ({ isReadOnly, id }) => {
  const query = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const reloadPage = () => {
    navigate(location.pathname);
  };

  const tariffId = id ?? query.tariffId ?? 0;

  const [isLoading, setIsLoading] = useState(false);
  const [tariff, setTariff] = useState<ITariffProps>();
  const [invoice, setInvoice] = useState<IInvoiceProps>();
  const [receivables, setReceivables] = useState<Array<ICardReceivable>>([]);

  const loadReceivablesAndValue = async (
    tariffs: IResponse<ITariffWithInvoice>
  ) => {
    if (!tariffs.body) return 0;

    const allPaymentMethods = await fetchReceivablesPaymentMethods(tariffs);

    const tariffValue = tariffs.body.tariffCar
      .map((receivable) => {
        setReceivables((current) => {
          return getCardReceivableState(
            current,
            receivable,
            tariffs.body as ITariffWithInvoice,
            allPaymentMethods
          );
        });

        if (receivable.processingCar.processingStatusCar.enum !== "BLOCK") {
          return receivable.value;
        }
        return 0;
      })
      .reduce((acc, curr) => Number(acc) + Number(curr));

    return tariffValue;
  };

  const loadTariff = useCallback(async (tariffId: number) => {
    setIsLoading(true);
    try {
      setReceivables([]);
      const tariffById = await GetTariffByID(tariffId);

      if (!tariffById.body) return;

      const tariffValue = await loadReceivablesAndValue(tariffById);

      const tariffProps = getTariffState(tariffById, tariffValue);
      setTariff(tariffProps);

      const invoiceProps = getInvoiceState(tariffById);

      setInvoice(invoiceProps);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (!!tariffId) {
      loadTariff(+tariffId);
    }
  }, [loadTariff, tariffId, location.key]);

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />

      {isLoading && <Backdrop />}
      <Box mb={20}>
        <HLarge2xl py="6px" mb="32px" mt={10} color="gray.600" w="100%">
          Tarifa - {tariffId}
        </HLarge2xl>

        {tariff && <Tariff tariff={tariff} />}
        {invoice && <Invoice {...invoice} isReadOnly={isReadOnly} />}
        {receivables && receivables.length > 0 && (
          <>
            <Flex justifyContent="space-between" alignItems="baseline">
              <HSmallxl mt="32px" mb="20px">
                Contas a receber
              </HSmallxl>
              {!isReadOnly && (
                <CreateCar
                  idTariff={+tariffId}
                  isReadOnly={isReadOnly}
                  reloadCar={reloadPage}
                />
              )}
            </Flex>
            <Grid mt="12px" templateColumns="repeat(2, 1fr)" gap="24px 24px">
              {receivables.map((item) => (
                <CardCar
                  key={item.uuid}
                  {...item}
                  isReadOnly
                  reloadCar={reloadPage}
                />
              ))}
            </Grid>
          </>
        )}
      </Box>
      <Flex justifyContent="flex-end" my={8}>
        <Link to="/tarifflist">
          <Button variant="link" color="gray.700">
            Cancelar
          </Button>
        </Link>
      </Flex>
    </Wrapper>
  );
};
