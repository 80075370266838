import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";

import { CardCar } from ".";
import { IconEdit } from "../../../../../../../main/common/assets";
import { HSmallmd } from "../../../../../../../main/components/Tipography";
import { useAuth } from "../../../../../../../main/hooks/useAuth";
import { IPaymentOptionsGroup } from "../../../../../../../types/main/paymentMethods";
import { enumProcessingStatusCar } from "../../../../../../../types/main/processingStatusCar";
import { CAN_PERMISSION_EDIT } from "../../../../../CARs/constants/permissions";

interface IProps {
  uuid: string;
  invoiceInterest: number;
  invoiceFine: number;
  invoiceValue: number;
  invoiceDiscount: number;
  invoiceTotal: number;
  paymentMethodUuid?: string;
  service: string;
  allMethods?: IPaymentOptionsGroup[];
  discardInterestAndFine: boolean;
  reloadCar: () => void;
  enumProcessing: keyof typeof enumProcessingStatusCar;
  serviceInaactive?: boolean;
  dueDate: Date;
  idContract: number;
}

export const EditCardModal: React.FC<IProps> = ({
  uuid,
  invoiceInterest,
  invoiceFine,
  invoiceValue,
  invoiceDiscount,
  invoiceTotal,
  paymentMethodUuid,
  service,
  allMethods,
  discardInterestAndFine,
  reloadCar,
  enumProcessing,
  serviceInaactive,
  dueDate,
  idContract,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { permissionLevel } = useAuth();

  if (!CAN_PERMISSION_EDIT[permissionLevel]) return null;

  return (
    <>
      <Flex
        py="8px"
        as="button"
        alignItems="center"
        _hover={{ bg: "gray.50" }}
        onClick={() => onOpen()}
        onMouseDown={() => onOpen()}
      >
        <IconEdit />
        <HSmallmd ml="8.46px" color="gray.500">
          Editar
        </HSmallmd>
      </Flex>

      <Modal onClose={onClose} size="2xl" isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody justifyContent="center" mt="15px" mx="15px" mb="30px">
            <CardCar
              isReadOnly={false}
              uuid={uuid}
              invoiceInterest={invoiceInterest}
              invoiceFine={invoiceFine}
              invoiceValue={invoiceValue}
              invoiceDiscount={invoiceDiscount}
              invoiceTotal={invoiceTotal}
              paymentMethodUuid={paymentMethodUuid}
              cancel={onClose}
              service={service}
              allMethods={allMethods}
              discardInterestAndFine={discardInterestAndFine}
              reloadCar={reloadCar}
              enumProcessing={enumProcessing}
              serviceInaactive={serviceInaactive}
              dueDate={dueDate}
              idContract={idContract}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <Divider my="5px" />
    </>
  );
};
