import { Flex, Spinner, useToast } from "@chakra-ui/react";
import { useState } from "react";

import { IconExport } from "../../../../../main/common/assets";
import { HLargexs, HSmallmd } from "../../../../../main/components/Tipography";
import {
  IBlobError,
  useDownloadFile,
} from "../../../../../main/hooks/useDownloadFile";
import { handleError } from "../invoicePopover/functions/handleError";

type InvoicesZipProps = {
  invoiceIds: (number | undefined)[];
};

export const InvoicesZip = ({ invoiceIds }: InvoicesZipProps) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const preDownloading = () => {
    setIsLoading(true);
  };
  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage = await handleError(error);

    toast({
      title: "Serviço indisponível.",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const { downloadFile } = useDownloadFile({
    apiDefinition: {
      endpoint: "/invoiceszip",
      method: "post",
      payload: {
        invoiceIds: invoiceIds.filter((item) => !!item),
      },
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: "Faturas",
    extension: "zip",
  });

  if (isLoading) {
    return (
      <Flex justifyContent="center" alignItems="center">
        <Spinner color="#2b6cb0" />
      </Flex>
    );
  }

  return (
    <>
      <HLargexs pl="24px" pt="16px" mb="6px">
        Download Faturas Filtradas
      </HLargexs>
      <Flex
        pl="25.33px"
        py="8px"
        as="button"
        alignItems="center"
        _hover={{ bg: "gray.50" }}
        onClick={downloadFile}
        width="100%"
      >
        <IconExport />
        <HSmallmd ml="8.46px" color="gray.500">
          Baixa (.zip)
        </HSmallmd>
      </Flex>
    </>
  );
};
