import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IPaymentMethods } from "../../../../types/main/paymentMethods";
import { GetPaymentMethodByUUID } from "../service/PaymentMethodService";

export const useExistingPaymentMethod = (uuid: string | undefined) => {
  const toast = useToast();

  const [existingPaymentMethod, setExistingPaymentMethod] = useState<
    IPaymentMethods | undefined
  >();

  const [endRequestExistingPaymentMethod, setendRequestExistingPaymentMethod] =
    useState(false);

  const getExistingPaymentMethod = useCallback(
    async (uuid: string) => {
      try {
        const response = await GetPaymentMethodByUUID(uuid);
        setExistingPaymentMethod(response.body ?? undefined);
        setendRequestExistingPaymentMethod(true);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setendRequestExistingPaymentMethod(true);
      }
    },
    [toast]
  );

  useEffect(() => {
    if (uuid) {
      getExistingPaymentMethod(uuid);
    } else {
      setendRequestExistingPaymentMethod(true);
    }
  }, [getExistingPaymentMethod, uuid]);

  return { existingPaymentMethod, endRequestExistingPaymentMethod };
};
