import { DEFAULT_ERROR_MESSAGES } from "../../../../../main/common/constants/defaultMessages";
import { customToast } from "../../../../../main/common/utils/customToast";
import { IChannelERPConfigData } from "../../../../../types/main/channelERPConfig";
import { IClientOptions } from "../../../../../types/main/client";
import { manageERPConfig } from "../../services/ChannelERPService";

interface IUseChannelERP {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  channelId: string | undefined;
  clientList: IClientOptions[];
}

export const useChannelERP = ({
  channelId,
  setIsLoading,
  clientList,
}: IUseChannelERP) => {
  const onSubmit = async (erpConfigData: IChannelERPConfigData) => {
    const validateDeletedAtClient = clientList.find(
      ({ uuid, deletedAt }) => uuid === erpConfigData.holderUuid && deletedAt
    );

    if (validateDeletedAtClient) {
      customToast(
        "error",
        `${validateDeletedAtClient.fullName} está inativo, selecione outro cliente ou contate o administrador`
      );
    } else {
      try {
        setIsLoading(true);
        if (channelId) {
          const payload = {
            erpConfigData,
            channelId,
          };

          const response = await manageERPConfig(payload);

          customToast("success", response.body?.message);
        }
      } catch (error: any) {
        customToast(
          "error",
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION
        );
      }
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
  };
};
