import { IResponse } from "../../../../../types/main/response";
import { ITariffWithInvoice } from "../../../../../types/main/tariff";
import { getPaymentMethodOptions } from "../../../PaymentMethod/service/PaymentMethodService";

export const fetchReceivablesPaymentMethods = async (
  tariffs: IResponse<ITariffWithInvoice>
) => {
  if (!tariffs.body) return [];

  const tariffCarsPaymentMethods = tariffs.body.tariffCar
    .filter(
      ({ paymentMethod, paymentMethodUuid }) =>
        !!paymentMethodUuid || !!paymentMethod
    )
    .map(({ paymentMethod, paymentMethodUuid }) => {
      return paymentMethodUuid || paymentMethod?.uuid;
    }) as string[];

  const allMethodsRequest = await getPaymentMethodOptions({
    return_even_if_deleted:
      tariffCarsPaymentMethods.length > 0
        ? tariffCarsPaymentMethods
        : undefined,
  });
  // const allMethods = allMethodsRequest.body?.map(
  //   ({ description, uuid, deletedAt }) => ({
  //     name: description,
  //     uuid,
  //     deletedAt,
  //   })
  // );

  return allMethodsRequest.body ?? [];
};
