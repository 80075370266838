import { IResponse } from "../../../../../types/main/response";
import { ITariffWithInvoice } from "../../../../../types/main/tariff";
import { IInvoiceProps } from "../../components/TariffDetails/Invoice/invoice.interface";

export const getInvoiceState = (
  tariffs: IResponse<ITariffWithInvoice>
): IInvoiceProps | undefined => {
  if (!tariffs.body) return undefined;

  const hasInvoice = !!tariffs.body?.invoiceId;

  if (!hasInvoice) return undefined;

  const invoiceStatus: ("OPEN" | "CLOSED" | "CANCELED")[] =
    tariffs.body.tariffsInInvoice.map(
      ({ tariffStatus }) => tariffStatus.status
    );

  const invoiceState = tariffs.body?.tariffsInInvoice.reduce(
    (acc, cur) => {
      return {
        invoiceId: cur.invoiceId,
        tariffId: cur.id,
        nfeGenerated: !!tariffs.body?.nfeGenerated,
        invoiceNumber: acc.invoiceNumber,
        invoiceStatus: acc.invoiceStatus,
        invoiceValue:
          acc.invoiceValue +
          Number(cur.totalTariff.valueProvided + cur.totalTariff.valueReceived),
        invoiceInterest:
          acc.invoiceInterest +
          Number(
            cur.totalTariff.totalInterestProvided +
              cur.totalTariff.totalInterestReceived
          ),
        invoiceFine:
          acc.invoiceFine +
          Number(
            cur.totalTariff.totalFineProvided +
              cur.totalTariff.totalFineReceived
          ),
        invoiceTotal:
          acc.invoiceTotal +
          Number(cur.totalTariff.totalProvided + cur.totalTariff.totalReceived),
      };
    },
    {
      tariffId: 0,
      invoiceNumber: tariffs.body.tariffsInInvoice[0].invoiceId,
      nfeGenerated: false,
      invoiceInterest: 0,
      invoiceFine: 0,
      invoiceTotal: 0,
      invoiceValue: 0,
      invoiceStatus,
    }
  );

  return invoiceState;

  // return {
  //   tariffId: tariffs.body.id,
  //   invoiceId: tariffs.body.invoiceId,
  //   invoiceFine: tariffs.body.invoice?.invoiceFine,
  //   invoiceInterest: tariffs.body.invoice?.invoiceInterest,
  //   invoiceNumber: tariffs.body.invoice?.id,
  //   invoiceTotal: tariffs.body.invoice?.invoiceTotal,
  //   invoiceValue: tariffs.body.invoice?.invoiceValue,
  //   nfeGenerated: !!tariffs.body?.nfeGenerated,
  //   invoiceStatus,
  // };
};
