import { useFormContext } from "react-hook-form";

import { TSmallxs } from "../../../Tipography";

type DayPickerClearProps = {
  name: string;
  onClear?: () => void;
};

export const DayPickerClear = ({ name, onClear }: DayPickerClearProps) => {
  const { resetField } = useFormContext();

  return (
    <TSmallxs
      onClick={() => {
        resetField(name, {
          defaultValue: null,
        });
        onClear && onClear();
      }}
      fontWeight={600}
      className="DayPicker-clear"
      textTransform="none"
    >
      Limpar
    </TSmallxs>
  );
};
