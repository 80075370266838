import axios, { AxiosInstance } from "axios";

import { jwtRequestInterceptor } from "./interceptor";

const apiUri: string =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8005"
    : "https://ENV_JOBS_API_URL";

const jobsApi: AxiosInstance = axios.create({
  baseURL: apiUri,
  headers: {
    accept: "application/json",
    "Content-Type": "application/json",
  },
});

jwtRequestInterceptor(jobsApi);

export { jobsApi };
