import { Flex } from "@chakra-ui/react";

import { IconDelete } from "../../common/assets";
import { HSmallmd } from "../Tipography";

export const ButtonDelete = ({ ...rest }) => {
  return (
    <Flex
      pl="25.33px"
      py="8px"
      as="button"
      alignItems="center"
      _hover={{ bg: "gray.50" }}
      mb="6px"
      {...rest}
    >
      <IconDelete />
      <HSmallmd ml="8.46px" color="red.500">
        Deletar
      </HSmallmd>
    </Flex>
  );
};
