import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_CHANNELS_PERMISSION_LEVEL,
  GET_CHANNELS_PERMISSION_LEVEL,
  UPDATE_CHANNELS_PERMISSION_LEVEL,
} from "./constants/permissions";
import { ChannelManager } from "./pages/ChannelManager";
import { ChannelsList } from "./pages/ChannelsList";

export function ChannelModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_CHANNELS_PERMISSION_LEVEL} />}
      >
        <Route path="/channelslist" element={<ChannelsList />} />
        <Route
          path="/viewchannel/:id"
          element={<ChannelManager isReadOnly />}
        />
      </Route>
      <Route
        element={<PrivateRoute permission={CREATE_CHANNELS_PERMISSION_LEVEL} />}
      >
        <Route path="/managechannel" element={<ChannelManager />} />
      </Route>
      <Route
        element={<PrivateRoute permission={UPDATE_CHANNELS_PERMISSION_LEVEL} />}
      >
        <Route path="/managechannel/:id" element={<ChannelManager />} />
      </Route>
    </Routes>
  );
}
