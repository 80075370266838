import { PaginationState } from "@tanstack/react-table";
import {
  TableFilteredParams,
  TableSortingParams,
} from "../../../../main/components/DataTableServer/useTableServer";
import { recordApi } from "../../../../main/services/RecordService";
import { IBackgroundProcessesLogs } from "../../../../types/main/backgroundProcessesLogs";
import { IPaginateResponse } from "../../../../types/main/pagination";
import { IResponse } from "../../../../types/main/response";

const defaultLogs = {
  data: [],
  count: 0,
  currentPage: 0,
  lastPage: 0,
  nextPage: 0,
  prevPage: 0,
  take: 0,
  ttl: 0,
};

type BackgroundListParams = {
  pagination?: PaginationState;
  columnFilters?: TableFilteredParams;
  sorting?: TableSortingParams;
};

export const getTariffReceivableBackgroundLogs = async ({
  columnFilters,
  pagination,
  sorting,
}: BackgroundListParams): Promise<
  IPaginateResponse<IBackgroundProcessesLogs>
> => {
  try {
    const page = pagination?.pageIndex;

    const { data } = await recordApi.get<
      IResponse<IPaginateResponse<IBackgroundProcessesLogs>>
    >("/background-logs/tariff/receivable", {
      params: {
        page: page === undefined ? page : page + 1,
        take: pagination?.pageSize ?? 10,
        ...columnFilters,
        ...sorting,
      },
    });

    return data.body ?? defaultLogs;
  } catch (error) {
    return defaultLogs;
  }
};

export const getAllBackgroundLogs = async ({
  columnFilters,
  pagination,
  sorting,
}: BackgroundListParams): Promise<
  IPaginateResponse<IBackgroundProcessesLogs>
> => {
  try {
    const page = pagination?.pageIndex;

    const { data } = await recordApi.get<
      IResponse<IPaginateResponse<IBackgroundProcessesLogs>>
    >("/background-logs", {
      params: {
        page: page === undefined ? page : page + 1,
        take: pagination?.pageSize ?? 10,
        ...columnFilters,
        ...sorting,
      },
    });

    return data.body ?? defaultLogs;
  } catch (error) {
    return defaultLogs;
  }
};

export const getAllBackgroundLogsCSV = async () => {
  try {
    const { data } = await recordApi.post(`getAllBackgroundLogsCSV`);
    return data;
  } catch (err: any) {
    return null;
  }
};

export const getFilteredBackgroundLogsCSV = async (filters: any) => {
  try {
    const { data } = await recordApi.post(
      `getAllBackgroundLogsCSV`,
      undefined,
      {
        params: {
          ...filters,
        },
      }
    );
    return data;
  } catch (err: any) {
    return null;
  }
};

export const deleteBackgroundLog = async (id: string, type: string) => {
  try {
    const { data } = await recordApi.delete(`/background-logs/${id}/${type}`);

    return data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};

export const countBackgroundLogs = async (type: string) => {
  try {
    const { data } = await recordApi.get(`/background-logs/count/${type}`);

    return data.body.data;
  } catch (error: any) {
    throw new Error(error.message);
  }
};
