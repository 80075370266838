import {
  DELETE_PERMISSIONS,
  PERMISSION_LEVELS,
} from "../../../common/constants/permissions";
import { ModulesType } from "../../../common/types/modules";

type CanDeleteProps = {
  permissionLevel: string;
  loggedUserUuid: string;
  userUuid: string;
  userProfile?: string;
  module: ModulesType;
};

const canDeleteUser = ({
  loggedUserUuid,
  permissionLevel,
  userUuid,
  userProfile,
}: Omit<CanDeleteProps, "module">) => {
  const isUserTryingToDeleteHisOwnAccount = loggedUserUuid === userUuid;

  if (isUserTryingToDeleteHisOwnAccount) return false;

  const isSelectedUserAdminOrMaster =
    userProfile === PERMISSION_LEVELS.MASTER ||
    userProfile === PERMISSION_LEVELS.ADMIN;

  if (
    permissionLevel === PERMISSION_LEVELS.ADMIN &&
    isSelectedUserAdminOrMaster
  )
    return false;

  return true;
};

export const canDelete = ({
  loggedUserUuid,
  permissionLevel,
  userUuid,
  userProfile,
  module,
}: CanDeleteProps) => {
  const permittedModules =
    (DELETE_PERMISSIONS[permissionLevel] as ModulesType[]) || [];

  if (!permittedModules.includes(module)) return false;

  if (!!userProfile)
    return canDeleteUser({
      loggedUserUuid,
      permissionLevel,
      userUuid,
      userProfile,
    });

  return true;
};
