import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IBiller } from "../../../../types/main/biller";
import { GetBillerByUUID } from "../service/BillerService";

interface IUseExistingBillerForm {
  uuid?: string | undefined;
}

export const useExistingBiller = ({ uuid }: IUseExistingBillerForm) => {
  const toast = useToast();
  const [existingBiller, setExistingBiller] = useState<IBiller | undefined>();

  const getExistingBiller = useCallback(
    async (uuid: string) => {
      try {
        const response = await GetBillerByUUID(uuid);

        setExistingBiller(response.body ?? undefined);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: DEFAULT_ERROR_MESSAGES.LIST_ACTION,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );
  useEffect(() => {
    if (uuid) {
      getExistingBiller(uuid);
    }
  }, [getExistingBiller, uuid]);

  return { existingBiller };
};
