import { Badge, Flex, ThemeTypings, Tooltip } from "@chakra-ui/react";

export interface IBadgeTooltip {
  label: string;
  colorScheme: ThemeTypings["colorSchemes"];
}
const BadgeTooltip = ({ colorScheme, label }: IBadgeTooltip) => {
  return (
    <Flex
      alignItems="center"
      h="24px"
      p="8px"
      justifyContent="center"
      width="fit-content"
      // mr="20px"
    >
      <Tooltip hasArrow label={label} placement="top">
        <Badge
          backgroundColor={`${colorScheme}.400`}
          h="16px"
          w="16px"
          borderRadius="50%"
        />
      </Tooltip>
    </Flex>
  );
};
export { BadgeTooltip };
