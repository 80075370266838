import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IPaymentType } from "../../../../../types/main/paymentType";
import { allTypesPaymentMethod } from "../../../PaymentMethod/service/PaymentMethodService";

export const useGetAllTypes = () => {
  const toast = useToast();

  const [allTypes, setAllTypes] = useState<Array<IPaymentType>>();

  const updateTypes = useCallback(async () => {
    try {
      const response = await allTypesPaymentMethod();
      if (response.type === "success" && response.body) {
        const list = response.body.map(({ name, uuid }) => ({
          name,
          uuid,
        }));
        setAllTypes(list);
      }
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    updateTypes();
  }, [updateTypes]);

  return { allTypes };
};
