import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  GridItem,
  Input,
  Select,
  Switch,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { DEFAULT_REGEX_ONLY_NUMBERS_WITHOUT_DECIMALS } from "../../../../../../main/common/constants/defaultValidations";
import { NumberFilterInput } from "../../../../../../main/components/NumberFilterInput";
import { IClientData } from "../../../../../../types/main/client";
import { IMaritalStatus } from "../../../../../../types/main/maritalStatus";

type ClientPFProps = {
  isReadOnly: boolean;
  maritalList: IMaritalStatus[];
};

export const ClientPF = ({ isReadOnly, maritalList }: ClientPFProps) => {
  const [checkRG, setCheckRG] = useState(true);
  const [checkOAB, setCheckOAB] = useState(false);
  const [checkCNH, setCheckCNH] = useState(false);

  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext<IClientData>();

  const cnh = watch("cnh");
  const rg = watch("rg");
  const oab = watch("oab");

  useEffect(() => {
    if (!checkCNH) {
      setValue("cnh", "");
    }
  }, [checkCNH, setValue]);

  useEffect(() => {
    if (!checkOAB) {
      setValue("oab", "");
    }
  }, [checkOAB, setValue]);

  useEffect(() => {
    if (!checkRG) {
      setValue("rg", "");
    }
  }, [checkRG, setValue]);

  useEffect(() => {
    if (!!cnh) {
      setCheckCNH(true);
    }
  }, [cnh]);

  useEffect(() => {
    if (!!rg) {
      setCheckRG(true);
    }
  }, [rg]);

  useEffect(() => {
    if (!!oab) {
      setCheckOAB(true);
    }
  }, [oab]);

  return (
    <>
      <GridItem colSpan={1}>
        <FormControl isInvalid={!!errors.rg}>
          <FormLabel
            htmlFor="document"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            RG
            <Switch
              size="sm"
              isChecked={checkRG}
              onChange={() => setCheckRG((oldState) => !oldState)}
              disabled={isReadOnly}
            />
          </FormLabel>
          <NumberFilterInput
            maxLength={10}
            fieldname="rg"
            regex={DEFAULT_REGEX_ONLY_NUMBERS_WITHOUT_DECIMALS}
            disabled={!checkRG || isReadOnly}
            type="number"
          />
          <FormErrorMessage>{errors.rg && errors.rg.message}</FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={1}>
        <FormControl isInvalid={!!errors.oab}>
          <FormLabel
            htmlFor="oab"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            OAB
            <Switch
              size="sm"
              isChecked={checkOAB}
              onChange={() => setCheckOAB((oldState) => !oldState)}
              disabled={isReadOnly}
            />
          </FormLabel>
          <Input
            id="oab"
            {...register("oab")}
            disabled={!checkOAB || isReadOnly}
            _disabled={{
              cursor: "not-allowed",
              color: "inherit",
              opacity: "inherit",
            }}
          />
          <FormErrorMessage>
            {errors.oab && errors.oab.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={1}>
        <FormControl isInvalid={!!errors.cnh}>
          <FormLabel
            htmlFor="cnh"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            CNH
            <Switch
              size="sm"
              isChecked={checkCNH}
              onChange={() => setCheckCNH((oldState) => !oldState)}
              disabled={isReadOnly}
            />
          </FormLabel>
          <NumberFilterInput
            fieldname="cnh"
            regex={DEFAULT_REGEX_ONLY_NUMBERS_WITHOUT_DECIMALS}
            disabled={!checkCNH || isReadOnly}
            type="number"
          />
          <FormErrorMessage>
            {errors.cnh && errors.cnh.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>
      <GridItem colSpan={1}>
        <FormControl isInvalid={!!errors.maritalStatus}>
          <FormLabel htmlFor="maritalStatus">Estado Cívil</FormLabel>
          <Select
            isDisabled={isReadOnly}
            _disabled={{
              cursor: "default",
              color: "inherit",
              opacity: "inherit",
            }}
            id="maritalStatus"
            placeholder="-"
            {...register("maritalStatus", {
              required: "This is required",
            })}
          >
            {maritalList &&
              maritalList.map((maritalStatus) => (
                <option key={maritalStatus.uuid} value={maritalStatus.uuid}>
                  {maritalStatus.name}
                </option>
              ))}
          </Select>

          <FormErrorMessage>
            {errors.maritalStatus && errors.maritalStatus.message}
          </FormErrorMessage>
        </FormControl>
      </GridItem>
    </>
  );
};
