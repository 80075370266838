import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IBiller } from "../../../../types/main/biller";
import { AllBillers, DeleteBiller } from "../service/BillerService";

interface IBillerTable
  extends Omit<IBiller, "createdId" | "createdAt" | "apis"> {
  apis: string;
}

export const BillerList: React.FC = () => {
  const [billers, setBillers] = useState<Array<IBillerTable>>([]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Nome",
            accessor: "name",
          },
          {
            Header: "Token de Autenticação",
            accessor: "securityCode",
          },
          {
            Header: "API's Permitidas",
            accessor: "apis",
          },
          {
            Header: "ID do Biller",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const handleBillerList = async () => {
    setIsLoading(true);
    const response = await AllBillers();

    if (response.type === "success" && response.body) {
      const billerList = response.body.map(
        ({ uuid, name, securityCode, apis }) => {
          return {
            uuid,
            name,
            securityCode,
            apis: apis.join(", "),
          };
        }
      );

      setBillers(billerList);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    handleBillerList();
  }, []);

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Cadastros e Consultas",
      href: "/",
    },
    {
      description: "Tarifadores",
      href: "/billerlist",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex justifyContent="space-between" alignItems="center">
        <HLarge2xl mb="32px" color="gray.600">
          Gerenciamento de Tarifadores
        </HLarge2xl>
        <Box>
          <SimpleMenu label="Exibir colunas">
            <SelectableColumns />
          </SimpleMenu>
          <CreateNewDataButton
            module={MODULES.BILLERS}
            path="/managebiller"
            text="Cadastrar novo tarifador"
          />
        </Box>
      </Flex>
      <DataTable
        refreshTable={handleBillerList}
        module="Billers"
        isLoading={isLoading}
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={billers}
        popup={{
          refreshTable: handleBillerList,
          callToDelete: DeleteBiller,
          urlEdit: "/managebiller/",
          urlView: "/viewbiller/",
        }}
        showCheckbox={false}
      />
    </Wrapper>
  );
};
