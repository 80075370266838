/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useCallback, useEffect, useRef, useState } from "react";
import { Location, useLocation, useNavigate } from "react-router-dom";

import { customToast } from "../../../../main/common/utils/customToast";
import {
  IFilterRemittancesSimulation,
  IRemittanceSimulated,
  IRemittanceTariffCars,
  IRemittanceView,
} from "../../../../types/main/remittances";
import {
  createRemittances,
  simulateRemittances,
} from "../service/remittanceService";

const getFiltersFromLocationState = (location: Location) => {
  const { state } = location as {
    state: { filters: IFilterRemittancesSimulation };
  };
  if (state.filters) {
    return state.filters;
  }
  return null;
};

export const useSimulation = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [remittanceSimulated, setRemittanceSimulates] = useState<{
    isFetching: boolean;
    data: IRemittanceView[];
  }>({
    isFetching: true,
    data: [],
  });

  const [isMountingTable, setIsMountingTable] = useState(false);

  const hasCARWithNullPaymentMethod = useRef<IRemittanceTariffCars[]>();

  const handleRemittanceSimulation = useCallback(
    (remittances: IRemittanceSimulated) => {
      if (
        Array.isArray(remittances.simulation) &&
        remittances.simulation.length > 0
      ) {
        setIsMountingTable(true);
        hasCARWithNullPaymentMethod.current =
          remittances.carsWithoutPaymentMethod;

        setTimeout(() => {
          setRemittanceSimulates({
            isFetching: false,
            data: remittances.simulation.map((item) => ({
              ...item.remittance,
            })),
          });
          setIsMountingTable(false);
        }, 3000);
      } else {
        customToast(
          "info",
          "Nenhum CAR com Método de Pagamento encontrado.",
          5000
        );

        navigate(-1);
      }
    },
    [navigate]
  );

  const loadTariffsCarsFromFilters = useCallback(async () => {
    try {
      setRemittanceSimulates({
        data: [],
        isFetching: true,
      });
      const filters = getFiltersFromLocationState(location);
      const remittancesSimulation = await simulateRemittances(filters);
      handleRemittanceSimulation(remittancesSimulation);
    } catch (error: any) {
      customToast("error", error?.body?.message, 5000);
      setIsMountingTable(false);
    }
  }, [handleRemittanceSimulation, location]);

  const saveRemittances = useCallback(async () => {
    if (!remittanceSimulated) return;

    if (remittanceSimulated.data.some((item) => !item.remittanceDueDate)) {
      customToast("warning", "Há datas de vencimento não preenchidas.", 2000);
      return;
    }

    const response = await createRemittances(
      remittanceSimulated.data.map((remittanceCharge) => ({
        calculatePayload: remittanceCharge.calculatePayload,
        cars: remittanceCharge.cars,
        paymentMethodUuid: remittanceCharge.paymentMethodUuid,
        discardInterestAndFine: remittanceCharge.discardInterestAndFine,
        remittanceDueDate: remittanceCharge.remittanceDueDate!,
        remittanceValue: remittanceCharge.value,
        remittanceDiscount: remittanceCharge.totalDiscount,
        remittanceFine: remittanceCharge.totalFine,
        remittanceInterest: remittanceCharge.totalInterest,
        remittanceTotalValue: remittanceCharge.totalValue,
      }))
    );

    customToast("success", response.body?.message ?? "Dados Gravados", 2000);
    navigate("/remittancelist");
  }, [remittanceSimulated, navigate]);

  useEffect(() => {
    let isMounted = true;

    if (isMounted) loadTariffsCarsFromFilters();

    return () => {
      isMounted = false;
    };
  }, [loadTariffsCarsFromFilters]);

  return {
    saveRemittances,
    remittanceSimulated: remittanceSimulated.data,
    isLoading: remittanceSimulated.isFetching,
    setRemittanceSimulates,
    hasCARWithNullPaymentMethod,
    loadTariffsCarsFromFilters,
    isMountingTable,
  };
};
