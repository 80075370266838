import { useMsal } from "@azure/msal-react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { MenuItem } from "@chakra-ui/react";
import React from "react";

import { useAuth } from "../../hooks/useAuth";

export const Logout: React.FC = () => {
  const { instance } = useMsal();
  const { handleLogout } = useAuth();

  return (
    <MenuItem
      color="current"
      onClick={() => {
        handleLogout(instance);
      }}
      icon={<ArrowForwardIcon />}
      aria-label="Sair do sistema"
    >
      Sair
    </MenuItem>
  );
};
