import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Portal,
  Flex,
} from "@chakra-ui/react";
import { Table } from "@tanstack/react-table";

import { ExportFragment } from "../../Popover/ExportFragment";

type PopoverInvoiceProps = {
  table: Table<any>;
  headerObject: Record<string, string>;
};

export const CSVPopover = ({ table, headerObject }: PopoverInvoiceProps) => {
  const dataFiltered = table
    .getFilteredRowModel()
    .flatRows.map((item) => item.original);
  const data = table.getRowModel().flatRows.map((item) => item.original);

  return (
    <Popover placement="left">
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0">
                <Flex flexDir="column">
                  <ExportFragment
                    data={data}
                    dataFiltered={dataFiltered}
                    headerObject={headerObject}
                  />
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
