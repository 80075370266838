import { ChevronDownIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
} from "@chakra-ui/react";
import { debounceMyFunction } from "debounce-my-function";
import { ChangeEvent, useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useInterstarTheme } from "../../hooks/useInterstarTheme";
import { useMultiCheckboxSelect } from "./useMultiSelect";

interface IOptions {
  label: string;
  value: string | number;
}

interface IMultiSelectProps {
  defaultOptions: IOptions[];
  buttonLabel: string;
  fieldname: string;
}

export const MultiSelect = ({
  defaultOptions,
  buttonLabel,
  fieldname,
}: IMultiSelectProps) => {
  const { colors } = useInterstarTheme();

  const { setValue } = useFormContext();

  const [isAbleToSelectAll, setIsAbleToSelectAll] = useState(true);

  const {
    MultiSelectCheckbox,
    optionsCheckState,
    setCheckableOptions,
    placeholder,
  } = useMultiCheckboxSelect({
    options: defaultOptions,
    isAbleToSelectAll,
  });

  const searchForOptions = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;

    if (inputValue.length <= 2) {
      setCheckableOptions((oldOptions) =>
        oldOptions.map((option) => ({ ...option, visible: true }))
      );
      setIsAbleToSelectAll(true);
      return;
    }

    const filteredOption = defaultOptions.map((option) => ({
      ...option,
      visible: String(option.label.toLowerCase()).includes(
        inputValue.toLowerCase()
      ),
    }));

    setIsAbleToSelectAll(false);

    setCheckableOptions(filteredOption);
  };

  const searchForOptionsDebounced = debounceMyFunction(searchForOptions, 500);

  useEffect(() => {
    const checkedItems = optionsCheckState.filter((item) => item.checked);

    setValue(
      fieldname,
      checkedItems.map((item) => String(item.value))
    );
  }, [fieldname, optionsCheckState, setValue]);

  return (
    <Menu matchWidth>
      <MenuButton borderRadius="50" variant="solid" as={InputGroup}>
        <Input
          bg={colors.gray200}
          _placeholder={{ color: colors.blueInterstar }}
          _disabled={{ color: colors.gray700 }}
          isDisabled
          placeholder={!!placeholder ? placeholder : buttonLabel}
        />
        <InputRightElement zIndex="0">
          <ChevronDownIcon color={colors.gray700} />
        </InputRightElement>
      </MenuButton>
      <MenuList padding={3} maxWidth="282px" borderColor="gray.500">
        <InputGroup>
          <InputLeftElement
            pointerEvents="none"
            children={<Search2Icon color="gray.300" />}
          />
          <Input
            type="text"
            placeholder=""
            onKeyUp={searchForOptionsDebounced}
          />
        </InputGroup>
        <Box overflowY="scroll" maxH="200px">
          <MultiSelectCheckbox />
        </Box>
      </MenuList>
    </Menu>
  );
};
