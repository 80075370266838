import * as yup from "yup";

export const templateSchema = yup
  .object({
    name: yup
      .string()
      .required("Esse campo é obrigatório")
      .min(4, "Mínimo de 4 caracteres"),
    title: yup.string().required("Esse campo é obrigatório"),
    sender: yup
      .string()
      .email("Email em formato inválido")
      .required("Esse campo é obrigatório"),
    replyTo: yup.string().email("Email em formato inválido").nullable(),
    recipients: yup
      .array()
      .of(yup.string().email("Um dos destinatários não é um e-mail válido.")),
    emailBody: yup.string().required("Esse campo é obrigatório"),
  })
  .required();
