import axios, { ResponseType } from "axios";

import { downloadFileLink } from "../../common/utils/downloadFile";
import { recordApi } from "../../services/RecordService";

export interface IBlobError {
  response: {
    data: Blob | any;
  };
}

interface IDownloadFileProps {
  readonly apiDefinition?: {
    endpoint: string;
    method?: "get" | "post";
    payload?: any;
    responseType?: ResponseType;
  };
  readonly preDownloading: () => void;
  readonly postDownloading: () => void;
  readonly onError: (error: IBlobError) => Promise<void> | (() => void);
  readonly filename: string;
  readonly extension?: string;
}

export const useDownloadFile = ({
  apiDefinition,
  preDownloading,
  postDownloading,
  onError,
  filename,
  extension = "pdf",
}: IDownloadFileProps) => {
  const {
    endpoint,
    responseType = "blob",
    method = "get",
    payload,
  } = apiDefinition ?? {};

  const callApi = async (link?: string) => {
    if (!!link) {
      if (method === "get") return axios.get(link, { responseType });
      return axios.post(link, payload, { responseType });
    }
    if (!!endpoint) {
      if (method === "get") return recordApi.get(endpoint, { responseType });
      return recordApi.post(endpoint, payload, { responseType });
    }

    return { data: null };
  };

  const downloadFile = async (
    _?: any,
    link?: string,
    otherFilename?: string
  ) => {
    try {
      preDownloading();
      const { data } = await callApi(link);
      const url = URL.createObjectURL(new Blob([data]));
      downloadFileLink({
        url,
        filename: `${otherFilename || filename}.${extension}`,
      });
      URL.revokeObjectURL(url);
    } catch (error: any) {
      console.log("error", error);
      if (error?.response?.statusCode === 401) {
        window.sessionStorage.clear();
        window.location.href = window.location.origin;
      }
      onError(error);
    }
    postDownloading();
  };

  return { downloadFile };
};
