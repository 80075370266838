import { ModulesType } from "../types/modules";
import { MODULES } from "./modules";

export const PERMISSION_LEVELS = {
  ADMIN: "Admin",
  MASTER: "Master",
  OPERADOR: "Operador",
  AUDITOR: "Auditor",
} as const;

export type PermissionLevel =
  typeof PERMISSION_LEVELS[keyof typeof PERMISSION_LEVELS];

interface IMap {
  [key: string]: readonly ModulesType[];
}

export const CREATE_PERMISSIONS: IMap = {
  Master: Object.values(MODULES),
  Admin: Object.values(MODULES),
  Auditor: [
    MODULES.TARIFF,
    MODULES.CAR,
    MODULES.INVOICES,
    MODULES.REMITTANCE,
    MODULES.MOVIMENTS,
  ],
  Operador: [MODULES.CLIENTS],
} as const;

export const UPDATE_PERMISSIONS: IMap = {
  Master: Object.values(MODULES),
  Admin: Object.values(MODULES),
  Auditor: [
    MODULES.TARIFF,
    MODULES.CAR,
    MODULES.INVOICES,
    MODULES.REMITTANCE,
    MODULES.MOVIMENTS,
  ],
  Operador: [MODULES.CLIENTS],
} as const;

export const DELETE_PERMISSIONS: IMap = {
  Master: Object.values(MODULES),
  Admin: Object.values(MODULES),
  Auditor: [
    MODULES.TARIFF,
    MODULES.CAR,
    MODULES.INVOICES,
    MODULES.REMITTANCE,
    MODULES.MOVIMENTS,
  ],
  Operador: [],
} as const;
