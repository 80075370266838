import * as yup from "yup";

export const uniqueManualWriteOffSchema = yup
  .object({
    totalReceived: yup.number().required("Esse campo é obrigatório"),
    fineReceived: yup.number().required("Esse campo é obrigatório"),
    interestReceived: yup.number().required("Esse campo é obrigatório"),
    valueReceived: yup.number().required("Esse campo é obrigatório"),
    discountReceived: yup.number().required("Esse campo é obrigatório"),
    reasonForManualLow: yup.string().required("Esse campo é obrigatório"),
    writeOffReasons: yup.string().required("Esse campo é obrigatório"),
    dateOfLow: yup.string().required("Esse campo é obrigatório"),
  })
  .required();
