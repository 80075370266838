import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  Spinner,
} from "@chakra-ui/react";
import { useRef, useState } from "react";

import { customToast } from "../../../../../../main/common/utils/customToast";

type ConfirmationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  saveInvoices: () => Promise<void>;
};

export const ConfirmationModal = ({
  saveInvoices,
  isOpen,
  onClose,
}: ConfirmationModalProps) => {
  const cancelRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const confirm = async () => {
    setIsLoading(true);
    try {
      await saveInvoices();
    } catch (err: any) {
      setIsLoading(false);
      customToast("error", err?.body?.message, 5000);
    }
  };

  return (
    <AlertDialog
      motionPreset="slideInBottom"
      onClose={onClose}
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      isCentered
      closeOnEsc={false}
      closeOnOverlayClick={isLoading}
    >
      <AlertDialogOverlay />

      <AlertDialogContent>
        <AlertDialogHeader>
          {isLoading ? "Gerando faturamento..." : "Gerar Faturamento?"}
        </AlertDialogHeader>
        <AlertDialogCloseButton />
        <AlertDialogBody
          display="flex"
          flexDir="column"
          gap={4}
          alignItems="flex-start"
          justifyContent="center"
        >
          {isLoading
            ? "Aguarde enquanto geramos o faturamento."
            : "Confirmar a geração deste faturamento."}

          {isLoading && (
            <Spinner size="lg" color="green.500" alignSelf="center" />
          )}
        </AlertDialogBody>
        <AlertDialogFooter>
          <Button
            ref={cancelRef}
            onClick={onClose}
            colorScheme="gray"
            hidden={isLoading}
          >
            Cancelar ação
          </Button>
          <Button
            colorScheme="green"
            ml={3}
            onClick={confirm}
            disabled={isLoading}
          >
            Gerar
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
