import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import { DEFAULT_ISO_DATE_FORMAT } from "../../../common/constants/defaultValidations";

export const replaceObjectKeys = (obj: any, replace: any) => {
  const result: any = {};
  Object.keys(replace).forEach((key) => {
    if (key !== "uuid") {
      if (
        (Array.isArray(obj[key]) && obj[key].length === 0) ||
        obj[key] === null ||
        obj[key] === undefined
      )
        result[replace[key]] = "(vazio)";
      else {
        const isDate =
          !!Date.parse(obj[key]) && DEFAULT_ISO_DATE_FORMAT.test(obj[key]);

        const value = isDate
          ? format(new Date(obj[key]), "dd/MM/yyyy", {
              locale: ptBR,
            })
          : obj[key];

        result[replace[key]] = value;
      }
    }
  });

  return result;
};

export const formatDateToNameFile = (date: Date) => {
  return date
    .toLocaleDateString("en-ZA", {
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    })
    .replace(/,/g, "")
    .replace(/\//g, "-");
};
