import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Backdrop } from "../../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputSelect } from "../../../../../../main/components/InputSelect";
import { NumberFilterInput } from "../../../../../../main/components/NumberFilterInput";
import { TableForm } from "../../../../../../main/components/TableForm";
import { TLargexl } from "../../../../../../main/components/Tipography";
import { IChannelData } from "../../../../../../types/main/channel";
import {
  defaultServiceProduct,
  serviceProductsHeader,
} from "../../../constants/channelConfig.constants";
import { useChannelConfig } from "../../../hooks/ChannelConfig/useChannelConfig";
import { useExistingChannel } from "../../../hooks/ChannelConfig/useExistingChannelConfig";
import { useFetchChannelConfigLists } from "../../../hooks/ChannelConfig/useFetchChannelConfigLists";
import { channelConfigSchema } from "../../../validation/channelSchema";
import { WarningStep } from "../WarningStep";
import { ServiceProductRow } from "./ServiceProductRow";

interface IChannelForm {
  isReadOnly?: boolean;
  channelId?: string;
}

export const ChannelConfigForm = ({ isReadOnly, channelId }: IChannelForm) => {
  const [existingChannelConfig, setExistingChannelConfig] = useState<
    IChannelData | null | undefined
  >();
  const [endRequestExistingChannelConfig, setEndRequestExistingChannelConfig] =
    useState(false);

  const formMethods = useForm<IChannelData>({
    resolver: yupResolver(channelConfigSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    trigger,
    formState: { errors, isSubmitting },
  } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const dataFetched = useFetchChannelConfigLists({
    setIsLoading,
    waitForRequest: endRequestExistingChannelConfig,
    idListOnScreenProvider: existingChannelConfig?.providerUuid,
    idListOnScreenServiceProvision:
      existingChannelConfig?.servicesProducts?.map(
        ({ serviceProvisionUuid }) => serviceProvisionUuid
      ),
    idListOnScreenProduct: existingChannelConfig?.servicesProducts?.map(
      ({ productUuid }) => productUuid
    ),
    idListOnScreenPaymentMethod: existingChannelConfig?.servicesProducts?.map(
      ({ paymentMethodUuid }) => paymentMethodUuid
    ),
  });

  const {
    parentChannelsList,
    providersList,
    hasFetchError,
    ...serviceProductRowFormLists
  } = dataFetched;

  const { onSubmit, tableForm, inheritance, canAddNewServiceProduct } =
    useChannelConfig({
      channelId,
      watch,
      setIsLoading,
      isReadOnly,
    });

  const { hasExistingChannelFetchError } = useExistingChannel({
    id: channelId,
    setIsLoading,
    setEndRequestExistingChannelConfig,
    setExistingChannelConfig,
  });

  useEffect(() => {
    if (
      existingChannelConfig &&
      providersList &&
      parentChannelsList &&
      serviceProductRowFormLists.paymentMethodsList &&
      serviceProductRowFormLists.paymentMethodsList &&
      serviceProductRowFormLists.servicesList
    ) {
      setValue("id", existingChannelConfig.id);
      setValue("description", existingChannelConfig.description);
      setValue("inheritance", existingChannelConfig.inheritance);
      setValue("parentId", existingChannelConfig.parentId ?? undefined);
      setValue("providerUuid", existingChannelConfig.providerUuid);
      setValue(
        "servicesProducts",
        existingChannelConfig.servicesProducts ?? undefined
      );
    }
  }, [
    existingChannelConfig,
    parentChannelsList,
    providersList,
    serviceProductRowFormLists.paymentMethodsList,
    serviceProductRowFormLists.servicesList,
    setValue,
  ]);

  useEffect(() => {
    if (inheritance !== undefined) {
      trigger();
    }
  }, [inheritance, trigger]);

  if (hasFetchError || hasExistingChannelFetchError) {
    return (
      <WarningStep hasError={hasFetchError || hasExistingChannelFetchError} />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid
          h="100%"
          templateRows="(2, 1fr)"
          templateColumns="repeat(4, 1fr)"
          gap={4}
        >
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.inheritance}>
              <FormLabel htmlFor="inheritance">Herdar configurações?</FormLabel>
              <BooleanRadioGroup
                isReadOnly={isReadOnly}
                fieldname="inheritance"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
              />
              <FormErrorMessage>
                {errors.inheritance && errors.inheritance.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.parentId}>
              <FormLabel htmlFor="parentId">Canal Mãe</FormLabel>

              <InputSelect
                disabled={isReadOnly}
                name="parentId"
                placeholder="Canal Mãe"
                isClearable
                option={
                  parentChannelsList?.map(({ description, id }) => {
                    return { label: description, value: id.toString() };
                  }) ?? []
                }
              />

              <FormErrorMessage>
                {errors.parentId && errors.parentId.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.id}>
              <FormLabel htmlFor="id">ID do ponto de venda</FormLabel>
              <NumberFilterInput
                fieldname="id"
                disabled={isReadOnly || (!!channelId && !isReadOnly)}
                type="number"
                maxLength={10}
              />

              <FormErrorMessage>
                {errors.id && errors.id.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.providerUuid}>
              <FormLabel htmlFor="providerUuid">Prestador</FormLabel>
              <InputSelect
                disabled={isReadOnly}
                name="providerUuid"
                placeholder="Prestador"
                isClearable
                option={
                  providersList?.map(({ uuid, name, deletedAt }) => {
                    return {
                      label: deletedAt ? `(inativo) ${name}` : name,
                      value: uuid,
                      deletedAt,
                    };
                  }) ?? []
                }
              />

              <FormErrorMessage>
                {errors.providerUuid && errors.providerUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={4}>
            <FormControl isInvalid={!!errors.description}>
              <FormLabel htmlFor="description">Descrição</FormLabel>
              <Input
                isReadOnly={isReadOnly}
                _readOnly={{
                  cursor: "not-allowed",
                }}
                id="description"
                {...register("description")}
                autoComplete="off"
              />
              <FormErrorMessage>
                {errors.description && errors.description.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <TLargexl mb="1em" mt="2rem" alignSelf="flex-start" textAlign="left">
          Seleção de Serviços/Produtos
        </TLargexl>
        <Grid h="100%" templateRows="(1, 1fr)" templateColumns="1fr" gap={4}>
          <GridItem colSpan={1} opacity={inheritance ? 0.9 : 1}>
            <TableForm
              ref={tableForm}
              tableHeader={serviceProductsHeader}
              rowReferenceName="servicesProducts"
              canDeleteRow={!isReadOnly && !inheritance}
              canAddRow={!isReadOnly && canAddNewServiceProduct && !inheritance}
              defaultNewRow={defaultServiceProduct}
              renderProp={(index) => (
                <ServiceProductRow
                  isReadOnly={isReadOnly || inheritance}
                  index={index}
                  {...serviceProductRowFormLists}
                />
              )}
            />
          </GridItem>
        </Grid>

        <Flex w="100%" justify="flex-end" mb="2rem" mt="2rem">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/channelslist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                {channelId ? "Confirmar alterações" : "Cadastrar canal"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
        {isLoading && <Backdrop />}
      </form>
    </FormProvider>
  );
};
