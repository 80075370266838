export const emailConfigTableHeader = [
  {
    name: "CATEGORIA DE PRODUTO",
  },
  {
    name: "TEMPLATE",
  },
  {
    name: "OBJETIVO",
  },
];
