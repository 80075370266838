import {
  PERMISSION_LEVELS,
  UPDATE_PERMISSIONS,
} from "../../../common/constants/permissions";
import { ModulesType } from "../../../common/types/modules";

type CanEditProps = {
  permissionLevel: string;
  module: ModulesType;
  userProfile?: string;
};

const canEditUser = (permissionLevel: string, userProfile?: string) => {
  const isSelectedUserAdminOrMaster =
    userProfile === PERMISSION_LEVELS.MASTER ||
    userProfile === PERMISSION_LEVELS.ADMIN;

  if (
    permissionLevel === PERMISSION_LEVELS.ADMIN &&
    isSelectedUserAdminOrMaster
  )
    return false;

  return true;
};

export const canEdit = ({
  module,
  permissionLevel,
  userProfile,
}: CanEditProps) => {
  const permittedModules =
    (UPDATE_PERMISSIONS[permissionLevel] as ModulesType[]) || [];

  if (!permittedModules.includes(module)) return false;

  if (!!userProfile) return canEditUser(permissionLevel, userProfile);

  return true;
};
