import { useEffect, useState } from "react";

import { IChannelData } from "../../../../../types/main/channel";
import { getChannelConfigById } from "../../services/ChannelService";

interface IUseExistingChannel {
  id: string | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setEndRequestExistingChannelConfig: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setExistingChannelConfig: React.Dispatch<
    React.SetStateAction<IChannelData | null | undefined>
  >;
}

export const useExistingChannel = ({
  id,
  setIsLoading,
  setEndRequestExistingChannelConfig,
  setExistingChannelConfig,
}: IUseExistingChannel) => {
  const [hasExistingChannelFetchError, setHasExistingChannelFetchError] =
    useState(false);

  useEffect(() => {
    let isCancelled = false;

    const loadChannel = async (id: string) => {
      try {
        setIsLoading(true);
        const channels = await getChannelConfigById({
          id,
          withProviderData: true,
        });
        if (!isCancelled) {
          setExistingChannelConfig(channels);
          setIsLoading(false);
        }
        setEndRequestExistingChannelConfig(true);
      } catch (e) {
        if (!isCancelled) setHasExistingChannelFetchError(true);
        setEndRequestExistingChannelConfig(true);
      }
    };

    if (id) {
      loadChannel(id);
    } else {
      setEndRequestExistingChannelConfig(true);
    }

    return () => {
      isCancelled = true;
    };
  }, [
    id,
    setHasExistingChannelFetchError,
    setIsLoading,
    setEndRequestExistingChannelConfig,
    setExistingChannelConfig,
  ]);

  return {
    hasExistingChannelFetchError,
  };
};
