import { useEffect, useState } from "react";

import { IServiceProvisionOption } from "../../../../types/main/serviceProvision";
import { getServiceProvisionOptions } from "../../ServiceProvision/service/ServiceProvisionService";

export const useFetchDataList = ({
  waitForRequest,
  idsListOnScreen,
}: {
  waitForRequest: boolean;
  idsListOnScreen?: string[];
}) => {
  const [servicesList, setServicesList] = useState<IServiceProvisionOption[]>(
    []
  );

  useEffect(() => {
    if (waitForRequest) {
      const loadServices = async () => {
        const allServices = await getServiceProvisionOptions({
          return_even_if_deleted: idsListOnScreen,
        });
        setServicesList(allServices?.body ?? []);
      };

      loadServices();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitForRequest]);

  return {
    servicesList,
  };
};
