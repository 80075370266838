import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  NumberInput,
  NumberInputField,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import { memo } from "react";

import { TSmallsm } from "../../../Tipography";
import { PAGE_SIZE_OPTIONS } from "../../constants/dataTable.constants";
import { useTableContext } from "../../context/useTableContext";

interface IPaginationProps {
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  pageCount: number;
  gotoPage: (updater: number | ((pageIndex: number) => number)) => void;
  nextPage: () => void;
  previousPage: () => void;
  setPageSize: (pageSize: number) => void;
  pageIndex: number;
  pageSize: number;
}

export const TablePagination = memo(
  ({
    canNextPage,
    canPreviousPage,
    gotoPage,
    nextPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
    previousPage,
    setPageSize,
  }: IPaginationProps) => {
    const { isFilterOpen } = useTableContext();

    const handlePageNumberInputChange = (value: any) => {
      const page = value ? value - 1 : 0;
      gotoPage(page);
    };

    return (
      <Flex justifyContent="space-between" m={4} alignItems="center" mb="2rem">
        <Flex alignItems="center" zIndex={isFilterOpen ? "-1" : "0"}>
          <TSmallsm as="span" mr="8px">
            Exibir linhas por página
          </TSmallsm>
          <Select
            w="75px"
            value={pageSize}
            onChange={(e: any) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {PAGE_SIZE_OPTIONS.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Select>
        </Flex>
        <Flex alignItems="center" zIndex={isFilterOpen ? "-1" : "0"}>
          <TSmallsm flexShrink="inherit" mr="8px">
            Ir para a página
          </TSmallsm>
          <NumberInput
            colorScheme="gray"
            w="60px"
            min={1}
            max={pageOptions.length}
            focusBorderColor="gray.100"
            onChange={handlePageNumberInputChange}
            defaultValue={pageIndex + 1}
          >
            <NumberInputField />
          </NumberInput>
          <Flex>
            <Tooltip label="Primeira Página">
              <IconButton
                variant="unstyled"
                _focus={{ borderColor: "white" }}
                aria-label="First"
                onClick={() => gotoPage(0)}
                isDisabled={!canPreviousPage}
                icon={<ArrowLeftIcon h={3} w={3} />}
                mr={4}
              />
            </Tooltip>
            <Tooltip label="Pagina Anterior">
              <IconButton
                _focus={{ borderColor: "white" }}
                variant="unstyled"
                aria-label="Next"
                onClick={previousPage}
                isDisabled={!canPreviousPage}
                icon={<ChevronLeftIcon h={6} w={6} />}
              />
            </Tooltip>
          </Flex>
          <Flex alignItems="center">
            <TSmallsm flexShrink="inherit" mr={8}>
              <TSmallsm as="span">{pageIndex + 1}</TSmallsm> de{" "}
              <TSmallsm as="span">{pageOptions.length || 1}</TSmallsm>
            </TSmallsm>
          </Flex>
          <Flex>
            <Tooltip label="Próxima Página">
              <IconButton
                _focus={{ borderColor: "white" }}
                variant="unstyled"
                aria-label="Next"
                onClick={nextPage}
                isDisabled={!canNextPage}
                icon={<ChevronRightIcon h={6} w={6} />}
              />
            </Tooltip>
            <Tooltip label="Última Página">
              <IconButton
                _focus={{ borderColor: "white" }}
                variant="unstyled"
                aria-label="last"
                onClick={() => gotoPage(pageCount - 1)}
                isDisabled={!canNextPage}
                icon={<ArrowRightIcon h={3} w={3} />}
                ml={4}
              />
            </Tooltip>
          </Flex>
        </Flex>
      </Flex>
    );
  }
);
