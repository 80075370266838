import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { Backdrop } from "../../../../../../main/components/Backdrop";
import { EnumRadioGroup } from "../../../../../../main/components/CustomRadioGroup/EnumRadioGroup";
import { InputSelectAsync } from "../../../../../../main/components/InputSelectAsync";
import { NumberFilterInput } from "../../../../../../main/components/NumberFilterInput";
import { IChannelERPConfigData } from "../../../../../../types/main/channelERPConfig";
import { getClientOptions } from "../../../../Clients/service/ClientService";
import { useChannelERP } from "../../../hooks/ChannelERP/useChannelERP";
import { useFetchChannelERP } from "../../../hooks/ChannelERP/useFetchERPConfig";
import { channelERPSchema } from "../../../validation/channelERPSchema";
import { WarningStep } from "../WarningStep";

interface IChannelInvoiceProps {
  goBackToConfigTab: () => void;
  isReadOnly?: boolean;
  channelId?: string;
}

export const ChannelERP = ({
  isReadOnly,
  goBackToConfigTab,
  channelId,
}: IChannelInvoiceProps) => {
  const formMethods = useForm<IChannelERPConfigData>({
    resolver: yupResolver(channelERPSchema),
  });

  const {
    handleSubmit,
    setValue,
    formState: { isSubmitting, errors },
  } = formMethods;

  const [isLoading, setIsLoading] = useState(false);

  const [shouldBlockPercentageValue, setShouldBlockPercentageValue] =
    useState(true);

  const { hasFetchError, clientList, existingERPConfig } = useFetchChannelERP({
    setIsLoading,
    channelId,
  });

  const { onSubmit } = useChannelERP({
    channelId,
    setIsLoading,
    clientList,
  });

  useEffect(() => {
    if (existingERPConfig && clientList) {
      setValue("uuid", existingERPConfig.uuid);
      setValue("holderUuid", existingERPConfig.holderUuid);
      setValue("type", existingERPConfig.type);
      setValue("percentageValue", existingERPConfig.percentageValue);
      if (existingERPConfig.percentageValue) {
        setShouldBlockPercentageValue(false);
      }
    }
  }, [clientList, existingERPConfig, setValue]);

  if (!channelId || hasFetchError) {
    return (
      <WarningStep
        goTo={!hasFetchError ? goBackToConfigTab : undefined}
        hasError={hasFetchError}
      />
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
          position: "relative",
        }}
      >
        <Grid
          h="100%"
          templateRows="(1, 1fr)"
          templateColumns="repeat(3, 1fr)"
          gap={4}
        >
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.type}>
              <FormLabel htmlFor="type">Tipo</FormLabel>
              <EnumRadioGroup
                defaultCustomValue="INTEGRAL"
                isReadOnly={isReadOnly}
                fieldname="type"
                options={[
                  { label: "Valor integral", value: "INTEGRAL" },
                  { label: "Fração de valor", value: "FRACTIONAL" },
                ]}
                onCheckChange={(value) => {
                  if (value === "FRACTIONAL") {
                    setShouldBlockPercentageValue(false);
                  } else {
                    setShouldBlockPercentageValue(true);
                    setValue("percentageValue", undefined);
                  }
                }}
              />
              <FormErrorMessage>
                {errors.type && errors.type.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>

          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.percentageValue}>
              <FormLabel htmlFor="percentageValue">Valor Percentual</FormLabel>
              <NumberFilterInput
                isReadOnly={isReadOnly || shouldBlockPercentageValue}
                fieldname="percentageValue"
                type="percentage"
                InputProps={{
                  min: 0.01,
                  max: 999.99,
                  type: "number",
                  step: 0.01,
                }}
              />

              <FormErrorMessage>
                {errors.percentageValue && errors.percentageValue.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.holderUuid}>
              <FormLabel htmlFor="holderUuid">Titular</FormLabel>
              {clientList && (
                <InputSelectAsync
                  fetchOptions={getClientOptions}
                  disabled={isReadOnly}
                  name="holderUuid"
                  placeholder="Mesmo cliente CAR"
                  option={
                    clientList.map((option) => {
                      return {
                        ...option,
                        label: option.deletedAt
                          ? `(inativo) ${option.fullName}`
                          : option.fullName,
                      };
                    }) ?? []
                  }
                />
              )}

              <FormErrorMessage>
                {errors.holderUuid && errors.holderUuid.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Flex w="100%" justify="flex-end" mb="2rem" mt="4rem">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/channelslist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                Confirmar alterações
              </Button>
            )}
          </ButtonGroup>
        </Flex>
        {isLoading && <Backdrop />}
      </form>
    </FormProvider>
  );
};
