import { ViewIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { exportmeExcel } from "excel-ent";
import { useCallback, useState } from "react";
import { IconExportWhite, IconSort } from "../../../../main/common/assets";
import { formatDateToNameFile } from "../../../../main/components/Popover/functions/exportHelper";
import { HLarge2xl, HSmallmd } from "../../../../main/components/Tipography";
import { ITariffMovementsFields } from "../../../../types/main/tariffMovements";
import { GetTariffMovementsByIdOrUuid } from "../service/TariffMovementsService";

type IProps =
  | {
      tariffId: number;
      uuid?: string;
      setIsLoading?: (loading: boolean) => void;
      onCloseMenu?: () => void;
    }
  | {
      tariffId?: number;
      uuid: string;
      setIsLoading?: (loading: boolean) => void;
      onCloseMenu?: () => void;
    };

export const TariffMovementDetailsModal = ({
  tariffId,
  uuid,
  setIsLoading,
  onCloseMenu,
}: IProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [tariffMovement, setTariffMovement] =
    useState<ITariffMovementsFields[]>();

  const [sortTo, setSortTo] = useState("DESC");

  const handleClick = useCallback(
    async ({ tariffId, uuid }: { tariffId?: number; uuid?: string }) => {
      onCloseMenu && onCloseMenu();
      setIsLoading && setIsLoading(true);
      if (tariffId) {
        const { body } = await GetTariffMovementsByIdOrUuid({ tariffId });
        body && setTariffMovement(body);
      } else if (uuid) {
        const { body } = await GetTariffMovementsByIdOrUuid({
          tariffCarUuid: uuid,
        });
        body && setTariffMovement(body);
      }
      setIsLoading && setIsLoading(false);
      onOpen();
    },
    [onOpen]
  );

  function handleExportAllData() {
    const now = new Date();
    const now_str = formatDateToNameFile(now);

    const newArray = tariffMovement?.map(
      ({
        createdAt,
        description,
        tariffId,
        username,
        tariffMovementsEvents,
      }) => {
        return tariffMovementsEvents.map(({ label, oldValue, newValue }) => {
          return {
            "Data do evento": new Date(createdAt).toLocaleDateString("pt-br", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
              second: "2-digit",
            }),
            "Descrição do evento": description,
            "Usuário que realizou o evento": username,
            "Id da tarifa": tariffId,
            Evento: label,
            "Valor antigo": oldValue,
            "Valor novo": newValue,
          };
        });
      }
    );

    exportmeExcel(
      newArray?.flat() || [],
      `Movimentações tarifa - ${tariffId} ${now_str}`,
      {
        type: "download",
      }
    );
  }

  return (
    <>
      <Flex
        py="8px"
        as="button"
        alignItems="center"
        color="gray.500"
        _hover={{ color: "#444" }}
        transition="all 0.2s ease-in-out"
        onClick={() => handleClick({ tariffId, uuid })}
      >
        <ViewIcon color="gray.500" />
        <HSmallmd ml="8.46px">Movimentações</HSmallmd>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton position="fixed" />
          <ModalBody>
            <Box ml="160px" mr="160px">
              <Flex
                py="6px"
                mb="32px"
                mt="40px"
                color="gray.600"
                position="sticky"
                top="0"
                backgroundColor="white"
                zIndex={2}
                w="100%"
                justify="space-between"
              >
                <HLarge2xl>
                  Movimentações tarifa -{" "}
                  {(tariffMovement && tariffMovement[0]?.tariffId) || ""}
                </HLarge2xl>
                <Box>
                  <Button
                    onClick={handleExportAllData}
                    leftIcon={<IconExportWhite />}
                    colorScheme="blue"
                    mr="16px"
                  >
                    Exportar
                  </Button>
                  <Popover>
                    <PopoverTrigger>
                      <Button leftIcon={<IconSort />}>Ordenar</Button>
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <RadioGroup onChange={setSortTo} value={sortTo}>
                          <Stack spacing={5}>
                            <Text>Ordenar listagem por</Text>
                            <Radio
                              value="DESC"
                              onClick={(e) => {
                                console.log(e);
                              }}
                            >
                              Mais recentes
                            </Radio>
                            <Radio
                              value="ASC"
                              onClick={(e) => {
                                console.log(e);
                              }}
                            >
                              Mais antigas
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      </PopoverBody>
                    </PopoverContent>
                  </Popover>
                </Box>
              </Flex>
              <Accordion defaultIndex={[0]} allowToggle>
                {tariffMovement &&
                  tariffMovement
                    .sort((a, b) => {
                      if (sortTo === "ASC") {
                        return (
                          new Date(a.createdAt).getTime() -
                          new Date(b.createdAt).getTime()
                        );
                      }
                      return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                      );
                    })
                    .map(
                      ({
                        description,
                        uuidMovements,
                        tariffMovementsEvents,
                        username,
                        createdAt,
                      }) => (
                        <Box
                          boxShadow="xl"
                          // p="6"
                          rounded="md"
                          // bg="white"
                          key={uuidMovements}
                          my="20px"
                        >
                          <AccordionItem key={uuidMovements} py="16px">
                            <h2>
                              <AccordionButton>
                                <Flex justifyContent="space-between" flex={1}>
                                  <Flex justifyContent="space-between" flex={1}>
                                    <Box>{`${username} - ${description}`}</Box>
                                    <Box mr="16px">
                                      {createdAt &&
                                        new Date(createdAt).toLocaleDateString(
                                          "pt-br",
                                          {
                                            day: "2-digit",
                                            month: "2-digit",
                                            year: "numeric",
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            second: "2-digit",
                                          }
                                        )}
                                    </Box>
                                  </Flex>

                                  <AccordionIcon />
                                </Flex>
                              </AccordionButton>
                            </h2>
                            <AccordionPanel pb="36px">
                              <>
                                <Box>Detalhes do evento</Box>
                                <Divider
                                  my="20px"
                                  size="2px"
                                  color="gray.300"
                                  borderBottomWidth="3px"
                                />
                                <TableContainer>
                                  <Table
                                    size="sm"
                                    variant="striped"
                                    colorScheme="gray"
                                  >
                                    <Thead>
                                      <Tr>
                                        <Th> </Th>
                                        <Th>ANTES</Th>
                                        <Th>DEPOIS</Th>
                                      </Tr>
                                    </Thead>
                                    <Tbody>
                                      {tariffMovementsEvents &&
                                        tariffMovementsEvents.map(
                                          ({
                                            id,
                                            label,
                                            newValue,
                                            oldValue,
                                          }) => (
                                            <Tr key={id}>
                                              <Td>{label}</Td>
                                              <Td>{oldValue}</Td>
                                              <Td>{newValue}</Td>
                                            </Tr>
                                          )
                                        )}
                                    </Tbody>
                                  </Table>
                                </TableContainer>
                              </>
                            </AccordionPanel>
                          </AccordionItem>
                        </Box>
                      )
                    )}
              </Accordion>
            </Box>
          </ModalBody>

          <ModalFooter
            justifyContent="space-between"
            ml="160px"
            mr="160px"
            position="sticky"
            bottom="0"
            backgroundColor="white"
          >
            <Button
              colorScheme="gray"
              variant="outline"
              size="sm"
              onClick={onClose}
            >
              Voltar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
