import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

import { ITariffWithInvoice } from "../../../../types/main/tariff";
import { WarningType } from "../constants/tariff.constants";

export const getTariffDueDateInfo = (tariff: ITariffWithInvoice) => {
  if (!!tariff.dueDate) {
    return format(new Date(tariff.dueDate), "dd/MM/yyyy", {
      locale: ptBR,
    });
  }

  if (!tariff.dueDate) {
    return "Nenhuma relação de Serviços X Canais";
  }
};

type WarningKeys = keyof typeof WarningType;

export const getWarningFromTariff = (
  tariff: ITariffWithInvoice
): WarningKeys | undefined => {
  if (!tariff.dueDate) return "missing";

  return undefined;
};
