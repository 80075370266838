import * as yup from "yup";

const MAX_INTEGER_VALUE = 2147483647;

export const channelConfigSchema = yup
  .object({
    id: yup
      .string()
      .test("Max Number", "ID excede os limites permitidos.", (value) => {
        if (!value) return false;
        return Number(value) <= MAX_INTEGER_VALUE;
      })
      .required("Esse campo é obrigatório"),
    description: yup.string().required("Esse campo é obrigatório"),
    inheritance: yup.boolean().required("Esse campo é obrigatório"),
    providerUuid: yup.string().required("Esse campo é obrigatório"),
    parentId: yup.string().when("inheritance", {
      is: true,
      then: yup.string().required("Esse campo é obrigatório"),
      otherwise: yup.string().notRequired(),
    }),
    servicesProducts: yup.array().when("inheritance", {
      is: true,
      then: yup.array().notRequired(),
      otherwise: yup.array().of(
        yup.object().shape({
          serviceProvisionUuid: yup
            .string()
            .required("Esse campo é obrigatório"),
          productUuid: yup.string().required("Esse campo é obrigatório"),
          paymentMethodUuid: yup.string().required("Esse campo é obrigatório"),
          dueDateType: yup.string().required("Esse campo é obrigatório"),
          dueDateRule: yup.number().required("Esse campo é obrigatório"),
          commissionType: yup.string().required("Esse campo é obrigatório"),
          commissionValue: yup.number().required("Esse campo é obrigatório"),
          commissionOrder: yup.string().required("Esse campo é obrigatório"),
          creditGuarantor: yup.boolean().required("Esse campo é obrigatório"),
          paymentMethodGuarantorUuid: yup.string().when("creditGuarantor", {
            is: true,
            then: yup.string().required("Esse campo é obrigatório"),
            otherwise: yup.string().nullable().notRequired(),
          }),
        })
      ),
    }),
  })
  .required();
