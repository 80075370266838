import { useToast } from "@chakra-ui/react";
import lightFormat from "date-fns/lightFormat";
import React, { useCallback } from "react";
import { Row, TableToggleAllRowsSelectedProps } from "react-table";

import { DefaultColumnFilter, fuzzyTextFilterFn } from "../Filters";

export const useDataTable = () => {
  const toast = useToast();

  function copyTextToClipboard(text: string, shouldCopy = true) {
    if (!navigator.clipboard || !text || !shouldCopy) {
      return;
    }
    navigator.clipboard.writeText(text).then(
      () => {
        toast({
          title: "Copiado para a área de transferência.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
      },
      (err) => {
        console.error("Async: Could not copy text: ", err);
      }
    );
  }

  const inputFilter = useCallback(
    (placeholder: string) =>
      ({ filter, onFilterChange }: any) =>
        (
          <input
            type="text"
            placeholder={placeholder}
            style={{
              width: "100%",
            }}
            value={filter ? filter.value : ""}
            onChange={(event) => onFilterChange(event.target.value)}
          />
        ),
    []
  );

  const filterRender = React.useMemo(
    () => ({
      makePlaceholderFilter(placeholder: any) {
        return inputFilter(placeholder);
      },
    }),
    [inputFilter]
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, ...rest }: any, ref: any) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);

      return (
        <input
          type="checkbox"
          style={{ width: "18px" }}
          ref={resolvedRef}
          {...rest}
        />
      );
    }
  );

  const headerComponent = ({
    getToggleAllRowsSelectedProps,
  }: {
    getToggleAllRowsSelectedProps: (
      props?: Partial<TableToggleAllRowsSelectedProps> | undefined
    ) => TableToggleAllRowsSelectedProps;
  }) => <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />;

  const cellComponent = ({ row }: { row: Row<object> }) => (
    <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
  );

  const filterTypes = React.useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      equals: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase() === filterValue
            : true;
        });
      },
      text: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
      textCommaSeparated: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue: number[] = row.values[id];
          const valueSplited = filterValue.split(",");
          const valueSplitedContainInRowValue = valueSplited.some(
            (value: string) =>
              String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase()) ||
              rowValue.indexOf(Number(value.trim())) !== -1
          );
          return rowValue !== undefined ? valueSplitedContainInRowValue : true;
        });
      },
      multi: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];

          if (rowValue !== undefined) {
            if (Array.isArray(rowValue)) {
              return rowValue.some((value: string) =>
                filterValue.includes(value)
              );
            }

            return filterValue.includes(rowValue);
          }

          return true;
        });
      },
      date: (rows: any, id: any, filterValue: any) => {
        return rows.filter((row: any) => {
          const rowValue = row.values[id];
          try {
            const dateRowValue = lightFormat(new Date(rowValue), "dd/MM/yyyy");
            return rowValue !== undefined ? dateRowValue === filterValue : true;
          } catch (err) {
            return rowValue !== undefined ? rowValue === filterValue : true;
          }
        });
      },
      currency: (rows: any, id: any, filterValue: string) => {
        return rows.filter((row: any) => {
          const rowValue = Number(row.values[id]).toFixed(2);

          const getPureValue = filterValue
            .replaceAll(".", "")
            .replaceAll(",", ".");

          return rowValue !== undefined
            ? rowValue.startsWith(getPureValue)
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  return {
    inputFilter,
    headerComponent,
    cellComponent,
    filterTypes,
    defaultColumn,
    filterRender,
    copyTextToClipboard,
  };
};
