import { ChevronRightIcon } from "@chakra-ui/icons";
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

import { TSmallmd } from "../Tipography";

export interface IBreadcrumbProps {
  description: string;
  href: string;
}

interface IBreadcrumb {
  breadcrumb: Array<IBreadcrumbProps>;
}

const CustomBreadcrumb: React.FC<IBreadcrumb> = ({ breadcrumb }) => {
  const navigate = useNavigate();

  return (
    <Breadcrumb
      mb="32px"
      spacing="8px"
      separator={<ChevronRightIcon color="gray.500" />}
    >
      {breadcrumb.map(({ description, href }) => (
        <BreadcrumbItem key={`${description}-${href}`}>
          <BreadcrumbLink
            onClick={() => {
              navigate(href);
            }}
          >
            <TSmallmd>{description}</TSmallmd>
          </BreadcrumbLink>
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export { CustomBreadcrumb };
