import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IChannelERPConfig,
  IChannelERPConfigData,
} from "../../../../types/main/channelERPConfig";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/erpconfig";

export const manageERPConfig = async ({
  erpConfigData,
  channelId,
}: {
  erpConfigData: IChannelERPConfigData;
  channelId: string;
}) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(`${MODULE_BASE_URL}/${channelId}`, {
        ...erpConfigData,
        percentageValue: erpConfigData.percentageValue
          ? Number(erpConfigData.percentageValue)
          : undefined,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getChannelERPConfig = async (channelId: string) => {
  try {
    const { data }: AxiosResponse<IResponse<IChannelERPConfig>> =
      await recordApi.get(`${MODULE_BASE_URL}/${channelId}`);

    return data;
  } catch (error: any) {
    throw error?.response?.data;
  }
};
