export const CREATE_INVOICES_PERMISSIONS_LEVEL = ["Master", "Admin", "Auditor"];

type IInvoiceMap = {
  [key: string]: boolean;
};

export const CAN_SET_MANUAL_LOW: IInvoiceMap = {
  Master: true,
  Admin: true,
  Auditor: true,
  Operador: false,
} as const;
