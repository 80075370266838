import { Box, Input } from "@chakra-ui/react";
import { useForm } from "react-hook-form";

import { CustomFilterProps } from "../TableHeader";

export const NormalFilter = ({ column }: CustomFilterProps) => {
  const { register, handleSubmit } = useForm<{ value: string }>();

  const onSubmit = (data: { value: string }) => {
    column.setFilterValue(data.value);
  };

  return (
    <Box w="100%">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          zIndex={0}
          {...register("value", {
            onChange: (e) => {
              if (!e.target.value) column.setFilterValue(e.target.value);
            },
          })}
        />
      </form>
    </Box>
  );
};
