import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { IconCheckFile } from "../../../../../../main/common/assets";
import { EnumRadioGroup } from "../../../../../../main/components/CustomRadioGroup/EnumRadioGroup";
import { DatePicker } from "../../../../../../main/components/DateTimePicker/DatePicker";
import {
  HLargexs,
  HSmallsm,
  TLargemd,
  TSmallsm,
} from "../../../../../../main/components/Tipography";
import { useAuth } from "../../../../../../main/hooks/useAuth";
import { IProcessingCarLow } from "../../../../../../types/main/processingCar";
import { IProcessingCarTypeOfLow } from "../../../../../../types/main/processingCarTypeOfLow";
import { getTariffCarById } from "../../../../CARs/service/CARsService";
import { getAllProcessingCarPaymentConfirmationType } from "../../../../CARs/service/ProcessingCarService";
import { CAN_PERMISSION_SET_MANUAL_LOW } from "../../../../Tariff/constants/permissions";
import { DEFAULT_VALIDATION_TARIFF_OPEN } from "../../../../Tariff/constants/tariff.constants";
import { GetTariffByID } from "../../../../Tariff/service/TariffService";
import { carManuallySchema } from "../../../../Tariff/validation/carManuallySchema";
import { CarManuallyConfirm } from "./CarManuallyConfirm";

const CarManually = ({
  typeButton,
  uuidTariff,
  uuidTariffCar,
  refreshTable,
  reloadCar,
  autoOpen,
  setDate,
  onCancelled,
  forcedManualLow = false,
}: {
  typeButton: "withIcon" | "button";
  uuidTariff?: number;
  uuidTariffCar?: string;
  refreshTable?: () => void;
  reloadCar?: () => void;
  autoOpen?: boolean;
  setDate?: Date;
  onCancelled?: () => void;
  forcedManualLow?: boolean;
}) => {
  const { permissionLevel } = useAuth();

  const methods = useForm<IProcessingCarLow>({
    resolver: yupResolver(carManuallySchema),
  });

  const [allProcessingCarTypeOfLowList, setAllProcessingCarTypeOfLowList] =
    useState<IProcessingCarTypeOfLow[]>([]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uuidsTariff, setUuidsTariff] = useState<string[]>();

  useEffect(() => {
    if (setDate) {
      methods.setValue("dateOfLow", setDate);
    }
  }, [setDate, methods]);

  const clear = useCallback(() => {
    setUuidsTariff(undefined);
    methods.setValue("processingCarTypeOfLowUuid", "");
    methods.setValue("dateOfLow", undefined);
    methods.setValue("reasonForManualLow", undefined);
    methods.setValue("uuidCar", "");
  }, [methods]);

  const handleOnOpen = useCallback(() => {
    onOpen();
    async function fetchData() {
      const response = await getAllProcessingCarPaymentConfirmationType();
      if (response.body) {
        setAllProcessingCarTypeOfLowList(response.body);
        methods.setValue("processingCarTypeOfLowUuid", response.body[0].uuid);
      }
      if (uuidTariff) {
        const response = await GetTariffByID(uuidTariff);
        if (response.body) {
          const uuidTariff = response.body.tariffCar
            .filter(({ processingCar }) =>
              DEFAULT_VALIDATION_TARIFF_OPEN.includes(
                processingCar.processingStatusCar.enum
              )
            )
            .map(({ uuid }) => uuid);
          if (uuidTariff.length > 0) {
            setUuidsTariff(uuidTariff);
          }
        }
      } else if (uuidTariffCar) {
        const response = await getTariffCarById(uuidTariffCar);
        if (response.body) {
          if (
            DEFAULT_VALIDATION_TARIFF_OPEN.includes(
              response.body.processingCar.processingStatusCar.enum
            )
          ) {
            setUuidsTariff([uuidTariffCar]);
          }
        }
      }
    }
    fetchData();
  }, [methods, onOpen, uuidTariff, uuidTariffCar]);

  useEffect(() => {
    if (autoOpen) {
      handleOnOpen();
    }
  }, [autoOpen, handleOnOpen]);

  const handleClose = useCallback(() => {
    onClose();
    clear();
    if (onCancelled) onCancelled();
  }, [clear, onClose, onCancelled]);

  const handleClosePreceding = useCallback(() => {
    onClose();
    clear();
    onCancelled && onCancelled();
  }, [clear, onClose, onCancelled]);

  if (!CAN_PERMISSION_SET_MANUAL_LOW[permissionLevel]) {
    return null;
  }

  return (
    <FormProvider {...methods}>
      {typeButton === "withIcon" && !autoOpen && (
        <Flex
          py="8px"
          as="button"
          alignItems="center"
          color="red.500"
          _hover={{ color: "red.600" }}
          transition="all 0.2s ease-in-out"
          onClick={handleOnOpen}
          mt="5px"
          gap="4px"
        >
          <IconCheckFile />
          <HSmallsm textAlign="left">Dar baixa manualmente</HSmallsm>
        </Flex>
      )}
      {typeButton === "button" && !autoOpen && (
        <Button
          onClick={handleOnOpen}
          colorScheme="red"
          variant="outline"
          size="sm"
        >
          Dar baixa manualmente
        </Button>
      )}

      <Modal
        closeOnOverlayClick={!forcedManualLow}
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        size="4xl"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            TARIFA - Dar baixa manualmente
            <TSmallsm color="red.500">
              CARs com Remessa em aberto serão mantidos.
            </TSmallsm>
          </ModalHeader>
          {!forcedManualLow && <ModalCloseButton onClick={handleClose} />}
          <ModalBody>
            <form>
              <Flex justifyContent="space-between">
                <EnumRadioGroup
                  fieldname="processingCarTypeOfLowUuid"
                  options={allProcessingCarTypeOfLowList.map(
                    ({ labelTypeOfLow, uuid }) => {
                      return {
                        label: labelTypeOfLow,
                        value: uuid,
                      };
                    }
                  )}
                />
                <Flex flexDirection="column">
                  <HLargexs whiteSpace="nowrap" color="gray.700" mb="8px">
                    Data da baixa
                  </HLargexs>
                  <DatePicker fieldname="dateOfLow" isDisabled={!!setDate} />
                </Flex>
              </Flex>
              <TLargemd mb="20px">Descreva o motivo da baixa manual</TLargemd>
              <Textarea
                {...methods.register("reasonForManualLow")}
                placeholder="Insira aqui o motivo"
                size="sm"
              />
            </form>
          </ModalBody>

          <ModalFooter>
            {!uuidsTariff && (
              <Text mr="30px" color="red.500" fontWeight="bold">
                Não há CAR para baixar
              </Text>
            )}
            {!forcedManualLow && (
              <Button
                colorScheme="gray"
                variant="outline"
                mr="12px"
                onClick={handleClose}
              >
                Cancelar
              </Button>
            )}
            {uuidsTariff && uuidsTariff.length > 0 && (
              <CarManuallyConfirm
                reloadCar={reloadCar}
                refreshTable={refreshTable}
                arrayuuidTariff={uuidsTariff}
                onClosePreceding={handleClosePreceding}
                forcedManualLow={forcedManualLow}
              />
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </FormProvider>
  );
};
export { CarManually };
