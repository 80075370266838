import { AxiosResponse } from "axios";
import { recordApi } from "../../../../main/services/RecordService";
import {
  IGetAllInvoicingTariffs,
  IInvoicingLogList,
  IInvoicingSimulation,
} from "../../../../types/main/invoicing";
import { IResponse } from "../../../../types/main/response";

const MODULE_BASE_URL = "/invoicing";

export const generateInvoicingSimulateCsv = async (
  filters: IGetAllInvoicingTariffs | null
) => {
  try {
    const payload = filters ?? null;

    const { data } = await recordApi.post(
      `${MODULE_BASE_URL}/generateinvoincingsimulatecsv`,
      payload
    );

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const simulateInvoices = async (
  filters: IGetAllInvoicingTariffs | null
) => {
  try {
    const payload = filters ?? null;

    const { data }: AxiosResponse<IInvoicingSimulation, IInvoicingSimulation> =
      await recordApi.post(`${MODULE_BASE_URL}/simulate`, payload);

    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const getAllInvoicing = async () => {
  try {
    const { data }: AxiosResponse<IResponse<IInvoicingLogList[]>> =
      await recordApi.get(MODULE_BASE_URL);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
