import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IProductCategories,
  IProductList,
  IProductOption,
  IProducts,
  IProductsData,
} from "../../../../types/main/products";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";

const MODULE_BASE_URL = "/products";

export const getProductOptions = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string[];
}): Promise<IResponse<Array<IProductOption>>> => {
  try {
    const response = await recordApi.get(
      return_even_if_deleted
        ? `${MODULE_BASE_URL}/options?return_even_if_deleted=${return_even_if_deleted.join(
            ";"
          )}`
        : `${MODULE_BASE_URL}/options`
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const allProducts = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string;
}): Promise<IResponse<Array<IProductList>>> => {
  try {
    const response = await recordApi.get(
      return_even_if_deleted
        ? `${MODULE_BASE_URL}?return_even_if_deleted=${return_even_if_deleted}`
        : MODULE_BASE_URL
    );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const allCategoryProducts = async (): Promise<
  IResponse<Array<IProductCategories>>
> => {
  try {
    const response: AxiosResponse<IResponse<Array<IProductCategories>>> =
      await recordApi.get(`${MODULE_BASE_URL}/category`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const deleteProducts = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewProduct = async (data: IProductsData) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, {
        ...data,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
interface IUpdateProducts extends IProductsData {
  uuid?: string;
}

export const UpdateProduct = async ({
  category,
  name,
  productMask,
  uuid,
}: IUpdateProducts) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, {
        category,
        name,
        productMask,
      });

    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const GetProductByUUID = async (
  uuid: string
): Promise<IResponse<IProducts>> => {
  try {
    const { data } = await recordApi.get(`${MODULE_BASE_URL}/search/${uuid}`);
    return data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
