import { getDaysInMonth, parse } from "date-fns";

const validateYear = (year: string): void => {
  const isYearComplete = year.length === 4;
  const MAX_YEAR = 2100;
  const MIN_YEAR = 1900;

  const isYearOutOfRange = Number(year) < MIN_YEAR || Number(year) > MAX_YEAR;

  if (isYearComplete && isYearOutOfRange) throw new Error("Invalid year");
};

const validateMonth = (month: string): void => {
  const isMonthComplete = month.length === 2;
  const isMonthOutOfRange = Number(month) < 1 || Number(month) > 12;

  if (isMonthComplete && isMonthOutOfRange) throw new Error("Invalid month");
};

const validateDay = (day: string): void => {
  const isDayComplete = day.length === 2;
  const isDayOutOfRange = Number(day) > 31;

  if (isDayComplete && isDayOutOfRange) throw new Error("Invalid day");
};

const validateDayInMonth = (day: string, month: string, year: string): void => {
  const numberOfDaysInMonth = getDaysInMonth(
    new Date(Number(year), Number(month) - 1)
  );

  const isDayOutOfRange = Number(day) > numberOfDaysInMonth;

  if (isDayOutOfRange) throw new Error("Invalid day");
};

export const checkIfStringDateIsValid = (date?: string): boolean => {
  if (!date) return true;

  const isValid = true;
  const isInvalid = false;

  const splittedDate = date.replaceAll("_", "").split("/");
  const day = splittedDate[0];
  const month = splittedDate[1];
  const year = splittedDate[2];

  try {
    validateDay(day);
    validateMonth(month);
    validateYear(year);
    if (year.length === 4) validateDayInMonth(day, month, year);

    return isValid;
  } catch (err) {
    return isInvalid;
  }
};

export const getSelectedDate = (date?: string): Date | undefined => {
  if (!date) return undefined;

  try {
    if (typeof date === "string" && date.includes("-")) {
      return new Date(date);
    }

    if (typeof date === "string") {
      return parse(date, "ddMMyyyy", new Date());
    }

    return date;
  } catch (err) {
    return parse(date, "ddMMyyyy", new Date());
  }
};
