import { useToast } from "@chakra-ui/react";

const MAX_FILE_SIZE = 3242880; // 3MB

export const useInputFile = () => {
  const toast = useToast();

  const validateImageFormat = (file: File) => {
    if (
      file.type !== "image/png" &&
      file.type !== "image/jpeg" &&
      file.type !== "image/jpg"
    ) {
      toast({
        title: "Formato inválido",
        description: "Formatos aceitos: PNG, JPEG e JPG.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      throw new Error("Formato inválido");
    }
  };

  const validateImageSize = (file: File) => {
    if (file.size >= MAX_FILE_SIZE) {
      toast({
        title: "Imagem inválida.",
        description: "Imagem excede o limite máximo de 5MB.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      throw new Error("Imagem inválida");
    }
  };

  return {
    validateImageFormat,
    validateImageSize,
  };
};
