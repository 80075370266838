import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { UseFormReset } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IClientData } from "../../../../types/main/client";
import { IMaritalStatus } from "../../../../types/main/maritalStatus";
import {
  EditClient,
  GetMaritalStatus,
  NewClient,
} from "../service/ClientService";

interface IClientForm {
  reset: UseFormReset<IClientData>;
  clientType: "PJ" | "PF";
  editClientUUID?: string | undefined;
}

export const useClientForm = ({ clientType, editClientUUID }: IClientForm) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [maritalList, setMaritalList] = useState<IMaritalStatus[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (clientData: IClientData) => {
      try {
        setIsLoading(true);

        let response;

        if (editClientUUID) {
          response = await EditClient(clientData, editClientUUID);
        } else response = await NewClient(clientData);

        toast({
          title: "Efetuado com sucesso.",
          description: response.body?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate("/clientlist");
      } catch (error: any) {
        setIsLoading(false);
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    },
    [editClientUUID, navigate, toast]
  );

  useEffect(() => {
    const loadMaritals = async () => {
      const allMaritalStatus = await GetMaritalStatus();
      setMaritalList(allMaritalStatus.body ?? []);
    };

    clientType === "PF" && loadMaritals();
  }, [clientType]);

  return {
    maritalList,
    isLoading,

    onSubmit,
  };
};
