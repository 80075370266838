import { Routes, Route } from "react-router-dom";

import { BackgroundLogsList } from "./pages/BackgroundLogsList";

export function BackgroundProcessesLogsModule() {
  return (
    <Routes>
      <Route path="/invoice-remittance-logs" element={<BackgroundLogsList />} />
    </Routes>
  );
}
