import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react";

const zIndices = {
  zIndices: {
    overlay: 9,
    modal: 10,
  },
};

const customTheme = extendTheme(
  withDefaultColorScheme({
    colorScheme: "blue",
  }),
  {
    styles: {
      global: {
        "::-webkit-scrollbar": {
          width: "50",
          borderRadius: "10px",
        },
        "::-webkit-scrollbar-track": {
          background: "#f1f1f1",
          borderRadius: "100px",
        },
        body: {
          bg: "white",
        },
      },
    },
    fonts: {
      body: "Inter, sans-serif",
    },
  },
  zIndices
);

const interstarFigmaTheme = {
  colors: {
    blueInterstar: "#2C58A4",
    blueInterstarLighter: "#3182CE",
    black: "#000",
    white: "#FFF",
    gray50: "#F7FAFC",
    gray100: "#EDF2F7",
    gray200: "#E2E8F0",
    gray300: "#CBD5E0",
    gray400: "#A0AEC0",
    gray500: "#718096",
    gray600: "#4A5568",
    gray700: "#2D3748",
    gray800: "#1A202C",
    gray900: "#171923",
    red50: "#FFF5F5",
    red100: "#FED7D7",
    red200: "#FEB2B2",
    red300: "#FC8181",
    red400: "#F56565",
    red500: "#E53E3E",
    red600: "#C53030",
    red700: "#9B2C2C",
    red800: "#822727",
    red900: "#63171B",
    yellow50: "#FFFFF0",
    yellow100: "#FEFCBF",
    yellow200: "#FAF089",
    yellow300: "#F6E05E",
    yellow400: "#ECC94B",
    yellow500: "#D69E2E",
    yellow600: "#B7791F",
    yellow700: "#975A16",
    yellow800: "#744210",
    yellow900: "#5F370E",
    green50: "#F0FFF4",
    green100: "#C6F6D5",
    green200: "#9AE6B4",
    green300: "#68D391",
    green400: "#48BB78",
    green500: "#38A169",
    green600: "#25855A",
    green700: "#276749",
    green800: "#22543D",
    green900: "#1C4532",
  },
  fonts: {
    headingLargeWeight: 700,
    headingSmallWeight: 600,
    textLargeWeight: 500,
    textSmallWeight: 400,
    fontSize4XL: `${3.75}rem`,
    fontSize3XL: `${3}rem`,
    fontSize2XL: `${2.25}rem`,
    fontSizeXL: `${1.875}rem`,
    fontSizeLG: `${1.5}rem`,
    fontSizeMD: `${1.25}rem`,
    fontSizeSM: `${1}rem`,
    fontSizeXM: `${0.875}rem`,
  },
};

export { customTheme, interstarFigmaTheme };
