import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IProducts } from "../../../../types/main/products";
import { GetProductByUUID } from "../service/ProductService";

export const useExistingProduct = (uuid: string | undefined) => {
  const toast = useToast();

  const [existingProductData, setExistingProductData] = useState<
    IProducts | undefined
  >();

  const getExistingProduct = useCallback(
    async (uuid: string) => {
      try {
        const response = await GetProductByUUID(uuid);
        setExistingProductData(response.body ?? undefined);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (uuid) {
      getExistingProduct(uuid);
    }
  }, [getExistingProduct, uuid]);

  return { existingProductData };
};
