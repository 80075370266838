import { Flex } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography/HeadingLarge";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ContractForm } from "../components/ContractForm";

interface IProductForm {
  isReadOnly?: boolean;
}

export const ContractManagement: React.FC<IProductForm> = ({ isReadOnly }) => {
  const { uuid } = useParams();

  const isEditing = !!uuid;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Contratos", href: "/contractList" },
    {
      description: isReadOnly
        ? "Visualizar contrato"
        : uuid
        ? "Editar contrato"
        : "Cadastrar contrato",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="32px" color="gray.600">
          {isReadOnly
            ? "Visualizar contrato"
            : isEditing
            ? "Editar contrato"
            : "Cadastrar contrato"}
        </HLarge2xl>
      </Flex>
      <ContractForm contractUUID={uuid} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
