import { useEffect, useState } from "react";

import { getProductOptions } from "../../../modules/Records/Products/service/ProductService";
import { IProductOption } from "../../../types/main/products";

export const useProductOptions = () => {
  const [products, setProducts] = useState<IProductOption[]>([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await getProductOptions({
        return_even_if_deleted: [],
      });
      setProducts(response.body || []);
    };

    fetchProducts();
  }, []);

  return {
    productsOptions: products.map((item) => ({
      value: item.uuid,
      label: item.name,
    })),
  };
};
