import { useEffect, useState } from "react";

import { getProfiles } from "../service/UserService";

interface IProfileData {
  uuid: string;
  name: string;
}

interface IUseFetchProfile {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const useFetchProfiles = ({ setIsLoading }: IUseFetchProfile) => {
  const [profiles, setProfiles] = useState<Array<IProfileData>>([]);

  useEffect(() => {
    let isCancelled = false;

    const handleProfileList = async () => {
      setIsLoading(true);
      const profiles = await getProfiles();

      if (profiles.data.type === "success" && profiles.data.body) {
        const profileList = profiles.data.body.map(({ uuid, name }) => {
          return { uuid, name };
        });
        !isCancelled && setProfiles(profileList);
      }
      !isCancelled && setIsLoading(false);
    };

    handleProfileList();

    return () => {
      isCancelled = true;
    };
  }, [setIsLoading]);

  return { profiles };
};
