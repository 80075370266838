import { Flex } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { UserForm } from "../components/UserForm";

interface IUserForm {
  isReadOnly?: boolean;
}

export const UserManagement: React.FC<IUserForm> = ({ isReadOnly }) => {
  const { uuid } = useParams();

  const isEditing = !!uuid;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Usuários", href: "" },
    { description: "Gerenciamento de usuários", href: "/userlist" },
    {
      description: isReadOnly
        ? "Visualizar usuário"
        : uuid
        ? "Editar usuário"
        : "Cadastrar usuário",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="64px" color="gray.600">
          {isReadOnly
            ? "Visualizar usuário"
            : isEditing
            ? "Editar usuário"
            : "Cadastrar usuário"}
        </HLarge2xl>
      </Flex>
      <UserForm userUUID={uuid} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
