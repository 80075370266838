import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Box,
  Grid,
  GridItem,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
} from "@chakra-ui/react";

import { HLargexs, HSmallmd } from "../../../../../main/components/Tipography";

interface IProps {
  totalInterestReceived: number;
  totalInterestProvided: number;
  totalFineReceived: number;
  totalFineProvided: number;
  totalReceived: number;
  totalProvided: number;
}

export const TariffValues = ({
  totalInterestReceived,
  totalFineReceived,
  totalReceived,
  totalInterestProvided,
  totalFineProvided,
  totalProvided,
}: IProps) => {
  return (
    <Popover trigger="hover">
      <PopoverTrigger>
        <InfoOutlineIcon width="15px" height="15px" />
      </PopoverTrigger>
      <Portal>
        <PopoverContent w="100%">
          <PopoverArrow />
          <PopoverBody p="0px" m="0px">
            <Box>
              <Grid templateColumns="repeat(3, 4fr)" gap="8px 16px">
                <GridItem mt="24px" ml="24px">
                  <HLargexs
                    textAlign="right"
                    whiteSpace="nowrap"
                    color="gray.500"
                  >
                    JUROS RECEBIDO
                  </HLargexs>
                </GridItem>
                <GridItem mt="24px">
                  <HLargexs
                    textAlign="right"
                    whiteSpace="nowrap"
                    color="gray.500"
                  >
                    MULTA RECEBIDA
                  </HLargexs>
                </GridItem>
                <GridItem mt="24px" mr="24px">
                  <HLargexs
                    textAlign="right"
                    whiteSpace="nowrap"
                    color="gray.500"
                  >
                    VALOR RECEBIDO
                  </HLargexs>
                </GridItem>
                <GridItem ml="24px" mb="24px">
                  <HSmallmd textAlign="right">
                    {Number(totalInterestReceived).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </HSmallmd>
                </GridItem>
                <GridItem mb="24px">
                  <HSmallmd textAlign="right">
                    {Number(totalFineReceived).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </HSmallmd>
                </GridItem>
                <GridItem mb="24px" mr="24px">
                  <HSmallmd textAlign="right">
                    {Number(totalReceived).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </HSmallmd>
                </GridItem>
              </Grid>
            </Box>
            <Box bg="gray.100">
              <Grid templateColumns="repeat(3, 4fr)" gap="8px 16px">
                <GridItem mt="24px" ml="24px">
                  <HLargexs
                    textAlign="right"
                    whiteSpace="nowrap"
                    color="gray.500"
                  >
                    JUROS PREVISTO
                  </HLargexs>
                </GridItem>
                <GridItem mt="24px">
                  <HLargexs
                    textAlign="right"
                    whiteSpace="nowrap"
                    color="gray.500"
                  >
                    MULTA PREVISTA
                  </HLargexs>
                </GridItem>
                <GridItem mt="24px" mr="24px">
                  <HLargexs
                    textAlign="right"
                    whiteSpace="nowrap"
                    color="gray.500"
                  >
                    VALOR RECEBIDO PREVISTO
                  </HLargexs>
                </GridItem>
                <GridItem ml="24px" mb="24px">
                  <HSmallmd textAlign="right">
                    {Number(totalInterestProvided).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </HSmallmd>
                </GridItem>
                <GridItem mb="24px">
                  <HSmallmd textAlign="right">
                    {Number(totalFineProvided).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </HSmallmd>
                </GridItem>
                <GridItem mb="24px" mr="24px">
                  <HSmallmd textAlign="right">
                    {Number(totalProvided).toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </HSmallmd>
                </GridItem>
              </Grid>
            </Box>
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
