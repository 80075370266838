import { Table } from "@tanstack/react-table";
import { useCallback } from "react";

import { downloadFileCsv } from "../../../../main/common/utils/downloadFile";
import { transformFilterStateToQueryParams } from "../../../../main/components/DataTableServer/helpers/transformStateObject";
import { IRemittanceListView } from "../../../../types/main/remittances";
import { getRemittanceCSV } from "../service/remittanceService";

export const useExportRemittance = (
  table: Table<IRemittanceListView>,
  onLoading: (loading: boolean) => void
) => {
  const exportAll = useCallback(async () => {
    onLoading(true);
    const data = await getRemittanceCSV();
    onLoading(false);
    downloadFileCsv(data, "Remessa");
  }, [onLoading]);

  const exportFiltered = useCallback(async () => {
    const dataFiltered = transformFilterStateToQueryParams(
      table.getState().columnFilters
    );

    onLoading(true);

    const data = await getRemittanceCSV(dataFiltered);
    onLoading(false);
    downloadFileCsv(data, "Remessa_filtrada");
  }, [table, onLoading]);

  return { exportAll, exportFiltered };
};
