import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ClientForm } from "../components/ClientForm";
import { useExistingClient } from "../hooks/useExistingClient";

interface IClientManagement {
  isReadOnly?: boolean;
  clientUUID?: string;
  isModal?: boolean;
}

export const ClientManagement = ({
  isReadOnly,
  clientUUID,
  isModal,
}: IClientManagement) => {
  const { uuid } = useParams();

  const isEditing = !!uuid && !isReadOnly;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Clientes", href: "" },
    { description: "Gerenciamento de clientes", href: "/clientlist" },
    {
      description: isReadOnly
        ? "Visualizar cliente"
        : uuid
        ? "Editar cliente"
        : "Cadastrar cliente",
      href: "",
    },
  ];

  const clientUuid = isModal && clientUUID ? clientUUID : uuid;

  const { existingClient } = useExistingClient({
    clientUUID: clientUuid,
  });

  return (
    <Wrapper asModal={isModal}>
      {!isModal && <CustomBreadcrumb breadcrumb={breadcrumb} />}
      <Flex>
        <Tabs align="end" variant="unstyled" w="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="2em"
          >
            <HLarge2xl color="gray.600">
              {isReadOnly
                ? `Visualizando cliente - ${existingClient?.type}`
                : isEditing
                ? "Editar cliente"
                : "Cadastrar cliente"}
            </HLarge2xl>

            <TabList alignItems="flex-end" display="flex">
              <Tab
                hidden={isEditing || isReadOnly}
                _selected={{
                  borderBottomColor: "blue.500",
                  borderBottomWidth: "2px",
                }}
              >
                Pessoa Física
              </Tab>
              <Tab
                hidden={isEditing || isReadOnly}
                _selected={{
                  borderBottomColor: "blue.500",
                  borderBottomWidth: "2px",
                }}
              >
                Pessoa Jurídica
              </Tab>
            </TabList>
          </Box>
          <TabPanels>
            {(!existingClient || existingClient?.type === "PF") && (
              <TabPanel>
                <ClientForm
                  clientType="PF"
                  existingClient={existingClient}
                  isReadOnly={isReadOnly}
                  modal={isModal}
                />
              </TabPanel>
            )}
            {(!existingClient || existingClient?.type === "PJ") && (
              <TabPanel>
                <ClientForm
                  clientType="PJ"
                  existingClient={existingClient}
                  isReadOnly={isReadOnly}
                  modal={isModal}
                />
              </TabPanel>
            )}
          </TabPanels>
        </Tabs>
      </Flex>
    </Wrapper>
  );
};
