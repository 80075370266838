import {
  Button,
  ButtonGroup,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { useAuth } from "../../../../../../main/hooks/useAuth";
import { ITariffCarData } from "../../../../../../types/main/tariffCar";
import { CAN_PERMISSION_CREATE } from "../../../../CARs/constants/permissions";
import { useTariffCar } from "../../../hooks/useTariffCar";
import { CreateCarTableForm } from "./CreateCarTableForm";

const CreateCar = ({
  idTariff,
  isReadOnly,
  reloadCar,
}: {
  idTariff: number;
  isReadOnly?: boolean;
  reloadCar: () => void;
}) => {
  const { permissionLevel } = useAuth();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [error, setError] = useState("");

  const methods = useForm<any>();
  const { isLoading, onSubmitToCreate } = useTariffCar();

  const validate = useCallback(
    ({
      data,
      id: idTariff,
    }: {
      data: { tariffCarData: Array<ITariffCarData> };
      id: number;
    }) => {
      const { tariffCarData } = data;

      let isValid = true;
      tariffCarData.forEach((item) => {
        if (Number(item.value) === 0 || item.serviceUuid === "")
          isValid = false;
        setError("Preencha todos os campos");
      });
      if (tariffCarData.length === 0) {
        isValid = false;
        setError("Adicione um novo car");
      }

      if (isValid) {
        onSubmitToCreate({
          data: tariffCarData,
          id: idTariff,
          onClose,
          reloadCar,
        });
      }
    },
    [onClose, onSubmitToCreate, reloadCar]
  );

  if (!CAN_PERMISSION_CREATE[permissionLevel]) return null;

  return (
    <>
      <Button onClick={onOpen} size="sm">
        Criar nova CAR
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size="3xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Criar nova CAR</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormProvider {...methods}>
              <Flex
                as="form"
                onSubmit={methods.handleSubmit(
                  (data) => validate({ data, id: idTariff })
                  // onSubmitToCreate({ data, id: idTariff })
                )}
                h="100%"
                flexDirection="column"
                justifyContent="space-between"
                onKeyDown={(e) => {
                  e.key === "Enter" && e.preventDefault();
                }}
              >
                <CreateCarTableForm isReadOnly={isReadOnly} />
                <Flex my="50px" w="100%" justify="flex-end">
                  <ButtonGroup spacing="58" alignItems="center">
                    <Button variant="link" color="gray.700" onClick={onClose}>
                      Cancelar
                    </Button>
                    <Button
                      backgroundColor="blue.500"
                      type="submit"
                      width="214px"
                      isLoading={isLoading}
                      ml="32px"
                      size="md"
                    >
                      Cadastrar tarifa
                    </Button>
                  </ButtonGroup>
                </Flex>
              </Flex>
            </FormProvider>
          </ModalBody>

          <ModalFooter>
            <Text color="red.500" fontWeight="bold">
              {error}
            </Text>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export { CreateCar };
