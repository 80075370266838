import * as yup from "yup";

import { DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED } from "../../../../main/common/constants/defaultValidations";

export const billerSchema = yup.object({
  name: yup
    .string()
    .test(
      "Special Characters validation",
      "Caracteres especiais não permitidos.",
      (value) => {
        if (!value) return false;
        return !DEFAULT_REGEX_ESPECIAL_CHARACTERS_HYPHEN_PERMITED.test(value);
      }
    )
    .required("Esse campo é obrigatório"),
  securityCode: yup.string().required("Esse campo é obrigatório"),
});
