/* eslint-disable jsx-a11y/label-has-associated-control */
import { DeleteIcon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Image,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
} from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useFormContext } from "react-hook-form";

import { useInterstarTheme } from "../../hooks/useInterstarTheme";
import { TSmallxs } from "../Tipography";
import { b64toBlob, getBase64 } from "./functions";
import "./inputFile.css";
import { useInputFile } from "./useInputFile";

interface IInputFileProps {
  fieldname: string;
  isReadOnly?: boolean;
}

export const InputFile = ({ fieldname, isReadOnly }: IInputFileProps) => {
  const { colors } = useInterstarTheme();

  const { setValue, clearErrors, watch } = useFormContext();

  const { validateImageFormat, validateImageSize } = useInputFile();

  const [file, setFile] = useState<File | Blob>();
  const [loadedFromDB, setLoadedFromDB] = useState(false);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const preview = useMemo(() => {
    return file ? URL.createObjectURL(file) : "";
  }, [file]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (!files) return;

    clearErrors(fieldname);

    validateImageFormat(files[0]);
    validateImageSize(files[0]);

    setFile(files[0]);
    const fileInBase64 = await getBase64(files[0]);
    setValue(fieldname, fileInBase64);
  };

  const handleRemoveFile = () => {
    setFile(undefined);
    setValue(fieldname, undefined);
  };

  const watchFieldname = watch(fieldname);

  const loadPreviousFile = useCallback(async () => {
    if (watchFieldname && !loadedFromDB) {
      const blobFile = await b64toBlob(watchFieldname);
      return blobFile;
    }
  }, [loadedFromDB, watchFieldname]);

  useEffect(() => {
    let isMounted = true;

    const loadFile = async () => {
      const blobFile = await loadPreviousFile();
      if (blobFile && isMounted) {
        setFile(blobFile);
        setLoadedFromDB(true);
      }
    };

    loadFile();
    return () => {
      isMounted = false;
    };
  }, [loadPreviousFile]);

  return (
    <Flex
      minHeight="2.5rem"
      justifyContent="space-between"
      alignItems="center"
      border="1px solid"
      borderRadius="0.375rem"
      borderColor="inherit"
      overflow="hidden"
      paddingInline="0.5rem"
      flexWrap="wrap"
    >
      <label
        className="file-label"
        htmlFor={fieldname}
        style={{
          background: file ? colors.red100 : colors.gray200,
          cursor: isReadOnly ? "not-allowed" : "pointer",
        }}
      >
        <TSmallxs>{file ? "Mudar arquivo" : "Escolher arquivo"}</TSmallxs>
        <Input
          disabled={isReadOnly}
          id={fieldname}
          display="none"
          type="file"
          accept="image/png, image/jpeg, image/jpg"
          onChange={handleChange}
        />
      </label>

      <Flex gap="0.5rem" justifyContent="space-between" alignItems="center">
        {file && (
          <Tooltip label="Visualizar arquivo" fontSize="x-small">
            <Box>
              <Popover isOpen={isPopoverOpen} placement="top">
                <PopoverTrigger>
                  <ViewIcon
                    onMouseEnter={() => setIsPopoverOpen(true)}
                    onMouseLeave={() => setIsPopoverOpen(false)}
                    color={colors.blueInterstar}
                    cursor="pointer"
                    fontSize="20px"
                  />
                </PopoverTrigger>
                <PopoverContent
                  borderColor="gray.200"
                  _focus={{ border: "gray.200" }}
                >
                  <PopoverBody
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Image src={preview} maxWidth="100%" />
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Box>
          </Tooltip>
        )}
        {file && !isReadOnly && (
          <Tooltip label="Remover arquivo" fontSize="x-small">
            <DeleteIcon
              color="red.500"
              cursor="pointer"
              fontSize="16px"
              onClick={handleRemoveFile}
            />
          </Tooltip>
        )}
      </Flex>
    </Flex>
  );
};
