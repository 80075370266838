import { Routes, Route } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_SERVICES_PERMISSION_LEVEL,
  GET_SERVICES_PERMISSION_LEVEL,
  UPDATE_SERVICES_PERMISSION_LEVEL,
} from "./constants/permissions";
import { ServiceProvisionList } from "./pages/ServiceProvisionList";
import { ServiceProvisionManagement } from "./pages/ServiceProvisionManagement";

export function ServicesProvisionModule() {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={CREATE_SERVICES_PERMISSION_LEVEL} />}
      >
        <Route
          path="/manageserviceprovision"
          element={<ServiceProvisionManagement />}
        />
      </Route>
      <Route
        element={<PrivateRoute permission={UPDATE_SERVICES_PERMISSION_LEVEL} />}
      >
        <Route
          path="/manageserviceprovision/:uuid"
          element={<ServiceProvisionManagement />}
        />
      </Route>
      <Route
        element={<PrivateRoute permission={GET_SERVICES_PERMISSION_LEVEL} />}
      >
        <Route
          path="/serviceprovisionlist"
          element={<ServiceProvisionList />}
        />
        <Route
          path="/viewserviceprovision/:uuid"
          element={<ServiceProvisionManagement isReadOnly />}
        />
      </Route>
    </Routes>
  );
}
