const checkIfCellValueIsStringOrNumber = (cellValue: any) => {
  const isDate = !!Date.parse(cellValue);
  const isString = typeof cellValue === "string";
  const isNumber = typeof cellValue === "number";

  return !isDate && (isString || isNumber);
};

export const getCellTitle = (value: unknown) => {
  const cellValue = Array.isArray(value) ? value.join(", ") : value;

  const isStringOrNumber = checkIfCellValueIsStringOrNumber(cellValue);

  const titleToolTip: string =
    cellValue && isStringOrNumber ? (cellValue as string) : "";

  return titleToolTip;
};
