import { AxiosResponse } from "axios";

import { recordApi } from "../../../../main/services/RecordService";
import {
  IResponse,
  ISuccessfulResponseMessage,
} from "../../../../types/main/response";
import {
  IServiceProvision,
  IServiceProvisionData,
  IServiceProvisionList,
  IServiceProvisionOption,
} from "../../../../types/main/serviceProvision";

const MODULE_BASE_URL = "/servicesprovision";

export const getServiceProvisionOptions = async ({
  return_even_if_deleted,
}: {
  return_even_if_deleted?: string[];
}): Promise<IResponse<Array<IServiceProvisionOption>>> => {
  try {
    const response: AxiosResponse<IResponse<Array<IServiceProvisionOption>>> =
      await recordApi.get(
        return_even_if_deleted
          ? `${MODULE_BASE_URL}/options?return_even_if_deleted=${return_even_if_deleted.join(
              ";"
            )}`
          : `${MODULE_BASE_URL}/options`
      );
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const AllServicesProvision = async (): Promise<
  IResponse<Array<IServiceProvisionList>>
> => {
  try {
    const response: AxiosResponse<IResponse<Array<IServiceProvisionList>>> =
      await recordApi.get(MODULE_BASE_URL);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const GetServiceProvision = async (
  uuid: string
): Promise<IResponse<IServiceProvision>> => {
  try {
    const response: AxiosResponse<IResponse<IServiceProvision>> =
      await recordApi.get(`${MODULE_BASE_URL}/search/${uuid}`);
    return response.data;
  } catch (err: any) {
    return err?.response?.data;
  }
};

export const DeleteServiceProvision = async (identifier: string) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.delete(`${MODULE_BASE_URL}/${identifier}`);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const NewServiceProvision = async (
  serviceProvisionData: IServiceProvisionData
) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.post(MODULE_BASE_URL, serviceProvisionData);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};

export const EditServiceProvision = async (
  serviceProvisionData: IServiceProvisionData,
  uuid: string
) => {
  try {
    const response: AxiosResponse<IResponse<ISuccessfulResponseMessage>> =
      await recordApi.patch(`${MODULE_BASE_URL}/${uuid}`, serviceProvisionData);
    return response.data;
  } catch (err: any) {
    throw err?.response?.data;
  }
};
