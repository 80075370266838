import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { IPaymentTransmissionChannel } from "../../../../types/main/paymentTransmissionChannel";
import { allTransmissionChannelPaymentMethod } from "../service/PaymentMethodService";

export const useGetAllTransmissionChannel = () => {
  const toast = useToast();

  const [allTransmissionChannel, setAllTransmissionChannel] =
    useState<Array<IPaymentTransmissionChannel>>();

  const updateTransmissionChannel = useCallback(async () => {
    try {
      const response = await allTransmissionChannelPaymentMethod();
      if (response.type === "success" && response.body) {
        const list = response.body.map(({ name, paymentType, uuid }) => ({
          name,
          paymentType,
          uuid,
        }));
        setAllTransmissionChannel(list);
      }
    } catch (error: any) {
      toast({
        title: "Não encontrado.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    updateTransmissionChannel();
  }, [updateTransmissionChannel]);

  return { allTransmissionChannel };
};
