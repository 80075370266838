/* eslint-disable no-promise-executor-return */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Box,
  Tfoot,
  Icon,
  Skeleton,
  Button,
} from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { MdFindInPage } from "react-icons/md";
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useRowSelect,
  useFilters,
  useGlobalFilter,
} from "react-table";
import { useSticky } from "react-table-sticky";

import { SearchIcon, SearchDivisorIcon } from "../../common/assets";
import { isNumeric } from "../../common/utils/isNumeric";
import { PopoverList } from "../Popover";
import { HLargexs, TLargemd, TLargesm } from "../Tipography";
import { TableProvider } from "./context/useTableContext";
import { getTooltipContentAndCopyInfo } from "./functions";
import { useDataTable } from "./hooks/useDataTable";
import { IDataTableProps } from "./IDataTable";
import "./styles.css";
import { TableContainer } from "./TableParts/TableContainer";
import { TablePagination } from "./TableParts/TablePagination";

export function DataTable({
  columns,
  data,
  showCheckbox,
  popup,
  hiddenColumns = [],
  excelColumnHeader,
  isLoading,
  module,
  startFiltered,
  refreshTable,
  exportFunction,
}: IDataTableProps) {
  const RenderComponent = popup ? popup?.RenderComponent ?? PopoverList : null;

  const hasPopup = !!RenderComponent && !!popup;

  const {
    cellComponent,
    headerComponent,
    filterTypes,
    defaultColumn,
    filterRender,
    copyTextToClipboard,
  } = useDataTable();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    filteredRows,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, hiddenColumns },
      defaultColumn,
      filterTypes,
      filterRender,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) =>
        showCheckbox
          ? [
              {
                id: "selection",
                Header: headerComponent,
                Cell: cellComponent,
              },
              ...columns,
            ]
          : [...columns]
      );
    },
    useSticky
  );

  const paginationProps = {
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    pageIndex,
    pageSize,
  };

  const excelColumnHeaders = useMemo(() => {
    if (excelColumnHeader) return excelColumnHeader;
    const columnsFlat = columns
      .map((item) => [...item.columns])
      .flat()
      .map((item) => ({
        [item.accessor]: item.Header,
      }));
    return Object.assign({}, ...columnsFlat);
  }, [columns, excelColumnHeader]);

  // * It has to be done this way because react-sticky recommends to use footer like this
  const footerGroups = headerGroups.slice().reverse();

  useEffect(() => {
    if (startFiltered) setFilter(startFiltered.column, startFiltered.value);
  }, [setFilter, startFiltered]);

  return (
    <Flex flexDir="column" maxW="calc(100vw - 300px)">
      <Flex w="100%" justifyContent="flex-end" mb={4} gap={4}>
        <Button
          onClick={refreshTable}
          variant="outline"
          hidden={!refreshTable}
          maxW="fit-content"
        >
          Atualizar
        </Button>
        <Button
          onClick={exportFunction}
          variant="outline"
          hidden={!exportFunction}
          maxW="fit-content"
        >
          Exportar csv
        </Button>
      </Flex>
      <TableProvider>
        <TableContainer pageCount={page.length}>
          <Table
            {...getTableProps()}
            className="table sticky"
            variant="striped"
            colorScheme="gray"
            size="md"
          >
            <Thead className="header">
              {headerGroups.map((headerGroup) => (
                <Tr
                  className="tr"
                  height="36px"
                  background="gray.100"
                  {...headerGroup.getHeaderGroupProps({
                    className: "header-table-css-global",
                  })}
                >
                  {headerGroup.headers.map((column, index) => {
                    const showFilter = !showCheckbox || index !== 0;

                    return (
                      <Th
                        className="th"
                        padding={0}
                        backgroundColor="#edf2f7"
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        isNumeric={isNumeric(column)}
                        colSpan={1}
                      >
                        <Flex alignItems="center" justifyContent="flex-start">
                          <Flex flexDir="column" ml="5px">
                            {showFilter && (
                              <>
                                <TriangleUpIcon
                                  height="8px"
                                  mb={-2}
                                  aria-label="sorted ascending"
                                  color={
                                    column.isSorted && column.isSortedDesc
                                      ? "blue.500"
                                      : "gray.700"
                                  }
                                />
                                <Box mt="6.67px" mb="6.67px">
                                  <SearchDivisorIcon />
                                </Box>
                                <TriangleDownIcon
                                  height="8px"
                                  aria-label="sorted descending"
                                  color={
                                    column.isSorted && !column.isSortedDesc
                                      ? "blue.500"
                                      : "gray.700"
                                  }
                                />
                              </>
                            )}
                          </Flex>
                          <Box paddingBlock="12px" mr="10px" minW="100px">
                            <HLargexs>{column.render("Header")}</HLargexs>
                          </Box>
                        </Flex>
                      </Th>
                    );
                  })}
                  {hasPopup && (
                    <Th position="sticky" right="0" bgColor="gray.100" w="30px">
                      {popup ? (
                        <RenderComponent
                          excelColumnHeaders={excelColumnHeaders}
                          dataFiltered={filteredRows}
                          data={data}
                          isHeader
                          uuid="EMPTY"
                          module={module}
                          {...popup}
                        />
                      ) : (
                        <Box p="20px" />
                      )}
                    </Th>
                  )}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()} className="body">
              {headerGroups.map((headerGroup) => (
                <Tr
                  className="tr"
                  height="56px"
                  {...headerGroup.getHeaderGroupProps({
                    className: "header-table-css-global",
                  })}
                >
                  {headerGroup.headers.map((column, i) => {
                    return (
                      <Th
                        backgroundColor="#fff"
                        className="th"
                        paddingBottom="20px"
                        paddingInline="0"
                        {...column.getHeaderProps()}
                        colSpan={1}
                      >
                        {i === 0 && showCheckbox && <SearchIcon />}
                        <Box className="input-search">
                          {column.canFilter ? column.render("Filter") : null}
                        </Box>
                      </Th>
                    );
                  })}
                  {hasPopup && (
                    <Th
                      position="sticky"
                      right="0"
                      background="white"
                      w="30px"
                      className="th"
                    />
                  )}
                </Tr>
              ))}
              {isLoading && (
                <Tr>
                  <Td colSpan={24} padding="0">
                    <Skeleton height="56px" />
                  </Td>
                </Tr>
              )}

              {!isLoading &&
                page.map((row) => {
                  prepareRow(row);
                  return (
                    <Tr height="56px" {...row.getRowProps()} className="tr">
                      {row.cells.map((cell) => {
                        const { shouldCopyValueTooBig, titleToolTip } =
                          getTooltipContentAndCopyInfo(cell);

                        return (
                          <Td
                            {...cell.getCellProps({
                              className: "td",
                            })}
                          >
                            <TLargesm
                              as="span"
                              title={titleToolTip}
                              onClick={() =>
                                copyTextToClipboard(
                                  titleToolTip,
                                  shouldCopyValueTooBig
                                )
                              }
                            >
                              {cell.render("Cell")}
                            </TLargesm>
                          </Td>
                        );
                      })}
                      {hasPopup && (
                        <Td
                          position="sticky"
                          right="0"
                          bgColor="white"
                          w="30px"
                          className="td"
                          padding={0}
                        >
                          <RenderComponent
                            module={module}
                            excelColumnHeaders={excelColumnHeaders}
                            dataFiltered={filteredRows}
                            data={data}
                            uuid={row.values.uuid ?? row.values.id}
                            {...popup}
                          />
                        </Td>
                      )}
                    </Tr>
                  );
                })}
            </Tbody>
            {columns.some((column) => column.hasFooter) && (
              <Tfoot className="footer">
                <Tr {...footerGroups[0].getHeaderGroupProps()} className="tr">
                  {footerGroups[0].headers.map((column) => (
                    <Td
                      {...column.getHeaderProps()}
                      className="td"
                      borderTop="1px solid #ccc"
                    >
                      {!!column.Footer ? column.render("Footer") : null}
                    </Td>
                  ))}
                </Tr>
              </Tfoot>
            )}
            {columns.some((column) => !!column.absoluteFooterLabel) && (
              <Flex
                as="tfoot"
                zIndex="overlay"
                m={4}
                justifyContent="center"
                alignItems="center"
                className="footer-label"
                fontWeight="bold"
              >
                <tr>
                  <td>
                    {
                      columns.find((column) => column.absoluteFooterLabel)
                        ?.absoluteFooterLabel
                    }
                  </td>
                </tr>
              </Flex>
            )}
          </Table>
        </TableContainer>
        {page.length === 0 && !isLoading && (
          <Box
            width="calc(100vw - (160px + 160px) )"
            bgColor="#edf2f7"
            border="1px solid #ddd"
            borderTop="none"
            borderRadius="5px"
            borderTopRadius="none"
          >
            <Flex
              height="56px"
              textAlign="center"
              justifyContent="center"
              alignItems="center"
              gap="0.5rem"
            >
              <Icon as={MdFindInPage} fontSize="20px" color="gray.600" />
              <TLargemd>Nenhum registro encontrado</TLargemd>
            </Flex>
          </Box>
        )}
        <TablePagination {...paginationProps} />
      </TableProvider>
    </Flex>
  );
}
