/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
import {
  ArrowDownIcon,
  ArrowRightIcon,
  ExternalLinkIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Button,
  Divider,
  Flex,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { CellContext, Table } from "@tanstack/react-table";
import { useState } from "react";
import { GiFiles } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import { IconExport } from "../../../../../main/common/assets";
import { NFeModal } from "../../../../../main/components/NFeModal";
import {
  HLargexs,
  HSmallmd,
  TSmallmd,
  TSmallsm,
} from "../../../../../main/components/Tipography";
import { useAnchorDownload } from "../../../../../main/hooks/useAnchorDownload";
import { IBlobError } from "../../../../../main/hooks/useDownloadFile";
import { IInvoiceDataList } from "../../../../../types/main/invoice";
import { useExportInvoice } from "../../hooks/useExportInvoice";
import { forceSendInvoiceEmail } from "../../service/InvoiceService";
import { InvoicesZip } from "../invoiceZip";
import { handleError } from "./functions/handleError";

const MIN_INVOICE_ZIP = 1;
const MAX_INVOICE_ZIP = 20;

type PopoverInvoiceProps = {
  info?: CellContext<IInvoiceDataList, any>;
  table: Table<IInvoiceDataList>;
  refetch: (data?: any) => Promise<void>;
  onLoading: (loading: boolean) => void;
};

export const InvoicePopover = ({
  info,
  table,
  onLoading,
}: PopoverInvoiceProps) => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const navigate = useNavigate();

  const { exportAll, exportFiltered } = useExportInvoice(table, onLoading);

  const invoice = info?.row.original;

  const handleForceEmailToSend = async (props: any) => {
    if (!invoice?.invoiceId) {
      toast({
        title: "Erro ao forçar envio do email.",
        description: "Fatura não encontrada.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (isSendingEmail) return;
    toast({
      title: "Envio de emails da fatura iniciado.",
      status: "info",
      duration: 1500,
      isClosable: true,
    });
    try {
      setIsSendingEmail(true);
      const response = await forceSendInvoiceEmail({ id: invoice.invoiceId });
      if (response.body?.type === "error") {
        toast({
          title: "Erro ao forçar envio do email.",
          description: response.body.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      toast({
        title: "Email enviado com sucesso.",
        description: "O email foi enviado com sucesso.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSendingEmail(false);
    }
  };

  const preDownloading = () => {
    setIsLoading(true);
    onClose();
  };
  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage = await handleError(error);

    toast({
      title: "Serviço indisponível.",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const { downloadFile, ref, url, name, viewInNewTab } = useAnchorDownload({
    apiDefinition: {
      endpoint: `/invoicepdf/reducedInvoice/${invoice?.invoiceId}`,
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Fatura-${invoice?.invoiceId}-Resumida`,
    viewUrlRequest: `/viewinvoicepdf/reducedInvoice/${invoice?.invoiceId}`,
  });

  const { viewInNewTab: viewBoletoInNewTab } = useAnchorDownload({
    apiDefinition: {
      endpoint: "",
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Boleto-fatura-${invoice?.invoiceId}`,
    viewUrlRequest: `/boletos/view/${invoice?.invoiceId}`,
  });

  const filteredInvoices = table.getFilteredRowModel().flatRows;

  const canDownloadZip =
    Array.isArray(filteredInvoices) &&
    filteredInvoices.length >= MIN_INVOICE_ZIP &&
    filteredInvoices.length <= MAX_INVOICE_ZIP;

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
              disabled={isLoading}
              _disabled={{ bg: "gray.200" }}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "..."
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody>
                <Flex flexDir="column">
                  <>
                    {!invoice && (
                      <>
                        {canDownloadZip && (
                          <InvoicesZip
                            invoiceIds={filteredInvoices.map(
                              (item) => item.original.invoiceId
                            )}
                          />
                        )}
                        <HLargexs pl="24px" pt="12px" mb="6px">
                          EXPORTAR CSV
                        </HLargexs>
                        <Flex
                          pl="25.33px"
                          py="8px"
                          as="button"
                          alignItems="center"
                          _hover={{ bg: "gray.50" }}
                          onClick={exportAll}
                          width="100%"
                        >
                          <IconExport />
                          <HSmallmd ml="8.46px" color="gray.500">
                            Tabela inteira
                          </HSmallmd>
                        </Flex>
                        <Flex
                          pl="25.33px"
                          py="8px"
                          as="button"
                          alignItems="center"
                          _hover={{ bg: "gray.50" }}
                          onClick={exportFiltered}
                          width="100%"
                        >
                          <IconExport />
                          <HSmallmd ml="8.46px" color="gray.500">
                            Tabela filtrada
                          </HSmallmd>
                        </Flex>
                      </>
                    )}
                    {!!invoice && (
                      <>
                        <TSmallsm color="#4a5568" fontWeight="bold">
                          FAZER DOWNLOAD
                        </TSmallsm>
                        <a href={url} download={name} hidden ref={ref} />
                        <Flex
                          mt="2"
                          flexDir="row"
                          justifyContent="flex-start"
                          gap="2"
                          alignItems="center"
                          color="#718096"
                          as={Button}
                          variant="unstyled"
                          padding="0"
                          onClick={downloadFile}
                        >
                          <ArrowDownIcon />
                          <TSmallmd fontWeight="medium">Fatura (.pdf)</TSmallmd>
                        </Flex>
                        <NFeModal invoiceId={+(invoice.invoiceId ?? 0)} />
                        <Divider my="5px" />

                        <TSmallsm color="#4a5568" fontWeight="bold">
                          VISUALIZAR
                        </TSmallsm>
                        {invoice.hasBoleto && (
                          <Flex
                            mt="2"
                            flexDir="row"
                            justifyContent="flex-start"
                            gap="2"
                            alignItems="center"
                            color="#718096"
                            as={Link}
                            padding="0"
                            to={`/boletos?invoiceId=${invoice.invoiceId}`}
                          >
                            <ArrowRightIcon />
                            <TSmallmd fontWeight="medium">
                              Boletos relacionados
                            </TSmallmd>
                          </Flex>
                        )}
                        <Flex
                          mt="2"
                          flexDir="row"
                          justifyContent="flex-start"
                          gap="2"
                          alignItems="center"
                          color="#718096"
                          as={Button}
                          variant="unstyled"
                          padding="0"
                          onClick={viewInNewTab}
                        >
                          <ExternalLinkIcon />
                          <TSmallmd fontWeight="medium">Fatura (.pdf)</TSmallmd>
                        </Flex>
                        <Divider my="5px" />

                        <TSmallsm color="#4a5568" fontWeight="bold">
                          AÇÕES
                        </TSmallsm>
                        <Flex
                          mt="2"
                          flexDir="row"
                          justifyContent="flex-start"
                          gap="2"
                          alignItems="center"
                          color="#718096"
                          as={Button}
                          variant="unstyled"
                          padding="0"
                          isLoading={isSendingEmail}
                          onClick={handleForceEmailToSend}
                        >
                          <RepeatIcon />
                          <TSmallmd fontWeight="medium">
                            Forçar envio do email
                          </TSmallmd>
                        </Flex>
                        <Divider my="5px" />
                      </>
                    )}
                  </>
                  {!!invoice && (
                    <Flex
                      marginBlock="2"
                      flexDir="row"
                      justifyContent="flex-start"
                      gap="2"
                      alignItems="center"
                      color="#718096"
                      as={Link}
                      padding="0"
                      to={`/tarifflist?invoiceId=${invoice.invoiceId}`}
                    >
                      <GiFiles />
                      <TSmallmd fontWeight="medium">
                        Ver Tarifas inclusas
                      </TSmallmd>
                    </Flex>
                  )}
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
