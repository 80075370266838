import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Alert, AlertIcon, Box, Button, Flex, Grid } from "@chakra-ui/react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Logo } from "../components";
import {
  HLarge2xl,
  TSmallmd,
  TSmallsm,
  TLargemd,
} from "../components/Tipography";
import { useAuth } from "../hooks/useAuth";
import { useInterstarTheme } from "../hooks/useInterstarTheme";

export const Login = () => {
  const { colors } = useInterstarTheme();

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const navigate = useNavigate();

  const { handleLogin, handleLogout, loginError, permissionLevel } = useAuth();

  useEffect(() => {
    if (isAuthenticated && !loginError.isUserForbidden && !!permissionLevel) {
      navigate("/home");
    }
  }, [isAuthenticated, loginError.isUserForbidden, navigate, permissionLevel]);

  return (
    <Grid gridTemplateColumns="40% 1fr" h="100vh">
      <Flex
        bgColor={colors.blueInterstar}
        justifyContent="center"
        alignItems="center"
      >
        <Logo color="white" width="200px" height="300px" />
      </Flex>
      <Flex
        flexDir="column"
        justifyContent="space-evenly"
        alignItems="center"
        p="0 2rem"
      >
        <Box w="50%">
          <HLarge2xl>Login com SSO</HLarge2xl>
        </Box>
        <Box w="50%">
          <TLargemd>Utilize o e-mail da sua empresa para fazer login</TLargemd>
          <Button mt="1rem" w="100%" onClick={() => handleLogin()}>
            Fazer login com SSO
          </Button>
          {loginError.isUserForbidden && (
            <Alert status="warning" borderRadius="10px" mt="10px">
              <AlertIcon />
              {loginError.forbiddenMessage}
            </Alert>
          )}
        </Box>
        <Flex flexDir="column" alignItems="center" gap="1rem">
          <TSmallmd>INTERSTAR</TSmallmd>
          {isAuthenticated && (
            <TSmallsm
              color="blue.500"
              cursor="pointer"
              onClick={() => handleLogout(instance)}
            >
              Sair do SSO
            </TSmallsm>
          )}
        </Flex>
      </Flex>
    </Grid>
  );
};
