import { Text, TextProps } from "@chakra-ui/react";
import React from "react";

const TLarge6xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="60px" lineHeight="60px" {...rest}>
      {children}
    </Text>
  );
};

const TLarge5xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="48px" lineHeight="48px" {...rest}>
      {children}
    </Text>
  );
};

const TLarge4xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="36px" lineHeight="40px" {...rest}>
      {children}
    </Text>
  );
};

const TLarge3xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="30px" lineHeight="36px" {...rest}>
      {children}
    </Text>
  );
};

const TLarge2xl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="24px" lineHeight="32px" {...rest}>
      {children}
    </Text>
  );
};

const TLargexl: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="20px" lineHeight="28px" {...rest}>
      {children}
    </Text>
  );
};

const TLargelg: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="18px" lineHeight="28qpx" {...rest}>
      {children}
    </Text>
  );
};

const TLargemd: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="16px" lineHeight="24px" {...rest}>
      {children}
    </Text>
  );
};

const TLargesm: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="14px" lineHeight="20px" {...rest}>
      {children}
    </Text>
  );
};

const TLargexs: React.FC<TextProps> = ({ children, ...rest }) => {
  return (
    <Text fontWeight="500" fontSize="12px" lineHeight="16px" {...rest}>
      {children}
    </Text>
  );
};

export {
  TLarge6xl,
  TLarge5xl,
  TLarge4xl,
  TLarge3xl,
  TLarge2xl,
  TLargexl,
  TLargelg,
  TLargemd,
  TLargesm,
  TLargexs,
};
