/* eslint-disable react/no-danger */
import { ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";

interface IEmailBody {
  data?: string;
  templateName: string;
}

export const EmailBody = ({ data, templateName }: IEmailBody) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const btnRef = useRef(null);

  return (
    <Flex as="span" justifyContent="center">
      <IconButton
        ref={btnRef}
        variant="outline"
        colorScheme="blue"
        aria-label="View Email Body"
        fontSize="20px"
        onClick={onOpen}
        icon={<ViewIcon />}
        disabled={!data}
      />
      {data && (
        <Drawer
          isOpen={isOpen}
          placement="right"
          onClose={onClose}
          finalFocusRef={btnRef}
          size="md"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Corpo do Template {templateName}</DrawerHeader>
            <Divider />
            <DrawerBody mt="1rem">
              <Box>
                <span dangerouslySetInnerHTML={{ __html: data }} />
              </Box>
            </DrawerBody>

            <DrawerFooter>
              <Button variant="outline" mr={3} onClick={onClose}>
                Fechar
              </Button>
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </Flex>
  );
};
