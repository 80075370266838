import { useCallback, useState } from "react";

import { formatDate } from "../../../../../../main/common/utils/dateHelper";
import {
  DatePickerFilter,
  DocumentColumnFilter,
} from "../../../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { getBlockedClients } from "../../../service/ReportService";
import { BlockedClientsFiltersPayload } from "./Filters";

export const useBlockedClients = () => {
  const [report, setReport] = useState<{
    data: Array<any>;
    isFetching: boolean;
  }>({
    data: [],
    isFetching: false,
  });

  const { columns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "CAR",
            accessor: "tariffCarId",
          },
          {
            Header: "Nº Contrato",
            accessor: "contract",
          },
          {
            Header: "Nome do Cliente",
            accessor: "customername",
          },
          {
            Header: "CPF/CNPJ",
            accessor: "document",
            Filter: DocumentColumnFilter,
            Cell: ({ value }: { value: string }) => {
              const document =
                value.length > 11
                  ? value.replace(
                      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{1,2})/g,
                      "$1.$2.$3/$4-$5"
                    )
                  : value.replace(
                      /(\d{3})(\d{3})(\d{3})(\d{1,2})/g,
                      "$1.$2.$3-$4"
                    );

              return document;
            },
          },
          {
            Header: "Canal",
            accessor: "channel",
          },
          {
            Header: "Hierarquia",
            accessor: "parentchannel",
          },
          {
            Header: "Tipo do Serviço (Categoria)",
            accessor: "category",
          },
          {
            Header: "Produto",
            accessor: "product",
          },
          {
            Header: "Mét. Pagamento",
            accessor: "paymentMethod",
          },
          {
            Header: "Valor em Aberto",
            accessor: "pendingValue",
            Cell: ({ value }) => {
              if (!value) return "";

              return Number(value).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              });
            },
          },
          {
            Header: "Data de Vencimento",
            accessor: "dueDate",
            Cell: ({ value }) => {
              if (!value) return "";

              return formatDate(value);
            },
            filter: "date",
            Filter: DatePickerFilter,
          },
          {
            Header: "Ultima Tentativa de Débito",
            accessor: "lastDebitAttempt",
            Cell: ({ value }) => {
              if (!value) return "";

              return formatDate(value);
            },
            filter: "date",
            Filter: DatePickerFilter,
          },
          {
            Header: "Motivo do Bloqueio",
            accessor: "blockreason",
          },
        ],
      },
    ],
  });

  const loadReport = useCallback(
    async (filters: BlockedClientsFiltersPayload) => {
      setReport((old) => ({
        ...old,
        isFetching: true,
      }));

      try {
        const response = await getBlockedClients(filters);

        setReport({
          data:
            response.type === "success" && response.body
              ? response.body.data
              : [],
          isFetching: false,
        });
      } catch (error) {
        setReport({
          data: [],
          isFetching: false,
        });
      }
    },
    []
  );

  return {
    report,
    columns,
    loadReport,
  };
};
