import { Table } from "@tanstack/react-table";
import { useCallback } from "react";

import { downloadFileCsv } from "../../../../main/common/utils/downloadFile";
import { transformFilterStateToQueryParams } from "../../../../main/components/DataTableServer/helpers/transformStateObject";
import { IInvoiceDataList } from "../../../../types/main/invoice";
import {
  GetInvoiceCsvAllUuids,
  GetInvoiceCsvByIds,
} from "../service/InvoiceService";

export const useExportInvoice = (
  table: Table<IInvoiceDataList>,
  onLoading: (loading: boolean) => void
) => {
  const exportAll = useCallback(async () => {
    onLoading(true);
    const data = await GetInvoiceCsvAllUuids();
    onLoading(false);
    downloadFileCsv(data, "Fatura");
  }, [onLoading]);

  const exportFiltered = useCallback(async () => {
    const dataFiltered = transformFilterStateToQueryParams(
      table.getState().columnFilters
    );

    onLoading(true);

    const data = await GetInvoiceCsvByIds(dataFiltered);
    onLoading(false);
    downloadFileCsv(data, "Fatura_filtrada");
  }, [table, onLoading]);

  return { exportAll, exportFiltered };
};
