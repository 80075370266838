/* eslint-disable react/no-unstable-nested-components */
import { Flex, useToast } from "@chakra-ui/react";
import { ColumnDef } from "@tanstack/react-table";
import { useMemo } from "react";
import useSWR, { mutate } from "swr";
import { ConfirmationPopover } from "../../../../main/components/ConfirmationPopover";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { TableServer } from "../../../../main/components/DataTableServer";
import { useTableServer } from "../../../../main/components/DataTableServer/useTableServer";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import {
  BackgroundProcessesLogsTypes,
  BackgroundProcessLogsTypeDescription,
  IBackgroundProcessesLogs,
} from "../../../../types/main/backgroundProcessesLogs";
import { Recommendation } from "../components/Recommendation";
import { LOGS_NEEDS_TREATMENT } from "../constants/logsNeedsTreatment.constant";
import {
  deleteBackgroundLog,
  getTariffReceivableBackgroundLogs,
} from "../services/BackgroundProcessesLogs";

const handleTypeCell = (type: BackgroundProcessesLogsTypes) => {
  return BackgroundProcessLogsTypeDescription[type];
};

const getIDDescription = (type: BackgroundProcessesLogsTypes, id: string) => {
  if (type === "DUE_DATE") {
    return `Tarifa ${id}`;
  }

  if (type === "PAYMENT_METHOD") {
    return `CAR ${id}`;
  }

  if (type === "NFE_BLOCK") {
    return `Fatura ${id}`;
  }

  return id;
};

export const BackgroundLogsList = () => {
  const toast = useToast();

  const {
    columnFiltersParams,
    columnSortingParams,
    paginationParams,
    columnFiltersState,
    paginationState,
    sortingState,
  } = useTableServer();

  const fetchKey = `/background-logs/tariff/receivable
    /${paginationParams.pageIndex}
    /${paginationParams.pageSize}
    /${columnSortingParams?.sortColumn}
    /${columnSortingParams?.sortOrder}}
    /${columnFiltersParams?.identifier}}
    /${columnFiltersParams?.type}}
    /${columnFiltersParams?.errorDescription}}`;

  const { data: logs, isLoading } = useSWR(
    fetchKey,
    () =>
      getTariffReceivableBackgroundLogs({
        pagination: paginationParams,
        columnFilters: columnFiltersParams,
        sorting: columnSortingParams,
      }),
    {
      revalidateOnFocus: true,
      refreshInterval: 1000 * 15, // 15 seconds
    }
  );

  const handleResolveError = async (id: string, type: string) => {
    try {
      const response = await deleteBackgroundLog(id, type);
      mutate(fetchKey);
      toast({
        title: "Sucesso ao marcar como resolvido.",
        description: response.message,
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error: any) {
      mutate(fetchKey);
      toast({
        title: "Erro ao marcar como resolvido.",
        description: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const columns = useMemo<ColumnDef<IBackgroundProcessesLogs, any>[]>(
    () => [
      {
        header: "Identificador",
        id: "identifier",
        accessorKey: "identifier",
        minSize: 50,
        cell: (info) =>
          getIDDescription(info.row.original.type, info.getValue()),
      },
      {
        header: "Tipo",
        id: "type",
        accessorKey: "type",
        filterFn: "select",
        meta: [
          {
            value: "DUE_DATE",
            label: "Datas de Vencimento",
          },
          {
            value: "PAYMENT_METHOD",
            label: "Método de Pagamento",
          },
        ],
        cell: (info) => handleTypeCell(info.getValue()),
      },
      {
        header: "Problema encontrado",
        id: "errorDescription",
        accessorKey: "errorDescription",
      },

      {
        header: "Recomendação para verificar",
        id: "recommendation",
        cell: (info) => (
          <Flex justifyContent="space-between" marginX={8}>
            <Recommendation
              type={info.row.original.type}
              extraTips={info.row.original.extraTips}
            />
            {LOGS_NEEDS_TREATMENT.includes(info.row.original.type) && (
              <ConfirmationPopover
                onPrimaryButtonClick={() =>
                  handleResolveError(
                    info.row.original.identifier,
                    info.row.original.type
                  )
                }
                primaryButtonText="Confirmar"
                secondaryButtonText="Cancelar"
                triggerButtonText="Marcar como resolvido"
                title="Marcar como resolvido"
                text="Voce tem certeza?"
                disabled={false}
              />
            )}
          </Flex>
        ),
      },
    ],
    []
  );

  const breadcrumb: IBreadcrumbProps[] = [
    {
      description: "Processos Background (Remessa/Fatura)",
      href: "/",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600">
        Processos Background (Remessa/Fatura)
      </HLarge2xl>
      <Flex justifyContent="flex-end" alignItems="center" mb="1rem" />
      <TableServer
        data={logs?.data ?? []}
        defaultColumns={columns}
        isLoading={isLoading}
        columnFiltersState={columnFiltersState}
        paginationState={{
          ...paginationState,
          pageCount: logs?.lastPage || 1,
        }}
        sortingState={sortingState}
      />
    </Wrapper>
  );
};
