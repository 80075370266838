interface IUseHandleErrors {
  errors: {
    [x: string]: any;
  };
  index: number;
}

export const useTableFormRowHandleErrors = ({
  errors,
  index,
}: IUseHandleErrors) => {
  const hasError = (field: string, subField: string) => {
    return (
      !!errors &&
      !!errors[field] &&
      !!errors[field][index] &&
      errors[field][index][subField]
    );
  };

  const getErrorMessage = (field: string, subField: string) => {
    if (!!errors && !!errors[field] && !!errors[field][index]) {
      return `${errors[field][index][subField]?.message ?? ""}`;
    }

    return "";
  };

  return { hasError, getErrorMessage };
};
