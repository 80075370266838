import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Tooltip,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputSelect } from "../../../../../main/components/InputSelect";
import { WebhookTypeEnum } from "../../../../../types/main/webHooks";
import { useExistingWebHooks } from "../../hooks/useExistingWebHooks";
import { useWebHooksForm } from "../../hooks/useWebHooksForm";
import { IWebhooks, IWebHooksForm } from "../../types";
import { webHooksSchema } from "../../validation/webHooksSchema";

export const WebhooksForm = ({ id, isReadOnly }: IWebHooksForm) => {
  const formMethods = useForm<IWebhooks>({
    resolver: yupResolver(webHooksSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { existingWebHooks } = useExistingWebHooks(id);

  const { onSubmit, isLoading, productsOptions } = useWebHooksForm({
    webHookId: id,
  });

  useEffect(() => {
    if (existingWebHooks) {
      setValue("status", existingWebHooks.status);
      setValue("headerKey", existingWebHooks.headerKey);
      setValue("headerValue", existingWebHooks.headerValue);
      setValue("webhookType", existingWebHooks.webhookType);
      setValue("productUuid", existingWebHooks.productUuid);
      setValue("url", existingWebHooks.url);
      setValue("timeOut", existingWebHooks.timeOut);
      setValue("queueTimeOut", existingWebHooks.queueTimeOut);
      setValue("queueMaxRetries", existingWebHooks.queueMaxRetries);
    }
  }, [existingWebHooks]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          w="100%"
          h="100%"
          justifyContent="space-between"
          gap="10px"
          flexWrap="wrap"
        >
          <FormControl
            maxWidth="150px"
            isInvalid={!!errors.status}
            display="flex"
            flexDir="column"
            alignItems="center"
          >
            <FormLabel htmlFor="status">Ativo?</FormLabel>

            <BooleanRadioGroup
              defaultCustomValue
              isReadOnly={isReadOnly}
              fieldname="status"
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
            />
            <FormErrorMessage>
              {errors.status && errors.status.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.url}
            isReadOnly={isReadOnly}
            maxWidth="500px"
          >
            <FormLabel htmlFor="name">URL</FormLabel>
            <Input id="url" {...register("url")} isReadOnly={isReadOnly} />
            <FormErrorMessage>
              {errors.url && errors.url.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.timeOut}
            isReadOnly={isReadOnly}
            maxWidth="200px"
          >
            <FormLabel htmlFor="timeOut">Tempo de resposta (ms)</FormLabel>
            <Tooltip
              placement="right"
              label="Tempo (em ms) referente ao tempo de espera máximo para resposta do webhook."
              fontSize="x-small"
            >
              <Input
                defaultValue={60000}
                id="timeOut"
                {...register("timeOut")}
                isReadOnly={isReadOnly}
                type="number"
              />
            </Tooltip>
            <FormErrorMessage>
              {errors.timeOut && errors.timeOut.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.queueTimeOut}
            isReadOnly={isReadOnly}
            maxWidth="200px"
          >
            <FormLabel htmlFor="queueTimeOut">Tempo de espera (ms)</FormLabel>
            <Tooltip
              placement="right"
              label="Tempo (em ms) referente ao tempo de espera entre uma tentativa e outra."
              fontSize="x-small"
            >
              <Input
                defaultValue={60000}
                id="queueTimeOut"
                {...register("queueTimeOut")}
                isReadOnly={isReadOnly}
                type="number"
              />
            </Tooltip>
            <FormErrorMessage>
              {errors.queueTimeOut && errors.queueTimeOut.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.queueMaxRetries}
            isReadOnly={isReadOnly}
            maxWidth="200px"
          >
            <FormLabel htmlFor="queueMaxRetries">Máximo de tentivas</FormLabel>
            <Tooltip
              placement="right"
              label="Máximo de tentativas em casa de erro"
              fontSize="x-small"
            >
              <Input
                defaultValue={10}
                id="queueMaxRetries"
                {...register("queueMaxRetries")}
                isReadOnly={isReadOnly}
                type="number"
              />
            </Tooltip>
            <FormErrorMessage>
              {errors.queueMaxRetries && errors.queueMaxRetries.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.headerKey}
            isReadOnly={isReadOnly}
            maxWidth="400px"
          >
            <FormLabel htmlFor="name">
              Chave do cabeçalho de segurança
            </FormLabel>
            <Input
              id="headerKey"
              {...register("headerKey")}
              isReadOnly={isReadOnly}
            />
            <FormErrorMessage>
              {errors.headerKey && errors.headerKey.message}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            isInvalid={!!errors.headerValue}
            isReadOnly={isReadOnly}
            maxWidth="350px"
          >
            <FormLabel htmlFor="name">
              Valor do cabeçalho de segurança
            </FormLabel>
            <Input
              id="headerValue"
              {...register("headerValue")}
              isReadOnly={isReadOnly}
            />
            <FormErrorMessage>
              {errors.headerValue && errors.headerValue.message}
            </FormErrorMessage>
          </FormControl>

          <FormControl maxWidth="350px" isInvalid={!!errors.webhookType}>
            <FormLabel htmlFor="webhookType">Tipo de Webhook</FormLabel>

            <InputSelect
              disabled={isReadOnly}
              name="webhookType"
              placeholder="Tipo de Webhook"
              isClearable
              option={[
                {
                  label: "Atualização de CAR",
                  value: WebhookTypeEnum.UPDATE_CAR_STATUS,
                },
                {
                  label: "Atualização de Pagamento",
                  value: WebhookTypeEnum.UPDATE_PAYMENT_STATUS,
                },
              ]}
              isMulti={false}
            />

            <FormErrorMessage>
              {errors.webhookType && errors.webhookType.message}
            </FormErrorMessage>
          </FormControl>

          {productsOptions && (
            <FormControl maxWidth="400px" isInvalid={!!errors.productUuid}>
              <FormLabel htmlFor="products">Produtos</FormLabel>

              <InputSelect
                disabled={isReadOnly}
                name="productUuid"
                placeholder="Produto"
                isClearable
                option={productsOptions}
              />

              <FormErrorMessage>
                {errors.productUuid && errors.productUuid.message}
              </FormErrorMessage>
            </FormControl>
          )}
          <Flex width="100%" justifyContent="flex-end">
            <ButtonGroup
              spacing="58"
              alignItems="center"
              position="relative"
              mt="5rem"
            >
              <Link to="/webhooks">
                <Button variant="link" color="gray.700">
                  Cancelar
                </Button>
              </Link>

              {!isReadOnly && (
                <Button
                  backgroundColor="blue.500"
                  type="submit"
                  width="214px"
                  isLoading={isSubmitting}
                  isDisabled={isReadOnly}
                  ml="32px"
                >
                  {id ? "Editar Serviço" : "Cadastrar Serviço"}
                </Button>
              )}
            </ButtonGroup>
          </Flex>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </FormProvider>
  );
};
