import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Tooltip } from "@chakra-ui/react";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

import { WarningType } from "../../../../modules/Records/Tariff/constants/tariff.constants";

export const DueDateCell = ({
  dueDate,
  warning,
}: {
  dueDate: string | null;
  warning?: "missing" | "expired" | "divergent";
}) => {
  return (
    <Flex justifyContent="center" alignItems="center" gap="5px">
      {!!dueDate
        ? format(parseISO(dueDate.split("T")[0]), "dd/MM/yyyy", {
            locale: ptBR,
          })
        : ""}
      {!!warning && (
        <Tooltip
          as="span"
          label={WarningType[warning].message}
          aria-label="Aviso de dados desiguais"
        >
          <WarningTwoIcon color={WarningType[warning].color} cursor="pointer" />
        </Tooltip>
      )}
    </Flex>
  );
};
