import { DEFAULT_REGEX_ESPECIAL_CHARACTERS } from "../constants/defaultValidations";
import { DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUT_DOT_AND_AT } from "../constants/defaultValidations";

export const checkForSpecialChars = (
  str: string,
  regex = DEFAULT_REGEX_ESPECIAL_CHARACTERS
): boolean => {
  const format = regex;
  return format.test(str);
};

export const checkForSpecialCharsOnUserCreation = (str: string): boolean => {
  const format = DEFAULT_REGEX_ESPECIAL_CHARACTERS_WITHOUT_DOT_AND_AT;

  return format.test(str);
};

export const checkForNumericChars = (str: string): boolean => {
  const format = /[0-9]/;

  return format.test(str);
};
