import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";

import { TSmallxs } from "../../../../../../../../main/components/Tipography";

export const DateRuleForm = ({
  index,
  onCancel,
  isReadOnly,
}: {
  index: number;
  onCancel: () => void;
  isReadOnly?: boolean;
}) => {
  const { setValue, watch } = useFormContext();

  const [hasMissingFieldMessage, setHasMissingFieldMessage] = useState("");

  const [dueDateType, setDueDateType] = useState("");
  const [dueDateRule, setDueDateRule] = useState("");

  const thisDueDateType = watch(`servicesProducts[${index}].dueDateType`);
  const thisDueDateRule = watch(`servicesProducts[${index}].dueDateRule`);

  const handleOnConfirm = () => {
    if ((dueDateType && dueDateRule) || (!dueDateType && !dueDateRule)) {
      setHasMissingFieldMessage("");
      setValue(`servicesProducts[${index}].dueDateRule`, Number(dueDateRule));
      setValue(`servicesProducts[${index}].dueDateType`, dueDateType);
      onCancel();
    } else {
      setHasMissingFieldMessage("Termine de preencher os campos corretamente");
    }
  };

  useEffect(() => {
    if (thisDueDateType) setDueDateType(thisDueDateType);
  }, [thisDueDateType]);

  useEffect(() => {
    if (thisDueDateRule) setDueDateRule(thisDueDateRule);
  }, [thisDueDateRule]);

  return (
    <Stack spacing={4} padding="1rem">
      <FormControl display="flex" flexDir="column" alignItems="flex-start">
        <Grid
          gridTemplateColumns="1fr"
          width="100%"
          gap="1rem"
          justifyContent="space-between"
          alignItems="center"
        >
          <RadioGroup
            onChange={setDueDateType}
            value={dueDateType}
            isDisabled={isReadOnly}
          >
            <Stack direction="column">
              <Radio value="FIXED">Data fixa</Radio>
              <Radio value="VARIABLE">Data variável</Radio>
            </Stack>
          </RadioGroup>
          <InputGroup>
            <InputLeftAddon children={dueDateType === "FIXED" ? "Dia" : "D+"} />
            <Input
              isReadOnly={isReadOnly}
              maxW="100%"
              size="md"
              value={dueDateRule}
              onChange={(event) =>
                setDueDateRule(
                  /^[\\0-9]*$/.test(event.target.value)
                    ? event.target.value
                    : ""
                )
              }
              onBlur={(event) => {
                if (
                  Number(event.target.value) > 31 &&
                  dueDateType === "FIXED"
                ) {
                  setDueDateRule("");
                }
                if (
                  Number(event.target.value) > 1000 &&
                  dueDateType === "VARIABLE"
                ) {
                  setDueDateRule("");
                }
              }}
            />
          </InputGroup>
        </Grid>
      </FormControl>
      <ButtonGroup d="flex" justifyContent="space-between">
        <TSmallxs color="red">{hasMissingFieldMessage}</TSmallxs>
        <Button
          colorScheme={hasMissingFieldMessage ? "red" : "blue"}
          size="xs"
          onClick={handleOnConfirm}
        >
          Fechar
        </Button>
      </ButtonGroup>
    </Stack>
  );
};
