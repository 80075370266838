import { ViewIcon } from "@chakra-ui/icons";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Button,
  Portal,
  Divider,
  Flex,
} from "@chakra-ui/react";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import { IUser } from "../../../types/main/user";
import { ModalDelete } from "../CustomModal/ModalDelete";
import { IPopoverListProps } from "../DataTable/IDataTable";
import { HSmallmd } from "../Tipography";
import { ButtonDelete } from "./ButtonDelete";
import { ButtonEdit } from "./ButtonEdit";
import { ExportFragment } from "./ExportFragment";

export const PopoverList = ({
  dataFiltered,
  data,
  isHeader,
  excelColumnHeaders,
  uuid,
  refreshTable = () => new Promise(() => undefined),
  callToDelete = () => new Promise((e) => e),
  urlEdit,
  urlView,
  hideInHeader = false,
  module,
}: IPopoverListProps) => {
  const selectedUserProfile = useMemo(() => {
    const isUserModule = Array.isArray(data) && "username" in (data[0] || {});
    if (!isUserModule) return undefined;

    const userProfile = data?.find((user: IUser) => user.uuid === uuid)
      ?.profileName as string | undefined;

    return userProfile;
  }, [data, uuid]);

  if (hideInHeader && isHeader) {
    return null;
  }

  return (
    <Popover placement="left">
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
            >
              ...
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0">
                <Flex flexDir="column">
                  <ExportFragment
                    data={data}
                    dataFiltered={dataFiltered}
                    headerObject={excelColumnHeaders}
                  />
                  {!isHeader && (
                    <>
                      <Divider my="5px" ml="12px" />

                      <ButtonEdit
                        urlEdit={urlEdit}
                        uuid={uuid}
                        selectedUserProfile={selectedUserProfile}
                        module={module}
                      />

                      <Flex
                        pl="25.33px"
                        py="8px"
                        as={Link}
                        alignItems="center"
                        _hover={{ bg: "gray.50" }}
                        mb="6px"
                        to={`${urlView}${uuid}`}
                      >
                        <ViewIcon color="gray.500" />
                        <HSmallmd ml="8.46px" color="gray.500">
                          Visualizar informações
                        </HSmallmd>
                      </Flex>

                      <Divider my="5px" ml="12px" />
                      <ModalDelete
                        ButtonOpen={ButtonDelete}
                        uuid={uuid}
                        callApi={callToDelete}
                        refreshTable={refreshTable}
                        selectedUserProfile={selectedUserProfile}
                        module={module}
                      />
                    </>
                  )}
                  <PopoverCloseButton />
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
