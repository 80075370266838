import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IProvider } from "../../../../types/main/providers";
import { getServiceProviderById } from "../services/ServiceProviderService";

export const useExistingProvider = (providerUUID?: string) => {
  const toast = useToast();

  const [existingProvider, setExistingProvider] = useState<IProvider>();
  const [endRequestLoadExistingProvider, setEndRequestLoadExistingProvider] =
    useState(false);

  const loadExistingProvider = useCallback(
    async (uuid: string) => {
      try {
        const response = await getServiceProviderById(uuid);

        setExistingProvider(response.body ?? undefined);
        setEndRequestLoadExistingProvider(true);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: DEFAULT_ERROR_MESSAGES.LIST_ACTION,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (providerUUID) {
      loadExistingProvider(providerUUID);
    } else {
      setEndRequestLoadExistingProvider(true);
    }
  }, [loadExistingProvider, providerUUID]);

  return {
    existingProvider,
    endRequestLoadExistingProvider,
  };
};
