import { Route, Routes } from "react-router-dom";

import { PrivateRoute } from "../../../main/privateRoute/PrivateRoute";
import {
  CREATE_PROVIDER_PERMISSION_LEVEL,
  GET_PROVIDER_PERMISSION_LEVEL,
  UPDATE_PROVIDER_PERMISSION_LEVEL,
} from "./constants/permissions";
import { ServiceProviderList } from "./pages/ServiceProviderList";
import { ServiceProviderManager } from "./pages/ServiceProviderManager";

export const ServiceProviderModule = () => {
  return (
    <Routes>
      <Route
        element={<PrivateRoute permission={GET_PROVIDER_PERMISSION_LEVEL} />}
      >
        <Route path="/serviceproviderlist" element={<ServiceProviderList />} />
        <Route
          path="/viewserviceprovider/:uuid"
          element={<ServiceProviderManager isReadOnly />}
        />
      </Route>
      <Route
        element={<PrivateRoute permission={CREATE_PROVIDER_PERMISSION_LEVEL} />}
      >
        <Route
          path="/manageserviceprovider"
          element={<ServiceProviderManager />}
        />
      </Route>
      <Route
        element={<PrivateRoute permission={UPDATE_PROVIDER_PERMISSION_LEVEL} />}
      >
        <Route
          path="/manageserviceprovider/:uuid"
          element={<ServiceProviderManager />}
        />
      </Route>
    </Routes>
  );
};
