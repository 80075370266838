export const onProcessError = (error: any) => {
  try {
    const jsonError = JSON.parse(error);
    return jsonError.body?.message ?? "Erro ao validar arquivo";
  } catch (err) {
    return "Erro ao validar arquivo";
  }
};

export const validateFileType = (file: File, type: string) => {
  if (!!type) return Promise.resolve(type);

  const fileType = file.name.split(".").pop();
  if (fileType) return Promise.resolve(`.${fileType}`);
  return Promise.reject();
};
