import {
  Button,
  createStandaloneToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { customTheme } from "../../../styles/theme";
import { IResponse } from "../../../types/main/response";
import { DEFAULT_ERROR_MESSAGES } from "../../common/constants/defaultMessages";
import { TSmallmd } from "../Tipography";

interface IButtonOpen {
  uuid: string;
  closeModal: () => void;
  callApi: (uuid: string) => Promise<IResponse<any>>;
  refreshTable: () => void;
  isUser?: boolean;
  setTextModalConfirmation?: {
    modalHeaderModalConfirmation: string;
    modalButtonCancelModalConfirmation: string;
    modalButtonConfirmModalConfirmation: string;
  };
}

export const ModalConfirmDelete: React.FC<IButtonOpen> = ({
  uuid,
  closeModal,
  callApi,
  refreshTable,
  isUser,
  setTextModalConfirmation = {
    modalHeaderModalConfirmation: "Tem certeza que deseja deletar?",
    modalButtonCancelModalConfirmation: "Cancelar",
    modalButtonConfirmModalConfirmation: "Sim, deletar",
  },
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = createStandaloneToast(customTheme);

  const handleDelete = useCallback(async () => {
    try {
      await callApi(uuid);
      refreshTable();
      toast({
        position: "bottom",
        title: "Sucesso",
        description: "Ação realizada com sucesso",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
      closeModal();
      onClose();
    } catch (error: any) {
      toast({
        position: "bottom",
        title: "Ocorreu um erro",
        description: error?.message ?? DEFAULT_ERROR_MESSAGES.DEFAULT_ERROR,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      closeModal();
      onClose();
    }
  }, [callApi, closeModal, onClose, refreshTable, toast, uuid]);

  return (
    <>
      <Button onClick={onOpen} colorScheme="red">
        Confirmar
      </Button>
      <Modal isOpen={isOpen} onClose={onClose} isCentered blockScrollOnMount>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {setTextModalConfirmation.modalHeaderModalConfirmation}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <TSmallmd>
              {isUser
                ? "Essa ação não pode ser desfeita posteriormente. (O usuário será deslogado do sistema)"
                : "Essa ação não pode ser desfeita posteriormente"}
            </TSmallmd>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              {setTextModalConfirmation.modalButtonCancelModalConfirmation}
            </Button>
            <Button colorScheme="red" onClick={handleDelete}>
              {setTextModalConfirmation.modalButtonConfirmModalConfirmation}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
