import { useToast } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { ITariffData } from "../../../../types/main/tariff";
import { NewTariff, UpdatePaymentMethod } from "../service/TariffService";

export const useTariff = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmitToCreate = async (data: ITariffData) => {
    try {
      setIsLoading(true);
      const response = await NewTariff(data);
      toast({
        title: "Criação efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      navigate("/tarifflist");
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  const onSubmitToUpdate = async (data: ITariffData) => {
    const {
      billerUuid,
      contractUuid,
      dueDate,
      endDate,
      id,
      startDate,
      tariffCarData,
      idProject,
      invoiceId,
      projectDescription,
      tariffIdentifier,
    } = data;
    try {
      setIsLoading(true);

      const response = await UpdatePaymentMethod({
        billerUuid,
        contractUuid,
        dueDate,
        endDate,
        id,
        startDate,
        tariffCarData,
        idProject,
        invoiceId,
        projectDescription,
        tariffIdentifier,
      });

      toast({
        title: "Atualização efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    onSubmitToCreate,
    onSubmitToUpdate,
  };
};
