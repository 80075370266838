import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

type DayPickerNavbarProps = {
  onPreviousClick: () => void;
  onNextClick: () => void;
  className: string;
};

export const DayPickerNavbar = ({
  onPreviousClick,
  onNextClick,
  className,
}: DayPickerNavbarProps) => {
  return (
    <div className={className}>
      <button
        type="button"
        style={{ float: "left" }}
        onClick={() => onPreviousClick()}
      >
        <ChevronLeftIcon fontSize={25} color="gray.400" />
      </button>
      <button
        type="button"
        style={{
          float: "right",
        }}
        onClick={() => onNextClick()}
      >
        <ChevronRightIcon fontSize={25} color="gray.400" />
      </button>
    </div>
  );
};
