import * as yup from "yup";

import { checkForSpecialChars } from "../../../../main/common/utils/checkStrings";
import { isFirstDateBeforeOrEqualSecondDate } from "../../../../main/common/utils/dateHelper";

export const automaticDebitSchema = yup
  .object({
    idContract: yup.string().required("Esse campo é obrigatório"),
    clientUuid: yup.string().required("Esse campo é obrigatório"),
    channelId: yup.string().required("Esse campo é obrigatório"),
    productUuid: yup.string().required("Esse campo é obrigatório"),
    registrationNumber: yup
      .string()
      .nullable()
      .test(
        "Special Characters validation",
        "Caracteres especiais não permitidos.",
        (value) => !checkForSpecialChars(value ?? "")
      )
      .required("Esse campo é obrigatório"),
    bankNumber: yup.string().required("Esse campo é obrigatório"),
    agencyNumber: yup.string().required("Esse campo é obrigatório"),
    currentAccount: yup.string().required("Esse campo é obrigatório"),
    inclusionDate: yup
      .string()
      .test("Future Date Validation", "Data não pode ser futura.", (value) =>
        isFirstDateBeforeOrEqualSecondDate(value, new Date())
      )
      .required("Esse campo é obrigatório"),
    juros: yup.string().required("Esse campo é obrigatório"),
    multa: yup.string().required("Esse campo é obrigatório"),
  })
  .required();
