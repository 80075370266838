import { Navigate, Outlet } from "react-router-dom";

import { Backdrop } from "../components/Backdrop";
import { useAuth } from "../hooks/useAuth";

export const PrivateRoute = ({ permission }: { permission: string[] }) => {
  const { permissionLevel, isLoginLoading } = useAuth();

  if (isLoginLoading) return <Backdrop />;

  if (permission.includes(permissionLevel)) return <Outlet />;

  return <Navigate to="/home" />;
};
