export enum ReceivableStatusEnum {
  "WAITING_SEND" = "WAITING_SEND",
  "WAITING_BANK" = "WAITING_BANK",
  "REJECTION_BANK" = "REJECTION_BANK",
  "WAITING_BANK_REPICK" = "WAITING_BANK_REPICK",
  "CONFIRMATION_BANK" = "CONFIRMATION_BANK",
  "CONFIRMATION_BANK_REPICK" = "CONFIRMATION_BANK_REPICK",
  "CONFIRMATION_MANUAL" = "CONFIRMATION_MANUAL",
  "BLOCK" = "BLOCK",
  "CONFIRMATION_SENIOR" = "CONFIRMATION_SENIOR",
}

export const ReceivableStatusOptions = [
  {
    value: ReceivableStatusEnum.WAITING_SEND,
    label: "Aguardando envio",
  },
  {
    value: ReceivableStatusEnum.WAITING_BANK,
    label: "Aguardando retorno",
  },
  {
    value: ReceivableStatusEnum.REJECTION_BANK,
    label: "Débito em aberto",
  },
  {
    value: ReceivableStatusEnum.WAITING_BANK_REPICK,
    label: "Aguardando Retorno Repick",
  },
  {
    value: ReceivableStatusEnum.CONFIRMATION_BANK,
    label: "Débito efetuado",
  },
  {
    value: ReceivableStatusEnum.CONFIRMATION_BANK_REPICK,
    label: "Repick efetuado",
  },
  {
    value: ReceivableStatusEnum.CONFIRMATION_MANUAL,
    label: "Baixa manual",
  },
  {
    value: ReceivableStatusEnum.BLOCK,
    label: "Débito bloqueado",
  },
  {
    value: ReceivableStatusEnum.CONFIRMATION_SENIOR,
    label: "Confirmação Manual via Sênior",
  },
];
