import { useAccount, useMsal } from "@azure/msal-react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Grid,
  GridItem,
  Input,
  InputGroup,
  InputRightElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logo, Logout } from "..";
import { GET_USER_PERMISSION_LEVELS } from "../../../modules/Access/Users/constants/userPagePermissionLevel";
import { GET_BILLER_PERMISSION_LEVEL } from "../../../modules/Records/Biller/constants/permissions";
import { GET_CHANNELS_PERMISSION_LEVEL } from "../../../modules/Records/Channels/constants/permissions";
import { GET_CLIENTS_PERMISSION_LEVEL } from "../../../modules/Records/Clients/constants/permissions";
import { GET_CONTRACTS_PERMISSIONS_LEVEL } from "../../../modules/Records/Contract/constants/permissions";
import { GET_PAYMENT_METHODS_PERMISSION_LEVEL } from "../../../modules/Records/PaymentMethod/constants/permissions";
import { GET_PRODUCTS_PERMISSION_LEVEL } from "../../../modules/Records/Products/constants/permissions";
import { GET_REMITTANCE_PERMISSIONS_LEVEL } from "../../../modules/Records/Remittance/constants/permissions";
import { GET_PROVIDER_PERMISSION_LEVEL } from "../../../modules/Records/ServiceProvider/constants/permissions";
import { GET_SERVICES_PERMISSION_LEVEL } from "../../../modules/Records/ServiceProvision/constants/permissions";
import { GET_TARIFF_MOVEMENTS_PERMISSIONS_LEVEL } from "../../../modules/Records/TariffMovements/constants/permissions";
import { GET_TEMPLATES_PERMISSION_LEVEL } from "../../../modules/Records/Templates/constants/permissions";
import { GET_WEB_HOOKS_PERMISSION_LEVEL } from "../../../modules/Records/WebHooks/constants/permissions";
import { useAuth } from "../../hooks/useAuth";

export const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const bg = useColorModeValue("#2C58A4", "gray.700");
  const border = useColorModeValue("blue.700", "gray.800");
  const color = useColorModeValue("blue.700", "gray.700");
  const button = useColorModeValue("white", "gray.200");
  const buttonActive = useColorModeValue("blue.200", "gray.300");
  const buttonHover = useColorModeValue("blue.50", "gray.300");

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [email, setEmail] = useState("email@interstar.com.br");

  const { permissionLevel } = useAuth();

  const menuRoutes = useMemo(
    () => [
      {
        label: "Clientes",
        path: "/clientlist",
        isPermitted: GET_CLIENTS_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Produtos",
        path: "/productlist",
        isPermitted: GET_PRODUCTS_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Serviços",
        path: "/serviceprovisionlist",
        isPermitted: GET_SERVICES_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Prestadores",
        path: "/serviceproviderlist",
        isPermitted: GET_PROVIDER_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Templates",
        path: "/templatelist",
        isPermitted: GET_TEMPLATES_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Métodos de pagamento",
        path: "/paymentmethodslist",
        isPermitted:
          GET_PAYMENT_METHODS_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Canais",
        path: "/channelslist",
        isPermitted: GET_CHANNELS_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Contratos",
        path: "/contractlist",
        isPermitted: GET_CONTRACTS_PERMISSIONS_LEVEL.includes(permissionLevel),
      },
      {
        label: "Usuários",
        path: "/userlist",
        isPermitted: GET_USER_PERMISSION_LEVELS.includes(permissionLevel),
      },
      {
        label: "Tarifadores",
        path: "/billerlist",
        isPermitted: GET_BILLER_PERMISSION_LEVEL.includes(permissionLevel),
      },
      {
        label: "Webhooks",
        path: "/webhooks",
        isPermitted: GET_WEB_HOOKS_PERMISSION_LEVEL.includes(permissionLevel),
      },
    ],
    [permissionLevel]
  );

  const menuInvoice = useMemo(
    () => [
      {
        label: "Tarifas",
        path: "/tarifflist",
        isPermitted: true,
      },
      {
        label: "Contas a receber",
        path: "/carslist",
        isPermitted: true,
      },
      {
        label: "Faturamentos",
        path: "/invoicing",
        isPermitted: true,
      },
      {
        label: "Faturas",
        path: "/invoicelist",
        isPermitted: true,
      },
      {
        label: "Boletos",
        path: "/boletos",
        isPermitted: true,
      },
      {
        label: "Movimentações",
        path: "/tariffmovementlist",
        isPermitted:
          GET_TARIFF_MOVEMENTS_PERMISSIONS_LEVEL.includes(permissionLevel),
      },
      {
        label: "Remessas",
        path: "/remittancelist",
        isPermitted: GET_REMITTANCE_PERMISSIONS_LEVEL.includes(permissionLevel),
      },
    ],
    [permissionLevel]
  );

  useEffect(() => {
    if (account) {
      setEmail(account.username);
    }
  }, [instance, account]);

  return (
    <Grid
      pos="fixed"
      zIndex={2}
      minW="100vw"
      h="100px"
      templateRows="1fr"
      bg={bg}
      borderBottom="2px"
      borderColor={border}
      pl="160px"
      pr="160px"
      width="100vw"
      alignItems="center"
      gap="10px"
      mt={0}
    >
      <GridItem colSpan={1} rowSpan={1} marginRight="1rem">
        <Link to="/home">
          <Logo color="white" height="100%" width="100%" />
        </Link>
      </GridItem>
      <GridItem
        colStart={2}
        colSpan={6}
        rowSpan={1}
        display="flex"
        justifyContent="center"
        gap="1rem"
      >
        <Menu>
          <MenuButton
            height="2.5rem"
            color={color}
            bg={button}
            _active={{ bg: buttonActive }}
            _hover={{ bg: buttonHover }}
            borderRadius="10px"
            variant="solid"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            Cadastros e Consultas
          </MenuButton>
          <MenuList>
            {menuRoutes
              .filter((route) => route.isPermitted)
              .map((route) => {
                return (
                  <MenuItem key={route.path} as={Link} to={route.path}>
                    {route.label}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton
            color={color}
            bg={button}
            _active={{ bg: buttonActive }}
            _hover={{ bg: buttonHover }}
            borderRadius="10px"
            variant="solid"
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            Faturamento
          </MenuButton>
          <MenuList>
            {menuInvoice
              .filter((route) => route.isPermitted)
              .map((route) => {
                return (
                  <MenuItem key={route.path} as={Link} to={route.path}>
                    {route.label}
                  </MenuItem>
                );
              })}
          </MenuList>
        </Menu>
        <Link to="/reports">
          <Button
            color={color}
            bg={button}
            _active={{ bg: buttonActive }}
            _hover={{ bg: buttonHover }}
            borderRadius="10px"
            variant="solid"
            as={Button}
          >
            Relatório
          </Button>
        </Link>
        <Link to="/logs">
          <Button
            color={color}
            bg={button}
            _active={{ bg: buttonActive }}
            _hover={{ bg: buttonHover }}
            borderRadius="10px"
            variant="solid"
            as={Button}
          >
            Log de Atividade
          </Button>
        </Link>
        {permissionLevel === "Master" && (
          <Link to="/clearData">
            <Button
              color={color}
              bg={button}
              _active={{ bg: buttonActive }}
              _hover={{ bg: buttonHover }}
              borderRadius="10px"
              variant="solid"
              as={Button}
              disabled
              onClick={() => {
                navigate("/clearData");
              }}
            >
              Limpar dados
            </Button>
          </Link>
        )}
      </GridItem>
      <GridItem colEnd={10} rowSpan={1}>
        <Menu>
          <MenuButton borderRadius="50" variant="solid" as={InputGroup}>
            <Input
              bg={button}
              _placeholder={{ color }}
              _disabled={{ color }}
              isDisabled
              placeholder={email}
            />
            <InputRightElement>
              <ChevronDownIcon color={color} />
            </InputRightElement>
          </MenuButton>
          <MenuList>
            <Logout />
            {/* <ColorModeSwitcher /> */}
          </MenuList>
        </Menu>
      </GridItem>
    </Grid>
  );
};
