import { WarningTwoIcon } from "@chakra-ui/icons";
import { Flex, Tooltip } from "@chakra-ui/react";
import { parseISO } from "date-fns";
import { FormProvider, useForm } from "react-hook-form";

import { customToast } from "../../../../../main/common/utils/customToast";
import { useTableContext } from "../../../../../main/components/DataTable/context/useTableContext";
import { DatePicker } from "../../../../../main/components/DateTimePicker/DatePicker";
import { IRemittanceView } from "../../../../../types/main/remittances";
import { WarningType } from "../../../Tariff/constants/tariff.constants";
import { validateDueDate } from "../../service/remittanceService";

interface IDueDateCellEditableProps {
  dueDate: Date | string | null;
  warning?: keyof typeof WarningType;
  remittance: IRemittanceView;
  setRemittances: React.Dispatch<
    React.SetStateAction<{
      isFetching: boolean;
      data: IRemittanceView[];
    }>
  >;
}

const getDefaultValues = (dueDate: string | null | Date) => {
  if (!dueDate) {
    return {
      remittanceDueDate: null,
    };
  }

  if (typeof dueDate === "string") {
    return {
      remittanceDueDate: parseISO(dueDate.split("T")[0]),
    };
  }

  return {
    remittanceDueDate: dueDate as Date,
  };
};

export const DueDateCellEditable = ({
  dueDate,
  warning,
  remittance,
  setRemittances,
}: IDueDateCellEditableProps) => {
  const formMethods = useForm<{ remittanceDueDate: Date | null }>({
    defaultValues: getDefaultValues(dueDate),
  });

  const { open, close } = useTableContext();

  const handleChange = async (date: Date | undefined) => {
    if (!date) {
      setRemittances((oldState) => ({
        ...oldState,
        data: oldState.data.map((state) => {
          if (state.key === remittance.key) {
            return {
              ...state,
              shouldBlockForInconsistentDueDate: true,
              dueDate: date,
            };
          }
          return state;
        }),
      }));
    } else {
      try {
        await validateDueDate(date);

        setRemittances((oldState) => ({
          ...oldState,
          data: oldState.data.map((state) => {
            if (state.key === remittance.key) {
              return {
                ...state,
                shouldBlockForInconsistentDueDate: false,
                remittanceDueDate: date,
              };
            }
            return state;
          }),
        }));
        formMethods.setValue("remittanceDueDate", date ?? null);
      } catch (err: any) {
        setRemittances((oldState) => ({
          ...oldState,
          data: oldState.data.map((state) => {
            if (state.key === remittance.key) {
              return {
                ...state,
                shouldBlockForInconsistentDueDate: true,
                remittanceDueDate: date,
              };
            }
            return state;
          }),
        }));
        customToast("warning", err.message);
      }
    }
  };

  return (
    <Flex justifyContent="center" alignItems="center" gap="5px" width="100%">
      {!!warning && (
        <Tooltip
          as="span"
          label={WarningType[warning].message}
          aria-label="Aviso de dados desiguais"
        >
          <WarningTwoIcon color={WarningType[warning].color} cursor="pointer" />
        </Tooltip>
      )}
      <FormProvider {...formMethods}>
        <form>
          <DatePicker
            fieldname="remittanceDueDate"
            onDateChange={handleChange}
            placement="auto"
            fontSize={!!warning ? "xs" : "sm"}
            onOpen={open}
            onClose={close}
          />
        </form>
      </FormProvider>
    </Flex>
  );
};
