import { useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../../main/common/constants/defaultMessages";
import { IManageEmailConfig } from "../../../../../types/main/channelEmailConfig";
import { ITemplateOptions } from "../../../../../types/main/templates";
import { manageEmailConfig } from "../../services/ChannelEmailService";

interface IUseChannelEmail {
  channelId: string | undefined;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  templateOptions: ITemplateOptions[];
}

export const useChannelEmail = ({
  channelId,
  setIsLoading,
  templateOptions,
}: IUseChannelEmail) => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();

  const onSubmit = async (emailConfigData: IManageEmailConfig) => {
    const validateDeletedAtTemplate = emailConfigData.emailConfigs
      ?.map(
        // eslint-disable-next-line array-callback-return
        ({ templateUuid }) => {
          return (
            templateOptions
              // eslint-disable-next-line array-callback-return
              .map(({ name, uuid, deletedAt }) => {
                if (templateUuid === uuid && deletedAt) {
                  return name;
                }
              })
              .find((name) => name)
          );
        }
      )
      .filter((name) => name);

    if (validateDeletedAtTemplate && validateDeletedAtTemplate.length > 0) {
      validateDeletedAtTemplate.forEach((name) => {
        toast({
          title: "Error",
          description: `${name} está inativo, selecione outro template ou contate o administrador`,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      });
    } else {
      try {
        setIsLoading(true);

        if (channelId) {
          const response = await manageEmailConfig(emailConfigData, channelId);

          toast({
            title: "Efetuado com sucesso.",
            description: response.body?.message,
            status: "success",
            duration: 5000,
            isClosable: true,
          });
        }
        navigate(location.pathname);
      } catch (error: any) {
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
  };

  return {
    onSubmit,
  };
};
