import { Flex } from "@chakra-ui/react";

import { IconDelete } from "../../../../main/common/assets";
import { HSmallmd } from "../../../../main/components/Tipography";

export const ButtonCancel = ({ ...rest }) => {
  return (
    <Flex
      py="8px"
      as="button"
      alignItems="center"
      _hover={{ bg: "gray.50" }}
      mb="6px"
      {...rest}
    >
      <IconDelete />
      <HSmallmd ml="8.46px" color="red.500">
        Cancelar remessa
      </HSmallmd>
    </Flex>
  );
};
