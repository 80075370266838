import { useToast } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { UseFormReset } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { ITemplatesData } from "../../../../types/main/templates";
import { createNewTemplate, updateTemplate } from "../service/TemplateService";

interface IUseTemplateForm {
  reset: UseFormReset<ITemplatesData>;
  templateToUpdateUUID?: string;
}

export const useTemplateForm = ({
  reset,
  templateToUpdateUUID,
}: IUseTemplateForm) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async (data: ITemplatesData) => {
      try {
        setIsLoading(true);

        let response;

        if (templateToUpdateUUID)
          response = await updateTemplate(data, templateToUpdateUUID);
        else response = await createNewTemplate(data);

        toast({
          title: "Efetuado com sucesso.",
          description: response.body?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        reset();
        navigate("/templatelist");
      } catch (error: any) {
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    },
    [navigate, reset, templateToUpdateUUID, toast]
  );

  return {
    isLoading,
    onSubmit,
  };
};
