export const WarningType = {
  divergent: {
    color: "yellow.500",
    message: "Datas de vencimento diferentes encontradas",
  },
  expired: {
    color: "yellow.500",
    message: "Há datas de vencimento expiradas",
  },
  missing: {
    color: "red.500",
    message: "Há datas de vencimento inválidas",
  },
};

export const DEFAULT_VALIDATION_TARIFF_OPEN = [
  "WAITING_SEND",
  "WAITING_BANK",
  "REJECTION_BANK",
  "WAITING_BANK_REPICK",
];

export const DEFAULT_VALIDATION_IN_REMITTANCE = [
  "WAITING_SEND",
  "REJECTION_BANK",
  "WAITING_BANK",
  "WAITING_BANK_REPICK",
];
