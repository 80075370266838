import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import { MODULES } from "../../../../main/common/constants/modules";
import { CreateNewDataButton } from "../../../../main/components/CreateNewDataButton";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { DataTable } from "../../../../main/components/DataTable";
import { SelectColumnFilter } from "../../../../main/components/DataTable/Filters";
import { useColumnsDisclosure } from "../../../../main/components/DataTable/hooks/useColumnsDisclosure";
import { SimpleMenu } from "../../../../main/components/SimpleMenu";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { IServiceProvisionList } from "../../../../types/main/serviceProvision";
import {
  AllServicesProvision,
  DeleteServiceProvision,
} from "../service/ServiceProvisionService";

interface IServiceProvisionTable
  extends Omit<IServiceProvisionList, "issueInvoice" | "contributesToEfd"> {
  issueInvoice: string | undefined;
  contributesToEfd: string | undefined;
}

export const ServiceProvisionList: React.FC = () => {
  const [servicesProvision, setServicesProvision] = useState<
    Array<IServiceProvisionTable>
  >([]);

  const [isLoading, setIsLoading] = useState(false);

  const { SelectableColumns, columns, hiddenColumns } = useColumnsDisclosure({
    tableColumns: [
      {
        Header: " ",
        columns: [
          {
            Header: "Descrição",
            accessor: "name",
          },
          {
            Header: "Emite Nota Fiscal",
            accessor: "issueInvoice",
            Filter: SelectColumnFilter,
          },
          {
            Header: "Contribui no EFD",
            accessor: "contributesToEfd",
            Filter: SelectColumnFilter,
          },
          {
            Header: "ID do Serviço",
            accessor: "uuid",
            startHidden: true,
          },
        ],
      },
    ],
  });

  const handleServiceProvisionList = async () => {
    setIsLoading(true);
    const response = await AllServicesProvision();
    if (response.type === "success" && response.body) {
      const serviceProvisionList = response.body.map(
        ({ uuid, name, issueInvoice, contributesToEfd }) => {
          return {
            uuid,
            name,
            issueInvoice: issueInvoice === true ? "SIM" : "NÃO",
            contributesToEfd: contributesToEfd === true ? "SIM" : "NÃO",
          };
        }
      );
      setServicesProvision(serviceProvisionList);
    }
    setIsLoading(false);
  };

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e Consultas", href: "" },
    { description: "Serviços", href: "/serviceprovisionlist" },
  ];

  useEffect(() => {
    handleServiceProvisionList();
  }, []);

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex justifyContent="space-between" alignItems="center">
        <HLarge2xl mb="32px" color="gray.600">
          Gerenciamento de serviços
        </HLarge2xl>
        <Box>
          <SimpleMenu label="Exibir colunas">
            <SelectableColumns />
          </SimpleMenu>
          <CreateNewDataButton
            module={MODULES.SERVICES}
            path="/manageserviceprovision"
            text="Cadastrar novo serviço"
          />
        </Box>
      </Flex>
      <DataTable
        refreshTable={handleServiceProvisionList}
        module="Services"
        isLoading={isLoading}
        hiddenColumns={hiddenColumns}
        columns={columns}
        data={servicesProvision}
        popup={{
          callToDelete: DeleteServiceProvision,
          refreshTable: handleServiceProvisionList,
          urlEdit: "/manageserviceprovision/",
          urlView: "/viewserviceprovision/",
        }}
        showCheckbox={false}
      />
    </Wrapper>
  );
};
