import {
  Box,
  Input,
  InputGroup,
  Menu,
  MenuButton,
  MenuList,
  Stack,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";

import { getTimeOptionsBetweenInterval } from "../../../common/utils/dateHelper";
import { useInterstarTheme } from "../../../hooks/useInterstarTheme";
import { TSmallmd } from "../../Tipography";

import "./time-picker.css";

const INTERVAL_BETWEEN_EACH_TIME_OPTION = 15;
const timeOptions = getTimeOptionsBetweenInterval(
  INTERVAL_BETWEEN_EACH_TIME_OPTION
);

interface ITimePicker {
  fieldname: string;
  onTimeChange?: (time: string | undefined) => void;
  readonly?: boolean;
}

export const TimePicker = ({
  fieldname,
  onTimeChange,
  readonly,
}: ITimePicker) => {
  const { colors } = useInterstarTheme();

  const { register, setValue } = useFormContext();

  const [isTimerSelectorOpen, setIsTimerSelectorOpen] = useState(false);

  const { watch } = useFormContext();

  const watchFieldname = watch(fieldname);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!watchFieldname && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [watchFieldname]);

  function handleSelectOptionTime(selectedTime: string) {
    setIsTimerSelectorOpen(false);
    setValue(fieldname, selectedTime);
    if (inputRef.current) inputRef.current.value = selectedTime;
    if (onTimeChange) onTimeChange(selectedTime);
  }

  const handleManualInputTimeChange = (event: any) => {
    setValue(fieldname, event.target.value);
    if (onTimeChange) onTimeChange(event.target.value);
  };

  return (
    <Menu
      matchWidth
      isOpen={isTimerSelectorOpen}
      placement="auto"
      strategy="fixed"
      closeOnBlur
      closeOnSelect
      onClose={() => setIsTimerSelectorOpen(false)}
    >
      <MenuButton
        maxWidth="140px"
        as={InputGroup}
        onClick={() =>
          !readonly && setIsTimerSelectorOpen((oldState) => !oldState)
        }
      >
        <Input
          readOnly
          _placeholder={{ color: colors.gray600 }}
          _disabled={{ color: colors.gray600 }}
          {...register(fieldname)}
          w="100px"
        />
      </MenuButton>
      <MenuList padding={2} minW="140px">
        <InputGroup>
          <Input
            ref={inputRef}
            type="time"
            placeholder="Horário específico"
            onChange={handleManualInputTimeChange}
          />
        </InputGroup>
        <Box overflowY="scroll" maxH="200px" mt={2}>
          <Stack pl={1} mt={4} spacing={1}>
            {timeOptions &&
              timeOptions.map((option) => {
                return (
                  <TSmallmd
                    className={`time-option ${
                      inputRef.current?.value === option &&
                      "time-option-selected"
                    }`}
                    fontWeight={500}
                    key={uuidv4()}
                    onClick={() => handleSelectOptionTime(option)}
                  >
                    {option}
                  </TSmallmd>
                );
              })}
          </Stack>
        </Box>
      </MenuList>
    </Menu>
  );
};
