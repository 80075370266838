import { SearchIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { MdErrorOutline } from "react-icons/md";
import { useParams, useLocation, useSearchParams } from "react-router-dom";
import { Backdrop } from "../../../../main/components/Backdrop";
import { DatePicker } from "../../../../main/components/DateTimePicker/DatePicker";
import { DynamicDocumentInputMask } from "../../../../main/components/DynamicInputMask";
import { MultiSelect } from "../../../../main/components/MultiSelect";
import { NumberFilterInput } from "../../../../main/components/NumberFilterInput";
import { TreeSelect } from "../../../../main/components/TreeSelect";
import { IBoletoFilters } from "../../../../types/main/boleto";
import { useBoletosFilters } from "../hooks/useBoletoFilters";

type Props = {
  handleSearch: (filters?: IBoletoFilters) => Promise<void>;
};
export const BoletosFilters = memo(({ handleSearch }: Props) => {
  const [query] = useSearchParams();

  const formMethods = useForm<IBoletoFilters>({
    defaultValues: {
      invoiceId: query.get("invoiceId") ?? null,
      tariffId: query.get("tariffId") ?? null,
    },
  });

  const {
    handleSubmit,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { channels, hasFetchError, isFetching, products, onSubmitFilters } =
    useBoletosFilters({
      handleSearch,
    });

  if (isFetching) return <Backdrop />;

  if (hasFetchError) {
    return (
      <div>
        <MdErrorOutline />
      </div>
    );
  }

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmitFilters)}>
        <div>
          <Flex
            w="100%"
            justifyContent="flex-start"
            gap="15px"
            marginBlock="0.5rem"
          >
            <FormControl isInvalid={!!errors.channels} maxWidth="500px">
              <TreeSelect
                data={channels}
                name="channels"
                placeholder="Canais"
              />
            </FormControl>

            <FormControl maxWidth="300px" isInvalid={!!errors.products}>
              <FormLabel htmlFor="products">Produtos</FormLabel>
              <MultiSelect
                buttonLabel="Produtos"
                fieldname="products"
                defaultOptions={products.map((item) => ({
                  label: item.name,
                  value: item.uuid,
                }))}
              />
            </FormControl>

            <FormControl maxWidth="300px" isInvalid={!!errors.dueDate}>
              <FormLabel htmlFor="dueDate">Vencimento</FormLabel>

              <DatePicker fieldname="dueDate" />
              <FormErrorMessage>
                {errors.dueDate && errors.dueDate?.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl maxWidth="300px" isInvalid={!!errors.document}>
              <FormLabel htmlFor="document">Documento</FormLabel>

              <DynamicDocumentInputMask />
              <FormErrorMessage>
                {errors.document && errors.document?.message}
              </FormErrorMessage>
            </FormControl>
          </Flex>

          <Flex
            w="100%"
            justifyContent="flex-start"
            gap="15px"
            marginBottom="2rem"
            alignContent="center"
          >
            <FormControl>
              <FormLabel htmlFor="tariffId">Id da tarifa</FormLabel>
              <NumberFilterInput
                fieldname="tariffId"
                type="number"
                maxLength={10}
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="invoiceId">Id da fatura</FormLabel>
              <NumberFilterInput
                fieldname="invoiceId"
                type="number"
                maxLength={10}
              />
            </FormControl>

            <ButtonGroup alignItems="flex-end" mb="">
              <Button
                backgroundColor="blue.500"
                type="submit"
                isLoading={isSubmitting}
                leftIcon={<SearchIcon />}
              >
                Consultar
              </Button>
            </ButtonGroup>
          </Flex>
        </div>
      </form>
    </FormProvider>
  );
});
