import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Link } from "react-router-dom";

import { AddressForm } from "../../../../../main/components/AdressForm";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { BooleanRadioGroup } from "../../../../../main/components/CustomRadioGroup/BooleanRadioGroup";
import { InputMask } from "../../../../../main/components/InputMask";
import { InputPassword } from "../../../../../main/components/InputPassword";
import { TableForm } from "../../../../../main/components/TableForm";
import { TLargexl } from "../../../../../main/components/Tipography";
import { IProviderData } from "../../../../../types/main/providers";
import { defaultServiceAliquot } from "../../constants/defaultAliquot.constant";
import { useExistingProvider } from "../../hooks/useExistingProvider";
import { useFetchDataList } from "../../hooks/useFetchDataList";
import { useServiceProviderForm } from "../../hooks/useServiceProviderForm";
import { providerSchema } from "../../validation/providerSchema";
import { AliquotsRowForm } from "../AliquotsRowForm";

interface IServiceProviderFormProps {
  providerUUID?: string;
  isReadOnly?: boolean;
}

export const ServiceProviderForm = ({
  providerUUID,
  isReadOnly,
}: IServiceProviderFormProps) => {
  const formMethods = useForm<IProviderData>({
    resolver: yupResolver(providerSchema),
  });

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { existingProvider, endRequestLoadExistingProvider } =
    useExistingProvider(providerUUID);

  const { servicesList } = useFetchDataList({
    waitForRequest: endRequestLoadExistingProvider,
    idsListOnScreen: existingProvider?.servicesPerProvider?.map(
      ({ serviceProvisionUuid }) => serviceProvisionUuid
    ),
  });

  const {
    isLoading,
    tableForm,

    aliquotsTableHeader,
    canAddRow,

    onSubmit,
  } = useServiceProviderForm({
    editProviderUUID: providerUUID,
    simple_national: watch("simple_national"),
    currentServicePerProvider: watch("servicesPerProvider") ?? [],
    isReadOnly,
    serviceListLength: servicesList.length,
  });

  useEffect(() => {
    if (existingProvider && servicesList) {
      setValue("name", existingProvider.name);
      setValue("document", existingProvider.document);
      setValue("ie", existingProvider.ie);
      setValue("taxes_description", existingProvider.taxes_description);
      setValue("simple_national", existingProvider.simple_national);
      setValue("cep", existingProvider.cep);
      setValue("state", existingProvider.state);
      setValue("city", existingProvider.city);
      setValue("district", existingProvider.district);
      setValue("address", existingProvider.address);
      setValue("addressNumber", existingProvider.addressNumber);
      setValue("additionalInformation", existingProvider.additionalInformation);
      setValue("nfePassword", existingProvider.nfePassword);
      setValue("nfeUsername", existingProvider.nfeUsername);
      setValue("servicesPerProvider", existingProvider.servicesPerProvider);
      setValue("codCompany", existingProvider.codCompany);
      setValue("codSubsidiary", existingProvider.codSubsidiary);
      setValue("EFD_COD_MUN", existingProvider.EFD_COD_MUN);
    }
  }, [existingProvider, servicesList, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit((providerData) =>
          onSubmit({ providerData, servicesList })
        )}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <TLargexl mb="1em" alignSelf="flex-start">
          Dados do prestador
        </TLargexl>
        <Grid templateRows="(2, 1fr)" templateColumns="repeat(4, 1fr)" gap={4}>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Nome</FormLabel>
              <Input
                id="name"
                {...register("name")}
                autoComplete="off"
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.name && errors.name.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.document}>
              <FormLabel htmlFor="document">CNPJ</FormLabel>
              <InputMask
                fieldname="document"
                format="##.###.###/####-##"
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.document && errors.document.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.ie}>
              <FormLabel htmlFor="ie">Inscrição Estadual</FormLabel>
              <Input
                id="ie"
                maxLength={16}
                {...register("ie")}
                autoComplete="off"
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.ie && errors.ie.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={3}>
            <FormControl isInvalid={!!errors.taxes_description}>
              <FormLabel htmlFor="taxes_description">
                Informação complementar de tributos incidentes
              </FormLabel>
              <Input
                id="taxes_description"
                {...register("taxes_description")}
                autoComplete="off"
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.taxes_description && errors.taxes_description.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.simple_national}>
              <FormLabel htmlFor="simple_national">Simples nacional</FormLabel>
              <BooleanRadioGroup
                fieldname="simple_national"
                options={[
                  { label: "Sim", value: true },
                  { label: "Não", value: false },
                ]}
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.simple_national && errors.simple_national.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.nfeUsername}>
              <FormLabel htmlFor="nfeUsername">
                Usuário NFe - Credencial
              </FormLabel>
              <Input
                id="nfeUsername"
                {...register("nfeUsername")}
                autoComplete="off"
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.nfeUsername && errors.nfeUsername.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.nfePassword}>
              <FormLabel htmlFor="nfePassword">
                Senha NFe - Credencial
              </FormLabel>
              <InputPassword
                id="nfePassword"
                {...register("nfePassword")}
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.nfePassword && errors.nfePassword.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.codCompany}>
              <FormLabel htmlFor="codCompany">
                Código da empresa (Senior)
              </FormLabel>
              <Input
                id="codCompany"
                {...register("codCompany")}
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.codCompany && errors.codCompany.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.codSubsidiary}>
              <FormLabel htmlFor="codSubsidiary">
                Código da filial (Senior)
              </FormLabel>
              <Input
                id="codSubsidiary"
                {...register("codSubsidiary")}
                readOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.codSubsidiary && errors.codSubsidiary.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid templateRows="(2, 1fr)" templateColumns="repeat(12, 1fr)" gap={4}>
          <AddressForm isDisabled={isReadOnly} uuidToEdit={providerUUID} />
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.EFD_COD_MUN}>
              <FormLabel htmlFor="EFD_COD_MUN">Cód. Municipio</FormLabel>
              <Input
                disabled={isReadOnly}
                placeholder="Código"
                {...register("EFD_COD_MUN")}
              />

              <FormErrorMessage>
                {errors.EFD_COD_MUN && errors.EFD_COD_MUN.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>

        <TLargexl mb="1em" mt="2rem" alignSelf="flex-start">
          Serviços
        </TLargexl>
        <Grid templateRows="(1, 1fr)" templateColumns="1fr" gap={4}>
          <GridItem colSpan={1}>
            <TableForm
              tableHeader={aliquotsTableHeader}
              rowReferenceName="servicesPerProvider"
              canDeleteRow={!isReadOnly}
              canAddRow={canAddRow}
              defaultNewRow={defaultServiceAliquot}
              ref={tableForm}
              renderProp={(index: number) => (
                <AliquotsRowForm
                  servicesList={servicesList}
                  index={index}
                  isReadOnly={isReadOnly}
                />
              )}
            />
          </GridItem>
        </Grid>

        <Flex w="100%" justify="flex-end" mb="4rem">
          <ButtonGroup spacing="58" alignItems="center">
            <Link to="/serviceproviderlist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>

            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                {existingProvider ? "Editar prestador" : "Cadastrar prestador"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </FormProvider>
  );
};
