import { useToast } from "@chakra-ui/react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../../main/common/constants/defaultMessages";
import { IManageInvoiceConfig } from "../../../../../types/main/channelInvoiceConfig";
import { manageInvoiceConfig } from "../../services/ChannelInvoiceService";

interface IUseChannelInvoice {
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  channelId: string | undefined;
}

export const useChannelInvoice = ({
  channelId,
  setIsLoading,
}: IUseChannelInvoice) => {
  const toast = useToast();

  const onSubmit = async (invoiceConfigData: IManageInvoiceConfig) => {
    try {
      setIsLoading(true);

      if (channelId) {
        const payload = {
          invoiceConfigData,
          channelId,
        };

        const response = await manageInvoiceConfig(payload);

        toast({
          title: "Efetuado com sucesso.",
          description: response.body?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setIsLoading(false);
  };

  return {
    onSubmit,
  };
};
