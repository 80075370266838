import {
  useToast,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { DEFAULT_ERROR_MESSAGES } from "../../../../../main/common/constants/defaultMessages";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { TSmallmd } from "../../../../../main/components/Tipography/TextSmall";
import { IPaymentMethodsData } from "../../../../../types/main/paymentMethods";
import { enumTypeOfPaymentType } from "../../../../../types/main/paymentType";
import { useGetAllTypes } from "../../../shared/hooks/PaymentMethod/useGetAllTypes";
import { useExistingPaymentMethod } from "../../hooks/useExistingPaymentMethod";
import { useGetAllBanks } from "../../hooks/useGetAllBanks";
import { useGetAllLayouts } from "../../hooks/useGetAllLayouts";
import { useGetAllTemplates } from "../../hooks/useGetAllTemplates";
import { useGetAllTransmissionChannel } from "../../hooks/useGetAllTransmissionChannel";
import {
  IUpdatePaymentMethod,
  NewPaymentMethod,
  UpdatePaymentMethod,
} from "../../service/PaymentMethodService";
import { AutomaticDebit } from "./Tabs/AutomaticDebit";
import { Boleto } from "./Tabs/Boleto";
import { ThirdPartyDebit } from "./Tabs/ThirdPartyDebit";

interface IContractForm {
  paymentMethodUUID?: string;
  isReadOnly?: boolean;
}

export const PaymentMethodForm: React.FC<IContractForm> = ({
  paymentMethodUUID,
  isReadOnly,
}) => {
  const toast = useToast();
  const navigate = useNavigate();
  const { existingPaymentMethod, endRequestExistingPaymentMethod } =
    useExistingPaymentMethod(paymentMethodUUID);
  const { allTypes } = useGetAllTypes();
  const { allBanks } = useGetAllBanks();
  const { allLayout } = useGetAllLayouts();
  const { allTemplates } = useGetAllTemplates({
    waitForRequest: endRequestExistingPaymentMethod,
    idListOnScreen: existingPaymentMethod?.templateUuid,
  });
  const { allTransmissionChannel } = useGetAllTransmissionChannel();

  const [isLoading, setIsLoading] = useState(false);
  const [showAUTOMATIC_DEBIT, setShowAUTOMATIC_DEBIT] = useState(true);
  const [showBOLETO, setShowBOLETO] = useState(true);
  const [showTHIRD_PARTY_DEBIT, setShowTHIRD_PARTY_DEBIT] = useState(true);

  const onSubmitToCreatePaymentMethod = async (data: IPaymentMethodsData) => {
    try {
      setIsLoading(true);
      const response = await NewPaymentMethod(data);
      toast({
        title: "Criação efetuada.",
        description: response.body?.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
      navigate("/paymentmethodslist");
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const onSubmitToUpdatePaymentMethod = async (data: IUpdatePaymentMethod) => {
    const validateDeletedAt = allTemplates?.find(
      ({ uuid, deletedAt }) => uuid === data.templateUuid && deletedAt !== null
    );

    if (validateDeletedAt) {
      toast({
        title: "Error",
        description: `${validateDeletedAt.name} está inativo, selecione outro serviço ou contate o administrador`,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } else {
      try {
        setIsLoading(true);

        const response = await UpdatePaymentMethod({
          ...data,
          uuid: paymentMethodUUID ?? "",
        });

        toast({
          title: "Atualização efetuada.",
          description: response.body?.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
        navigate("/paymentmethodslist");
      } catch (error: any) {
        toast({
          title: "Ocorreu um problema.",
          description:
            error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (paymentMethodUUID) {
      if (
        existingPaymentMethod &&
        existingPaymentMethod.paymentType.name === "AUTOMATIC_DEBIT"
      ) {
        setShowBOLETO(false);
        setShowTHIRD_PARTY_DEBIT(false);
      } else if (
        existingPaymentMethod &&
        existingPaymentMethod.paymentType.name === "THIRD_PARTY_DEBIT"
      ) {
        setShowBOLETO(false);
        setShowAUTOMATIC_DEBIT(false);
      } else if (
        existingPaymentMethod &&
        existingPaymentMethod.paymentType.name === "BOLETO"
      ) {
        setShowAUTOMATIC_DEBIT(false);
        setShowTHIRD_PARTY_DEBIT(false);
      }
    }
  }, [paymentMethodUUID, existingPaymentMethod]);

  return (
    <>
      <Tabs variant="unstyled">
        <TabList>
          {showAUTOMATIC_DEBIT && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd>{enumTypeOfPaymentType.AUTOMATIC_DEBIT}</TSmallmd>
            </Tab>
          )}
          {showBOLETO && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd>{enumTypeOfPaymentType.BOLETO}</TSmallmd>
            </Tab>
          )}
          {showTHIRD_PARTY_DEBIT && (
            <Tab
              _selected={{
                borderBottomColor: "blue.500",
                borderBottomWidth: "2px",
                color: "blue.600",
              }}
            >
              <TSmallmd> {enumTypeOfPaymentType.THIRD_PARTY_DEBIT}</TSmallmd>
            </Tab>
          )}
        </TabList>

        <TabPanels>
          {showAUTOMATIC_DEBIT && (
            <TabPanel>
              <AutomaticDebit
                onSubmitToCreatePaymentMethod={onSubmitToCreatePaymentMethod}
                onSubmitToUpdatePaymentMethod={onSubmitToUpdatePaymentMethod}
                paymentMethodUUID={paymentMethodUUID}
                isReadOnly={isReadOnly}
                allTypes={allTypes ?? []}
                existingPaymentMethod={existingPaymentMethod}
                allLayout={allLayout}
                allBanks={allBanks}
                allTemplates={allTemplates}
                allTransmissionChannel={allTransmissionChannel}
              />
            </TabPanel>
          )}
          {showBOLETO && (
            <TabPanel>
              <Boleto
                onSubmitToCreatePaymentMethod={onSubmitToCreatePaymentMethod}
                onSubmitToUpdatePaymentMethod={onSubmitToUpdatePaymentMethod}
                paymentMethodUUID={paymentMethodUUID}
                isReadOnly={isReadOnly}
                allTypes={allTypes ?? []}
                existingPaymentMethod={existingPaymentMethod}
                allLayout={allLayout}
                allTemplates={allTemplates}
                allTransmissionChannel={allTransmissionChannel}
              />
            </TabPanel>
          )}
          {showTHIRD_PARTY_DEBIT && (
            <TabPanel>
              <ThirdPartyDebit
                onSubmitToCreatePaymentMethod={onSubmitToCreatePaymentMethod}
                onSubmitToUpdatePaymentMethod={onSubmitToUpdatePaymentMethod}
                paymentMethodUUID={paymentMethodUUID}
                isReadOnly={isReadOnly}
                allTypes={allTypes ?? []}
                existingPaymentMethod={existingPaymentMethod}
                allLayout={allLayout}
                allTemplates={allTemplates}
                allTransmissionChannel={allTransmissionChannel}
              />
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
      {isLoading && <Backdrop />}
    </>
  );
};
