import {
  Button,
  Flex,
  GridItem,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import {
  clearDataRecords,
  clearDataStructure,
  getQtyRecords,
  getQtyStructure,
  IResponseClearData,
} from "../service/clearData";

export const ClearData = ({ permited }: { permited: boolean }) => {
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [dataRecords, setDataRecords] = useState<Array<IResponseClearData>>();
  const [dataStructure, setDataStructure] =
    useState<Array<IResponseClearData>>();

  const updateQty = async () => {
    const responseRecords = await getQtyRecords();
    const responseStructure = await getQtyStructure();
    if (responseRecords.type === "success" && responseRecords.body) {
      setDataRecords(responseRecords.body);
    }
    if (responseStructure.type === "success" && responseStructure.body) {
      setDataStructure(responseStructure.body);
    }
  };

  const handleClickRecords = async (nameToClear: string) => {
    setIsLoading(true);
    try {
      const response = await clearDataRecords({ nameToClear });

      if (response.type === "success") {
        toast({
          title: "Base limpa.",
          description: response.body?.message,
          status: "success",
          duration: 10000,
          isClosable: true,
        });
        updateQty();
      }
      setIsLoading(false);
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  const handleClickStructure = async (nameToClear: string) => {
    setIsLoading(true);
    try {
      const response = await clearDataStructure({ nameToClear });

      if (response.type === "success") {
        toast({
          title: "Base limpa.",
          description: response.body?.message,
          status: "success",
          duration: 10000,
          isClosable: true,
        });
        updateQty();
      }
      setIsLoading(false);
    } catch (error: any) {
      toast({
        title: "Ocorreu um problema.",
        description:
          error?.body?.message ?? DEFAULT_ERROR_MESSAGES.MANAGE_ACTION,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    async function fetchData() {
      await updateQty();
    }
    fetchData();
  }, []);

  return (
    <Wrapper>
      <GridItem rowSpan={1} colSpan={4}>
        {permited && (
          <>
            <HLarge2xl mb="16px" color="gray.600">
              Limpar base de dados
            </HLarge2xl>
            <Flex justifyContent="space-between" alignItems="center" />
            <Flex flexDir="column">
              <Text my="16px" fontWeight="bold" color="gray.600">
                Clique nos botões para limpar a base de dados do modulo
                desejado:
              </Text>
              <TableContainer>
                <Table size="sm" variant="striped">
                  <Thead>
                    <Tr>
                      <Td>Módulo</Td>
                      <Td>Relacionamentos serão deletados juntos</Td>
                      <Td>
                        Quantidade de linhas na tabela (incluindo deletadas)
                      </Td>
                      <Td textAlign="center">Limpar</Td>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {dataRecords?.map((item) => (
                      <Tr key={item.orn}>
                        <Td>{item.orn} </Td>
                        <Td>{item.msn} </Td>
                        <Td textAlign="center">{item.qty} </Td>
                        <Td>
                          <Button
                            my="0"
                            size="sm"
                            isLoading={isLoading}
                            onClick={() => handleClickRecords(item.identifier)}
                            w="300px"
                          >
                            Limpar {item.orn}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                    {dataStructure?.map((item) => (
                      <Tr key={item.orn}>
                        <Td>{item.orn} </Td>
                        <Td>{item.msn} </Td>
                        <Td textAlign="center">{item.qty} </Td>
                        <Td>
                          <Button
                            my="0"
                            size="sm"
                            isLoading={isLoading}
                            onClick={() =>
                              handleClickStructure(item.identifier)
                            }
                            w="300px"
                          >
                            Limpar {item.orn}
                          </Button>
                        </Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            </Flex>
          </>
        )}
      </GridItem>
    </Wrapper>
  );
};
