import {
  Box,
  FormControl,
  InputGroup,
  InputRightElement,
  Select,
  Td,
  Tooltip,
} from "@chakra-ui/react";
import { memo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { InputMask } from "../../../../../main/components/InputMask";
import { useTableFormRowHandleErrors } from "../../../../../main/hooks/useTableFormRowHandleErrors";
import { IServiceProvisionOption } from "../../../../../types/main/serviceProvision";
import { useAliquotsHiddenListOptions } from "./useAliquotsHiddenListOption";

interface IRowForm {
  servicesList: IServiceProvisionOption[];
  index: number;
  isReadOnly?: boolean;
}

export const AliquotsRowForm = memo(
  ({ servicesList, index, isReadOnly }: IRowForm) => {
    const {
      register,
      watch,
      setValue,
      formState: { errors },
    } = useFormContext();

    const { isThisServiceAlreadyChosen } = useAliquotsHiddenListOptions({
      index,
      watch,
    });

    const { getErrorMessage, hasError } = useTableFormRowHandleErrors({
      errors,
      index,
    });

    const simple_national = watch("simple_national");

    useEffect(() => {
      if (simple_national) {
        setValue(`servicesPerProvider[${index}].cofins`, undefined);
        setValue(`servicesPerProvider[${index}].pis`, undefined);
        setValue(`servicesPerProvider[${index}].iss`, undefined);
        setValue(`servicesPerProvider[${index}].csll`, undefined);
        setValue(`servicesPerProvider[${index}].ir`, undefined);
      } else {
        setValue(`servicesPerProvider[${index}].unique_aliquot`, undefined);
      }
    }, [index, setValue, simple_national]);

    return (
      <>
        <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
          <FormControl
            isInvalid={hasError("servicesPerProvider", "serviceProvisionUuid")}
          >
            <Tooltip
              hasArrow
              bg="red.500"
              label={getErrorMessage(
                "servicesPerProvider",
                "serviceProvisionUuid"
              )}
              placement="bottom"
              fontSize="small"
            >
              <Select
                placeholder="-"
                disabled={isReadOnly}
                _disabled={{
                  cursor: "default",
                  color: "inherit",
                  opacity: "inherit",
                }}
                {...register(
                  `servicesPerProvider[${index}].serviceProvisionUuid`
                )}
              >
                {servicesList &&
                  servicesList.map((serviceProvision) => (
                    <Box
                      as="option"
                      key={serviceProvision.uuid}
                      value={serviceProvision.uuid}
                      hidden={isThisServiceAlreadyChosen(serviceProvision.uuid)}
                      color={serviceProvision.deletedAt ? "red.500" : "black"}
                    >
                      {serviceProvision.deletedAt
                        ? `(inativo) ${serviceProvision.name}`
                        : serviceProvision.name}
                    </Box>
                  ))}
              </Select>
            </Tooltip>
          </FormControl>
        </Td>
        {simple_national ? (
          <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
            <FormControl
              isInvalid={hasError("servicesPerProvider", "unique_aliquot")}
            >
              <Tooltip
                hasArrow
                bg="red.500"
                label={getErrorMessage("servicesPerProvider", "unique_aliquot")}
                placement="bottom"
                fontSize="small"
              >
                <Box>
                  <InputGroup>
                    <InputMask
                      isReadOnly={isReadOnly}
                      size="md"
                      format="##.##"
                      fieldname={`servicesPerProvider[${index}].unique_aliquot`}
                      saveMaskedValue
                      beforeReceiveValue={(value) => {
                        if (!value) return "";
                        return value.replace(".", "");
                      }}
                    />
                    <InputRightElement
                      borderLeft="1px solid"
                      borderColor="gray.300"
                    >
                      %
                    </InputRightElement>
                  </InputGroup>
                </Box>
              </Tooltip>
            </FormControl>
          </Td>
        ) : (
          <>
            <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
              <FormControl
                isInvalid={hasError("servicesPerProvider", "cofins")}
              >
                <Tooltip
                  hasArrow
                  bg="red.500"
                  label={getErrorMessage("servicesPerProvider", "cofins")}
                  placement="bottom"
                  fontSize="small"
                >
                  <Box>
                    <InputGroup>
                      <InputMask
                        isReadOnly={isReadOnly}
                        size="md"
                        format="##.##"
                        fieldname={`servicesPerProvider[${index}].cofins`}
                        saveMaskedValue
                        beforeReceiveValue={(value) => {
                          if (!value) return "";
                          return value.replace(".", "");
                        }}
                      />
                      <InputRightElement
                        borderLeft="1px solid"
                        borderColor="gray.300"
                      >
                        %
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Tooltip>
              </FormControl>
            </Td>
            <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
              <FormControl isInvalid={hasError("servicesPerProvider", "pis")}>
                <Tooltip
                  hasArrow
                  bg="red.500"
                  label={getErrorMessage("servicesPerProvider", "pis")}
                  placement="bottom"
                  fontSize="small"
                >
                  <Box>
                    <InputGroup>
                      <InputMask
                        isReadOnly={isReadOnly}
                        size="md"
                        format="##.##"
                        fieldname={`servicesPerProvider[${index}].pis`}
                        saveMaskedValue
                        beforeReceiveValue={(value) => {
                          if (!value) return "";
                          return value.replace(".", "");
                        }}
                      />
                      <InputRightElement
                        borderLeft="1px solid"
                        borderColor="gray.300"
                      >
                        %
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Tooltip>
              </FormControl>
            </Td>
            <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
              <FormControl isInvalid={hasError("servicesPerProvider", "iss")}>
                <Tooltip
                  hasArrow
                  bg="red.500"
                  label={getErrorMessage("servicesPerProvider", "iss")}
                  placement="bottom"
                  fontSize="small"
                >
                  <Box>
                    <InputGroup>
                      <InputMask
                        isReadOnly={isReadOnly}
                        size="md"
                        format="##.##"
                        fieldname={`servicesPerProvider[${index}].iss`}
                        saveMaskedValue
                        beforeReceiveValue={(value) => {
                          if (!value) return "";
                          return value.replace(".", "");
                        }}
                      />
                      <InputRightElement
                        borderLeft="1px solid"
                        borderColor="gray.300"
                      >
                        %
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Tooltip>
              </FormControl>
            </Td>
            <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
              <FormControl isInvalid={hasError("servicesPerProvider", "csll")}>
                <Tooltip
                  hasArrow
                  bg="red.500"
                  label={getErrorMessage("servicesPerProvider", "csll")}
                  placement="bottom"
                  fontSize="small"
                >
                  <Box>
                    <InputGroup>
                      <InputMask
                        isReadOnly={isReadOnly}
                        size="md"
                        format="##.##"
                        fieldname={`servicesPerProvider[${index}].csll`}
                        saveMaskedValue
                        beforeReceiveValue={(value) => {
                          if (!value) return "";
                          return value.replace(".", "");
                        }}
                      />
                      <InputRightElement
                        borderLeft="1px solid"
                        borderColor="gray.300"
                      >
                        %
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Tooltip>
              </FormControl>
            </Td>
            <Td width="100%" paddingInlineStart="5px" paddingInlineEnd="5px">
              <FormControl isInvalid={hasError("servicesPerProvider", "ir")}>
                <Tooltip
                  hasArrow
                  bg="red.500"
                  label={getErrorMessage("servicesPerProvider", "ir")}
                  placement="bottom"
                  fontSize="small"
                >
                  <Box>
                    <InputGroup>
                      <InputMask
                        isReadOnly={isReadOnly}
                        size="md"
                        format="##.##"
                        fieldname={`servicesPerProvider[${index}].ir`}
                        saveMaskedValue
                        beforeReceiveValue={(value) => {
                          if (!value) return "";
                          return value.replace(".", "");
                        }}
                      />
                      <InputRightElement
                        borderLeft="1px solid"
                        borderColor="gray.300"
                      >
                        %
                      </InputRightElement>
                    </InputGroup>
                  </Box>
                </Tooltip>
              </FormControl>
            </Td>
          </>
        )}
      </>
    );
  }
);
