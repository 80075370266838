import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { ITemplates } from "../../../../types/main/templates";
import { getAllTemplates } from "../../Templates/service/TemplateService";

export const useGetAllTemplates = ({
  waitForRequest,
  idListOnScreen,
}: {
  waitForRequest: boolean;
  idListOnScreen?: string | null;
}) => {
  const toast = useToast();

  const [allTemplates, setAllTemplates] = useState<Array<ITemplates>>();

  const updateTransmissionChannel = useCallback(async () => {
    if (waitForRequest) {
      try {
        const response = await getAllTemplates({
          return_even_if_deleted: idListOnScreen,
        });
        if (response.type === "success" && response.body) {
          const list = response.body.map(
            ({
              emailBody,
              name,
              recipients,
              title,
              uuid,
              deletedAt,
              sender,
              replyTo,
            }) => ({
              emailBody,
              name,
              recipients,
              title,
              uuid,
              deletedAt,
              sender,
              replyTo,
            })
          );
          setAllTemplates(list);
        }
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waitForRequest]);

  useEffect(() => {
    updateTransmissionChannel();
  }, [updateTransmissionChannel]);

  return { allTemplates };
};
