import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";

import { useTableContext } from "../../context/useTableContext";

interface ITableContainer {
  children: ReactNode;
  pageCount: number;
}

export const TableContainer = ({ children, pageCount }: ITableContainer) => {
  const { isFilterOpen } = useTableContext();

  return (
    <Box
      className="data-table"
      overflowX={isFilterOpen ? "hidden" : "auto"}
      position="relative"
      maxW="calc(100vw - (160px + 160px) )"
      border="1px solid #ddd"
      borderBottom={pageCount === 0 ? "none" : "1px solid #ddd"}
      borderRadius="5px"
      borderBottomRadius={pageCount === 0 ? "none" : "5px"}
    >
      {children}
    </Box>
  );
};
