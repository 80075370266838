import { DataTable } from "../../../../../../main/components/DataTable";
import {
  PopoverReportList,
  PopoverReportListProps,
} from "../../PopoverReportList";
import { ReceiptsFilters } from "./Filters";
import { useReceipts } from "./useReceipts";

const Popover = (props: PopoverReportListProps) => (
  <PopoverReportList {...props} exportFileName="Recebimentos" />
);

export const Receipts = () => {
  const { columns, report, loadReport } = useReceipts();

  return (
    <>
      <ReceiptsFilters loadReport={loadReport} />
      <DataTable
        module="Channels" // ! mudar
        isLoading={report.isFetching}
        columns={columns}
        data={report.data}
        popup={{
          RenderComponent: Popover,
          urlEdit: "not-required",
          urlView: "not-required",
          hideInHeader: true,
        }}
      />
    </>
  );
};
