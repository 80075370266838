import { useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";

import { DEFAULT_ERROR_MESSAGES } from "../../../../main/common/constants/defaultMessages";
import { IClient } from "../../../../types/main/client";
import { GetClientByUUID } from "../service/ClientService";

interface IUseExistingClient {
  clientUUID?: string;
}

export const useExistingClient = ({ clientUUID }: IUseExistingClient) => {
  const toast = useToast();

  const [existingClient, setExistingClient] = useState<IClient | undefined>();

  const loadExistingClient = useCallback(
    async (uuid: string) => {
      try {
        const response = await GetClientByUUID(uuid);

        setExistingClient(response.body ?? undefined);
      } catch (error: any) {
        toast({
          title: "Não encontrado.",
          description: DEFAULT_ERROR_MESSAGES.LIST_ACTION,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [toast]
  );

  useEffect(() => {
    if (clientUUID) {
      loadExistingClient(clientUUID);
    }
  }, [clientUUID, loadExistingClient]);

  return {
    existingClient,
  };
};
