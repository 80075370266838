import { IContractsData } from "../../../../types/main/contract";
import { IContractsDataModified } from "../components/ContractForm";

export const convertHookFormTypeToAPItype = (
  data: IContractsDataModified
): IContractsData => {
  return {
    ...data,
    juros: Number(data.juros),
    multa: Number(data.multa),
  };
};
