import * as yup from "yup";

export const channelInvoiceSchema = yup
  .object({
    isInvoiceConfigInherited: yup
      .boolean()
      .required("Esse campo é obrigatório"),
    invoiceConfigs: yup.object().when("isInvoiceConfigInherited", {
      is: true,
      then: yup.object().notRequired(),
      otherwise: yup
        .object()
        .shape({
          fontUuid: yup.string().required("Esse campo é obrigatório"),
          headerInformation: yup.string().required("Esse campo é obrigatório"),
          developerCompany: yup.string().required("Esse campo é obrigatório"),
          color: yup.string().required("Esse campo é obrigatório"),
          commissionDescription: yup.string(),
          footerText: yup.string().required("Esse campo é obrigatório"),
          headerImage: yup.string().required("Esse campo é obrigatório"),
          footerBackgroundImage: yup
            .string()
            .nullable()
            .required("Esse campo é obrigatório"),
          promotionalImage: yup.string().nullable(),
          footerLeftImage: yup.string().nullable(),
          footerRightImage: yup.string().nullable(),
        })
        .required("Esse campo é obrigatório"),
    }),
  })
  .required();
