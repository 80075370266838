import {
  Divider,
  Spinner,
  Stat,
  StatGroup,
  StatHelpText,
  StatLabel,
  StatNumber,
} from "@chakra-ui/react";
import { IStatsView } from "../../interface/statsView.interface";

export const StatsView = ({
  totalValue,
  totalDiscount,
  isLoading,
}: IStatsView) => {
  const value = Number(totalValue);
  const discount = Number(totalDiscount) ?? 0;

  const calculateDiscountPercentage = () => {
    const percentage = (discount / value) * 100;

    return `${percentage.toFixed(3)}%`;
  };

  return (
    <StatGroup
      display="flex"
      justifyContent="center"
      width={400}
      margin="auto"
      gap={5}
    >
      <Stat textAlign="right">
        <StatLabel>Valor Total</StatLabel>
        <StatNumber color="#2C58A4">
          {isLoading ? (
            <Spinner variant="#2C58A4" />
          ) : (
            value.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          )}
        </StatNumber>
        <StatHelpText>
          {isLoading ? <Spinner color="#2C58A4" /> : "100%"}
        </StatHelpText>
      </Stat>

      <Divider
        orientation="vertical"
        borderColor="#2C58A4"
        size="lg"
        height={100}
      />

      <Stat>
        <StatLabel>Desconto Total</StatLabel>
        <StatNumber color="#2C58A4">
          {isLoading ? (
            <Spinner color="#2C58A4" />
          ) : (
            discount.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          )}
        </StatNumber>
        <StatHelpText>
          {isLoading ? (
            <Spinner color="#2C58A4" />
          ) : (
            calculateDiscountPercentage()
          )}
        </StatHelpText>
      </Stat>
    </StatGroup>
  );
};
