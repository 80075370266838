import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography/HeadingLarge";
import { Wrapper } from "../../../../main/components/Wrapper";
import { WebhooksForm } from "../components/webHooksForm.ts";

interface IServiceProviderManager {
  isReadOnly?: boolean;
}

export const WebHooksManager = ({ isReadOnly }: IServiceProviderManager) => {
  const { id } = useParams();

  const isEditing = !!id && !isReadOnly;

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Webhooks", href: "/webhooks" },
    {
      description: isReadOnly
        ? "Visualizar Webhooks"
        : isEditing
        ? "Editar Webhooks"
        : "Cadastrar Webhooks",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex>
        <HLarge2xl mb="32px" color="gray.600">
          {isReadOnly
            ? "Visualizar Webhooks"
            : isEditing
            ? "Editar Webhooks"
            : "Cadastrar Webhooks"}
        </HLarge2xl>
      </Flex>
      <WebhooksForm id={id} isReadOnly={isReadOnly} />
    </Wrapper>
  );
};
