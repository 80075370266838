import { useEffect, useMemo, useState } from "react";

import {
  IColumnDataTable,
  ITableColumns,
} from "../../../../types/main/reports";
import { useMultiCheckboxSelect } from "../../MultiSelect/useMultiSelect";
import { getOnlyCheckedOptionsValueName } from "../functions";

interface IUseTColumnsDisclosure {
  tableColumns: IColumnDataTable[];
}

export const useColumnsDisclosure = ({
  tableColumns,
}: IUseTColumnsDisclosure) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  const columns: ITableColumns[] = useMemo(() => {
    return tableColumns.map((item) => [...item.columns]).flat();
  }, [tableColumns]);

  const { MultiSelectCheckbox, optionsCheckState } = useMultiCheckboxSelect({
    options: columns
      .filter((item) => !item.disableFiltering)
      .map((item) => ({
        label: item.Header,
        value: item.accessor,
        checked: !item.startHidden,
      })),
    isAbleToSelectAll: true,
  });

  useEffect(() => {
    if (optionsCheckState.length > 0)
      setHiddenColumns(getOnlyCheckedOptionsValueName(optionsCheckState));
  }, [optionsCheckState]);

  return {
    SelectableColumns: MultiSelectCheckbox,
    columns: tableColumns,
    hiddenColumns,
  };
};
