import {
  Box,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import { useState } from "react";
import { useParams } from "react-router-dom";

import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { ChannelConfigForm } from "../components/FormSteps/ChannelConfigForm";
import { ChannelEmail } from "../components/FormSteps/ChannelEmail";
import { ChannelERP } from "../components/FormSteps/ChannelERP";
import { ChannelInvoice } from "../components/FormSteps/ChannelInvoice";

interface IChannelManager {
  isReadOnly?: boolean;
}

export const ChannelManager = ({ isReadOnly }: IChannelManager) => {
  const { id } = useParams();

  const isEditing = !!id && !isReadOnly;

  const [tabIndex, setTabIndex] = useState(0);

  function goToChannelConfig() {
    setTabIndex(0);
  }

  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Cadastros e consultas", href: "" },
    { description: "Canal", href: "/channelslist" },
    {
      description: isReadOnly
        ? "Visualizar Canal"
        : isEditing
        ? "Editar Canal"
        : "Cadastrar Canal",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <Flex flexDir="column">
        <HLarge2xl mb="32px" color="gray.600">
          {isReadOnly
            ? "Visualizar Canal"
            : isEditing
            ? "Editar Canal"
            : "Cadastrar Canal"}
        </HLarge2xl>
        <Tabs
          align="end"
          variant="unstyled"
          w="100%"
          index={tabIndex}
          onChange={(index) => setTabIndex(index)}
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb="2em"
          >
            <TabList alignItems="flex-start" display="flex">
              <Tab
                _selected={{
                  borderBottomColor: "blue.500",
                  borderBottomWidth: "2px",
                }}
              >
                Configurações do Canal
              </Tab>
              <Tab
                _selected={{
                  borderBottomColor: "blue.500",
                  borderBottomWidth: "2px",
                }}
              >
                Gestão de e-mails
              </Tab>
              <Tab
                _selected={{
                  borderBottomColor: "blue.500",
                  borderBottomWidth: "2px",
                }}
              >
                Layout de fatura
              </Tab>
              <Tab
                _selected={{
                  borderBottomColor: "blue.500",
                  borderBottomWidth: "2px",
                }}
              >
                Integração ERP
              </Tab>
            </TabList>
          </Box>
          <TabPanels>
            <TabPanel>
              <ChannelConfigForm channelId={id} isReadOnly={isReadOnly} />
            </TabPanel>
            <TabPanel>
              <ChannelEmail
                channelId={id}
                isReadOnly={isReadOnly}
                goBackToConfigTab={goToChannelConfig}
              />
            </TabPanel>
            <TabPanel>
              <ChannelInvoice
                channelId={id}
                isReadOnly={isReadOnly}
                goBackToConfigTab={goToChannelConfig}
              />
            </TabPanel>
            <TabPanel>
              <ChannelERP
                channelId={id}
                isReadOnly={isReadOnly}
                goBackToConfigTab={goToChannelConfig}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </Wrapper>
  );
};
