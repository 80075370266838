import { Routes, Route } from "react-router-dom";

import { LogsList } from "./page/LogsListing";

export function LogsModule() {
  return (
    <Routes>
      <Route path="/logs" element={<LogsList />} />
    </Routes>
  );
}
