import { useEffect, useState } from "react";
import { TreeData } from "react-dropdown-tree-select";

import { getChannelOptionsTree } from "../../../../Channels/services/ChannelService";

export const useReceiptsFilters = () => {
  const [channels, setChannels] = useState<TreeData[]>([]);

  const [isLoading, setIsLoading] = useState(false);

  const loadChannels = async () => {
    const response = await getChannelOptionsTree();
    setChannels(response.body ?? []);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        await loadChannels();
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  return {
    channels,
    isFetching: isLoading,
    setChannels,
  };
};
