import {
  CustomBreadcrumb,
  IBreadcrumbProps,
} from "../../../../main/components/CustomBreadcrumb";
import { HLarge2xl } from "../../../../main/components/Tipography";
import { Wrapper } from "../../../../main/components/Wrapper";
import { RemittanceForm } from "../components/RemittanceForm";

export const RemittanceManager = () => {
  const breadcrumb: IBreadcrumbProps[] = [
    { description: "Remessas", href: "" },
    { description: "Lista de Remessas", href: "/remittancelist" },
    {
      description: "Cadastrar Remessas",
      href: "",
    },
  ];

  return (
    <Wrapper>
      <CustomBreadcrumb breadcrumb={breadcrumb} />
      <HLarge2xl color="gray.600" mb="1em">
        Cadastrar Remessas
      </HLarge2xl>

      <RemittanceForm />
    </Wrapper>
  );
};
