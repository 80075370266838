import { ViewIcon } from "@chakra-ui/icons";
import {
  Input,
  InputGroup,
  InputProps,
  InputRightElement,
} from "@chakra-ui/react";
import { forwardRef, useState } from "react";

import { useInterstarTheme } from "../../hooks/useInterstarTheme";

type InputPasswordProps = {
  readOnly?: boolean;
} & InputProps;

export const InputPassword = forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ readOnly, ...inputProps }, ref) => {
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);

    const { colors } = useInterstarTheme();

    return (
      <InputGroup size="md">
        <Input
          pr="4.5rem"
          type={show ? "text" : "password"}
          placeholder="Enter password"
          autoComplete="off"
          readOnly={readOnly}
          ref={ref}
          {...inputProps}
        />
        <InputRightElement width="5rem">
          <ViewIcon
            w={4}
            h={4}
            color={colors.blueInterstar}
            onClick={handleClick}
            cursor="pointer"
          />
        </InputRightElement>
      </InputGroup>
    );
  }
);
