import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import { Link } from "react-router-dom";
import { Backdrop } from "../../../../../main/components/Backdrop";
import { InputEditor } from "../../../../../main/components/InputEditor";
import { InputTag } from "../../../../../main/components/InputTag";
import { TLargelg } from "../../../../../main/components/Tipography";
import { ITemplatesData } from "../../../../../types/main/templates";
import { useExistingTemplate } from "../../hooks/useExistingTemplate";
import { useTemplateForm } from "../../hooks/useTemplateForm";
import { templateSchema } from "../../validation/templateSchema";

interface ITemplateForm {
  templateUUID?: string;
  isReadOnly?: boolean;
}

export const TemplateForm = ({ templateUUID, isReadOnly }: ITemplateForm) => {
  const formMethods = useForm<ITemplatesData>({
    resolver: yupResolver(templateSchema),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = formMethods;

  const { existingTemplate } = useExistingTemplate(templateUUID);

  const { isLoading, onSubmit } = useTemplateForm({
    reset,
    templateToUpdateUUID: templateUUID,
  });

  useEffect(() => {
    if (existingTemplate) {
      setValue("name", existingTemplate.name);
      setValue("title", existingTemplate.title);
      setValue("recipients", existingTemplate.recipients);
      setValue("sender", existingTemplate.sender);
      setValue("replyTo", existingTemplate.replyTo);
      setValue("emailBody", existingTemplate.emailBody);
    }
  }, [existingTemplate, setValue]);

  return (
    <FormProvider {...formMethods}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Grid templateRows="(2, 1fr)" templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.name}>
              <FormLabel htmlFor="name">Nome do template</FormLabel>
              <Input isReadOnly={isReadOnly} id="name" {...register("name")} />
              <FormErrorMessage>
                {errors.name && errors.name?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.sender}>
              <FormLabel htmlFor="name">Remetente</FormLabel>
              <Input
                isReadOnly={isReadOnly}
                id="sender"
                {...register("sender")}
              />
              <FormErrorMessage>
                {errors.sender && errors.sender?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.title}>
              <FormLabel htmlFor="title">Título do email</FormLabel>
              <Input
                id="title"
                isReadOnly={isReadOnly}
                type="text"
                {...register("title")}
              />
              <FormErrorMessage>
                {errors.title && errors.title.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid templateRows="(2, 1fr)" templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem colSpan={1}>
            <FormControl isInvalid={!!errors.replyTo}>
              <FormLabel htmlFor="replyTo">Responder para</FormLabel>
              <Input
                id="replyTo"
                isReadOnly={isReadOnly}
                type="text"
                {...register("replyTo")}
              />
              <FormErrorMessage>
                {errors.replyTo && errors.replyTo.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem colSpan={2}>
            <FormControl isInvalid={!!errors.recipients}>
              <FormLabel htmlFor="recipients">Destinatários</FormLabel>
              <InputTag
                id="recipients"
                fieldname="recipients"
                isReadOnly={isReadOnly}
              />
              <FormErrorMessage>
                {errors.recipients &&
                  Array.isArray(errors.recipients) &&
                  errors.recipients?.find((item: any) => item?.message)
                    ?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Grid
          h="300px"
          templateRows="(2, 1fr)"
          templateColumns="repeat(3, 1fr)"
          gap={4}
        >
          <GridItem colSpan={4}>
            <FormControl isInvalid={!!errors.emailBody}>
              <FormLabel htmlFor="emailBody">
                <TLargelg>Corpo do Email</TLargelg>
              </FormLabel>
              <InputEditor
                fieldname="emailBody"
                isReadOnly={isReadOnly}
                editorFeatures="all"
              />
              <FormErrorMessage mt="50px">
                {errors.emailBody && errors.emailBody.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        </Grid>
        <Flex w="100%" justify="flex-end" pb="1rem">
          <ButtonGroup spacing="58" alignItems="center" mt="12rem" mb="2rem">
            <Link to="/templatelist">
              <Button variant="link" color="gray.700">
                Cancelar
              </Button>
            </Link>
            {!isReadOnly && (
              <Button
                backgroundColor="blue.500"
                type="submit"
                width="214px"
                isLoading={isSubmitting}
                ml="32px"
              >
                {existingTemplate ? "Atualizar template" : "Cadastrar template"}
              </Button>
            )}
          </ButtonGroup>
        </Flex>
      </form>
      {isLoading && <Backdrop />}
    </FormProvider>
  );
};
