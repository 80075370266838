import { ArrowDownIcon } from "@chakra-ui/icons";
import {
  Button,
  ButtonProps,
  Divider,
  Flex,
  FlexProps,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { format } from "date-fns";
import { useMemo, useState } from "react";

import { IPopoverListProps } from "../../../../../../main/components/DataTable/IDataTable";
import { TSmallmd } from "../../../../../../main/components/Tipography";
import {
  IBlobError,
  useDownloadFile,
} from "../../../../../../main/hooks/useDownloadFile";
import { IProcessingRemittanceView } from "../../../../../../types/main/processingRemittance";

const ButtonCommonProps: FlexProps & ButtonProps = {
  mt: "2",
  flexDir: "row",
  justifyContent: "flex-start",
  gap: "2",
  alignItems: "center",
  color: "#718096",
  as: Button,
  variant: "ghost",
  _hover: { bg: "gray.50" },
  padding: "0",
  py: "6px",
  paddingInline: "6",
};

export const ProcessingPopover: React.FC<IPopoverListProps> = ({
  uuid,
  isHeader,
  hideInHeader,
  data,
}) => {
  const toast = useToast();
  const { onOpen, onClose, isOpen } = useDisclosure();

  const [isLoading, setIsLoading] = useState(false);

  const processing = useMemo(() => {
    return data?.find((item) => item.id === uuid);
  }, [data, uuid]) as IProcessingRemittanceView | undefined;

  const preDownloading = () => {
    setIsLoading(true);
    onClose();
  };
  const postDownloading = () => setIsLoading(false);

  const onErrorDownloadFile = async (error: IBlobError) => {
    const errorMessage =
      error.response?.data?.message ?? "Houve um problema ao baixar arquivo.";

    toast({
      title: "Serviço indisponível.",
      description: errorMessage,
      status: "error",
      duration: 3000,
      isClosable: true,
    });
    setIsLoading(false);
  };

  const { downloadFile } = useDownloadFile({
    apiDefinition: {
      endpoint: `/processingremittances/processing/list/${uuid}`,
      responseType: "text",
    },
    preDownloading,
    postDownloading,
    onError: onErrorDownloadFile,
    filename: `Rem_${
      processing?.remittances?.join("-") ?? uuid
    }_INTERSTAR_${format(new Date(), "dd-MM-yyyy")}`,
    extension: "txt",
  });

  if (hideInHeader && isHeader) return null;

  return (
    <Popover placement="left" isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      {() => (
        <>
          <PopoverTrigger>
            <Button
              colorScheme="gray"
              variant="outline"
              border="0"
              color="gray.700"
              _hover={{ bg: "gray.200" }}
              disabled={isLoading}
              _disabled={{ bg: "gray.200" }}
            >
              {isLoading ? (
                <Spinner
                  thickness="4px"
                  speed="2s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="sm"
                />
              ) : (
                "..."
              )}
            </Button>
          </PopoverTrigger>
          <Portal>
            <PopoverContent width="242px">
              <PopoverBody px="0" mt="14px" pt="14px">
                {!isHeader && (
                  <Flex flexDir="column">
                    <>
                      <Flex {...ButtonCommonProps} onClick={downloadFile}>
                        <ArrowDownIcon />
                        <TSmallmd fontWeight="medium">
                          Baixar Arquivo (.txt)
                        </TSmallmd>
                      </Flex>

                      <Divider my="5px" ml="12px" />
                    </>
                    <PopoverCloseButton />
                  </Flex>
                )}
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </>
      )}
    </Popover>
  );
};
