import { Button } from "@chakra-ui/react";
import { BsFillArrowUpRightCircleFill } from "react-icons/bs";

export const ButtonLinkNfe = ({ value }: { value: string }) => {
  if (!value) return <> </>;
  return (
    <a href={`${value}`} target="_blank" rel="noreferrer">
      <Button
        iconSpacing={0}
        leftIcon={<BsFillArrowUpRightCircleFill />}
        colorScheme="blue"
      />
    </a>
  );
};
